import {RoutePaths, useRoutePathMatch} from "../../app-router";
import styles from './driver-day-view.module.css'
import moment, {Moment} from "moment";
import {useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {useApiClientContext} from "../../hooks/api-client-context";
import {DriverC2ApiClient, DriverDaySlot, Shift, ShiftStatus} from "../../client/c2-api-client";
import {DayLane, DayLaneShift} from "../../components/lanes/day-lane/day-lane";
import {DaySideBar} from "../../components/lanes/day-side-bar/day-side-bar";
import {Button} from "../../components/button/Button";
import {CheckboxInput} from "../../components/input/checkbox/CheckboxInput";
import {InputHook, useInput} from "../../hooks/input";
import {CommentIcon} from "../../icons/comment";
import {ReceiptIcon} from "../../icons/receipt";
import {OvernightIcon} from "../../icons/overnight";
import {Plus} from "../../icons/plus";
import {DayHeader} from "../../components/day-header/day-header";
import {Validators} from "../../validators";
import {TimeInput} from "../../components/input/time/time-input";
import {useRoutingContext} from "../../hooks/routing-context";
import {shiftService} from "../../services/shift/shift-service";
import {timeService} from "../../services/time-service";
import {useLocation} from "react-router-dom";
import {slotService} from "../../services/slot-service";

type AllShiftsSetParams = {
    date: Moment,
    allSlots: DriverDaySlot[],
    setShifts: (p: {loading: boolean, data: DayLaneShift[]}) => void,
    setDailySlot: (d: DriverDaySlot) => void,
    setSelectedShift: (sh?: Shift) => void,
    selectedShiftId?: string | undefined | null
}
const allShiftsData = ({allSlots, date, selectedShiftId, setDailySlot, setSelectedShift, setShifts}: AllShiftsSetParams) => {
    const partitioned = slotService.partitionByDate(allSlots)
    setShifts({
        loading: false, data: partitioned.shiftsByDate[timeService.formatDate(date)] ?? []})
    setDailySlot(partitioned.slotsByDate[timeService.formatDate(date)]!)
    setSelectedShift(selectedShiftId ? partitioned.allShifts.filter(sh => sh.id === selectedShiftId)[0] : partitioned.allShifts.length === 1 ? partitioned.allShifts[0] : undefined)

}
export function DriverDayView() {
    const dateParam = useRoutePathMatch(RoutePaths.DAY_VIEW)
    const location = useLocation()
    const shiftId = new URLSearchParams(location.search).get('shiftId')
    const apiClient = useApiClientContext().asOrFail(DriverC2ApiClient)
    const routingContext = useRoutingContext()
    const date = useMemo(() => {
        return dateParam ? moment(dateParam.params.date, 'YYYY-MM-DD') : moment()
    }, [dateParam?.params.date])

    const [shifts, setShifts] = useState<{ loading: boolean, data?: DayLaneShift[] }>({loading: true})
    const [dailySlot, setDailySlot] = useState<DriverDaySlot>()
    const [selectedShift, setSelectedShift] = useState<Shift>()
    useEffect(() => {
        setShifts({loading: true})
        apiClient.getDriverSlots({
            startDate: moment(date).subtract(1, 'days').format('YYYY-MM-DD'),
            endDate: date.format('YYYY-MM-DD')
        })
            .then(shiftsRes => {
                allShiftsData({
                    allSlots: shiftsRes,
                    date,
                    selectedShiftId: shiftId,
                    setDailySlot,
                    setSelectedShift,
                    setShifts
                })
            })
    }, [dateParam?.params.date, shiftId ?? ''])

    useEffect(() => {
        if (shifts.loading) return
        if (selectedShift?.status === 'ended') {
            routingContext.replace(RoutePaths.SHIFT_RESOURCES.getPath({
                date: timeService.formatDate(date),
                shiftId: selectedShift.id
            }))
        }
    }, [shifts.loading, selectedShift?.status ?? ''])
    const dayBack = () => {
        const newDate = moment(date).subtract(1, 'days')
        routingContext.navigate(RoutePaths.DAY_VIEW.getPath({date: newDate.format('YYYY-MM-DD')}))
    }
    const dayForward = () => {
        const newDate = moment(date).add(1, 'days')
        routingContext.navigate(RoutePaths.DAY_VIEW.getPath({date: newDate.format('YYYY-MM-DD')}))
    }
    const setStatus = (status: ShiftStatus, time?: string) => {
        if (!selectedShift) return
        const data = selectedShift
        const shiftDate = data.date
        setShifts({loading: true})
        const eventTime = status === 'ended' ?
            (timeService.parseTimestamp(time!).isBefore(timeService.parseTimestamp(data.startedAt!)) ? timeService.parseTimestamp(time!).add(1, 'days') : timeService.parseTimestamp(time!))
            : shiftService.calculateShiftEventTime(shiftDate, time)
        const eventTimeFormatted = timeService.formatTimestamp(eventTime)
        const set = status === 'confirmed' ? apiClient.confirmShift(data.id) :
            status === 'started' ? apiClient.startShift(data.id, eventTimeFormatted) :
                status === 'ended' ? apiClient.endShift(data.id, eventTimeFormatted) : Promise.resolve(data)
        set.then(sh => {
            setShifts({loading: false, data: (shifts.data ?? []).filter(s => s.id !== sh.id).concat([sh])})
            setSelectedShift(sh)
        })
    }

    const overnightInput: InputHook<boolean> = useMemo(() => ({
        valid: true,
        error: null,
        pristine: false,
        submit: () => {
        },
        value: selectedShift?.isOvernight ?? false,
        setValue: (val: boolean) => apiClient.setShiftOvernight(selectedShift?.id ?? '', val).then(() => {
            setSelectedShift({...selectedShift!, isOvernight: val})
        })
    }), [selectedShift?.id ?? '', selectedShift?.isOvernight ?? false])

    const startNewShiftHandler = (time: string) => {
        return apiClient.startShiftAtSite({
            startTime: time,
            siteId: ''
        }).then((_shift) => {
            setShifts({loading: true})
            apiClient.getDriverSlots({
                startDate: moment(date).subtract(1, 'days').format('YYYY-MM-DD'),
                endDate: date.format('YYYY-MM-DD')
            })
                .then(shiftsRes => {
                    allShiftsData({
                        allSlots: shiftsRes,
                        date,
                        selectedShiftId: shiftId,
                        setDailySlot,
                        setSelectedShift,
                        setShifts
                    })
                })
        })
    }
    console.log('loading', shifts.loading, 'data', shifts.data)
    const allowStartShift = !['assigned', 'holiday', 'sick', 'unavailable'].includes(dailySlot?.type ?? '') && ((shifts.data?.length ?? 0) < 1)
    return (
        <div className='flex-fill d-flex flex-column align-items-stretch'>
            <DayHeader date={date} dayBack={dayBack} dayForward={dayForward}/>
            <div className='flex-fill d-flex'>
                <div className={styles.scrollBar}
                     style={{flexBasis: '30%', display: 'flex'}}>
                    <div className={styles.sideBar}><DaySideBar/></div>
                    <div className='flex-fill'><DayLane day={{
                        day: date.format("dd"),
                        date: date.date(),
                        formatted: date.format('YYYY-MM-DD')
                    }} isToday={false} loading={shifts.loading}
                                                        shifts={selectedShift ? (shifts.data?.filter(sh => sh.id === selectedShift.id) ?? []) : (shifts.data ?? [])}
                                                        slot={dailySlot}/></div>
                </div>
                <div className='flex-fill d-flex'>
                    {selectedShift &&
                        <ShiftActions shift={selectedShift} date={date} overnightInput={overnightInput}
                                      startNewShift={startNewShiftHandler}
                                      setStatus={setStatus}/>}
                    {allowStartShift ? (
                        <ShiftActions shift={undefined} date={date} overnightInput={overnightInput}
                                      startNewShift={startNewShiftHandler}
                                      setStatus={setStatus}/>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

function ShiftActions({
                          shift,
                          date,
                          overnightInput,
                          setStatus,
                          startNewShift
                      }: {
    shift?: Shift,
    date: Moment,
    overnightInput: InputHook<boolean>,
    setStatus: (status: ShiftStatus, selectedTime?: string) => void,
    startNewShift: (time: string) => Promise<void>
}) {

    const shiftDate = shift?.date ? moment(shift.date, 'YYYY-MM-DD') : date
    const startLocation = shift?.startLocation
    const startTime = shift?.startTime
    console.log('shift', shift, 'date', date, 'diff', date.isSame(moment(), 'day'))
    return (
        <div className='flex-fill d-flex flex-column' style={{padding: '30px', gap: '30px'}}>
            {shift?.status === 'not_confirmed' &&
                <ConfirmButton startLocation={startLocation!} startTime={startTime!}
                               onClick={() => setStatus('confirmed')}/>}
            {shift?.status === 'confirmed' &&
                <StartButton withTimeSelect={true} shiftDate={shiftDate}
                             onClick={(time) => setStatus('started', time)}/>}
            {shift?.status === 'started' &&
                <EndButton estimatedEndedAt={estimatedShiftEndTime(shift)}
                           startedAt={timeService.parseTimestamp(shift.startedAt!)}
                           onClick={(time) => setStatus('ended', time)}/>}
            {(shift?.status === 'started') && <ShiftResourcesPanel shift={shift} overnightInput={overnightInput}/>}
            {!shift && date.isSame(moment(), 'day') &&
                <StartButton withTimeSelect={false} shiftDate={shiftDate} onClick={startNewShift}/>}
        </div>
    )
}

function estimatedShiftEndTime(shift: Shift): Moment {
    if (shift.status !== 'started') {
        return timeService.parseDate(shift.date)
    } else {
        const startedAt = timeService.parseTimestamp(shift.startedAt!)
        const startTime = timeService.parseTime(shift.startTime)
        const endTime = timeService.parseTime(shift.endTime)
        const estimatedDuration = (endTime.isBefore(startTime) ? moment(endTime).add(1, 'days') : endTime).diff(startTime, 'seconds')
        return startedAt.add(estimatedDuration, 'seconds')
    }
}

function ConfirmButton({
                           onClick,
                           startLocation,
                           startTime
                       }: { onClick: () => void, startTime: string, startLocation: string }) {
    return <>
        <div>
            <div>Location: {startLocation}</div>
            <div>Time: {startTime}</div>
            <Button onClick={onClick} width='fill' size='large' colorScheme='neutral'
                    shape='semi-rounded'>Confirm</Button>
        </div>
    </>
}

function StartButton({onClick, shiftDate, withTimeSelect}: {
    onClick: (time: string) => void,
    shiftDate: Moment,
    withTimeSelect: boolean
}) {
    const [selectTimeStep, setSelectTimeStep] = useState(false)
    const now = moment()
    const timeInput = useInput(moment(shiftDate).hour(now.hour()).minute(now.minute()), [Validators.required, (time) => {
        if (time && moment().isBefore(time)) {
            return Promise.resolve('future')
        }
        return Promise.resolve(null);
    }])
    const startButtonHandler = () => {
        if (withTimeSelect) {
            setSelectTimeStep(true)
        } else {
            onClick(timeService.formatTimestamp(now))
        }
    }

    return <>
        {!selectTimeStep && (
            <Button onClick={startButtonHandler} width='fill' size='large' colorScheme='neutral'
                    shape='semi-rounded'>Start
                Shift</Button>
        )}
        {selectTimeStep && (
            <>
                <TimeInput input={timeInput} label={'Select start time'}/>
                {!timeInput.valid &&
                    <span className='text-200' style={{color: 'red', fontWeight: 'bold'}}>Can not set start time in future</span>}
                <Button disabled={!timeInput.valid} onClick={() => onClick(timeInput.value!.format('HH:mm'))}
                        width='fill'
                        size='large' colorScheme='positive' shape='semi-rounded'>Confirm</Button>
            </>
        )}
    </>
}

function EndButton({
                       onClick,
                       estimatedEndedAt,
    startedAt
                   }: { onClick: (time: string) => void, estimatedEndedAt: Moment, startedAt: Moment }) {
    const [selectTimeStep, setSelectTimeStep] = useState(false)
    const timeInput = useInput(moment(estimatedEndedAt), [Validators.required, (time) => {
        if (time && moment().isBefore(time)) {
            return Promise.resolve('future')
        }

        return Promise.resolve(null)
    }])

    const withDateFromStartedAtCheck: InputHook<Moment> = {
        ...timeInput,
        setValue: (val, keepPristine) => {
            const startedHoursAfterChosen = startedAt.hours() > val.hours() || (startedAt.hours() === val.hours() && startedAt.minutes() >= val.minutes())
            const endedAtDate = startedHoursAfterChosen ? moment(startedAt).add(1, 'days') : moment(startedAt)
            const calculatedVal = endedAtDate.hours(val.hours()).minutes(val.minutes())
            timeInput.setValue(calculatedVal, keepPristine)
        }

    }
    const validationToError: Record<string, string> = {
        future: 'Can not set end time in the future',
        startTimeGreater: 'End Time must be after start time'
    }
    return <>
        {!selectTimeStep && (
            <Button onClick={() => setSelectTimeStep(true)} width='fill' size='large' colorScheme='neutral'
                    shape='semi-rounded'>End
                Shift</Button>
        )}
        {selectTimeStep && (
            <>
                <TimeInput input={withDateFromStartedAtCheck} label={'Select end time'}/>
                {!timeInput.valid && <span className='text-200' style={{
                    color: 'red',
                    fontWeight: 'bold'
                }}>{validationToError[timeInput.error]}</span>}
                <Button disabled={!timeInput.valid} onClick={() => onClick(timeService.formatTimestamp(timeInput.value!))}
                        width='fill'
                        size='large' colorScheme='positive' shape='semi-rounded'>Confirm</Button>
            </>
        )}
    </>
}

function ShiftResourcesPanel({shift, overnightInput}: { shift: Shift, overnightInput: InputHook<boolean> }) {
    const routingContext = useRoutingContext()
    const addComment = () => {
        const path = RoutePaths.SHIFT_RESOURCES.getPath({
            date: moment(shift.date).format('YYYY-MM-DD'),
            shiftId: shift.id
        });
        routingContext.navigate(path)
    }
    const addReceipt = () => {
        const path = RoutePaths.SHIFT_RESOURCES.getPath({
            date: moment(shift.date).format('YYYY-MM-DD'),
            shiftId: shift.id
        });
        routingContext.navigate(`${path}?section=receipts`)
    }
    return (
        <div className={styles.resourcesPanelContainer}>
            <div className={styles.resourcesPanelSubGroupTop}>
                <div className='d-flex justify-content-between'><span><OvernightIcon/> Overnight</span> <CheckboxInput
                    input={overnightInput}/></div>
            </div>
            <hr/>
            <div className={classnames(styles.resourcesPanelSubGroupBottom, 'd-flex flex-column')}>
                <div className='d-flex justify-content-between'><span><CommentIcon/> Comment</span><AddResourceBtn
                    onClick={addComment}/></div>
                <div className='d-flex justify-content-between'><span><ReceiptIcon/> Receipt</span><AddResourceBtn
                    onClick={addReceipt}/></div>
            </div>
        </div>
    )
}

function AddResourceBtn({onClick}: { onClick: () => void }) {
    return <span onClick={onClick} style={{cursor: 'pointer'}}><Plus fill='yellow'/></span>
}
