import styles from './Labeled.module.css'
import classNames from "classnames";
export function Labeled({label, children}: {label: string, children: React.ReactNode}){
  return (
    <div>
      <div className={classNames('text-200', styles.label)}>{label}</div>
      {children}
    </div>
  )
}
