import * as React from "react"

export function Edit() {
  return (
  <svg width={18} height={19} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2.5v14A1.5 1.5 0 0 0 2.5 18h12a1.5 1.5 0 0 0 1.5-1.5v-7a.5.5 0 0 1 1 0v7a2.5 2.5 0 0 1-2.5 2.5h-12A2.5 2.5 0 0 1 0 16.5v-14A2.5 2.5 0 0 1 2.5 0h6a.5.5 0 0 1 0 1h-6A1.5 1.5 0 0 0 1 2.5Z"
      fill="#008553"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.35 1.621a3 3 0 0 1 0 4.243l-7.543 7.542a1 1 0 0 1-.566.283l-3.3.472a1 1 0 0 1-1.131-1.132l.471-3.3a1 1 0 0 1 .283-.565l7.543-7.543a3 3 0 0 1 4.242 0ZM8.1 12.7l-3.3.472.471-3.3 5.775-5.775 2.828 2.829L8.1 12.699Zm7.542-7.542a2 2 0 1 0-2.828-2.829L11.753 3.39l2.829 2.829 1.06-1.061Z"
      fill="#008553"
    />
  </svg>
)}
