import {TableFilterBar} from "../table-filter-bar/table-filter-bar";
import {useRef, useState} from "react";
import {ArrowDownIcon} from "../../icons/ArrowDown";
import {Menu, MenuItem} from "@mui/material";
import styles from './table-dates-length-switch.module.css'


type TableDatesLengthSwitchProps = {
    currentLength: number
    setLength: (val: number) => void;
};

export function TableDatesLengthSwitch({currentLength, setLength}: TableDatesLengthSwitchProps) {
    const ref = useRef<HTMLDivElement | null>(null)
    const [open, setOpen] = useState(false)
    const setDays = (length: number) => {
        setLength(length)
        setOpen(false)
    }

    return (
        <TableFilterBar>
            <div className='d-flex align-items-center justify-content-start'>

                <div className={styles.filterEntry} ref={ref}>
                    <div style={{cursor: 'pointer', paddingLeft: '5px'}} onClick={() => setOpen(true)}>
                        <ArrowDownIcon/> <span>Days Shown: {currentLength} days</span></div>
                    <Menu open={open} onClose={() => setOpen(false)} anchorEl={ref.current as Element}
                          disableScrollLock={true}>
                        <MenuItem key={'1'} onClick={() => setDays(2)}>2 day</MenuItem>
                        <MenuItem key={'3'} onClick={() => setDays(4)}>4 days</MenuItem>
                        <MenuItem key={'5'} onClick={() => setDays(6)}>6 days</MenuItem>
                        <MenuItem key={'14'} onClick={() => setDays(14)}>14 days</MenuItem>
                    </Menu>
                </div>

            </div>
        </TableFilterBar>
    )
}