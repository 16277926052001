import React, {useMemo} from "react";
import {RoutingContext, routingContext} from "../../../hooks/routing-context";
import {useHistory} from "react-router-dom";

export function RoutingContextProvider({root, children}: {root?: string, children: React.ReactNode }) {
  const history = useHistory()
  const routing: RoutingContext = useMemo(() => {
    const r = {
      getPath: (path: string) => `${root ?? ''}${path}`,
      navigate: (path: string) => history.push(r.getPath(path)),
      replace: (path: string) => history.replace(r.getPath(path))
    }
    return r
  }, [history])
  if(root) {
    console.log('root for routing', root)
  }
  return (
    <routingContext.Provider value={routing}>
      {children}
    </routingContext.Provider>
  )
}
