import {NightOutPreference, ShiftStatus} from "../../client/c2-api-client";
import {SlotStatus} from "../../services/status-backgrounds";

export type DriverDateStatusType =
  ShiftStatus
  | 'available'
  | 'sick'
  | 'holiday'
  | 'training'
  | 'induction'
  | 'off'
  | 'unavailable'

export type DriverDateStatus = { isRotaWorkingDay: boolean, type: DriverDateStatusType, startTime: string, endTime?: string, shiftId?: string }
export type DriverWithRoundsAndSlots = {
  id: string;
  name: string;
  group: string;
  licence: string;
  nightOut: string;
  earliestStart: string;
  status: { [d: string]: DriverDateStatus[] }
  roundAssignments: Map<string, string>
}

export const statusTooltip: Record<SlotStatus, string> = {
  available: 'Available',
  sick: 'Sick Leave',
  holiday: 'Holiday',
  not_confirmed: 'Sent',
  confirmed: 'Confirmed',
  started: 'Started',
  ended: 'Finished',
  training: 'Training',
  induction: 'Induction',
  unavailable: 'Unavailable',
  off: '',
  '': ''
}

export const nightOutLabel: Record<NightOutPreference, string> = {
  [NightOutPreference.YES]: 'Yes',
  [NightOutPreference.NO]: 'No',
  [NightOutPreference.POSS]: 'Poss'
}