type PlusFillColor = 'white' | 'yellow' | 'green'
const fillToColor: Record<PlusFillColor, string> ={
  white: '#fff',
  yellow: '#FFCB4D',
  green: '#008553'
}
export function Plus({fill}: {fill?: PlusFillColor}) {
  const color = fillToColor[fill ?? 'white']
  return (
    <svg
      width={17}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.263 8.223V1.614a.776.776 0 1 0-1.555 0v6.609H1.1a.777.777 0 1 0 0 1.554h6.608v6.609a.776.776 0 1 0 1.555 0V9.777h6.608A.775.775 0 0 0 16.648 9a.776.776 0 0 0-.777-.777H9.263Z"
        fill={color}
      />
    </svg>
  )
}
