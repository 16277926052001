import classNames from "classnames";
import classnames from "classnames";
import styles from "./shift-details.module.css";
import {OvernightIcon} from "../../icons/overnight";
import {CheckboxInput} from "../input/checkbox/CheckboxInput";
import {CommentIcon} from "../../icons/comment";
import {ReceiptIcon} from "../../icons/receipt";
import {Input} from "../input/text/Input";
import {DriverC2ApiClient, Shift, ShiftActivity, ShiftComment, ShiftReceipt} from "../../client/c2-api-client";
import moment from "moment";
import {InputHook, useInput} from "../../hooks/input";
import {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {ArrowRight} from "../../icons/arrow-right";
import {useSessionContext} from "../../hooks/session-context";
import {shiftService} from "../../services/shift/shift-service";
import {apiClientContext, useApiClientContext} from "../../hooks/api-client-context";
import {Button} from "../button/Button";
import {timeService} from "../../services/time-service";


type ChatMessage = { id: string, userId: string, initials: string, message: string, type: 'comment' | 'activity', timestamp: string }

const activityToMessage = (a: ShiftActivity) => {
  switch (a.type) {
    case "shift_sent":
      return `Shift Sent (Start Time: ${a.additionalData.startTime}, End Time: ${a.additionalData.endTime})`
    case "shift_times_changed":
      return `Shift times changed (Start Time: ${a.additionalData.startTime}, End Time: ${a.additionalData.endTime})`
    case "shift_confirmed":
      return "Shift Confirmed"
    case "shift_started":
      return `Shift Started (Start Time: ${a.additionalData.startedAt})`
    case "shift_ended":
      return `Shift Ended (End Time: ${a.additionalData.endedAt})`
    case "receipt_submitted":
      return `Receipt "${a.additionalData.displayName}" Submitted`
    case "receipt_validated":
      return `Receipt "${a.additionalData.displayName}" ${a.additionalData.approved ? 'Approved' : 'Rejected'}`
    case "shift_validated":
      return `Operator Entered ${a.additionalData.operatorTime} as Shift Time`
    case "shift_tacho_added":
      return `Tacho For Shift Uploaded, Total Time: ${a.additionalData.totalTachoTime}`
    default:
      return ''
  }
}

const commentsToChat = (commentsFetcher: () => Promise<ShiftComment[]>, activityFetcher: (shiftId: string) => Promise<ShiftActivity[]>, shift: Shift, getUsername: (id: string) => Promise<{ id: string, name: string }>): Promise<ChatMessage[]> => {
  return Promise.all([commentsFetcher(), activityFetcher(shift.id)]).then(([comments, activities]: [ShiftComment[], ShiftActivity[]]) => {
    const allWithoutUsername: Omit<ChatMessage, 'initials'>[] = [
      ...comments.map(c => ({
        id: c.id,
        message: c.message,
        userId: c.userId,
        // initials: byId[c.userId].split(' ').map(n => n[0]).join(''),
        type: 'comment' as 'comment',
        timestamp: c.commentedAt
      })),
      ...activities.map(a => ({
        id: a.id,
        message: activityToMessage(a),
        userId: a.userId,
        // initials: byId[c.userId].split(' ').map(n => n[0]).join(''),
        type: 'activity' as 'activity',
        timestamp: a.addedAt
      }))
    ]
    allWithoutUsername.sort((f, s) => moment(f.timestamp).diff(moment(s.timestamp)))
    const ids = allWithoutUsername.map(c => c.userId)
    const idsDistinct = Object.keys(ids.reduce((soFar, e) => {
      soFar[e] = true;
      return soFar
    }, {} as any))
    return Promise.all(
      idsDistinct.map(id => getUsername(id))
    ).then(users => {
      const byId = users.reduce((r, u) => {
        r[u.id] = u.name;
        return r
      }, {} as Record<string, string>)
      return allWithoutUsername.map(c => ({
        ...c,
        initials: byId[c.userId].split(' ').map(n => n[0]).join(''),
      }))
    })
  });
}

type ReceiptMessage = { id: string, userId: string, initials: string, description: string, displayName: string, cost: number, approved?: boolean }
const receiptsToChat = (receiptsFetcher: () => Promise<ShiftReceipt[]>, shift: Shift, getUsername: (id: string) => Promise<{ id: string, name: string }>): Promise<ReceiptMessage[]> => {
  return receiptsFetcher().then(receipts => {
    receipts.sort((f, s) => moment(f.addedAt).diff(moment(s.addedAt)))
    const ids = receipts.map(c => c.userId)
    const idsDistinct = Object.keys(ids.reduce((soFar, e) => {
      soFar[e] = true;
      return soFar
    }, {} as any))
    return Promise.all(
      idsDistinct.map(id => getUsername(id))
    ).then(users => {
      const byId = users.reduce((r, u) => {
        r[u.id] = u.name;
        return r
      }, {} as Record<string, string>)
      return receipts.map(c => ({
        id: c.id,
        userId: c.userId,
        initials: byId[c.userId].split(' ').map(n => n[0]).join(''),
        description: c.description,
        displayName: c.displayName,
        approved: c.approved,
        cost: c.cost
      }))
    })
  });
}

const fullPadding = classnames(styles.verticalPadding, styles.horizontalPadding)


export interface ShiftDetailsProps {
  shift: Shift,
  activeSection?: 'comments' | 'receipts',
  usernameFetcher: (id: string) => Promise<{ id: string, name: string }>,
  setOvernight: (val: boolean) => void
  fetchComments: () => Promise<ShiftComment[]>,
  addComment: (message: string) => Promise<void>,
  fetchReceipts: () => Promise<ShiftReceipt[]>,
  addReceipt: (receipt: { description: string, cost: number, file: File }) => Promise<void>,
  downloadReceipt: (id: string) => void,
  validateReceipt?: (shiftId: string, receiptId: string, approved: boolean) => Promise<void>,
  fetchActivity: (shiftId: string) => Promise<ShiftActivity[]>
}

export function ShiftDetails({
                               shift,
                               activeSection,
                               fetchComments,
                               usernameFetcher,
                               addComment,
                               setOvernight,
                               fetchReceipts,
                               addReceipt,
                               downloadReceipt,
                               fetchActivity,
                               validateReceipt
                             }: ShiftDetailsProps) {
  const overnightInput: InputHook<boolean> = useMemo(() => ({
    valid: true,
    error: null,
    pristine: false,
    submit: () => {
    },
    value: shift.isOvernight,
    setValue: (val: boolean) => setOvernight(val)
  }), [shift.isOvernight, setOvernight])
  const sendMessageInput = useInput('')
  const receiptCostInput = useInput('', [(val) => {
    if(!val) return Promise.resolve('required')
    const fl = parseFloat(val)
    if(isNaN(fl)) return Promise.resolve('NaN')
    return Promise.resolve(null)
  }])
  const receiptDescriptionInput = useInput('')
  const [sectionShown, setSectionShown] = useState<'comments' | 'receipts'>(activeSection ?? 'comments')
  console.log('active section', sectionShown)
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [receipts, setReceipts] = useState<ReceiptMessage[]>([])
  const myId = useSessionContext().session?.userId
  const fileRef = useRef<HTMLInputElement>(null)
  const [fetchFlag, setFetchFlag] = useState(true)
  useEffect(() => {
    commentsToChat(fetchComments, fetchActivity, shift, usernameFetcher)
      .then(chat => setMessages(chat))

    receiptsToChat(fetchReceipts, shift, usernameFetcher)
      .then(ch => setReceipts(ch))

  }, [shift.id, fetchFlag, fetchComments, fetchActivity, fetchReceipts])

  const addMessage = () => {
    const txt = sendMessageInput.value
    if (!txt) return
    addComment(txt)
      .then(() => setFetchFlag(curr => !curr))
    sendMessageInput.setValue('')
  }

  const addReceiptMessage = () => {
    addReceipt({
      description: receiptDescriptionInput.value!,
      cost: parseFloat(receiptCostInput.value!),
      file: fileRef.current!.files![0]
    }).then(() => {
      receiptDescriptionInput.setValue('')
      receiptCostInput.setValue('')
      receiptCostInput.submit()
      fileRef.current!.files = null
      setFetchFlag(curr => !curr)
    })
  }
  useEffect(() => {
    const chatDiv = divRef.current
    if (!chatDiv) return
    console.log('curr top height', chatDiv.scrollTop, chatDiv.scrollHeight)
    chatDiv.scrollTop = chatDiv.scrollHeight
    console.log('after top height', chatDiv.scrollTop, chatDiv.scrollHeight)
  }, [messages.length, receipts.length, sectionShown])
  const divRef = useRef<HTMLDivElement>(null)

  const selectedFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const files = (ev.target as HTMLInputElement).files
    const selection: File | undefined = (files ?? [])[0]
    console.log('in change', ev.target.files, selection)
  }

  const receiptValidator = (receipt: ReceiptMessage) => {
    if (!validateReceipt) return false
    return (approved: boolean) => validateReceipt(shift.id, receipt.id, approved).then(() => setFetchFlag(curr => !curr))
  }
  return (
    <div style={{height: '100%'}} className='d-flex flex-column'>
      <div>
        <div className={classnames(fullPadding, 'd-flex justify-content-start')}>
          <span><OvernightIcon/> Overnight <CheckboxInput input={overnightInput}/></span>
        </div>
      </div>
      <div className={styles.spacer}></div>
      <div className={classnames('d-flex flex-row align-content-stretch', styles.btnsRow)}>
        <div
          onClick={() => setSectionShown('comments')}
          className={classnames(styles.btnsWrapper, styles.btnsWrapperBorder, fullPadding, 'd-flex justify-content-start align-items-center')}>
          <span
            className={sectionShown === 'comments' ? styles.activeSectionTitle : styles.inactiveSectionTitle}><CommentIcon
            color={sectionShown === 'comments' ? 'green' : 'gray'}/> Comment</span>
        </div>
        <div
          onClick={() => setSectionShown('receipts')}
          className={classnames(styles.btnsWrapper, fullPadding, 'd-flex justify-content-end align-items-center')}>
          <span
            className={sectionShown === 'receipts' ? styles.activeSectionTitle : styles.inactiveSectionTitle}><ReceiptIcon
            color={sectionShown === 'receipts' ? 'green' : 'gray'}/> Receipt</span>
        </div>
      </div>
      <div className={styles.spacer}></div>
      <div className={classnames(styles.verticalPadding, styles.chatWrapper, styles.horizontalPaddingSmall, '')}
           ref={divRef}>
        {sectionShown === 'comments' ? <ChatContainer messages={messages} currentUserId={myId}/> : null}
        {sectionShown === 'receipts' ?
          <ReceiptsContainer receipts={receipts} downloadReceipt={downloadReceipt} currentUserId={myId}
                             validatorFactory={receiptValidator}/> : null}
      </div>
      <div className={styles.spacer}></div>
      <div className={classnames(styles.verticalPadding, styles.horizontalPaddingSmall, 'd-flex flex-row')}
           style={{gap: '15px'}}>
        {sectionShown === 'comments' ? (
          <><Input name='chat-message' inputHook={sendMessageInput}/>
            <div>
              <SendBtn onClick={addMessage}/>
            </div>
          </>
        ) : null}
        {sectionShown === 'receipts' ? (
          <div style={{width: '100%', gap: '10px'}} className='d-flex flex-column'>
            <div className='d-flex' style={{gap: '10px'}}>
              <input style={{display: 'none'}} id='receipt-upload' name='receipt-upload' type='file' ref={fileRef}
                     onChange={(ev) => selectedFile(ev)}/>
              <label htmlFor='receipt-upload' style={{
                border: '1px solid black',
                cursor: 'pointer',
                borderRadius: '6px',
                padding: '5px',
                marginTop: '.25rem'
              }}>Browse</label>
              <Input placeholder='&#163;' name='receipt-cost' inputHook={receiptCostInput}/>
            </div>
            <div className='d-flex' style={{gap: '10px'}}>
              <Input placeholder='Receipt Description' name='receipt-description' inputHook={receiptDescriptionInput}/>
              <SendBtn onClick={addReceiptMessage}/>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

function SendBtn({onClick}: { onClick: () => void }) {
  return (
    <button onClick={onClick}
            className={classnames(styles.sendBtnContainer, 'd-flex justify-content-center align-items-center')}>
      <ArrowRight/></button>
  )
}

function ChatContainer({messages, currentUserId}: { messages: ChatMessage[], currentUserId?: string }) {
  return (
    <div>
      {messages.map(m => <ChatMessageComponent key={m.id} message={m} isMyMessage={m.userId === currentUserId}/>)}
    </div>
  )
}

function ChatMessageComponent({message, isMyMessage}: { message: ChatMessage, isMyMessage: boolean }) {
  return (
    <div className={classnames(styles.verticalPadding, 'd-flex align-items-start', isMyMessage && 'flex-row-reverse')}
         style={{gap: '10px'}}>
      <div><ChatMessageInitials>{message.initials}</ChatMessageInitials></div>
      <div className='flex-fill'><ChatMessageWrapper
        orientation={isMyMessage ? 'right' : 'left'}>
        {message.type === 'activity' && <><span className='text-200' style={{fontStyle: 'italic', fontWeight: 'bold', color: '#004d4d'}}>{message.timestamp}</span><br /></>}
        <span
        style={{color: message.type === 'activity' ? 'green' : 'auto'}}>{message.message}</span></ChatMessageWrapper>
      </div>
      <div style={{visibility: 'hidden'}}><ChatMessageInitials>{message.initials}</ChatMessageInitials></div>
    </div>
  )
}

function ChatMessageInitials({children}: { children: ReactNode }) {
  return (
    <div className={classnames('d-flex justify-content-center align-items-center', styles.initialsContainer)}>
      {children}
    </div>
  )
}

const orientationWrapperClasses: Record<'left' | 'right', string> = {
  left: styles.messageWrapperLeft,
  right: styles.messageWrapperRight
}

function ChatMessageWrapper({orientation, children}: { orientation: 'left' | 'right', children: ReactNode }) {
  return (
    <div className={classnames(styles.messageWrapper, orientationWrapperClasses[orientation])}>
      {children}
    </div>
  )
}

function ReceiptsContainer({
                             receipts,
                             currentUserId,
                             downloadReceipt,
                             validatorFactory
                           }: { receipts: ReceiptMessage[], currentUserId?: string, downloadReceipt: (id: string) => void, validatorFactory: (receipt: ReceiptMessage) => false | ((app: boolean) => Promise<void>) }) {
  return (
    <div>
      {receipts.map(m => <ReceiptMessageComponent key={m.id} receipt={m} isMyMessage={m.userId === currentUserId}
                                                  downloadReceipt={() => downloadReceipt(m.id)}
                                                  validator={validatorFactory(m)}
      />)}
    </div>
  )
}

function ReceiptMessageComponent({
                                   receipt,
                                   isMyMessage,
                                   downloadReceipt,
                                   validator
                                 }: { receipt: ReceiptMessage, isMyMessage: boolean, downloadReceipt: () => void, validator: false | ((app: boolean) => Promise<void>) }) {
  const pendingApproval = receipt.approved === undefined || receipt.approved === null
  return (
    <div className={classnames(styles.verticalPadding, 'd-flex align-items-start', isMyMessage && 'flex-row-reverse')}
         style={{gap: '10px'}}>
      <div><ChatMessageInitials>{receipt.initials}</ChatMessageInitials></div>
      <div className='flex-fill'><ReceiptMessageWrapper
        orientation={isMyMessage ? 'right' : 'left'}>

        <Button onClick={() => downloadReceipt()}>
          <div>
            <div>&#163; {receipt.cost}</div>
            <div>{receipt.description}</div>
          </div>
        </Button>
        {!pendingApproval && <div className='d-flex' style={{paddingTop: '10px'}}>
          {receipt.approved && <span className='text-200' style={{color: 'green'}}>Receipt Approved</span>}
          {!receipt.approved && <span className='text-200' style={{color: 'red'}}>Receipt Rejected</span>}
        </div>}
        {pendingApproval && validator && <div className='d-flex' style={{paddingTop: '10px'}}>
          <Button colorScheme={"positive"} onClick={() => validator(true)}><span
            className='text-200'>Approve</span></Button>
          <Button colorScheme={"negative"} onClick={() => validator(false)}><span
            className='text-200'>Reject</span></Button>
        </div>}
        {pendingApproval && !validator && <div className='d-flex' style={{paddingTop: '10px'}}>
          <span className='text-200' style={{color: 'gray'}}>Pending Approval</span>
        </div>}
      </ReceiptMessageWrapper></div>
      <div style={{visibility: 'hidden'}}><ChatMessageInitials>{receipt.initials}</ChatMessageInitials></div>
    </div>
  )
}

function ReceiptMessageWrapper({orientation, children}: { orientation: 'left' | 'right', children: ReactNode }) {
  return (
    <div className={classnames(styles.messageWrapper, orientationWrapperClasses[orientation])}>
      {children}
    </div>
  )
}
