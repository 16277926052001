import {ShiftStatus} from "../client/c2-api-client";

export type SlotStatus = ShiftStatus | 'available' | 'sick' | 'holiday' | 'training' | 'induction' | 'off' | 'unavailable' | ''


export const statusBackgrounds: Record<SlotStatus , string> = {
  available: 'rgba(241,75,176,0.62)',
  holiday: '#FF9797',
  sick: '#FF985E',
  not_confirmed: '#C1C0FD',
  confirmed: '#8CDFF1',
  started: '#34C18D',
  ended: '#34C18D',
  training: '#dec6c6',
  induction: '#dec6c6',
  unavailable: '#dec6c6',
  off: 'rgba(243, 243, 243, 1)',
  '': ''
}
