import * as React from "react"
type Color = 'green' | 'purple'
const colorToFill: Record<Color, string> = {
  green: '#18C671',
  purple: '#9B51E0'
}
export function CheckMarkIcon({color = 'green'}: {color?: Color}) {
  const fill = colorToFill[color]
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x={0.25}
        y={0.25}
        width={15.5}
        height={15.5}
        rx={5.75}
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
      />
      <path
        d="m5.333 7.776 2.155 2.89 3.179-5.333"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  )
}
