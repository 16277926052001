import {InputHook} from "../../../../hooks/input";
import {Labeled} from "../../labeled/Labeled";
import {Input} from "../../text/Input";

export function TextFormField({
                         input,
                         name,
                         type = 'text',
                         label,
                         disabled=false
                       }: { input: InputHook<string>, name: string, type?: 'text' | 'email', label: string, disabled?: boolean }) {
  return <Labeled label={label}>
    <div>
      <Input name={name} inputHook={input} type={type} disabled={disabled} />
    </div>
  </Labeled>
}
