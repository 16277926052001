import {TableHook} from "../../hooks/table";
import classNames from "classnames";
import styles from "./timesheets.module.css";
import {PublicHoliday, Shift} from "../../client/c2-api-client";
import {Moment} from "moment";
import {DriverSummary} from "./driver-summary";
import {ColumnConfig} from "../../components/table/table";
import {ShiftApproveStatusType, shiftService} from "../../services/shift/shift-service";
import {Download} from "../../icons/download";
import {ShiftCard} from "../../components/shift-card/shift-card";
import {Plus} from "../../icons/plus";
import {Button} from "../../components/button/Button";
import {timeService} from "../../services/time-service";
import {DriverTableHeader} from "../../components/table/column-header-components";

function ShiftTableHeader({label, sort, table}: { label: string, sort: string, table: TableHook<any> }) {
  return <th className={classNames(styles.tableHeader, 'text-200')} onClick={() => table.setSort(sort)}>{label}</th>
}

// type AddShiftHandler = (row: DriverSummary, date: Moment) => void
type SelectShiftHandler = (s: Shift, d: { name: string, site?: string }) => void
type DriversTableConfigParam = {
  table: TableHook<any>,
  dates: Moment[],
  today: Moment,
  onSelect: SelectShiftHandler,
  selectedId?: string,
  onDownload: (driverId: string) => void,
  addShift: (driver: DriverSummary, date: Moment) => void
  tachoDiffConfig: { warning: string, error: string }
  sendInvoice: (driverId: string) => void,
  publicHolidays: PublicHoliday[]
}
export const driversTableConfig: (arg: DriversTableConfigParam) => ColumnConfig<DriverSummary>[] = ({
                                                                                                      table,
                                                                                                      today,
                                                                                                      dates,
                                                                                                      onSelect,
                                                                                                      onDownload,
                                                                                                      selectedId,
                                                                                                      addShift,
                                                                                                      tachoDiffConfig,
                                                                                                      sendInvoice,
                                                                                                      publicHolidays
                                                                                                    }) => {
  const shiftApproveStatusHandler = shiftService.statusHandler(tachoDiffConfig)
  const publicHolidaysSet = new Set(publicHolidays.map((holiday) => holiday.date))
  return [{
    key: 'name',
    header: <DriverTableHeader label='Driver' table={table} sort='firstName'
                               classes={classNames(styles.tableHeader, 'text-200')}/>,
    cellFactory: row =>
      <td className={classNames(styles.tableCell, 'text-300')}>
        <p>{row.name} <span
          style={{cursor: 'pointer'}} onClick={() => onDownload(row.id)}><Download/></span></p>
        <p>(Total: {row.approvedTime}, Break: {row.totalBreak})</p>
        <p><Button disabled={!row.allShiftsApproved} size={'small'} onClick={() => sendInvoice(row.id)}>Send
          Invoice</Button></p>
      </td>
  }, {
    key: 'site',
    header: <DriverTableHeader label='Site' table={table} sort='site'
                               classes={classNames(styles.tableHeader, 'text-200')}/>,
    cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}>{row.site}</td>
  },
    ...dates.map((d, i) => ({
      key: `${d.toDate().getTime()}`,
      header: <ShiftDateHeader date={d}
                               isPublicHoliday={publicHolidaysSet.has(timeService.formatDate(d))}
                               compare={d.format('DD-MM-YYYY') === today.format('DD-MM-YYYY') ? 0 : d.isAfter(today) ? 1 : -1}/>,
      cellFactory: row => <ShiftDateCell row={row} date={d} onSelect={onSelect} onAdd={() => addShift(row, d)}
                                         compare={d.format('DD-MM-YYYY') === today.format('DD-MM-YYYY') ? 0 : d.isAfter(today) ? 1 : -1}
                                         approveStatusHandler={shiftApproveStatusHandler}
                                         selected={selectedId}/>
    } as ColumnConfig<DriverSummary>))
  ]
}

function ShiftDateHeader({
                           date,
                           compare,
                           isPublicHoliday
                         }: { date: Moment, compare: number, isPublicHoliday: boolean }) {
  const colorStyles = {
    backgroundColor: compare === 0 ? 'rgba(52, 193, 141, 1)' : 'auto',
    color: compare === 0 ? 'white' : ''
  }
  return (
    <th className={classNames(styles.dateHeader, 'text-200')} style={colorStyles}>
      <div className='d-flex flex-column align-items-center'>
        <div style={{color: compare === 0 ? 'white' : '#5C5C5C'}}>{date.format('DD.MM')} {isPublicHoliday &&
        <i>(BH)</i>}</div>
        <div>{date.format('ddd')}</div>
      </div>
    </th>
  )
}


// sve ovo sto dobijas u prop-ovima prebaci na row propertije, da radi rerender kako treba
function ShiftDateCell({
                         row,
                         date,
                         compare,
                         onSelect,
                         selected,
                         onAdd,
                         approveStatusHandler
                       }: { row: DriverSummary, compare: number, date: Moment, onSelect: SelectShiftHandler, selected?: string, onAdd: () => void, approveStatusHandler: (shift: Shift) => ShiftApproveStatusType }) {
  const shift = row.status[date.format('DD-MM-YYYY')]
  if (shift) {
    return (
      <td style={{verticalAlign: 'top'}}>
          {shift.map(sh => <div  style={{height: '110px'}}>
              <ShiftCard shift={sh} onClick={() => onSelect(sh, {name: row.name, site: row.site})}
                         isInFuture={compare > 0}
                         approveStatus={approveStatusHandler(sh)}
                         selected={selected === sh.id}/>
          </div>)}
      </td>
    );
  }
  return (
    <td style={{position: 'relative', height: '110px'}}>
      <div style={{position: 'absolute', top: '0', bottom: '0', left: '8px', right: '8px', paddingTop: '15px'}}>
        <div className='d-flex align-items-center justify-content-center'
             onClick={onAdd}
             style={{
               width: '100%',
               height: '100%',
               padding: '20px',
               background: 'rgba(227, 237, 235, 0.14)',
               border: '1px solid #18C671',
               borderRadius: '6px',
               cursor: 'pointer'
             }}>
          <Plus fill='green'/>
        </div>
      </div>
    </td>
  );
}

export const ColGroups = (tableConfig: ColumnConfig<DriverSummary>[]) => {
  const datesLength = tableConfig.length - 2
  const datesWidth = 80 / datesLength
  return (
    <colgroup>
      <col style={{width: '10%'}}/>
      <col style={{width: '10%'}}/>
      {new Array(datesLength).fill(1).map((a, i) => <col key={i} style={{width: `${datesWidth}%`}}/>)}
    </colgroup>

  )
}
