import styles from './Header.module.css'
import {useSessionContext} from "../../hooks/session-context";
import {Button} from "../button/Button";
import {StyledLink} from "../link/styled-link/StyledLink";
import {Link} from 'react-router-dom';
import {RoutePaths, useRoutePathMatch} from "../../app-router";
import classNames from "classnames";
import {ConnectedLogo} from "../../icons/ConnectedLogo";
import {useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {SuperAdminC2ApiClient} from "../../client/c2-api-client";
import {RouterLink} from "../link/router-link/RouterLink";

function UserAvatar(props: {text: string}) {
  return <div className={styles.avatarContainer}>{props.text}</div>
}
export interface UserInfoProps {
  name: string
  onClick?: () => void
}
function UserInfo(props: UserInfoProps) {
  const nameCapitalized = props.name.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
  const avatarLeaters = nameCapitalized.map(w => w[0])
    .join('')
  const clickHandler = props.onClick || (() => {})
  return (<div className="d-flex align-items-center">
    <StyledLink href="http://google.com" variant='secondary'>Help & Support</StyledLink>
    <div style={{marginRight: '10px'}}></div>
    <Button colorScheme="blank" shape='semi-rounded' size='large' onClick={clickHandler}>
      <div className="d-flex justify-content-between align-items-center">
        <span style={{marginRight: '5px'}}>{nameCapitalized.join(' ')}</span>
        <UserAvatar text={avatarLeaters}></UserAvatar>
      </div>
    </Button>
    </div>)
}
export function Header() {
  const sessionContext = useSessionContext()
  const apiClient = useApiClientContext().as(SuperAdminC2ApiClient)
  const operatorOverviewMatch = useRoutePathMatch(RoutePaths.SUPER_ADMIN_OPERATOR_OVERVIEW)
  const [operatorName, setOperatorName] = useState('')
  const {operatorId} = operatorOverviewMatch?.params ?? {}
  const session = sessionContext.session
  const name = session?.name

  useEffect(() => {
    if(operatorId) {
      apiClient?.asOperatorClient(operatorId)
        .getOperatorDetails()
        .then(details => setOperatorName(details.name))
    } else {
      setOperatorName('')
    }
  }, [operatorId ?? ''])

  const nameClickHandler = () => {
    sessionContext.clearSession()
  }
  return (
    <>
      {!!operatorName && (
        <div className={classNames(styles.operatorOverviewStyle, 'd-flex justify-content-center align-items-center')}>
          <div>Viewing data as admin for <span className={styles.operatorNameLabel}>{operatorName}</span> operator</div>
          <RouterLink to={RoutePaths.HOME.getPath()}>Go back</RouterLink>
        </div>
      )}
      {!operatorName && (
        <header className={classNames(styles.headerRow, styles.loginHeader)}>
          <div className="d-flex align-items-center">
            <Link to={RoutePaths.HOME.getPath()} component={(linkProps) => <div onClick={linkProps.navigate} style={{cursor: 'pointer'}}><ConnectedLogo /></div> } />
          </div>

          {!!name && <UserInfo name={name} onClick={nameClickHandler}></UserInfo>}
        </header>
      )}
    </>
  );
}
