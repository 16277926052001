import {DriverShiftsResponse, Shift} from "../../client/c2-api-client";
import {Moment} from "moment";
import {timeService} from "../../services/time-service";
import {shiftService} from "../../services/shift/shift-service";

export type DriverSummary = {
  id: string;
  name: string;
  group: string;
  site: string;
  licence: string;
  nightOut: string;
  earliestStart: string;
  approvedTime: string;
  totalBreak: string;
  status: { [d: string]: Shift[] },
  allShiftsApproved: boolean
}

export const driverResponseToSummaryMapper = (today: Moment, days: Moment[]) => (driver: DriverShiftsResponse, allShiftsApproved: boolean): DriverSummary => ({
  id: driver.driverId,
  name: `${driver.firstName} ${driver.lastName}`,
  nightOut: driver.nightOut,
  earliestStart: driver.earliestStartTime,
  licence: driver.licence.join(),
  group: driver.group ?? '',
  site: driver.site ?? '',
  status: days.reduce((res, d) => {
    const dateStr = d.format('YYYY-MM-DD')
    const slot = driver.daySlots.filter(s => s.date === dateStr)[0]
    if(!slot) return res
    const shifts = slot.shifts
    if (shifts[0]) {
      res[d.format('DD-MM-YYYY')] = shifts
    }
    return res;
  }, {} as DriverSummary['status']),
  approvedTime: timeService.formatDuration(shiftService.totalApprovedDuration(driver.daySlots.filter(d => d.shifts.length).reduce((acc, d) => acc.concat(d.shifts), [] as Shift[]))),
  totalBreak: timeService.formatDuration(shiftService.totalBreak(driver.daySlots.filter(d => d.shifts.length).reduce((acc, d) => acc.concat(d.shifts), [] as Shift[]))),
  allShiftsApproved
})
