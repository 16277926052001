export function VerticalDotsIcon() {
  return (
    <svg
      width={4}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={2} cy={2} r={2} fill="#F7C345" />
      <circle cx={2} cy={10} r={2} fill="#F7C345" />
      <circle cx={2} cy={18} r={2} fill="#F7C345" />
    </svg>
  )
}
