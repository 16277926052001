import * as React from "react"

export function Info() {
  return (
    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M0 0h16v16H0z" />
      <path
        d="M8 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1zm1 11V7H7v5h2zm0-6V4H7v2h2z"
        fill="#00B471"
        fillRule="evenodd"
      />
    </svg>
  )
}
