export function Sites({active = true}: {active: boolean}) {
  return (
    <svg
      width={17}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? 1 : 0.33} fillRule="evenodd" clipRule="evenodd" fill={active ? "var(--new-brand-1)" : "#000"}>
        <path d="M1.762 13.056a8.035 8.035 0 1 1 13.122-.82c-.174.284-3.982 5.313-5.528 7.203a1.695 1.695 0 0 1-2.642 0c-1.438-1.76-3.086-3.885-4.945-6.374l-.007-.008Zm.808-.59.123.164a239.112 239.112 0 0 0 1.534 2.024c.965 1.26 2.193 2.844 3.261 4.152a.695.695 0 0 0 1.094 0 176.718 176.718 0 0 0 3.27-4.158 279.93 279.93 0 0 0 1.536-2.026l.07-.094a.966.966 0 0 1 .07-.097 7.035 7.035 0 1 0-10.985 0l.006.008a.925.925 0 0 1 .021.027Z" />
        <path d="M10.035 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm-2 3a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
      </g>
    </svg>
  )
}
