import * as React from "react"

export function NotificationBell() {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.023 0a6.377 6.377 0 0 1 6.374 6.178l.003.199v2.56c0 .377.133.74.373 1.028l.096.104.8.8a1.131 1.131 0 0 1-.691 1.926l-.11.005H11.2a3.2 3.2 0 0 1-6.396.16L4.8 12.8H1.123a1.123 1.123 0 0 1-.858-1.848l.078-.083.823-.795a1.6 1.6 0 0 0 .482-1.011l.007-.145-.008-2.522A6.377 6.377 0 0 1 8.023 0ZM10 12.8H6a2 2 0 1 0 4 0ZM8.024 1.2A5.177 5.177 0 0 0 2.85 6.206l-.003.186.008 2.523a2.8 2.8 0 0 1-.733 1.896L2 10.937l-.687.663h13.39l-.683-.683a2.8 2.8 0 0 1-.815-1.809l-.005-.17V6.376A5.177 5.177 0 0 0 8.024 1.2Z"
        fill="#00B471"
      />
    </svg>
  )
}
