import styles from './settings-site-list.module.css'
import {useEffect, useState} from "react";
import classNames from "classnames";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient, SiteModel} from "../../client/c2-api-client";
import {OperatorAdminSettingsList} from "../../components/operator-admin-settings/operator-admin-settings-list/operator-admin-settings-list";
import {OperatorAdminSettingsCard} from "../../components/operator-admin-settings/operator-admin-settings-card/operator-admin-settings-card";
import {SettingsRouteSubPaths} from "../../app-router";
import {useRoutingContext} from "../../hooks/routing-context";

function SiteContainer(props: { site: SiteModel, editCallback: () => void }) {
  const {address, name, postCode} = props.site
  return (
    <OperatorAdminSettingsCard editCallback={props.editCallback} >
      <div className={classNames(styles.userName, 'text-300')}>{name}</div>
      <div className='text-200'>{address}, {postCode}</div>
    </OperatorAdminSettingsCard>
  )
}

export function SettingsSiteList() {
  const routingContext = useRoutingContext()
  const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const [sites, setSites] = useState<SiteModel[]>([])
  const editSite = (site: SiteModel) => {
    routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_SITES_EDIT.getPath({siteId: site.id}))
  }
  useEffect(() => {
    c2ApiClient.getOperatorSites().then(sitesRes => setSites(sitesRes.sites))
  }, [c2ApiClient])

  return (
    <OperatorAdminSettingsList title="Site List" addBtnLabel="Add Site"
                                    addBtnHandler={() => routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_SITES_ADD.getPath())}>
      {sites.map(site => <SiteContainer key={site.id} site={site} editCallback={() => editSite(site)}/>)}
    </OperatorAdminSettingsList>)
}
