import {useCallback, useEffect, useMemo, useState} from "react";
import {ListPaginatedResponse} from "../client/c2-api-client";

export type RowsFetcher<T, F = any> = (params: {page: number, pageSize: number, sort: string, ascending: boolean, filters: Partial<F>}) => Promise<ListPaginatedResponse<T>>
type Data<T> = {isLoading: boolean, rows: T[]}
export type SetFiltersCallback<F> = (currentFilters: F) => F
export interface TableHook<T, F = any> {
  pageSize: number;
  currentPage: number,
  currentData: Data<T>,
  currentFilters: Partial<F>,
  totalPages: number,
  currentSort: string,
  currentAscending: boolean,
  reload: () => void
  setSort: (sort: string) => void,
  setFilters: (filters: Partial<F> | SetFiltersCallback<Partial<F>>) => void,
  setPage: (page: number) => void
}
export function useTable<T, F = any>(props: {pageSize?: number, rowsFetcher: RowsFetcher<T,F>, defaultSort?: string, defaultSortAscending?: boolean}): TableHook<T, F> {
  const pageSize = props.pageSize || 10
  const [sort, setSort] = useState<string>(props.defaultSort ?? '')
  const [ascending, setAscending] = useState<boolean>(props.defaultSortAscending ?? false)
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState<Data<T>>({isLoading: true, rows: []})
  const [reloadSwitch, setReloadSwitch] = useState(false)
  const [filters, setFilters] = useState<Partial<F>>({})
  const setSortHook = useCallback((newSort: string) => {
    if(newSort === sort) {
      setAscending(!ascending)
    } else {
      setSort(newSort)
      setAscending(false)
    }
  }, [setSort, setAscending, sort, ascending])
  useEffect(() => {
    setData({isLoading: true, rows: data.rows})
    props.rowsFetcher && props.rowsFetcher({page, pageSize, sort, ascending, filters})
      .then(res => {
        setPage(res.page)
        setTotalPages(res.totalPages)
        setData({isLoading: false, rows: res.data})
      })
  }, [sort, ascending, page, pageSize, reloadSwitch, props.rowsFetcher, filters])
  return useMemo(() => ({
    pageSize,
    currentPage: page,
    currentData: data,
    currentFilters: filters as Partial<F>,
    totalPages: totalPages,
    currentSort: sort,
    currentAscending: ascending,
    reload: () => setReloadSwitch(!reloadSwitch),
    setSort: setSortHook,
    setFilters,
    setPage
  }), [pageSize, page, data, filters, totalPages, sort, ascending])
}
