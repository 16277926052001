import {RoutePaths, useRoutePathMatch} from "../../app-router";
import {form, InputHook, mapInput, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {
    DriverAgencyData, DriverResponse,
    LicenceCategory,
    NightOutPreference,
    OperatorAdminC2ApiClient,
    RotaType
} from "../../client/c2-api-client";
import {
    OperatorAdminSettingsForm
} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {Labeled} from "../../components/input/labeled/Labeled";
import {VerticalGroup} from "../../components/input/vertical-group/VerticalGroup";
import {PhoneNumber} from "../../components/input/phone-number/phone-number";
import moment, {Moment} from "moment";
import {SelectInput} from "../../components/input/select/select";
import {RadioGroup} from "../../components/input/radio-group/radio-group";
import {TimeInput} from "../../components/input/time/time-input";
import {useRoutingContext} from "../../hooks/routing-context";
import {DateInput} from "../../components/input/date/date-input";
import {TextFormField} from "../../components/input/composite/text-form-field/text-form-field";
import {UsernameModal} from "../../components/username-modal/username-modal";
import {ReviewDeletedDriversModal} from "../../components/review-deleted-drivers-modal/review-deleted-drivers-modal";

const RotaTypeOptions: Record<RotaType, string> = {
    "4_on_4_off": '4 On 4 Off',
    "4_on_3_off": '4 On 3 Off',
    "4_on_2_off": '4 On 2 Off',
    "5_on_2_off": '5 On 2 Off',
    "5_on_3_off": '5 On 3 Off',
    "1_on_5_off": '1 On 5 Off',
    "2_on_5_off": '2 On 5 Off',
    "2_on_2_off": '2 On 2 Off',
    "7_on_0_off": '7 On 0 Off',
}

export const LicenceLabels: Partial<Record<LicenceCategory, string>> = {
    NO_LICENCE: 'No Licence'
}

export function DriverEdit() {
    const match = useRoutePathMatch(RoutePaths.DRIVERS_EDIT)

    const driverId = match?.params.driverId
    const routingContext = useRoutingContext()
    const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
    const driverDefaults = {
        nightOut: NightOutPreference.YES,
        site: undefined as string | undefined,
        group: undefined as string | undefined,
        earliestStartTime: '',
        licences: [] as LicenceCategory[],
        tachoNumber: '',
        assessedDate: '',
        firstName: '',
        lastName: '',
        bambooId: ''
    }
    const [deletedDriversMatch, setDeletedDriversMatch] = useState<DriverResponse[]>()
    const [showUsernameModal, setShowUsernameModal] = useState(false)
    const firstName = useInput(driverDefaults.firstName, [Validators.required])
    const lastName = useInput(driverDefaults.lastName, [Validators.required])
    const email = useInput('', [Validators.required, Validators.email])
    const username = useInput('')
    const phone = useInput('', [Validators.required])
    const bambooId = useInput('')
    const tacho = useInput(driverDefaults.tachoNumber, [Validators.required])
    const licences = useInput(driverDefaults.licences, [Validators.required])
    const expirationDate = useInput<Moment>(moment(), [Validators.required])
    const nightOut = useInput<NightOutPreference>(driverDefaults.nightOut, [Validators.required])
    const site = useInput(driverDefaults.site, [Validators.required])
    const group = useInput(driverDefaults.group, [])
    const startTime = useInput<Moment>(moment(), [Validators.required])
    const rotaStartDate = useInput<Moment>(moment(), [Validators.required])
    const rotaType = useInput<RotaType>('4_on_4_off', [Validators.required])
    const driverForm = form([firstName, lastName, email, phone, tacho, licences, expirationDate, nightOut, site, group, startTime])
    const [sites, setSites] = useState<{ id: string, name: string }[]>([])
    const [groups, setGroups] = useState<{ id: string, name: string }[]>([])
    const [driverAgencyData, setDriverAgencyData] = useState<{
        operatorEnabled: boolean,
        driverData?: DriverAgencyData
    }>({operatorEnabled: false})
    const [driverBambooIntegrationEnbaled, setDriverBambooIntegrationEnbaled] = useState<boolean>(false)
    // @ts-ignore
    const licenceOptions = Object.keys(LicenceCategory).map(l => ({id: l, name: LicenceLabels[l] ?? l}))
    console.log('edit run')
    useEffect(() => {
        Promise.all([c2ApiClient.getOperatorSites(), c2ApiClient.getOperatorGroups(), c2ApiClient.getOperatorBambooConfig()])
            .then(([sitesData, groups, bambooConfig]) => {
                setSites(sitesData.sites.map(site => ({id: site.id, name: site.name})))
                setGroups([{id: '', name: '--None--'}, ...groups.groups.map(g => ({id: g.id, name: g.name}))])
                setDriverBambooIntegrationEnbaled(Boolean(bambooConfig?.bambooIntegrationEnbaled))
            })

    }, [])
    useEffect(() => {
        if (driverId) {

            console.log('fetching driver')
            c2ApiClient.getDriver(driverId).then(driver => {
                firstName.setValue(driver.firstName)
                lastName.setValue(driver.lastName)
                bambooId.setValue(driver.bambooId)
                // email.setValue()
                tacho.setValue(driver.tachoNumber)
                licences.setValue(driver.licences)
                expirationDate.setValue(moment(driver.expirationDate, 'YYYY-MM-DD'))
                nightOut.setValue(driver.nightOut)
                phone.setValue(driver.phone)
                if (driver.site?.id) {
                    site.setValue(driver.site?.id)
                }
                if (driver.group) {
                    group.setValue(driver.group?.id)
                }
                const parsedTime = moment(driver.earliestStartTime, 'HH:mm')
                startTime.setValue(parsedTime)
                email.setValue(driver.user.email)
                username.setValue(driver.user.username)
                rotaStartDate.setValue(moment(driver.rotaStartDate, 'YYYY-MM-DD'))
                rotaType.setValue(driver.rotaType)
            })
            c2ApiClient.getOperatorInvoiceConfig().then((config) => {
                return config?.invoicingEnabled ? c2ApiClient.getDriverAgencyData(driverId!).then(data => ({
                    enabled: true,
                    driverAgencyData: data
                })) : Promise.resolve({enabled: false, driverAgencyData: undefined})
            }).then(({enabled, driverAgencyData}) => {
                setDriverAgencyData({operatorEnabled: enabled, driverData: driverAgencyData})
            })
        }
    }, [driverId, c2ApiClient])

    const handleSubmit = () => {
        if (!driverForm.valid) {
            return;
        }
        if (driverId) return handleSave(driverId)
        checkDriversDeletedMatch()

    }

    const checkDriversDeletedMatch = () => {
        c2ApiClient.checkCreateDriverInput({
            firstName: firstName.value!,
            lastName: lastName.value!,
            tachoNumber: tacho.value!,
            email: email.value!,
        }).then((data) => {
            if(!data.length) {
                setShowUsernameModal(true)
            } else {
                setDeletedDriversMatch(data)
            }
        })
    }
    const handleSave = (driverId?: string, username?: string) => {
        if (!driverForm.valid) {
            return;
        }
        if (!driverId && !username) {
            return;
        }
        const save$ = driverId ?
            c2ApiClient.editDriver({
                id: driverId,
                firstName: firstName.value!,
                lastName: lastName.value!,
                phone: phone.value!,
                bambooId: bambooId.value!,
                nightOut: nightOut.value!,
                tachoNumber: tacho.value!,
                email: email.value!,
                licences: licences.value!,
                earliestStartTime: startTime.value!.format('HH:mm'),
                expirationDate: expirationDate.value!.format('YYYY-MM-DD'),
                siteId: site.value!,
                groupId: group.value || undefined,
                rotaType: rotaType.value!,
                rotaStartDate: rotaStartDate.value!.format('YYYY-MM-DD')
            }) :
            c2ApiClient.createDriver({
                firstName: firstName.value!,
                lastName: lastName.value!,
                phone: phone.value!,
                bambooId: bambooId.value!,
                nightOut: nightOut.value!,
                tachoNumber: tacho.value!,
                email: email.value!,
                username: username!,
                licences: licences.value!,
                earliestStartTime: startTime.value!.format('HH:mm'),
                expirationDate: expirationDate.value!.format('YYYY-MM-DD'),
                siteId: site.value!,
                groupId: group.value || undefined,
                rotaType: rotaType.value!,
                rotaStartDate: rotaStartDate.value!.format('YYYY-MM-DD')
            })
        save$.then(() => routingContext.navigate(RoutePaths.DRIVERS_MANAGEMENT.getPath()))
    }

    const handleUsernameModal = (params?: { username: string; }) => {
        params && handleSave(undefined, params.username)
        setShowUsernameModal(false)
    }

    const handleDeletedDriversModal = (params?: {chosenId: string}) => {
        if(!params) {
            setShowUsernameModal(true)
            return
        }
        handleSave(params.chosenId)
    }
    return (
        <OperatorAdminSettingsForm size='large' disableSubmit={!driverForm.valid} editCallback={handleSubmit}
                                   title={driverId ? "Edit Driver" : "New Driver"}
                                   submitLabel="Save Driver" cancelRoute={RoutePaths.DRIVERS_MANAGEMENT.getPath()}>
            <UsernameModal firstName={firstName.value!} lastName={lastName.value!} handle={handleUsernameModal}
                           open={showUsernameModal}/>
            <ReviewDeletedDriversModal open={(deletedDriversMatch?.length ?? 0) > 0} deletedDriversMatch={deletedDriversMatch} dismiss={() => setDeletedDriversMatch(undefined)}
                                       handle={handleDeletedDriversModal}
                                       />
            <div className='d-flex justify-content-between'>
                <VerticalGroup>
                    <TextFormField input={firstName} name='firstName' label='First Name'/>
                    <TextFormField input={lastName} name='lastName' label='Last Name'/>
                    <TextFormField input={email} name='email' type='email' label='Email' disabled={!!driverId}/>
                    {username.value &&
                        <TextFormField input={username} name='username' label='Username' disabled={true}/>}
                    <PhoneNumber input={phone} label='Mobile'/>
                    {driverBambooIntegrationEnbaled &&
                        <TextFormField input={bambooId} name='bambooId' label='Bamboo ID'/>}
                </VerticalGroup>
                <VerticalGroup>
                    <TextFormField input={tacho} name='tacho' label='Tacho Number'/>
                    <MultiSelectField input={licences} options={licenceOptions} label='Class of Licence'/>
                    <DateInput input={expirationDate} label={'Expiry Date'}/>
                    <Checkbox input={nightOut} options={[{id: NightOutPreference.YES, name: 'Yes'}, {
                        id: NightOutPreference.NO,
                        name: 'No'
                    }, {id: NightOutPreference.POSS, name: 'Poss'}]} label='Night Out'/>
                </VerticalGroup>
                <VerticalGroup>
                    <SingleSelect input={site} options={sites} label='Site'/>
                    <SingleSelect input={group} options={groups} label='Group'/>
                    <TimeInput label='Earliest Start Time' input={startTime}/>
                    <SingleSelect input={rotaType as InputHook<string>}
                                  options={Object.entries(RotaTypeOptions).map(([k, v]) => ({id: k, name: v}))}
                                  label='Rota Type'/>
                    <DateInput input={rotaStartDate} label={'Rota Start Date'}/>
                </VerticalGroup>
            </div>
            {driverAgencyData.operatorEnabled && (
                <div>
                    <h3>Driver Agency Data</h3>
                    <DriverAgencyDataView driverAgencyData={driverAgencyData.driverData}/>
                </div>
            )}
        </OperatorAdminSettingsForm>
    )
}

function MultiSelectField<T extends string>({
                                                input,
                                                options,
                                                label
                                            }: {
    input: InputHook<T[]>,
    options: { id: string, name: string }[],
    label: string
}) {
    return (
        <Labeled label={label}>
            <SelectInput
                input={input as InputHook<string[]>}
                options={options}
                multiple={true}
            />
        </Labeled>
    )
}

function SingleSelect({
                          input,
                          options,
                          label
                      }: { input: InputHook<string>, options: { id: string, name: string }[], label: string }) {
    return (
        <Labeled label={label}>
            <SelectInput
                input={mapInput(input, val => [val ?? ''], ([newV]) => newV!)}
                options={options}
                multiple={false}
            />
        </Labeled>
    )
}

function Checkbox({
                      input,
                      options,
                      label
                  }: {
    input: InputHook<NightOutPreference>,
    options: { id: NightOutPreference, name: string }[],
    label: string
}) {
    return (
        <Labeled label={label}>
            <RadioGroup input={input} options={options}/>
        </Labeled>
    )
}

function DriverAgencyDataView({driverAgencyData}: { driverAgencyData?: DriverAgencyData }) {
    const companyName = useInput(driverAgencyData?.companyName ?? 'N/A', [Validators.stringNotEquals('N/A')])
    const companyAddress = useInput(driverAgencyData?.companyAddress ?? 'N/A', [Validators.stringNotEquals('N/A')])
    const companyNumber = useInput(driverAgencyData?.companyNumber ?? 'N/A', [Validators.stringNotEquals('N/A')])
    const accountNumber = useInput(driverAgencyData?.accountNumber ?? 'N/A', [Validators.stringNotEquals('N/A')])
    const sortCode = useInput(driverAgencyData?.sortCode ?? 'N/A', [Validators.stringNotEquals('N/A')])

    return (
        <div className='d-flex flex-wrap' style={{gap: '10px'}}>
            <TextFormField input={companyName} name='company-name' label='Company Name' disabled={true}/>
            <TextFormField input={companyAddress} name='company-address' label='Company Address' disabled={true}/>
            <TextFormField input={companyNumber} name='company-number' label='Company Number' disabled={true}/>
            <TextFormField input={accountNumber} name='account-number' label='Account Number' disabled={true}/>
            <TextFormField input={sortCode} name='sort-code' label='Sort Code' disabled={true}/>
        </div>
    )
}
