import styles from "./driver-rota-rounds-dialog.module.css";
import {Button} from "../../components/button/Button";
import {DriverRotaRound, RotaType, RoundModel} from "../../client/c2-api-client";
import Dialog from "@mui/material/Dialog";
import {useEffect, useMemo, useState} from "react";
import {useInput} from "../../hooks/input";
import {SelectInput} from "../../components/input/select/select";
import {timeService} from "../../services/time-service";
import moment, {Moment} from "moment";

export type RoundAssignment = { roundId?: string | undefined, date: string }
type DriverRotaRoundsDialogProps = {
    allRounds: RoundModel[]
    driverRounds: DriverRotaRound[],
    driverRota: RotaType,
    driverRotaStart: string,
    onClose: (rounds?: RoundAssignment[]) => void
}
export const rotaDays: Record<RotaType, { cycle: number, working: number }> = {
    "1_on_5_off": {cycle: 6, working: 1},
    "2_on_5_off": {cycle: 7, working: 2},
    "2_on_2_off": {cycle: 4, working: 2},
    "4_on_2_off": {cycle: 6, working: 4},
    "4_on_3_off": {cycle: 7, working: 4},
    "4_on_4_off": {cycle: 8, working: 4},
    "5_on_2_off": {cycle: 7, working: 5},
    "5_on_3_off": {cycle: 8, working: 5},
    "7_on_0_off": {cycle: 7, working: 7},
};

function RotaWorkingDayRoundInput({
                                      rounds,
                                      disabled,
                                      selectedRound,
                                      onChange
                                  }: {
    rounds: RoundModel[],
    disabled: boolean,
    selectedRound?: string,
    onChange: (id?: string) => void
}) {
    const input = useInput([selectedRound ?? ''], [])
    const options = [{id: '', name: '', disabled: false} as {
        id: string,
        name: string,
        disabled?: boolean
    }].concat(rounds.map(opt => ({
        id: opt.id,
        name: opt.name
    })))
    useEffect(() => {
        onChange(input.value ? input.value[0] : undefined)
    }, [input.value ? input.value[0] : ''])
    return <SelectInput disabled={disabled} input={input} multiple={false} options={options}/>
}

type DayRoundAssignmentProps = {
    day: Moment,
    rotaStatus?: boolean,
    driverRound?: string,
    rounds: RoundModel[],
    setRound: (roundId?: string) => void
}

function DayRoundAssignment({
                                day,
                                driverRound,
                                rounds,
                                rotaStatus,
                                setRound
                            }: DayRoundAssignmentProps) {
    const dayName = day.format('dddd')
    const date = timeService.formatDate(day)
    const isToday = timeService.daysDiff(day, moment()) === 0
    const [excludedRound, setExcludedRound] = useState(!!driverRound && dayToExcluded(day.day(), rounds.find(r => r.id === driverRound)!))
    const onRoundChange = (roundId: string | undefined) => {
        setExcludedRound(!!roundId && dayToExcluded(day.day(), rounds.find((r) => r.id === roundId)!))
        setRound(roundId)
    }
    return (
        <div className='flex-fill' style={{padding: '10px 0px', width: '100%'}}>
            <div>{dayName} {isToday ? ' (Today)' : ''}<br/> ({date} {rotaStatus ? 'Working' : 'Off'})
            </div>
            <div>
                <RotaWorkingDayRoundInput key={date} disabled={!rotaStatus} selectedRound={driverRound}
                                          rounds={rounds}
                                          onChange={(roundId) => onRoundChange(roundId)}/>
                {excludedRound ? <span className='small-text'>Round Excluded on {dayName}s</span> : null}
            </div>
        </div>
    )
}

const getStartDay = ({cycle, rotaStart, today}: {
    rotaStart: moment.Moment;
    today: moment.Moment;
    cycle: number
}): Moment => {
    const remainder = timeService.daysDiff(rotaStart, today) % cycle
    return moment(today).add(-remainder, 'days')
}

export function DriverRotaRoundsDialog({
                                           allRounds,
                                           driverRota,
                                           driverRounds,
                                           driverRotaStart,
                                           onClose
                                       }: DriverRotaRoundsDialogProps) {
    const rotaStartMoment = moment(driverRotaStart)
    const today = moment()
    const {cycle} = rotaDays[driverRota]

    const startDay = getStartDay({cycle, rotaStart: rotaStartMoment, today})
    const days = Array(cycle * 2).fill(undefined).map((_, i) => moment(startDay).add(i, 'days'))
    const [rounds, setRounds] = useState<RoundAssignment[]>(days.map(d => ({date: timeService.formatDate(d)})))
    const driverRoundsByDate = useMemo(() => {
        return driverRounds.reduce((map, round) => {
            const {firstDate} = round
            const firstDateMoment = timeService.parseDate(firstDate)
            const day = days.find((d) => timeService.daysDiff(firstDateMoment, d) % (cycle * 2) === 0)
            if (day) {
                map.set(timeService.formatDate(day), round.roundId)
            }
            return map
        }, new Map<string, string>())
    }, [driverRounds])

    const rotaStatus = daysToRotationStatus({days, driverRota, driverRotaStart})

    const setRound = (index: number, roundId?: string) => {
        setRounds((val) => {
            const newVal = val.slice()
            newVal[index] = {roundId, date: val[index].date}
            return newVal
        })
    }

    const assignRounds = () => {
        const result = rounds.filter(r => r.roundId)
        onClose(result)
    }
    const dayAssignments: DayRoundAssignmentProps[] = days.map((day, index) => {
        const dayFormatted = timeService.formatDate(day);
        return {
            day,
            driverRound: driverRoundsByDate.get(dayFormatted),
            rounds: allRounds,
            rotaStatus: rotaStatus.get(dayFormatted),
            setRound: roundId => setRound(index, roundId)
        }
    })

    return (

        <Dialog
            PaperProps={{classes: {root: styles.assignDialogRoot}}}
            disableScrollLock={true}
            open={true}>
            <h4 style={{padding: '20px'}}>Set Driver Rotation Rounds</h4>
            <p>Assigning Driver to Round in this screen is recurring every two rotation cycles, so excluded round label is just informative.</p>
            <p>To assign just for specific dates, please use Driver Resources Planning screen</p>
            <div className='d-flex flex-row justify-content-center' style={{gap: '10px'}}>
                {
                    dayAssignments.filter((_, index) => index < cycle).map((props, index) =>
                        <DayRoundAssignment key={index} {...props} />
                    )
                }
            </div>
            <div className='d-flex flex-row justify-content-center' style={{gap: '10px'}}>
                {
                    dayAssignments.filter((_, index) => index >= cycle).map((props, index) =>
                        <DayRoundAssignment key={index} {...props} />
                    )
                }
            </div>
            <div style={{padding: '10px 0px', width: '100%'}}>
                <Button width={"fill"} disabled={!rounds.every(r => !!r)} onClick={assignRounds}>Confirm</Button>
            </div>
            <Button onClick={() => onClose()}>Cancel</Button>
        </Dialog>
    )
}


function daysToRotationStatus({
                                  driverRota,
                                  driverRotaStart,
                                  days
                              }: {
    driverRota: RotaType;
    driverRotaStart: string;
    days: Moment[]
}): Map<string, boolean> {
    const {cycle, working} = rotaDays[driverRota]
    const rotaStartMoment = moment(driverRotaStart);
    return days.reduce((map, day) => {
        const daysFromRotaStart = day.diff(rotaStartMoment, 'days');
        map.set(timeService.formatDate(day), daysFromRotaStart >= 0 && daysFromRotaStart % cycle < working)
        return map
    }, new Map<string, boolean>())
}

const dayToExcluded = (day: number, round: RoundModel): boolean => {
    const excludes = [round.sundayQuota, round.mondayQuota, round.tuesdayQuota, round.wednesdayQuota, round.thursdayQuota, round.fridayQuota, round.saturdayQuota]
    return excludes[day] === 0
}