export const QrIcon = () => <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 290.625 290.625"
  xmlSpace="preserve"
>
  <path
    style={{
      fill: "#f9ba48",
    }}
    d="M18.75 18.75h46.875v46.875H18.75zM225 18.75h46.875v46.875H225zM18.75 225h46.875v46.875H18.75z"
  />
  <path
    style={{
      fill: "#18c671",
    }}
    d="M0 0v290.625h290.625V0H0zm281.25 281.25H9.375V9.375H281.25V281.25z"
  />
  <path
    style={{
      fill: "#18c671",
    }}
    d="M37.5 37.5h9.375v9.375H37.5zM243.75 37.5h9.375v9.375h-9.375zM37.5 243.75h9.375v9.375H37.5zM37.5 103.125h18.75V93.75H37.5v-9.375h46.875v18.75h9.375v-18.75h65.625v18.75h9.375v-18.75h28.125V75H18.75v9.375h9.375V112.5H37.5zM18.75 112.5h9.375v103.125H18.75zM37.5 206.25h9.375v9.375H37.5zM56.25 215.625h46.875v18.75h9.375v-18.75H150v-9.375h-28.125V187.5H112.5v18.75H56.25zM37.5 196.875h9.375V150h18.75v-9.375h-18.75v-18.75H37.5zM46.875 112.5h9.375v9.375h-9.375z"
  />
  <path
    style={{
      fill: "#18c671",
    }}
    d="M56.25 103.125h9.375v9.375H56.25zM65.625 93.75H75v9.375h-9.375zM84.375 37.5H112.5v-9.375H84.375V18.75H75v46.875h9.375z"
  />
  <path
    style={{
      fill: "#18c671",
    }}
    d="M93.75 46.875h9.375v9.375H93.75zM103.125 56.25h9.375v9.375h-9.375zM112.5 37.5h9.375v9.375H112.5zM121.875 28.125h9.375V37.5h-9.375zM131.25 18.75h9.375v9.375h-9.375zM187.5 18.75h28.125v9.375H187.5zM206.25 37.5h9.375v28.125h-9.375zM178.125 37.5h18.75v9.375h-18.75zM187.5 56.25h9.375v9.375H187.5zM168.75 56.25h9.375v9.375h-9.375zM168.75 56.25v-9.375H150V37.5h-9.375v9.375h-18.75v9.375zM159.375 37.5h9.375v-9.375h9.375V18.75H150v9.375h9.375zM75 112.5h18.75v9.375H75zM103.125 103.125h18.75v9.375h-18.75zM121.875 93.75H150v9.375h-28.125zM178.125 93.75H225v9.375h-46.875zM215.625 75H225v9.375h-9.375zM234.375 75h37.5v9.375h-37.5zM262.5 93.75h9.375v28.125H262.5zM253.125 121.875h9.375v18.75h-9.375zM262.5 140.625h9.375v28.125H262.5zM262.5 178.125h9.375v18.75H262.5zM234.375 178.125h18.75v-18.75h-9.375v9.375h-9.375zM243.75 187.5h9.375v18.75h-9.375zM253.125 206.25v9.375h-18.75V225H262.5v-18.75zM253.125 271.875h9.375V262.5h9.375v-9.375H262.5v-18.75h-9.375zM262.5 225h9.375v9.375H262.5zM215.625 262.5h28.125v9.375h-28.125zM215.625 243.75h28.125v9.375h-28.125zM196.875 253.125h18.75v9.375h-18.75zM75 225h9.375v37.5H75zM56.25 187.5H75v9.375H56.25zM84.375 187.5h18.75v9.375h-18.75zM56.25 159.375h9.375v18.75H56.25zM65.625 121.875H75v18.75h-9.375zM84.375 131.25h37.5v9.375h-37.5zM75 150h18.75v9.375H75zM103.125 150h28.125v9.375h-28.125zM93.75 159.375h9.375v18.75H93.75zM112.5 168.75h18.75v9.375H112.5zM150 112.5h-9.375v65.625H150v-37.5h28.125v-9.375H150zM121.875 121.875h9.375v9.375h-9.375z"
  />
  <path
    style={{
      fill: "#18c671",
    }}
    d="M159.375 112.5v9.375h93.75V112.5h-9.375V93.75h-9.375v18.75zM159.375 150h9.375v37.5h-9.375zM150 196.875h28.125v9.375H150zM178.125 187.5H225v-9.375h-9.375v-18.75h18.75V150H206.25v28.125h-28.125z"
  />
  <path
    style={{
      fill: "#18c671",
    }}
    d="M187.5 140.625h9.375v28.125H187.5zM196.875 131.25H225v9.375h-28.125zM187.5 201.563h9.375v42.188H187.5zM206.25 225h28.125v9.375H206.25zM215.625 196.875H225v18.75h-9.375z"
  />
  <path
    style={{
      fill: "#18c671",
    }}
    d="M178.125 215.625h-9.375V262.5h-9.375v-18.75H150v18.75H84.375v9.375h112.5V262.5h-18.75z"
  />
  <path
    style={{
      fill: "#18c671",
    }}
    d="M93.75 243.75h28.125v9.375H93.75zM121.875 234.375h18.75v9.375h-18.75zM140.625 225H150v9.375h-9.375z"
  />
</svg>
