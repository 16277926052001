import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import styles from "./username-modal.module.css";
import {OperatorAdminSettingsForm} from "../operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {TextFormField} from "../input/composite/text-form-field/text-form-field";
import {CheckMarkIcon} from "../../icons/CheckMark";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient} from "../../client/c2-api-client";

export function UsernameModal({
                                        firstName,
                                        lastName,
                                        handle,
                                        open
                                      }: {firstName: string, lastName: string, handle: (params?: { username: string }) => void, open: boolean }) {
  const apiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const generatedUsername = useInput('', [Validators.required])
  const [unique, setUnique] = useState(true)

  const usernameForm = form([generatedUsername])

  useEffect(() => {
    if(open) {
      apiClient.generateUsername({
        firstName, lastName
      }).then(({username, unique: serverUnique}) => {
        generatedUsername.setValue(username, true)
        setUnique(serverUnique)

      })
    }
  }, [open, firstName, lastName])
  const submit = () => {
    apiClient.generateUsername({
      firstName, lastName, username: generatedUsername.value!
    }).then(({username, unique: serverUnique}) => {
      if(!serverUnique) {
        generatedUsername.setValue(username)
        generatedUsername.submit()
        setUnique(serverUnique)
      } else {
        handle({username: generatedUsername.value!})
      }
    })
  }
  return (
    <Dialog
      PaperProps={{classes: {root: styles.addShiftDialogRoot}}}
      disableScrollLock={true}
      open={open}>
      <OperatorAdminSettingsForm title={'Set Username'} submitLabel='Confirm' cancelCallback={() => handle()}
                                 disableSubmit={!usernameForm.valid || (!unique && generatedUsername.pristine)}
                                 paddingTop={false}
                                 editCallback={() => submit()}>
        <div className='d-flex flex-column' style={{gap: '20px'}}>

          <div>
            <TextFormField input={generatedUsername} name='username' label='Username' />
            {unique && <CheckMarkIcon />}
            {(!unique && generatedUsername.pristine) && <p style={{color: 'red'}}>Username not unique!</p>}
          </div>
        </div>
      </OperatorAdminSettingsForm>
    </Dialog>
  )
}
