import {InputHook, mapInput, useInput} from "../../../hooks/input";
import styles from "./phone-number.module.css";
import {Input} from "../text/Input";
import {useEffect, useState} from "react";
import {Labeled} from "../labeled/Labeled";

const countryCodeToFlag = {
  '44': '🇬🇧'
}
interface PhoneNumber {
  code: string
  number: string
}
function extractCodeAndPhone(fullNumber: string): PhoneNumber {
  const openBracketIndex = fullNumber.indexOf('(')
  const closeBracketIndex = fullNumber.indexOf(')')
  if(openBracketIndex < 0 || closeBracketIndex < 0) {
    return {code: '', number: fullNumber}
  }
  const code = fullNumber.substring(openBracketIndex + 1, closeBracketIndex)
  const number = fullNumber.substring(closeBracketIndex + 1)
  return {code, number}
}
function formNumber(num: PhoneNumber): string {
  if(!num.code && !num.number) {
    return ''
  }
  return `(${num.code})${num.number}`
}
export function PhoneNumber({input, label = 'Mobile'}: { input: InputHook<string>, label?: string }) {
  const phoneInput = mapInput<string, PhoneNumber>(input, (p) => extractCodeAndPhone(p ?? ''), arg => formNumber(arg))
  const numberInput = mapInput(phoneInput, p => p?.number, nu => {
    console.log('number mapper', nu)
    return ({code: phoneInput.value?.code ?? '44', number: nu ?? ''})
  })
  const codeInput = mapInput(phoneInput, p => p?.code, code => ({code: code ?? '44', number: phoneInput.value?.number ?? ''}))
  useEffect(() => {
    if(!codeInput.value) {
      codeInput.setValue('44')
    }
  }, [])
  console.log('running phone', input.value, phoneInput.value, numberInput.value, codeInput.value)

  const changeCode = (newCode: string) => {
    codeInput.setValue(newCode)
  }

  return <Labeled label={label}>
    <div>
      <Input name='mobile' inputHook={numberInput} layout='reverse'>
        <select className={styles.countryCodeSelect} value={codeInput.value ?? ''} onChange={ev => changeCode(ev.target.value)}>
          {Object.entries(countryCodeToFlag).map(([code, flag]) => <option key={code} value={code}>{flag}</option>)}
        </select>
      </Input>
    </div>
  </Labeled>
}
