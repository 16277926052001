import styles from './table-page-header.module.css'
import {ReactNode} from "react";

export function TablePageHeader({children}: {children: ReactNode}) {
  return (
    <div className={styles.headerRow}>
      {children}
    </div>
  )
}
