import profileIcon from '../../icons/profile-icon.svg'
import styles from './navigation-shell.module.css'
import headerStyles from '../header-bar/Header.module.css'
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useSessionContext} from "../../hooks/session-context";
import classNames from "classnames";
import {Link, useLocation} from "react-router-dom";
import {RoutePath, RoutePaths} from "../../app-router";
import {ConnectedLogo} from "../../icons/ConnectedLogo";
import {
    isActive
} from "../operator-admin-settings/operator-admin-settings-navigation/operator-admin-settings-navigation";
import {useRoutingContext} from "../../hooks/routing-context";
import {useInput} from "../../hooks/input";
import {Input} from "../input/text/Input";
import {Search} from "../../icons/Search";
import {CloseIcon} from "../../icons/close";

export interface NavigationShellProps {
    children: React.ReactNode;
}

export type SearchListener = (searchValue: string) => void

export type SearchContext = {
    attachListener: (listener: SearchListener) => number
    removeListener: (listenerId: number) => void
}
const SearchContext = React.createContext<SearchContext>({
    attachListener: () => -1, removeListener: () => {
    }
})

export function useSearchContext(): SearchContext {
    return useContext(SearchContext)
}

type HeaderLink = { routePath: RoutePath<void>, text: string }

export function NavigationShell(props: NavigationShellProps) {
    const searchHook = useInput('')
    const [searchListeners, setSearchListeners] = useState<Map<number, SearchListener>>(new Map())
    const searchContext = useRef<SearchContext>({
        attachListener: (listener: SearchListener) => {
            const listenerId = searchListeners.size
            setSearchListeners((currentListeners) => {
                return new Map(currentListeners).set(listenerId, listener)
            })
            return listenerId
        },
        removeListener: listenerId => {
            setSearchListeners((currentListeners) => {
                const map = new Map(currentListeners);
                map.delete(listenerId)
                return map
            })
        }
    })
    const hasListeners = useMemo(() => {
        console.log('search listeners', searchListeners)
        return searchListeners.size > 0
    }, [searchListeners])

    useEffect(() => {
        searchHook.setValue('')
        searchHook.submit()
    }, [searchListeners])
    const session = useSessionContext()
    const location = useLocation()
    const routingContext = useRoutingContext()

    const headerLinks: HeaderLink[] = [{
        text: 'Driver Management',
        routePath: RoutePaths.DRIVERS_MANAGEMENT
    }, {
        text: 'Driver Resources Planning', routePath: RoutePaths.DRIVERS_PLANNING
    }, {
        text: 'Timesheets', routePath: RoutePaths.TIMESHEETS
    }/*, {
    text: 'Agencies', routePath: RoutePaths.DRIVERS
  }*/, {text: 'Settings', routePath: RoutePaths.SETTINGS}]

    const submitSearch = () => {
        let listenerArg = searchHook.value ?? ''
        if (searchHook.value !== '' && searchHook.pristine) {
            searchHook.setValue('')
            listenerArg = ''
        }
        searchListeners.forEach(listener => listener(listenerArg))
        searchHook.submit()
    }
    const submitSearchEnter = () => {
        if(!searchHook.pristine) {
            submitSearch()
        }
    }
    return (
        <>
            <div className={classNames('d-flex align-items-stretch', headerStyles.headerRow)}>
                <div className={classNames(styles.navColumn, styles.newLogoContainer, 'd-flex align-items-center')}>
                    <Link to={routingContext.getPath(RoutePaths.HOME.getPath())} component={(navigator) => (
                        <div style={{cursor: 'pointer'}} onClick={() => navigator.navigate()}>
                            <ConnectedLogo/>
                        </div>
                    )}/>
                </div>
                <div
                    className={classNames('d-flex flex-fill align-items-stretch justify-content-start', styles.headerContainerLeft)}>
                    {headerLinks.map(l => (
                        <Link key={l.text} to={routingContext.getPath(l.routePath.getPath())}
                              component={(navigator) => {
                                  return <div onClick={navigator.navigate}
                                              className={classNames(styles.headerLink, 'text-300 d-flex align-items-center', {[styles.headerLinkActive]: isActive(location.pathname, routingContext.getPath(l.routePath.pathFormat))})}>{l.text}</div>
                              }}/>
                    ))}
                </div>
                <div className={classNames(styles.headerContainerRight, 'd-flex flex-row-reverse align-items-center')}>
                    <img src={profileIcon} style={{display: 'inline-block', marginLeft: '15px', cursor: 'pointer'}}
                         onClick={() => session.clearSession()}/>
                    <div style={{width: '330px'}}>
                        <Input name='search' onSubmit={submitSearchEnter} inputHook={searchHook} disabled={!hasListeners}
                               variant='rounded' size='small' layout='reverse'>
                            <div className={classNames(styles.searchContainer, 'd-flex align-items-center')}>
                                <div onClick={submitSearch}
                                     className={classNames(styles.searchIcon, hasListeners ? styles.clickableIcon : '')}>
                                    {searchHook.pristine && searchHook.value !== '' ? <CloseIcon/> : <Search/>}
                                </div>
                            </div>
                        </Input>
                    </div>
                </div>
            </div>
            <div className={classNames('d-flex flex-fill align-items-stretch', styles.contentRow)}>
                <div className='flex-fill'>
                    <SearchContext.Provider value={searchContext.current}>
                        {props.children}
                    </SearchContext.Provider>
                </div>
            </div>
        </>
    )
}
