import {Dialog} from "@mui/material";
import styles from "./filter-multiselect-modal.module.css";
import dropdownFilterStyles from '../dropdown-filter-entry/dropdown-filter-entry.module.css'
import React, {useState} from "react";
import {Button} from "../button/Button";
import {ArrowDownIcon} from "../../icons/ArrowDown";
import classNames from "classnames";

type SelectionModel = {id: string, name: string}
export function FilterMultiSelectModal({label, availableSelections, includeNA = false, labelForNA = 'Include N/A', currentSelection, setFilter}: {
    label: string,
    availableSelections: SelectionModel[],
    currentSelection?: string[] | undefined,
    includeNA?: boolean
    labelForNA?: string
    setFilter: (selection: string[] | undefined) => void
}) {
    const [selection, setSelection] = useState<string[]>([])
    const toggleSelection = (id: string) => {
        setSelection(current => current.includes(id) ? current.filter(c => c !== id) : current.concat([id]))
    }
    const [open, setOpen] = useState(false)

    const selectedNames = (currentSelection?.length ?? 0) < 1 ? [] :
        availableSelections.filter(({id}) => currentSelection?.includes(id) ?? false)
            .map(({name}) => name).concat(includeNA && currentSelection?.includes('') ? ['N/A'] : [])

    const openModal = () => {
        setSelection(currentSelection ?? [])
        setOpen(true)
    }
    const selectFilter = () => {
        setOpen(false)
        setFilter(selection)
    }
    const selectAll = () => setSelection(availableSelections.map(({id}) => id).concat(includeNA && selection?.includes('') ? [''] : []))
    const clearSelection = () => setSelection(([] as string[]).concat(includeNA && selection?.includes('') ? [''] : []))

    const resetFilter = () => {
        setSelection([])
        setOpen(false)
        setFilter(undefined)
    }
    return (<>
            <div style={{cursor: 'pointer'}} className={dropdownFilterStyles.filterEntry} onClick={openModal}><ArrowDownIcon/>{label}: <SelectionLabel
                selection={selectedNames} /></div>
            <Dialog
                PaperProps={{classes: {root: styles.filterMultiselectModalRoot}}}
                disableScrollLock={true} open={open}>
                <div className='d-flex justify-content-start' style={{gap: '15px'}}>
                    <Button onClick={selectAll}>Select All</Button>
                    <Button onClick={clearSelection}>Select None</Button>
                </div>
                <div className='d-flex flex-wrap align-items-lg-start' style={{minHeight: '50vh'}}>
                    {availableSelections.map(item => (
                        <FilterItemCheckbox key={item.id} checked={selection.includes(item.id)}
                                             onChange={() => toggleSelection(item.id)} label={item.name}/>
                    ))}

                </div>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-start'>
                        {includeNA && <FilterItemCheckbox noTopMargin={true} checked={selection.includes('')} onChange={() => toggleSelection('')} label={labelForNA} />}

                        <div style={{width: '15px', display: 'inline-block'}}></div>
                        <Button colorScheme='negative' onClick={resetFilter}>Clear Filter</Button>
                    </div>
                    <div>
                        <Button colorScheme='positive' disabled={selection.length < 1}
                                onClick={selectFilter}>Select</Button>
                        <div style={{width: '15px', display: 'inline-block'}}></div>
                        <Button colorScheme='negative' onClick={() => setOpen(false)}>Cancel</Button>
                    </div>
                </div>
            </Dialog>
        </>
    )

}

function FilterItemCheckbox({checked, label, onChange, noTopMargin}: { checked: boolean, onChange: () => void, label: string, noTopMargin?: boolean }) {
    return (
        <div className={classNames(noTopMargin ? styles.noTopMargin : '', styles.itemContainer)}>
            <label className="checkbox-container">
                <input type="checkbox" checked={checked}
                       onChange={onChange}/>
                <span className="checkbox-label-text">{label}</span>
            </label>
        </div>
    )
}

function SelectionLabel({selection}: {
    selection: string[] | undefined,
}) {
    const replaceNA = selection?.map((name) => !!name ? name : 'N/A')
    if ((replaceNA?.length ?? 0) < 1) {
        return <>All</>
    }
    if (replaceNA?.length === 1) {
        return <>{replaceNA[0]}</>
    }
    return <b>Multiple</b>
}
