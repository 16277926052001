import * as React from "react"
type ReceiptIconColor = 'green' | 'orange' | 'gray'

const colorToFill: Record<ReceiptIconColor, string> = {
  green: '#18C671',
  gray: 'rgba(0, 0, 0, 0.33)',
  orange: '#F2994A'
}
export function ReceiptIcon({color = 'green'}: {color?: ReceiptIconColor}) {
  const fill = colorToFill[color]
  return (
    <svg width={13} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m2.554 12.463.993.745.993-.745a.71.71 0 0 1 .852 0l.993.745.993-.745a.71.71 0 0 1 .852 0l.993.745.993-.745a.71.71 0 0 1 .88.022l.965.804V.711l-.965.804a.71.71 0 0 1-.88.022L9.223.792l-.993.745a.71.71 0 0 1-.852 0L6.385.792l-.993.745a.71.71 0 0 1-.852 0L3.547.792l-.993.745a.71.71 0 0 1-.88-.022L.71.71v12.578l.965-.804a.71.71 0 0 1 .88-.022ZM3.122.225 2.128.97 1.164.166A.71.71 0 0 0 0 .71v12.578a.71.71 0 0 0 1.164.545l.964-.804.994.745c.252.19.599.19.85 0l.994-.745.993.745c.253.19.6.19.852 0l.993-.745.993.745c.252.19.6.19.851 0l.994-.745.964.804a.71.71 0 0 0 1.164-.545V.711a.71.71 0 0 0-1.164-.545l-.964.804-.994-.745a.71.71 0 0 0-.85 0L7.803.97 6.81.225a.71.71 0 0 0-.852 0L4.966.97 3.973.225a.71.71 0 0 0-.851 0Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.547 5.226h5.676a.355.355 0 0 1 0 .71H3.547a.355.355 0 0 1 0-.71ZM3.547 8.064h2.838a.355.355 0 0 1 0 .71H3.547a.355.355 0 0 1 0-.71Z"
        fill={fill}
      />
    </svg>
  )
}
