import React, {useEffect, useRef, useState} from "react";
import QrScanner from "qr-scanner";
import {useRoutingContext} from "../../hooks/routing-context";
import {RoutePaths} from "../../app-router";

export function ScanSiteCode() {

  const routing = useRoutingContext()
  const qrScanner = useRef<QrScanner>()
  const [hasCamera, setHasCamera] = useState<{loading: true} | {loading: false, hasCam: boolean}>({loading: true})
  const scanRes = hasCamera.loading ? 'Initializing Camera' : (hasCamera.hasCam ?
    `Camera detected, please find the QR code on Your site and scan it to start a shift` :
  `No camera detected, please try to reload and allow Your browser to use device camera, or take a picture yourself and upload it on the button bellow`)
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null)

  useEffect(() => {
    QrScanner.hasCamera()
      .then(hasCam => {
        setHasCamera({loading: false, hasCam})
      })
  }, [])
  useEffect(() => {
    if(videoRef && hasCamera.loading === false && hasCamera.hasCam) {
      qrScanner.current = new QrScanner(videoRef, res => {
        console.log('scanned', res)
        if (/(\d|-)+/.exec(res) !== null) {
          routing.navigate(RoutePaths.START_SITE_SHIFT.getPath({siteId: res}))
          qrScanner.current?.destroy()

        }
      })
      qrScanner.current?.start()
    }

  }, [videoRef ?? false, (hasCamera.loading) ?? false])
  const acceptFileInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const files = (ev.target as HTMLInputElement).files
    const selection: File | undefined = (files ?? [])[0]
    console.log('in change', ev.target.files, selection)
    if(selection) {
      QrScanner.scanImage(selection, {returnDetailedScanResult: true})
        .then(result => {
          const res = result.data
          if (/(\d|-)+/.exec(res) !== null) {
            routing.navigate(RoutePaths.START_SITE_SHIFT.getPath({siteId: res}))

          }
        })

    }

  }
  useEffect(() => {
    return () => qrScanner.current?.destroy()
  }, [])
  return (
    <div className='flex-fill d-flex flex-column'>
      <h1>Scan QR Code For Your Site</h1>
      <p>{scanRes}</p>
      <video ref={setVideoRef} style={{width: '100%'}}></video>
      <input type='file' accept='image/*' onChange={acceptFileInput} />
      {(hasCamera.loading === false && hasCamera.hasCam === false) ?  <input type='file' accept='image/*' onChange={acceptFileInput} /> : null}
    </div>
  )
}
