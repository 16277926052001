
export function Download() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14}>
      <path
        fillRule="evenodd"
        d="M8 7.047 9.216 5.89a1.054 1.054 0 0 1 1.478 0 1.024 1.024 0 0 1 0 1.46L7 11 3.306 7.35a1.024 1.024 0 0 1 0-1.46 1.054 1.054 0 0 1 1.478 0l1.171 1.157L6 2.032C6 1.462 6.423 1 7 1s1 .462 1 1.032v5.015ZM12 12a1 1 0 0 1-1 1H3a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1Z"
      />
    </svg>
  )
}
