import React from "react";
import styles from './Button.module.css';
import classNames from "classnames";

type ButtonShape = 'rounded' | 'semi-rounded'
type ButtonSize = 'large' | 'normal' | 'small'
type ButtonColor = 'positive' | 'neutral' | 'negative' | 'blank'
type ButtonWidth = 'fit' | 'fill'
export interface ButtonProps {
  type?: 'button' | 'submit'
  colorScheme?: ButtonColor
  shape?: ButtonShape
  size?: ButtonSize
  width?: ButtonWidth
  disabled?: boolean;
  onClick?: React.MouseEventHandler
  children: React.ReactNode;
  withoutPadding?: boolean
}

const defaultProps: Required<Pick<ButtonProps, 'type' | 'colorScheme' | 'shape' | 'size' | 'disabled' | 'onClick' | 'width' | 'withoutPadding'>> = {
  disabled: false,
  colorScheme: 'neutral',
  size: 'normal',
  shape: 'semi-rounded',
  type: 'button',
  width: 'fit',
  onClick: () => {
  },
  withoutPadding: false
}
const colorClass: Record<ButtonColor, string> = {
  blank: styles.buttonBlank,
  neutral: styles.buttonNeutral,
  negative: styles.buttonNegative,
  positive: styles.buttonPositive
}

const shapeClass: Record<ButtonShape, string> = {
  rounded: styles.shapeRounded,
  'semi-rounded': styles.shapeSemiRounded
}
const sizeClass: Record<ButtonSize, string> = {
  small: styles.buttonSmall,
  normal: '',
  large: styles.buttonLarge,
}
const widthClass: Record<ButtonWidth, string> = {
  fill: styles.fillWidth,
  fit: ''
}
export function Button(props: ButtonProps) {
  const propsWithDefaults = {...defaultProps, ...props}
  const {children, colorScheme, disabled, onClick, shape, size, type, width, withoutPadding } = propsWithDefaults
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames(styles.button, !withoutPadding && styles.padding, shapeClass[shape], colorClass[colorScheme], sizeClass[size], widthClass[width])}>
      {children}
    </button>
  );
}
