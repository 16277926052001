import * as React from "react"

export function ArrowRight() {
  return (
    <svg width={6} height={11} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m.501.668-.335.344a.58.58 0 0 0-.162.406.58.58 0 0 0 .162.406l3.796 3.924-3.8 3.928a.58.58 0 0 0-.162.406.58.58 0 0 0 .162.406l.333.344c.217.224.57.224.786 0l4.54-4.677A.596.596 0 0 0 6 5.748v-.002a.596.596 0 0 0-.178-.406L1.293.668a.552.552 0 0 0-.792 0Z"
        fill="#ABABAB"
      />
    </svg>
  )
}
