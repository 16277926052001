import {Session, useSession} from "../../../hooks/session";
import {sessionContext} from "../../../hooks/session-context";
import React, {useEffect, useRef, useState} from "react";
import {clientFactory, SessionInfo} from "../../../client/c2-api-client";
import {apiClientContext} from "../../../hooks/api-client-context";
import styles from './session-and-client-provider.module.css'
import {ErrorHandler} from "../../../client/rest-service";
import classnames from "classnames";
import {useHistory} from "react-router-dom";
import {RoutePaths} from "../../../app-router";

export function SessionAndClientProvider({children, session: propsSession}: { children: React.ReactNode, session?: Session }) {
  const history = useHistory()
  const [session, setSession] = useSession(propsSession);
  const [error, setError] = useState<string | null>(null)
  const showErrorHandler: ErrorHandler = e => {
    setError(e.message ?? 'Unknown error occurred')
  }
  const unauthorizedErrorHandler = (e: Error) => {
    if((e as any).code === 403) {
      setSession(undefined)
      history.push(RoutePaths.LOGIN.getPath());
    }
  }
  const timeoutRef = useRef<number | null>(null)
  const clearTimeout = () => {
    if(timeoutRef.current) {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }
  useEffect(() => {
    if(error) {
      timeoutRef.current = window.setTimeout(() => {
        setError(null)
      }, 5000)
    }
    return clearTimeout
  }, [error])
  const closeError = () => {
    clearTimeout()
    setError(null)
  }
  const apiClient = clientFactory(session, [showErrorHandler, unauthorizedErrorHandler])
  const context = {
    session,
    setSession: (s: SessionInfo) => {
      setSession(s);
    },
    clearSession: () => {
      setSession(undefined)
    }
  }
  return (
    <sessionContext.Provider value={context}>
      <apiClientContext.Provider value={apiClient}>
        {error && (
          <div onClick={closeError} className={classnames(styles.errorContainer, 'text-700')}>
            {error}
          </div>
        )}
        {children}
      </apiClientContext.Provider>
    </sessionContext.Provider>
  )
}
