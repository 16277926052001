import {TableHook} from "../../hooks/table";
import {DriverFilters} from "../../client/c2-api-client";
import {TableFilterBar} from "../table-filter-bar/table-filter-bar";
import {FilterIcon} from "../../icons/Filter";
import {Fragment, ReactNode} from "react";


export type FiltersSetState<T> = (callback: (arg: T) => T) => void
export interface FilterConfig {
    id: string
    defaultValue: (val: Partial<DriverFilters>) => boolean
    filterComponent: (value: Partial<DriverFilters>, setFilters: FiltersSetState<Partial<DriverFilters>>) => ReactNode
}

export function DriversTableFilterBar({
                                          table,
                                          filtersConfig
                                      }: {
    table: TableHook<any, DriverFilters>,
    filtersConfig: FilterConfig[]
}) {
    const {currentFilters, setFilters} = table

    const hasFilters = filtersConfig.some(f => {
        return !f.defaultValue(currentFilters)
    })
    return (
        <TableFilterBar>
            <div className='d-flex align-items-center justify-content-start'>
                <span style={{cursor: 'pointer', paddingRight: '20px'}} onClick={() => setFilters({})}><FilterIcon
                    filled={hasFilters}/></span>
                {filtersConfig.map(f => <Fragment key={f.id}>{f.filterComponent(currentFilters, setFilters)}</Fragment>)}

            </div>
        </TableFilterBar>
    )
}
