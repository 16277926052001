import React from "react";
import './StyledLink.css';
import classNames from "classnames";

export interface LinkProps {
  href?: string;
  variant?: 'primary' | 'secondary'
  size?: 'small' | 'normal',
  onClick?: () => void,
  children: React.ReactNode;
}
export function StyledLink(props: LinkProps) {
  const variant = props.variant || 'primary'
  const size = props.size || 'small'
  const variantClass = {'primary': variant === 'primary', 'secondary': variant === 'secondary'}
  const sizeClass = {'small-text': size === 'small'}
  const elAttributes = {
    className: classNames("link", variantClass, sizeClass),
    ...(!!props.href ? {href: props.href} : {}),
    ...(!!props.onClick ? {onClick: props.onClick} : {})
  }
  return (
    <a {...elAttributes}>
      {props.children}
    </a>
  );
}
