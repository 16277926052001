import {useRoutingContext} from "../../hooks/routing-context";
import {useApiClientContext} from "../../hooks/api-client-context";
import {
    DriverFilters,
    DriverRotaRound,
    OperatorAdminC2ApiClient, RotaType, RoundModel, RoundResponse,
    SiteModel,
    SiteReponse
} from "../../client/c2-api-client";
import {useEffect, useMemo, useState} from "react";
import {RowsFetcher, useTable} from "../../hooks/table";
import {GroupModel} from "../settings-group-list/settings-group-list";
import {RoutePaths} from "../../app-router";
import {Moment} from "moment";
import {TablePageContainer} from "../../components/table-page-container/table-page-container";
import {TablePageHeader} from "../../components/table-page-header/table-page-header";
import {Dialog} from "@mui/material";
import styles from "./drivers-management.module.css";
import {Button} from "../../components/button/Button";
import {DriversTableFilterBar} from "../../components/drivers-table-filter-bar/drivers-table-filter-bar";
import {AddButton} from "../../components/add-button/add-button";
import {Table} from "../../components/table/table";
import {
    ColGroups,
    driverResponseToSummaryMapper,
    driversTableConfig,
    DriverSummary
} from "./management-table-components";
import {defaultFiltersConfig} from "../../components/drivers-table-filter-bar/default-drivers-filters-config";
import {PasswordInput} from "../../components/input/password/PasswordInput";
import {useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {DriverRotaRoundsDialog, RoundAssignment} from "./driver-rota-rounds-dialog";
import {useSearchContext} from "../../components/navigation-shell/navigation-shell";
import {driversTableService} from "../../services/drivers-table-service";

export function DriversManagement() {
    const routingContext = useRoutingContext()
    const apiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
    const [infoMessage, setInfoMessage] = useState<string | null>(null)
    const [passwordDialogState, setPasswordDialogState] = useState<{ driverId?: string }>({})
    const [roundsDialogState, setRoundsDialogState] = useState<{
        driverId: string,
        rota: RotaType,
        rotaStart: string,
        rounds: DriverRotaRound[]
    } | undefined>()
    const [allRounds, setAllRounds] = useState<RoundModel[]>([])
    const newPasswordInput = useInput('', [Validators.required])
    const confirmNewPasswordInput = useInput('', [Validators.required, (val) => Promise.resolve(newPasswordInput.value === val ? null : 'matchPass')])
    const driversFetcher = useMemo<RowsFetcher<DriverSummary, DriverFilters>>(() => {
        return ({page, pageSize, sort, ascending, filters}) => {
            return apiClient.listDrivers({page, pageSize, order: sort, ascending, filters}).then(driversPage => ({
                ...driversPage,
                data: driversPage.data.map(driverResponseToSummaryMapper)
            }))
        }
    }, [apiClient])
    const driversTableHook = useTable({
        pageSize: 1000,
        rowsFetcher: driversFetcher,
        defaultSort: 'firstName',
        defaultSortAscending: true
    })
    const searchContext = useSearchContext()
    useEffect(() => {
        const listenerId = searchContext.attachListener(driversTableService.nameSearchFilterListener(driversTableHook))
        return () => searchContext.removeListener(listenerId)
    }, [])
    const [sitesAndGroups, setSitesAndGroups] = useState<{ sites: SiteModel[], groups: GroupModel[] }>({
        sites: [],
        groups: []
    })
    useEffect(() => {
        Promise.all([apiClient.getOperatorSites(), apiClient.getOperatorGroups(), apiClient.getOperatorRounds()])
            .then(([sitesR, groupsR, rounds]: [SiteReponse, { groups: GroupModel[] }, RoundResponse]) => {
                const sites = sitesR.sites
                const groups = groupsR.groups
                setSitesAndGroups({sites, groups})
                setAllRounds(rounds.rounds)
            })
    }, [])

    const filtersConfig = useMemo(() => defaultFiltersConfig(sitesAndGroups.groups, sitesAndGroups.sites),
        [sitesAndGroups])

    const deleteDriver = (driver: DriverSummary) => {
        apiClient.deleteDriver(driver.id).then(() => driversTableHook.reload())
    }
    const editDriver = (driver: DriverSummary) => {
        const {id: driverId} = driver
        routingContext.navigate(RoutePaths.DRIVERS_EDIT.getPath({driverId}))
    }
    const resetPassword = (driver: DriverSummary) => {
        const {id: driverId} = driver
        apiClient.resetDriverPassword(driverId).then(() => {
            setInfoMessage(`Driver ${driver.name} will receive 'Reset Password' email shortly`)
        })
    }

    const setPassword = (driver: DriverSummary) => {
        const {id: driverId} = driver
        setPasswordDialogState({driverId})
    }


    const confirmSetPassword = () => {
        apiClient.setDriverPassword(passwordDialogState.driverId!, newPasswordInput.value!).then(() => {
            setInfoMessage(`Password set for driver`)
            setPasswordDialogState({})
        })

    }

    const setAssessedDate = (id: string, date: Moment) => {
        apiClient.patchDriver({id, assessedDate: date.format('YYYY-MM-DD')})
            .then(() => driversTableHook.reload())
    }

    const assignDriverRotaRounds = (rounds?: RoundAssignment[]) => {
        if (!rounds) {
            setRoundsDialogState(undefined)
            return
        }
        const driverId = roundsDialogState!.driverId
        apiClient.assignDriverRounds({driverId, rounds})
            .then(() => {
                setRoundsDialogState(undefined)
                driversTableHook.reload()
            })
    }
    const openAssignRoundsDialog = (driver: DriverSummary) => {

        setRoundsDialogState({
            driverId: driver.id,
            rota: driver.rota,
            rotaStart: driver.rotaStart,
            rounds: driver.rotaRounds
        })
    }
    const tableConfig = useMemo(() => driversTableConfig({
        deleteDriver,
        resetPassword,
        setPassword,
        editDriver,
        setAssessedDate,
        openAssignRoundsDialog,
        table: driversTableHook
    }), [driversTableHook])

    return (
        <TablePageContainer>
            <TablePageHeader>
                <Dialog
                    PaperProps={{classes: {root: styles.driverDialogRoot}}}
                    disableScrollLock={true}
                    open={!!infoMessage}>
                    <div style={{padding: '20px'}}>{infoMessage}</div>
                    <Button onClick={() => setInfoMessage(null)}>Close</Button>
                </Dialog>
                <Dialog
                    PaperProps={{classes: {root: styles.driverDialogRoot}}}
                    disableScrollLock={true}
                    open={!!passwordDialogState.driverId}>
                    <div style={{padding: '20px'}}>Set Driver Password</div>
                    <div>
                        <PasswordInput name='password' inputHook={newPasswordInput}/>
                        <PasswordInput name='confirm-password' inputHook={confirmNewPasswordInput}/>
                    </div>
                    <Button disabled={!newPasswordInput.valid || !confirmNewPasswordInput.valid}
                            onClick={() => confirmSetPassword()}>Confirm</Button>
                    <Button onClick={() => setPasswordDialogState({})}>Cancel</Button>
                </Dialog>
                {roundsDialogState?.driverId &&
                    <DriverRotaRoundsDialog allRounds={allRounds} driverRounds={roundsDialogState.rounds}
                                            driverRota={roundsDialogState.rota}
                                            driverRotaStart={roundsDialogState.rotaStart}
                                            onClose={assignDriverRotaRounds}/>}

                <div className='d-flex justify-content-between align-items-center'>
                    <DriversTableFilterBar table={driversTableHook} filtersConfig={filtersConfig}/>
                    <AddButton onClick={() => routingContext.navigate(RoutePaths.DRIVERS_ADD.getPath())}
                               label="Add driver"/>
                </div>
            </TablePageHeader>
            <div className={styles.tableContainer}>
                <Table columnsConfig={tableConfig} colGroups={ColGroups(tableConfig)} tableHook={driversTableHook}/>
            </div>
        </TablePageContainer>
    )
}
