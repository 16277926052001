import styles from './login.module.css'
import React, {useState} from "react";
import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {Input} from "../../components/input/text/Input";
import {PublicPageWrapper} from "../../components/public-page-wrapper/PublicPageWrapper";
import {PasswordInput} from "../../components/input/password/PasswordInput";
import {CheckboxInput} from "../../components/input/checkbox/CheckboxInput";
import {Button} from "../../components/button/Button";
import {Heading} from "../../components/heading/Heading";
import {useSessionContext} from "../../hooks/session-context";
import {RouterLink} from "../../components/link/router-link/RouterLink";
import {useApiClientContext} from "../../hooks/api-client-context";
import {UnauthorizedC2ApiClient} from "../../client/c2-api-client";
import classNames from "classnames";
import {RoutePaths} from "../../app-router";
import {useLocation} from "react-router-dom";
import {useRoutingContext} from "../../hooks/routing-context";

export function Login() {
  const apiClient = useApiClientContext().asOrFail(UnauthorizedC2ApiClient)
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const routingContext = useRoutingContext()
  const emailValidators = [Validators.required];
  const usernameInput = useInput<string>('', [Validators.debounced(emailValidators)]);
  const passwordInput = useInput<string>('', [Validators.required]);
  const rememberMeInput = useInput<boolean>(false);
  const loginForm = form([usernameInput, passwordInput]);
  const [error, setError] = useState<boolean>(false);
  const sessionContext = useSessionContext();

  const  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    apiClient.login({username: usernameInput.value, password: passwordInput.value})
      .then(loggedInInfo => {
        sessionContext.setSession(loggedInInfo)
        const path = search.get('from')
        if(path) {
          routingContext.replace(decodeURIComponent(path))
        }
      })
      // .catch(e => setError(true))

  }

  return (
    <PublicPageWrapper>
        <form
          onSubmit={(event) => { onSubmit(event); }}
          id="loginForm"
          className={classNames(styles.loginForm, 'd-flex flex-column')}
          noValidate>
          <Heading className="justify-content-center justify-content-sm-start">
            <span>Welcome Back</span>
          </Heading>
          <Input
            name="username"
            id="username"
            label={!!usernameInput.value ? 'username' : ''}
            autoComplete="username"
            placeholder="Username"
            inputHook={usernameInput}></Input>
          <PasswordInput
            name="password"
            id="password"
            autoComplete="current-password"
            label={!!passwordInput.value ? 'Password' : ''}
            placeholder="Password"
            inputHook={passwordInput} />
          {error && <span className="login-error-message">The username or password you have entered is incorrect</span>}
          {/*<div className="error-input-container">
            {false && <span className="login-error-message">Please provide both your username and password</span>}
            {error && <span className="login-error-message">The username or password you have entered is incorrect</span>}
            {false && <span className="login-error-message">Something went wrong, please try again later...</span>}
          </div>*/}
          <div className={classNames(styles.loginOptionsContainer, "d-flex justify-content-between align-content-center")}>
            <CheckboxInput input={rememberMeInput} label="Remember me"/>
            <RouterLink to={RoutePaths.FORGOT_PASSWORD.getPath()}>
              Forgot your password?
            </RouterLink>
          </div>
          <Button
            type="submit"
            size='large'
            colorScheme="positive" shape='rounded'
            disabled={!loginForm.valid}>
            Login
          </Button>
        </form>
    </PublicPageWrapper>
  );
}
