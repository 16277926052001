import {DriverDaySlot, ShiftStatus} from "../../../client/c2-api-client";
import classnames from "classnames";
import styles from './day-lane.module.css';
import {RoutePaths} from "../../../app-router";
import {useRoutingContext} from "../../../hooks/routing-context";
import {statusBackgrounds} from "../../../services/status-backgrounds";
import {timeService} from "../../../services/time-service";
import {Fragment} from "react";

export type DayLaneShift = {
    id?: string
    startTime: string,
    endTime: string,
    status: ShiftStatus | 'available' | 'holiday' | 'sick' | 'unavailable' | ''
}

export function DayLane({
                            day,
                            slot,
                            shifts,
                            isToday,
                            loading
                        }: {
    day: { day: string, date: number, formatted: string },
    slot?: DriverDaySlot,
    shifts: DayLaneShift[],
    isToday: boolean,
    loading: boolean
}) {
    const routingContext = useRoutingContext()
    /*const allShifts = slots.filter(sl => sl.shifts.length).map(sl => ({
      ...sl.shifts,
      displayStart: shiftService.startTimeForDisplay(sl.shift!),
      displayEnd: shiftService.endTimeForDisplay(sl.shift!)
    }))*/
    console.log('for date ', day.formatted, 'shifts', shifts, 'slot', slot)
    const allLanes = slot?.type !== "assigned" ? shifts.concat([{
        startTime: '07:00',
        endTime: '15:00',
        status: slot?.type ?? '',

    }]) : shifts
    const shiftsOrdered = Array.from(allLanes).sort((s1, s2) => timeService.parseTime(s1.startTime).diff(timeService.parseTime(s2.startTime)))
        .map((s) => {

            return ({
                displayStart: timeService.parseTime(s.startTime),
                displayEnd: timeService.parseTime(s.endTime),
                status: s.status,
                id: s.id
            });
        })
    const hoursProcessed = new Array(24).fill(0).map((v, i) => i)
        .map(hour => {
            const lanes = shiftsOrdered.map(sh => {
                const start = sh.displayStart
                const end = sh.displayEnd
                const belongsToShift = start.hours() <= hour && end.hours() >= hour
                return {
                    fullHour: (start.hours() < hour && end.hours() > hour),
                    startMinutes: start.hours() == hour ? start.minutes() : undefined,
                    endMinutes: end.hours() == hour && end.minutes() !== 0 ? end.minutes() : undefined,
                    status: loading ? '' : (belongsToShift ? sh.status : 'off' ) as '' | ShiftStatus | 'available' | 'holiday' | 'sick' | 'unavailable' | 'off',
                    onClick: () => {
                        if(!belongsToShift) {
                            return
                        }
                        const id = belongsToShift ? sh.id : ''
                        routingContext.navigate(`${RoutePaths.DAY_VIEW.getPath({date: day.formatted})}${id ? ('?shiftId='+id) : ''}`)
                    }
                }
            })
            return {hour, lanes}
            /*const belongsToShift = allShifts.filter(sh => {
                const startHours = sh.displayStart.hours()
                const endHours = sh.displayEnd.hours()
                return startHours <= hour && endHours >= hour
            })[0]
            const start = belongsToShift?.displayStart ?? startTime
            const end = belongsToShift?.displayEnd ?? endTime
            return {
                hour,
                fullHour: (start.hours() < hour && end.hours() > hour),
                startMinutes: start.hours() == hour ? start.minutes() : undefined,
                endMinutes: end.hours() == hour && end.minutes() !== 0 ? end.minutes() : undefined,
                status: loading ? '' : ((belongsToShift?.status ?? mainDaySlot?.type) ?? 'off') as '' | ShiftStatus | 'available' | 'holiday' | 'sick' | 'off',
                onClick: () => {
                    const date = belongsToShift?.date ?? mainDaySlot?.date
                    if (!date) return
                    routingContext.navigate(RoutePaths.DAY_VIEW.getPath({date}))
                }
            }*/
        })
    return (
        <>
            {hoursProcessed.map(h => <div key={h.hour}
                                          className={classnames(styles.hourCell, isToday ? styles.todayColumn : '')}>
                {h.lanes.map((lane, i) => <Fragment key={i}>
                    <div className={styles.laneCell}>
                        <HourCell
                            fullHour={lane.fullHour}
                            startMinutes={lane.startMinutes}
                            endMinutes={lane.endMinutes}
                            status={lane.status}
                            onClick={lane.onClick}
                        />
                    </div>
                </Fragment>)}
                </div>)}
        </>
    )
}


function HourCell({
                      fullHour,
                      startMinutes,
                      endMinutes,
                      status,
                      onClick
                  }: {
    fullHour: boolean,
    startMinutes?: number,
    endMinutes?: number,
    status: ShiftStatus | 'available' | 'sick' | 'holiday' | 'unavailable' | 'off' | '',
    onClick: () => void
}) {
    const topStyle = startMinutes !== undefined || endMinutes !== undefined ? {top: `${((startMinutes ?? 0) / 60) * 100}%`} : fullHour ? {top: '0'} : {}
    const bottom = startMinutes !== undefined || endMinutes !== undefined ? {bottom: `${((60 - (endMinutes ?? 60)) / 60) * 100}%`} : fullHour ? {bottom: '0'} : {}
    const sides = {left: '0', right: '0'}
    const color = {backgroundColor: statusBackgrounds[status]}
    const styles = {
        position: 'absolute' as 'absolute',
        cursor: status !== '' && status !== 'available' ? 'pointer' : 'auto', ...topStyle, ...bottom, ...sides, ...color
    }
    return <div style={styles} onClick={onClick}></div>
}
