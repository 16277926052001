import styles from './settings-round-list.module.css'
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient, RoundModel} from "../../client/c2-api-client";
import {
    OperatorAdminSettingsList
} from "../../components/operator-admin-settings/operator-admin-settings-list/operator-admin-settings-list";
import {
    OperatorAdminSettingsCard
} from "../../components/operator-admin-settings/operator-admin-settings-card/operator-admin-settings-card";
import {SettingsRouteSubPaths} from "../../app-router";
import {useRoutingContext} from "../../hooks/routing-context";
import classnames from "classnames";
import {Button} from "../../components/button/Button";
import {Dialog} from "@mui/material";

function RoundContainer(props: { round: RoundModel, deleteCallback: () => void, editCallback: () => void }) {
    const {name, description} = props.round
    return (
        <OperatorAdminSettingsCard editCallback={props.editCallback} deleteCallback={props.deleteCallback}>
            <div className={classNames(styles.userName, 'text-300')}>{name}</div>
            <div className='text-200'>{description}</div>
        </OperatorAdminSettingsCard>
    )
}

export function SettingsRoundList() {
    const routingContext = useRoutingContext()
    const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
    const [rounds, setRounds] = useState<RoundModel[]>([])
    const [selectedRoundId, setSelectedRoundId] = useState<string>()
    const selectedRound = rounds.find((r) => r.id === selectedRoundId)
    const editRound = (round: RoundModel) => {
        routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_ROUNDS_EDIT.getPath({roundId: round.id}))
    }
    const deleteRound = (round: RoundModel) => {
        setSelectedRoundId(round.id)
    }
    const onDeleteRound = () => (selectedRound ? c2ApiClient.deleteOperatorRound(selectedRound.id) : Promise.resolve()).then(() => c2ApiClient.getOperatorRounds().then(roundsRes => setRounds(roundsRes.rounds)))
    const onDismiss = () => setSelectedRoundId(undefined)
    useEffect(() => {
        c2ApiClient.getOperatorRounds().then(roundsRes => setRounds(roundsRes.rounds))
    }, [c2ApiClient])

    return (
        <OperatorAdminSettingsList title="Rounds List" addBtnLabel="Add Round"
                                   addBtnHandler={() => routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_ROUNDS_ADD.getPath())}>
            <DeleteRoundDialog round={selectedRound} onDelete={onDeleteRound} onDismiss={onDismiss} />
            {rounds.map(round => <RoundContainer key={round.id} round={round} editCallback={() => editRound(round)}
                                                 deleteCallback={() => deleteRound(round)}/>)}
        </OperatorAdminSettingsList>)
}
function DeleteRoundDialog(props: {round?: RoundModel, onDelete: () => Promise<void>, onDismiss: () => void}) {
    return (
        <Dialog
            PaperProps={{classes: {root: styles.deleteRoundDialogRoot}}}
            disableScrollLock={true}
            onClose={props.onDismiss}
            open={!!props.round}>
            <div>
                <div className={classnames('text-500', styles.title)}>Are you sure you want to delete round: {props.round?.name}</div>
                <div className='d-flex flex-column' style={{gap: '20px'}}>
                    <Button shape={"rounded"} colorScheme={"neutral"} onClick={props.onDelete}>Delete</Button>
                    <Button shape={"rounded"} colorScheme={"negative"} onClick={props.onDismiss}>Cancel</Button>
                </div>
            </div>
        </Dialog>
    )
}