import './super-admin-home.css'
import addIcon from '../../icons/add-white.svg'
import {SuperAdminPageWrapper} from "../../components/super-admin-page-wrapper/super-admin-page-wrapper";
import {Button} from "../../components/button/Button";
import {RowsFetcher, useTable} from "../../hooks/table";
import {ColumnConfig, Table} from "../../components/table/table";
import {TablePagination} from "../../components/table/table-pagination";
import React, {useCallback} from "react";
import {OperatorSummary, SuperAdminC2ApiClient} from "../../client/c2-api-client";
import {useApiClientContext} from "../../hooks/api-client-context";
import {RoutePaths} from "../../app-router";
import eyeIcon from "../../icons/eye.svg";
import {RouterLink} from "../../components/link/router-link/RouterLink";
import {useRoutingContext} from "../../hooks/routing-context";


export function SuperAdminHome() {
  const apiClient = useApiClientContext().asOrFail(SuperAdminC2ApiClient)
  const routingContext = useRoutingContext()
  const rowsFetcher: RowsFetcher<OperatorSummary> = useCallback(({page, pageSize, sort}) => {
    return apiClient.listOperators({page, pageSize, order: sort})
  }, []);
  const table = useTable({rowsFetcher: rowsFetcher})

  const columnsConfig: ColumnConfig<OperatorSummary>[] = [
    {
      key: 'name',
      header: <th><div>Name</div></th>,
      cellFactory: row => <td><RouterLink to={routingContext.getPath(RoutePaths.EDIT_OPERATOR.getPath({operatorId: row.id}))}>{row.name}</RouterLink></td>
    }, {
      key: 'alias',
      header: <th><div>Alias</div></th>,
      cellFactory: row => <td><span>{row.alias}</span></td>
    }, {
      key: 'sites',
      header: <th><div>Sites</div></th>,
      cellFactory: row => <td><span>{row.sitesCount}</span></td>
    }, {
      key: 'drivers',
      header: <th><div>Drivers</div></th>,
      cellFactory: row => <td><span>{row.driversCount}</span></td>
    }, {
      key: 'actions',
      header: <th style={{width: '15%'}}><div>Actions</div></th>,
      cellFactory: row => <td><img src={eyeIcon} style={{cursor: 'pointer', }} onClick={() => routingContext.navigate(RoutePaths.SUPER_ADMIN_OPERATOR_OVERVIEW.getPath({operatorId: row.id}))} /></td>
    }
  ]

  return (
    <SuperAdminPageWrapper>
      <div className="d-flex justify-content-between align-items-baseline">
        <p>Operators</p>
        <Button size='small' colorScheme="positive" shape='rounded' onClick={() => routingContext.navigate(RoutePaths.CREATE_OPERATOR.getPath())}>
          <div className="d-flex justify-content-between align-items-baseline">
            <div style={{marginLeft: '5px', marginRight: '5px'}}>
              <img src={addIcon}/>
            </div>
            <span>Add New Operator</span>
          </div>
        </Button>
      </div>
      <div style={{maxWidth: '600px'}}>
        <Table columnsConfig={columnsConfig} tableHook={table}></Table>
        <div className="d-flex justify-content-end">
          <div style={{marginTop: '25px'}}>
            <TablePagination tableHook={table}>
              <div style={{width: '20px'}}></div>
            </TablePagination>
          </div>
        </div>
      </div>
    </SuperAdminPageWrapper>
  );
}
