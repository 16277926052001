import {VerticalGroup} from "../../components/input/vertical-group/VerticalGroup";
import {InputHook, useInput} from "../../hooks/input";
import {Labeled} from "../../components/input/labeled/Labeled";
import {Input} from "../../components/input/text/Input";
import {CheckboxInput} from "../../components/input/checkbox/CheckboxInput";
import styles from './settings-round-edit-group.module.css'
import {useEffect} from "react";

export interface SettingsRoundEditGroupProps {
    name: InputHook<string>
    description: InputHook<string>
    mondayQuota: InputHook<string>
    tuesdayQuota: InputHook<string>
    wednesdayQuota: InputHook<string>
    thursdayQuota: InputHook<string>
    fridayQuota: InputHook<string>
    saturdayQuota: InputHook<string>
    sundayQuota: InputHook<string>
}

export function SettingsRoundEditGroup({
                                           name,
                                           description,
                                           mondayQuota,
                                           tuesdayQuota,
                                           wednesdayQuota,
                                           thursdayQuota,
                                           fridayQuota,
                                           saturdayQuota,
                                           sundayQuota
                                       }: SettingsRoundEditGroupProps) {

    return (
        <VerticalGroup>
            <RoundNameInput input={name}/>
            <DescriptionInput input={description}/>
            <DailyQuotas mondayQuota={mondayQuota} tuesdayQuota={tuesdayQuota} wednesdayQuota={wednesdayQuota}
                         thursdayQuota={thursdayQuota} fridayQuota={fridayQuota}
                         saturdayQuota={saturdayQuota} sundayQuota={sundayQuota}/>
        </VerticalGroup>
    )
}

function RoundNameInput({input}: { input: InputHook<string> }) {
    return <Labeled label='Round Name'>
        <div>
            <Input name='roundName' inputHook={input}/>
        </div>
    </Labeled>
}


function DescriptionInput({input}: { input: InputHook<string> }) {
    return <Labeled label='Round Description'>
        <div>
            <Input name='descriptionInput' inputHook={input}/>
        </div>
    </Labeled>
}

function DailyQuotas({
                         mondayQuota,
                         tuesdayQuota,
                         wednesdayQuota,
                         thursdayQuota,
                         fridayQuota,
                         saturdayQuota,
                         sundayQuota
                     }: {
    mondayQuota: InputHook<string>,
    tuesdayQuota: InputHook<string>,
    wednesdayQuota: InputHook<string>,
    thursdayQuota: InputHook<string>,
    fridayQuota: InputHook<string>,
    saturdayQuota: InputHook<string>,
    sundayQuota: InputHook<string>
}) {
    return <div>
        <h4>Daily Quotas</h4>
        <div className={styles.checkbox_group}>
            <DailyQuota input={mondayQuota} label='Monday'/>
            <DailyQuota input={tuesdayQuota} label='Tuesday'/>
            <DailyQuota input={wednesdayQuota} label='Wednesday'/>
            <DailyQuota input={thursdayQuota} label='Thursday'/>
            <DailyQuota input={fridayQuota} label='Friday'/>
            <DailyQuota input={saturdayQuota} label='Saturday'/>
            <DailyQuota input={sundayQuota} label='Sunday'/>
        </div>
    </div>
}

function DailyQuota({input, label}: { input: InputHook<string>, label: string }) {
    const excludeCheckbox = useInput<boolean>(input.value === '0')
    useEffect(() => {
        if (excludeCheckbox.value && input.value !== '0') {
            input.setValue('0', true)
        }
    }, [excludeCheckbox.value])

    return (
        <div>
            <div className='d-flex flex-row'>
                <div className={styles.quotaCheckbox}>
                    <label>{label}</label>
                    <div>
                        <CheckboxInput input={excludeCheckbox} label='Exclude Day'/>
                    </div>
                </div>
                <div className={styles.quotaInput}>
                    <Input name={label} inputHook={input} disabled={excludeCheckbox.value}/>
                </div>
                <hr/>
            </div>

        </div>
    )
}