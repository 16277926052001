import './create-password.css'
import React, {useEffect, useState} from "react";
import {PublicPageWrapper} from "../../components/public-page-wrapper/PublicPageWrapper";
import {Button} from "../../components/button/Button";
import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {PasswordInput} from "../../components/input/password/PasswordInput";
import {Heading} from "../../components/heading/Heading";
import {useSessionContext} from "../../hooks/session-context";
import {useLocation} from 'react-router-dom';
import {RoutePaths} from "../../app-router";
import {useApiClientContext} from "../../hooks/api-client-context";
import {UnauthorizedC2ApiClient} from "../../client/c2-api-client";
import {useRoutingContext} from "../../hooks/routing-context";
import { RoutingRedirect } from '../../components/routing/redirect/RoutingRedirect';

export function CreatePassword() {
  const sessionContext = useSessionContext();
  const apiClient = useApiClientContext().as(UnauthorizedC2ApiClient)
  const location = useLocation()
  const routingContext = useRoutingContext()
  const token = new URLSearchParams(location.search).get('token')
  const password = useInput('', [Validators.required]);
  const repeatPassword = useInput('', [Validators.required]);
  const [showPasswordMatchError, setShowPasswordMatchError] = useState(false)
  const createForm = form([password, repeatPassword]);
  useEffect(() => {
    if(apiClient === null) {
      sessionContext.clearSession()
    }
  }, [])
  const  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if(password.value !== repeatPassword.value) {
      setShowPasswordMatchError(true)
    } else {
      token && apiClient?.setPassword({token, password: password.value!})
        .then(sessionInfo => {
          sessionContext.setSession(sessionInfo)
          routingContext.navigate(RoutePaths.HOME.getPath())
        })
    }
  }
  if(!token) {
    return <RoutingRedirect to={RoutePaths.HOME.getPath()} />
  }
  return (

    <PublicPageWrapper>
      <form
        onSubmit={onSubmit}
        id="resetPasswordForm"
        className="reset-password-form d-flex flex-column"
        noValidate>
        <Heading className="justify-content-start">
          <span>Create new password</span>
        </Heading>
        <p className="small-text">Enter your new password</p>
        <PasswordInput name="password" inputHook={password} />
        <PasswordInput name="repeatPassword" inputHook={repeatPassword} />
        {showPasswordMatchError && <p style={{color: 'red'}}>Passwords do not match</p>}
        <Button
          type="submit"
          colorScheme="positive" shape='rounded' size='large'
          disabled={!createForm.valid}>
          Set Password
        </Button>
      </form>
    </PublicPageWrapper>
  );
}
