import {form, useInput} from "../../hooks/input";
import moment from "moment";
import {Validators} from "../../validators";
import {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import styles from "./assign-round-dates-modal.module.css";
import {OperatorAdminSettingsForm} from "../operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {DateInput} from "../input/date/date-input";
import {apiClientContext, useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient} from "../../client/c2-api-client";
import {SelectInput} from "../input/select/select";

export type AssignRoundDatesHandler = (params?: {start: string, end: string, roundId: string}) => void
export function AssignRoundDatesModal({
                                        startDate,
                                        handle,
                                        open
                                      }: {startDate: string, handle: AssignRoundDatesHandler, open: boolean }) {
  const apiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const startInput = useInput(moment(), [Validators.required])
  const endInput = useInput(moment(), [Validators.required])
  const roundInput = useInput<string[]>([], [Validators.required])
  const [allRoundsOptions, setAllRoundsOptions] = useState<{id: string, name: string}[]>([]);
  useEffect(() => {
    startInput.setValue(moment(startDate, 'YYYY-MM-DD'))
    endInput.setValue(moment(startDate, 'YYYY-MM-DD').add(1, 'days'))
    apiClient.getOperatorRounds().then(({rounds}) => {
      setAllRoundsOptions(rounds)
    })
  }, [startDate])
  useEffect(() => {
    startInput.setValue(moment(startDate, 'YYYY-MM-DD'))
    endInput.setValue(moment(startDate, 'YYYY-MM-DD').add(1, 'days'))
    roundInput.setValue([], true)
  }, [open])
  const assignRoundForm = form([startInput, endInput, roundInput])
  return (
    <Dialog
      PaperProps={{classes: {root: styles.assignRoundDatesDialogRoot}}}
      disableScrollLock={true}
      open={open}>
      <OperatorAdminSettingsForm title='Assign Driver To Round on Dates' submitLabel='Assign' cancelCallback={() => handle()}
                                 disableSubmit={!assignRoundForm.valid}
                                 paddingTop={false}
                                 editCallback={() => handle({
                                   start: startInput.value!.format('YYYY-MM-DD'),
                                   end: endInput.value!.format('YYYY-MM-DD'),
                                   roundId: roundInput.value![0]
                                 })}>
        <div className='d-flex flex-column' style={{gap: '20px'}}>
          <DateInput input={startInput} label={'Start Date'}/>
          <DateInput input={endInput} label={'End Date'}/>
          <label>Round</label>
          <SelectInput multiple={false} input={roundInput} options={allRoundsOptions}/>
        </div>
      </OperatorAdminSettingsForm>
    </Dialog>
  )
}

