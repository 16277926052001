import fetch from 'cross-fetch'
// @ts-ignore
import { saveAs } from 'file-saver';

export type QueryParams = {[k: string]: string | number | boolean};
export type ErrorHandler = (e: Error) => void
export class RestService {
  private defaultHeaders: {[k: string]: string}
  private errorHandlers: ErrorHandler[] = []
  constructor(public readonly baseUrl: string, defaultHeaders: {[k: string]: string}, errorHandlers?: ErrorHandler[]) {
    this.defaultHeaders = {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      ...defaultHeaders
    }
    if(errorHandlers) {
      this.errorHandlers = errorHandlers
    } else {
      this.errorHandlers = [(e) => {
        console.error('got rest service error ', e)
      }]
    }
  }

  public get<T>(params: {url: string, query?: QueryParams}): Promise<T>  {
    return fetch(`${this.baseUrl}/${RestService.createUrl(params)}`, {
      method: 'GET',
      headers: this.defaultHeaders
    }).then(res => res.json().then(body => res.status < 300 ? body : Promise.reject(body)))
      .then(body => body as T)
      .catch(e => this.handleError(e))
  }
  public download(params: {url: string, query?: QueryParams}): Promise<void>  {

    return fetch(`${this.baseUrl}/${RestService.createUrl(params)}`, {
      method: 'GET',
      headers: this.defaultHeaders
    }).then(res => {
      const disposition = res.headers.get('Content-Disposition') ?? ''
      console.log('disp', disposition)
      const filename = disposition
        .split(';')
        .find(n => n.includes('filename='))!
        .replace('filename=', '')
        .trim();
      return res.blob().then(bl => ({name: filename, blob: bl}))

    }).then(({name, blob}) => {
      // Download the file
      saveAs(blob, name);
    })
        .catch(e => this.handleError(e))
  }
  public post<T>(params: {url: string, body: any, query?: QueryParams}): Promise<T> {
    return fetch(`${this.baseUrl}/${RestService.createUrl(params)}`, {
      method: 'POST',
      headers: this.defaultHeaders,
      body: JSON.stringify(params.body)
    }).then(res => res.json().then(body => res.status < 300 ? body : Promise.reject(body)))
      .then(body => body as T)
      .catch(e => this.handleError(e))
  }

  public postFormData<T>(params: {url: string, query?: QueryParams, data: FormData}): Promise<T> {
    const {'Content-type': contentType, ...headersWithoutContentType} = this.defaultHeaders
    return fetch(`${this.baseUrl}/${RestService.createUrl(params)}`, {
      method: 'POST',
      headers: headersWithoutContentType,
      body: params.data
    }).then(res => res.json().then(body => res.status < 300 ? body : Promise.reject(body)))
      .then(body => body as T)
      .catch(e => this.handleError(e))
  }
  public put<T>(params: {url: string, body: any, query?: QueryParams}): Promise<T> {
    return fetch(`${this.baseUrl}/${RestService.createUrl(params)}`, {
      method: 'PUT',
      headers: this.defaultHeaders,
      body: JSON.stringify(params.body)
    }).then(res => res.json().then(body => res.status < 300 ? body : Promise.reject(body)))
      .then(body => body as T)
      .catch(e => this.handleError(e))
  }
  public patch<T>(params: {url: string, body: any, query?: QueryParams}): Promise<T> {
    return fetch(`${this.baseUrl}/${RestService.createUrl(params)}`, {
      method: 'PATCH',
      headers: this.defaultHeaders,
      body: JSON.stringify(params.body)
    }).then(res => res.json().then(body => res.status < 300 ? body : Promise.reject(body)))
      .then(body => body as T)
      .catch(e => this.handleError(e))
  }
  public delete<T>(params: {url: string, query?: QueryParams}): Promise<T>  {
    return fetch(`${this.baseUrl}/${RestService.createUrl(params)}`, {
      method: 'DELETE',
      headers: this.defaultHeaders
    })
      .then(res => res.json().catch(() => null).then(body => res.status < 300 ? body : Promise.reject(body)))
      .then(body => body as T)
      .catch(e => this.handleError(e))
  }
  public static createUrl(params: {url: string, query?: QueryParams}) {
    let url = params.url
    if(params.query) {
      let q = Object.keys(params.query).reduce((q, k) => {
        if(Array.isArray(params.query![k])) {
          const arrQuery = params.query![k] as unknown as any[]
          arrQuery.forEach((el) => q.append(`${k}[]`, String(el)))
          if(arrQuery.length < 1) {
            q.append(`${k}[]`, '')
          }
        } else {
          params.query![k] !== undefined && q.append(k, String(params.query![k]))
        }
        return q;
      }, new URLSearchParams())
      url = `${url}?${q.toString()}`
    }
    return url
  }

  private handleError<T>(e: Error): Promise<T> {
    console.log('handling error', e)
    this.errorHandlers.forEach(handler => handler(e))
      return Promise.reject()
  }

}
