import styles from "./bordered.module.css";
import classNames from "classnames";
import {ReactNode} from "react";

export type BorderedWidth = 'small' | 'large' | 'unbound'
const widthClasses: Record<BorderedWidth, string> = {
  'small': styles.widthSmall,
  'large': styles.widthLarge,
  'unbound': ''
}

export function Bordered({children, width='unbound'}: {children: ReactNode, width: BorderedWidth}) {
  return (
    <div className={classNames('d-flex flex-column flex-fill justify-content-between', styles.bordered, widthClasses[width])}>
      {children}
    </div>
  )
}
