import {VerticalGroup} from "../../components/input/vertical-group/VerticalGroup";
import {TextFormField} from "../../components/input/composite/text-form-field/text-form-field";
import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {Button} from "../../components/button/Button";
import {useApiClientContext} from "../../hooks/api-client-context";
import {DriverC2ApiClient} from "../../client/c2-api-client";
import {useRoutingContext} from "../../hooks/routing-context";
import {RoutePaths} from "../../app-router";
import {useEffect} from "react";
import {CheckboxInput} from "../../components/input/checkbox/CheckboxInput";


export function EnterAgencyData() {
    const apiClient = useApiClientContext().asOrFail(DriverC2ApiClient)
    const routing = useRoutingContext()
    const companyNameInput = useInput('', [Validators.required])
    const companyNumberInput = useInput('', [Validators.required])
    const companyAddressInput = useInput('', [Validators.required])
    const accountNumberInput = useInput('', [Validators.required])
    const sortCodeInput = useInput('', [Validators.required])
    const vatIncludedInput = useInput(false, [Validators.required])

    const agencyForm = form([
        companyNameInput,
        companyNumberInput,
        companyAddressInput,
        accountNumberInput,
        sortCodeInput,
        vatIncludedInput
    ])

    useEffect(() => {
        apiClient.getOperatorInvoiceConfig().then(invoiceConfig => {
            if (!(invoiceConfig?.invoicingEnabled && invoiceConfig?.requireAgencyData)) {
                routing.navigate(RoutePaths.HOME.getPath())
            }
        })
        apiClient.getDriverAgencyData().then(agencyData => {
            if (agencyData) {
                companyNameInput.setValue(agencyData.companyName, true)
                companyNumberInput.setValue(agencyData.companyNumber, true)
                companyAddressInput.setValue(agencyData.companyAddress, true)
                accountNumberInput.setValue(agencyData.accountNumber, true)
                sortCodeInput.setValue(agencyData.sortCode, true)
                vatIncludedInput.setValue(agencyData.vatIncluded, true)
            }
        })
    }, [])
    const setData = () => {
        apiClient.setDriverAgencyData({
            companyName: companyNameInput.value!,
            companyNumber: companyNumberInput.value!,
            companyAddress: companyAddressInput.value!,
            accountNumber: accountNumberInput.value!,
            sortCode: sortCodeInput.value!,
            vatIncluded: vatIncludedInput.value!
        }).then(() => {
            routing.navigate(RoutePaths.HOME.getPath())
        })
    }
    return (
        <div className='flex-fill'>
            <h3>Please enter your agency data to be used in invoicing documents</h3>
            <VerticalGroup>
                <TextFormField input={companyNameInput} name={'company-name'} label={'Company Name'}/>
                <TextFormField input={companyNumberInput} name={'company-number'} label={'Company Number'}/>
                <TextFormField input={companyAddressInput} name={'company-address'} label={'Company Address'}/>
                <TextFormField input={accountNumberInput} name={'account-number'} label={'Account Number'}/>
                <TextFormField input={sortCodeInput} name={'sort-code'} label={'Sort Code'}/>
                <CheckboxInput input={vatIncludedInput} label={'VAT Included'}/>

                <Button type={"button"} disabled={!agencyForm.valid || agencyForm.pristine} onClick={setData}>
                    Submit
                </Button>
            </VerticalGroup>
        </div>
    )
}
