import {InputHook, useInput} from "../../../hooks/input";
import moment, {Moment} from "moment";
import {useEffect, useState} from "react";
import {Validators} from "../../../validators";
import {Labeled} from "../labeled/Labeled";
import {TimePicker} from "@mui/lab";
import {Input} from "../text/Input";

export function TimeInput({input, label}: { input: InputHook<Moment>, label: string }) {
  const [open, setIsOpen] = useState(false)
  const inputHook = useInput('', [Validators.required, Validators.pattern(/\d{2}:\d{2}/)])

  useEffect(() => {
    const mom = moment(inputHook.value ?? '', 'HH:mm')
    console.log('time', mom.isValid(), inputHook.value)
    if (mom.isValid() && inputHook.value?.length === 5) {
      const inputVal = input.value!
      if (inputHook.value !== inputVal.format('HH:mm')) {
        input.setValue(mom)
      }
    }
  }, [inputHook.value])
  useEffect(() => {
    const val = input.value?.format('HH:mm') ?? ''
    if (val !== inputHook.value) {
      inputHook.setValue(val)
    }
  }, [input.value])
  return (
    <Labeled label={label}>
      <TimePicker
        open={open}
        onClose={() => setIsOpen(false)}
        value={input.value}
        ampm={false}
        onChange={(v) => v ? input.setValue(v) : null}
        renderInput={(props) => {
          const hasEndAdornment = !!props.InputProps?.endAdornment
          const onClick = hasEndAdornment ? () => {} : () => {
            setIsOpen(true)
          }
          if(hasEndAdornment) {
            return <span ref={props.inputRef}><Input name='assessedDate' inputHook={inputHook} onClick={onClick}
                                                     layout='reverse'>
              <div onClick={() => setIsOpen(true)} style={{cursor: 'pointer'}}
                   className='d-flex align-items-center'>{props.InputProps?.endAdornment}</div>
          </Input></span>
          }
          return <div
            onClick={onClick}
            className='d-flex justify-content-center' style={{
            cursor: 'pointer',
            minWidth: '100px',
            color: 'rgba(0, 0, 0, 1)',
            borderRadius: '6px',
            border: `1px solid ${input.valid ? '#E3F1EE' : '#FF0000'}`,
            background: 'rgba(227, 237, 235, 0.14)',
            padding: '5px'
          }}>{input.value?.format('HH:mm')}</div>
        }}
        // renderInput={(props) => <TextField {...props} />}
      />
    </Labeled>
  )
}
