import {InputHook} from "../../../hooks/input";
import {InputBase, MenuItem, Select} from "@mui/material";
import styles from "./select.module.css";

export function SelectInput({
                        input,
                        multiple = false,
                        fullWidth,
                        options,
                        disabled
                      }: { input: InputHook<string[]>, multiple: boolean, fullWidth?: boolean, options: { id: string, name: string, disabled?: boolean }[], disabled?: boolean }) {
  const displayArray = input.value ?? []
  return (
    <Select
      inputProps={{MenuProps: {disableScrollLock: true}}}
      multiple={multiple}
      disabled={disabled ?? false}
      value={multiple ? displayArray : (displayArray[0] ?? '')}
      onChange={ev => input.setValue(Array.isArray(ev.target.value) ? ev.target.value : [ev.target.value])}
      fullWidth={fullWidth ?? true}
      input={<InputBase classes={{root: styles.selectInput, disabled: styles.selectDisabled}}/>}
    >
      {options.map(opt => <MenuItem key={opt.id} value={opt.id} disabled={opt.disabled}>{opt.name}</MenuItem>)}
    </Select>
  )
}
