import {resultValue, Validator} from "./hooks/input";

function required<T>(val: T | undefined){
  if(typeof val === 'string')
    return Promise.resolve(val.trim() !== '' ? null : 'required');
  if(val instanceof Array) {
    return Promise.resolve(val.length > 0 ? null : 'required')
  }
  return Promise.resolve(val !== null && val !== undefined ? null : 'required');
}
function stringNotEquals(notEquals: string): Validator<string> {
  return val => Promise.resolve((val !== notEquals) ? null : 'notEquals');
}
function minLength(l: number): Validator<string> {
  return val => Promise.resolve(!!val && (val.length >= l) ? null : 'minLength');
}

function maxLength(l: number): Validator<string> {
  return val => Promise.resolve((val || '').length <= l ? null : 'maxLength');
}
function email(val: string | undefined): Promise<string | null> {
  if(!val) {
    return Promise.resolve('email');
  }
  const atIndex = val.indexOf('@');
  const dotIndex = val.lastIndexOf('.');
  return Promise.resolve(atIndex > 0 && dotIndex > atIndex ? null : 'email');
}

function debounced<T>(validators: Validator<T>[], debounceTime = 1000): Validator<T> {
  let validator = combineValidators(validators);
  let currentTimeout: number | null = null;
  return val => new Promise((res, rej) => {
    if(currentTimeout) {
      clearTimeout(currentTimeout);
    }
    currentTimeout = window.setTimeout(() => {
      validator(val).then(valid => res(valid));
    }, debounceTime)
  });
}

export function combineValidators<T>(validators: Validator<T>[]): Validator<T> {
  return value => Promise.all(validators.map(v => v(value)))
    .then(validResults => resultValue(validResults))
}
function pattern(regExp: RegExp, name = 'pattern'): Validator<string> {
  return val => {
    if(!val) return Promise.resolve(null)
    const res = regExp.exec(val)
    return Promise.resolve(res === null ? name : null)
  }
}
export const Validators = {
  combineValidators,
  required,
  stringNotEquals,
  minLength,
  maxLength,
  email,
  debounced,
  pattern
}
