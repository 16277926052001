import * as React from "react"
type CommentIconColor = 'green' | 'orange' | 'gray'

const colorToFill: Record<CommentIconColor, string> = {
  green: '#18C671',
  gray: 'rgba(0, 0, 0, 0.33)',
  orange: '#F2994A'
}
export function CommentIcon({color = 'green'}: {color?: CommentIconColor}) {
  const fill = colorToFill[color]
  return (
    <svg width={15} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.484 11.21h8.22c.826 0 1.495-.67 1.495-1.495V2.242c0-.826-.67-1.495-1.495-1.495H2.242c-.826 0-1.495.67-1.495 1.495v10.26c0 .634.74.98 1.226.575l2.032-1.694a.747.747 0 0 1 .479-.173Zm8.22.747a2.242 2.242 0 0 0 2.242-2.242V2.242A2.242 2.242 0 0 0 12.704 0H2.242A2.242 2.242 0 0 0 0 2.242v10.26c0 1.268 1.478 1.96 2.451 1.149l2.033-1.694h8.22Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.484 4.11h5.979a.374.374 0 1 1 0 .747H4.484a.374.374 0 1 1 0-.747ZM4.484 7.1h2.99a.374.374 0 0 1 0 .747h-2.99a.374.374 0 1 1 0-.748Z"
        fill={fill}
      />
    </svg>
  )
}
