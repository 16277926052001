import moment, {Duration, Moment} from "moment";
export const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export const timeService = {
  parseTime: (timeStr: string): Moment => {
    return moment(timeStr, 'HH:mm')
  },
  parseDate: (dateStr: string): Moment => {
    return moment(dateStr, 'YYYY-MM-DD')
  },
  formatTime: (time: Moment): string => {
    return time.format( 'HH:mm')
  },
  formatDate: (date: Moment): string => {
    return date.format('YYYY-MM-DD')
  },
  formatDuration: (duration: Duration | null): string => {
    if(!duration) return '-'
    const minutes = duration.minutes()
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`
    return `${Math.floor(duration.asHours())}${duration.minutes() !== 0 ? ('.' + minutesStr) : ''}h`
  },
  parseTimestamp: (timeStr: string): Moment => {
    return moment(timeStr, 'YYYY-MM-DDTHH:mm')
  },
  formatTimestamp: (timestamp: Moment): string => {
    return timestamp.format('YYYY-MM-DD HH:mm')
  },
  getNextWeekDay: (day: Moment, weekDay: string): Moment => {
    const dayAsNumber = dayNames.indexOf(weekDay)
    const inCurrentWeek = moment(day).day(dayAsNumber)
    if(day.day() >= dayAsNumber) {
      return inCurrentWeek.add(7, 'days')
    }
    return inCurrentWeek
  },
  getThisWeekDay: (day: Moment, weekDay: string): Moment => {
    const dayAsNumber = dayNames.indexOf(weekDay)
    return moment(day).day(dayAsNumber)
  },
  daysDiff: (first: Moment, second: Moment): number => {
    const midnightFirst = moment(first).startOf('day')
    const midnightSecond = moment(second).startOf('day')
    return midnightSecond.diff(midnightFirst, 'days')
  },
  combineMoments: (dateMoment: Moment, timeMoment: Moment): Moment => {
    return moment(dateMoment).hours(timeMoment.hours()).minutes(timeMoment.minutes())
        .seconds(timeMoment.seconds())
  }
}
