import {Shift} from "../../client/c2-api-client";
import {ShiftApproveStatusType, shiftService} from "../../services/shift/shift-service";
import styles from './shift-card.module.css'
import classNames from "classnames";
import {CheckMarkIcon} from "../../icons/CheckMark";
import {CommentIcon} from "../../icons/comment";
import {ReceiptIcon} from "../../icons/receipt";
import {OvernightIcon} from "../../icons/overnight";
import {timeService} from "../../services/time-service";
import moment from "moment";

const approveStatusToColor: Record<ShiftApproveStatusType, string> = {
  'unhandled': '#E3F1EE',
  'match': '#18C671',
  'warning': 'yellow',
  'error': 'red',
  'needsApprovalAfterTacho': 'pink'
}
export function ShiftCard({shift, isInFuture, onClick, selected, approveStatus}: { shift: Shift, isInFuture: boolean, onClick: () => void, selected: boolean, approveStatus: ShiftApproveStatusType}) {
  const driverTime = shiftService.driverDuration(shift)
  const operatorTime = shift.operatorTime
  const tachoTime = shift.tachoData?.totalShiftTime
  return (
    <div className={classNames('d-flex flex-column', selected && styles.selectedShiftCard)}
         onClick={() => {
           if(!isInFuture) onClick()
         }}
         style={{
           cursor: !isInFuture ? 'pointer' : 'inherit',
           marginTop: '15px',
           width: '100%',
           height: 'calc(100% - 15px)',
           padding: '10px',
           background: 'rgba(227, 237, 235, 0.14)',
           border: `1px solid ${approveStatusToColor[approveStatus]}`,
           borderRadius: '6px',
           gap: '5px'
         }}>
      <div className='d-flex align-items-center'>
        <div className={classNames('text-100', styles.timeColumn, styles.timeLabel)}>Driver</div>
        {tachoTime && <div className={classNames('text-100', styles.timeColumn, styles.timeLabel)}>Tacho</div>}
        <div className={classNames('text-100', styles.timeColumn, styles.timeLabel)}>{operatorTime ? 'Operator' : ''}</div>
        <span style={{visibility: operatorTime ? 'visible' : 'hidden'}}><CheckMarkIcon color={ approveStatus === 'match'  ? 'green' : 'purple'} /></span>
      </div>
      <div className='d-flex'>
        <div className={classNames('text-400', styles.timeColumn)}>{timeService.formatDuration(driverTime)}</div>
        {tachoTime && <div className={classNames('text-400', styles.timeColumn)}>{timeService.formatDuration(moment.duration(tachoTime))}</div>}
        <div className={classNames('text-400', styles.timeColumn)}>{operatorTime ? timeService.formatDuration(moment.duration(operatorTime)) : ''}</div>
        <span style={{visibility: 'hidden'}}><CheckMarkIcon /></span>
      </div>
      <div className={classNames('d-flex justify-content-start', styles.iconsRow)}>
        <CommentIcon color={shift.hasComments ? 'orange' : 'gray'} />
        <ReceiptIcon color={shift.hasReceipts ? 'orange' : 'gray'} />
        <OvernightIcon color={shift.isOvernight ? 'orange' : 'gray'} />
      </div>
    </div>
  )
}
