import React from "react";
import classNames from "classnames";
import styles from "./operator-admin-settings-form.module.css"
import {RouterLink} from "../../link/router-link/RouterLink";
import {Button} from "../../button/Button";
import {Bordered, BorderedWidth} from "../../bordered/bordered";

export interface OperatorAdminSettingsFormProps {
  title: string
  submitLabel?: string
  paddingTop?: boolean
  cancelRoute?: string
  cancelCallback?: () => void
  disableSubmit: boolean
  editCallback: () => void
  size?: BorderedWidth
  children: React.ReactNode
}

export function OperatorAdminSettingsForm({
                                            title,
                                            submitLabel,
                                            paddingTop = true,
                                            cancelRoute,
                                            cancelCallback,
                                            disableSubmit,
                                            size = 'small',
                                            children,
                                            editCallback
                                          }: OperatorAdminSettingsFormProps) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    editCallback()
  }
  return (
      <div className={classNames('d-flex align-items-start justify-content-center', styles.page, paddingTop ? styles.paddingTop : undefined)}>
        <Bordered width={size}>
          <form
                onSubmit={handleSubmit}>
            <div className={classNames('text-500', styles.formTitle)}>{title}</div>
            <div className="flex-fill">
              {children}
            </div>
            <div className={classNames(styles.buttonsContainer, 'd-flex justify-content-end align-items-center')}>
              {cancelRoute && <RouterLink to={cancelRoute}><span className={classNames('text-200', styles.cancelLink)}
                                                 style={{color: 'gray'}}>Cancel</span></RouterLink>}
              {cancelCallback && <span className={classNames('text-200', styles.cancelLink)}
                                       style={{color: 'gray', cursor: 'pointer'}} onClick={() => cancelCallback()}>Cancel</span>}
              {(submitLabel ?? undefined) && <Button type='submit' size='normal' colorScheme="neutral" shape='semi-rounded'
                       disabled={disableSubmit}><span className='text-200'>{submitLabel}</span></Button>}
            </div>
          </form>
        </Bordered>
      </div>)
}
