import classNames from "classnames";
import {Plus} from "../../icons/plus";
import {Button} from "../button/Button";
import React from "react";
import styles from './add-button.module.css'

export interface AddButtonProps {
  onClick: () => void
  label: string
}
export function AddButton({onClick, label}: AddButtonProps) {
  return (
    <Button onClick={onClick}>
      <div className={classNames("d-flex flex-nowrap align-items-center", styles.addBtnContent)}>
        <Plus/>
        <span className='text-200'>{label}</span>
      </div>
    </Button>
  )
}
