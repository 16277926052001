import {Reducer, useEffect, useReducer} from "react";

function validate<T>(value: T, validators: Validator<T>[]): Promise<string | null> {
  return Promise.all(validators.map(v => v(value)))
    .then(validResults => resultValue(validResults));
}
export function resultValue(values: (string | null)[]) {
  const err = values.filter(r => r !== null)
  return err.length ? err[0] : null
}
export type Validator<T> = (val: T | undefined) => Promise<string | null>

interface InputState<T> {
  value: T | undefined;
  pristine: boolean;
}

type ValidationState = { valid: true, error: null } | { valid: false, error: string }
type InputValidatedState<T> = ValidationState & InputState<T>

export type InputHook<T> ={
  setValue: (val: T, keepPristine?: boolean) => void
  submit: () => void
} &  InputValidatedState<T>

type InputAction<T> =
  { type: 'SET_VALUE', payload: { value: T, keepPristine?: boolean } }
  | { type: 'VALIDATOR_RESULT', payload: string | null }
  | {type: 'SUBMIT'}

function inputReducer<T>(inputState: InputValidatedState<T>, action: InputAction<T>): InputValidatedState<T> {
  switch (action.type) {
    case 'SET_VALUE':
      const {value, keepPristine} = action.payload
      const pristine = inputState.pristine ? keepPristine == true : false
      return {
        ...inputState,
        value,
        pristine
      }
    case "VALIDATOR_RESULT":
      const error = action.payload
      const validatedState: ValidationState = error === null ? {valid: true, error: null} : {valid: false, error}
      return {
        ...inputState,
        ...validatedState
      }
    case 'SUBMIT':
      return {
        ...inputState,
        pristine: true
      }
  }
}

export function useInput<T>(defaultValue: T, validators: Validator<T>[] = []): InputHook<T> {
  const [state, dispatch] = useReducer<Reducer<InputValidatedState<T>, InputAction<T>>>(inputReducer, {
    value: defaultValue,
    pristine: true,
    error: null,
    valid: true
  })
  useEffect(() => {
    validate(defaultValue, validators)
      .then(err => err && dispatch({type: 'VALIDATOR_RESULT', payload: err}))
  }, [])
  return {
    ...(state as InputValidatedState<T>),
    setValue: (newVal, keepPristine = false) => {
      dispatch({type: 'SET_VALUE', payload: {value: newVal, keepPristine: keepPristine}})
      validate(newVal, validators)
        .then(err => dispatch({type: 'VALIDATOR_RESULT', payload: err}))
    },
    submit: () => dispatch({type: 'SUBMIT'})
  };
}

export function form(inputs: InputHook<any>[]) {
  return {
    valid: inputs.every(i => i.valid),
    pristine: inputs.every(i => i.pristine)
  }
}
export function mapInput<T, R>(source: InputHook<T>, mapper: (arg: T | undefined) => R, writeMapper: (arg: R) => T): InputHook<R> {
  return {
    value: mapper(source.value),
    pristine: source.pristine,
    error: source.error,
    valid: source.valid,
    submit: source.submit,
    setValue: (val: R, pristine) => {
      source.setValue(writeMapper(val), pristine)
    }

  } as InputHook<R>
}
