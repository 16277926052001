import styles from './table-page-container.module.css'
import {ReactNode} from "react";

export function TablePageContainer({children}: {children: ReactNode}) {
  return (
    <div className={styles.pageContainer}>
      {children}
    </div>
  )
}
