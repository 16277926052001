import {form, InputHook, mapInput, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import React, {useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient} from "../../client/c2-api-client";
import {Labeled} from "../../components/input/labeled/Labeled";
import {VerticalGroup} from "../../components/input/vertical-group/VerticalGroup";
import {Button} from "../../components/button/Button";
import {Dialog} from "@mui/material";
import styles from "./settings-work-week.module.css";
import {SelectInput} from "../../components/input/select/select";
import classNames from "classnames";
import {OperatorSettingsListTitle} from "../../components/operator-admin-settings/list-title-container/operator-settings-list-title";
import {TimeInput} from "../../components/input/time/time-input";
import {timeService} from "../../services/time-service";
import moment from "moment";

const dayOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(day => ({
  id: day.toLowerCase(),
  name: day
}))
type WorkWeekConfig = { id: string, firstDay: string, amStart: string, amEnd: string, pmStart: string, pmEnd: string }
export const defaultOperatorConfig = {
  firstDay: 'sunday',
  amStart: '04:00',
  amEnd: '14:00',
  pmStart: '14:01',
  pmEnd: '3:59',
  tachoDiffWarning: '0:15',
  tachoDiffError: '0:30'
}


export function SettingsWorkWeek() {
  const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [errorModalData, setErrorModalData] = useState({show: false, message: ''})
  const startDay = useInput(defaultOperatorConfig.firstDay, [Validators.required])
  const endDay = mapInput(startDay, (day) => {
    if (!day) return undefined
    const index = dayOptions.map(d => d.id).indexOf(day)
    return dayOptions[index === 0 ? dayOptions.length - 1 : index - 1].id
  }, endDay => {
    if (!endDay) return ''
    const index = dayOptions.map(d => d.id).indexOf(endDay)
    return dayOptions[index === dayOptions.length - 1 ? 0 : index + 1].id
  })
  const amStart = useInput(timeService.parseTime(defaultOperatorConfig.amStart), [Validators.required])
  const amEnd = useInput(timeService.parseTime(defaultOperatorConfig.amEnd), [Validators.required])
  const pmStart = useInput(timeService.parseTime(defaultOperatorConfig.pmStart), [Validators.required])
  const pmEnd = useInput(timeService.parseTime(defaultOperatorConfig.pmEnd), [Validators.required])
  const tachoDiffWarning = useInput(timeService.parseTime(defaultOperatorConfig.tachoDiffWarning), [Validators.required])
  const tachoDiffError = useInput(timeService.parseTime(defaultOperatorConfig.tachoDiffError), [Validators.required])
  const weekConfigForm = form([startDay, amStart, amEnd, pmStart, pmEnd])
  useEffect(() => {
    c2ApiClient.getOperatorConfig().then(config => {
      if (config) {
        startDay.setValue(config.firstDay)

        amStart.setValue(timeService.parseTime(config.amStart))
        amEnd.setValue(timeService.parseTime(config.amEnd))
        pmStart.setValue(timeService.parseTime(config.pmStart))
        pmEnd.setValue(timeService.parseTime(config.pmEnd))
        tachoDiffWarning.setValue(timeService.parseTime(config.timeDiscrepancyWarning))
        tachoDiffError.setValue(timeService.parseTime(config.timeDiscrepancyError))
      }
    })
  }, [c2ApiClient])


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!weekConfigForm.valid) {
      return;
    }
    const amStartVal = amStart.value!
    const amEndVal = amEnd.value!
    const pmStartVal = pmStart.value!
    const pmEndVal = pmEnd.value!
    const timeDiffWarning = tachoDiffWarning.value!
    const timeDiffError = tachoDiffError.value!
    if (!amStartVal.isBefore(amEndVal)) {
      setErrorModalData({show: true, message: 'AM start time needs to be before AM end time'})
      return
    }
    if (!pmStartVal.isAfter(amEndVal)) {
      setErrorModalData({show: true, message: 'PM start time needs to be after AM end time'})
      return
    }
    const realPmEndVal = pmEndVal.isAfter(pmStartVal) ? moment(pmEndVal).subtract(1, 'days') : pmEndVal
    if (realPmEndVal.isAfter(amStartVal)) {
      setErrorModalData({show: true, message: 'PM end time needs to be before AM start time'})
      return
    }
    c2ApiClient.setOperatorConfig({
      firstDay: startDay.value!,
      amStart: timeService.formatTime(amStartVal),
      amEnd: timeService.formatTime(amEndVal),
      pmStart: timeService.formatTime(pmStartVal),
      pmEnd: timeService.formatTime(pmEndVal),
      timeDiscrepancyWarning: timeService.formatTime(timeDiffWarning),
      timeDiscrepancyError: timeService.formatTime(timeDiffError),
    }).then(() => {
      setShowSuccessModal(true)
    })
  }
  return (
    <div className={'d-flex align-items-start justify-content-center'}>
      <form
        onSubmit={handleSubmit}>
        <div className="flex-fill">
          <Dialog
            PaperProps={{classes: {root: styles.resetShiftDialog}}}
            disableScrollLock={true}
            open={showSuccessModal}>
            <>
              <div>Working Week has been successfully configured</div>
              <Button onClick={() => setShowSuccessModal(false)}>Close</Button>
            </>
          </Dialog>
          <Dialog
            PaperProps={{classes: {root: styles.resetShiftDialog}}}
            disableScrollLock={true}
            open={errorModalData.show}>
            <>
              <div style={{color: 'red'}}>{errorModalData.message}</div>
              <Button onClick={() => setErrorModalData({show: false, message: ''})}>Close</Button>
            </>
          </Dialog>
          <div className={styles.verticalContainer}>
            <VerticalGroup>
              <OperatorSettingsListTitle title='Defining Working Week'/>
              <SingleSelect label='Start Week' input={startDay} options={dayOptions}/>
              <SingleSelect label='End Week' input={endDay} options={dayOptions} disabled={true}/>
              <div className={classNames('d-flex flex-row', styles.standardTimesRow)}>
                <div>
                  <OperatorSettingsListTitle title='Shift Time Difference Settings'/>
                  <StandardTimesContainer>
                    <TimeInput input={tachoDiffWarning} label='Difference Allowed Before Warning (minutes)'/>
                    <TimeInput input={tachoDiffError} label='Difference Allowed Before Error (minutes)'/>
                  </StandardTimesContainer>
                </div>
              </div>
              <div className={classNames('d-flex flex-row', styles.standardTimesRow)}>
                <div>
                  <OperatorSettingsListTitle title={'AM Standard'}/>
                  <StandardTimesContainer>
                    <TimeInput input={amStart} label='From'/>
                    <TimeInput input={amEnd} label='To'/>
                  </StandardTimesContainer>
                </div>
                <div>
                  <OperatorSettingsListTitle title={'PM Standard'}/>
                  <StandardTimesContainer>
                    <TimeInput input={pmStart} label='From'/>
                    <TimeInput input={pmEnd} label='To'/>
                  </StandardTimesContainer>
                </div>
              </div>
            </VerticalGroup>

          </div>
        </div>
        <div className={classNames(styles.buttonsContainer, 'd-flex justify-content-end align-items-center')}>
          <Button type='submit' size='normal' colorScheme="positive" shape='semi-rounded'
                  disabled={!weekConfigForm.valid}><span className='text-200'>Save</span></Button>
        </div>
      </form>
    </div>

  )
}

function StandardTimesContainer({children}: { children: React.ReactNode }) {
  return <div className={classNames('d-flex flex-column', styles.standardTimesContainer)}>
    {children}
  </div>
}

function SingleSelect({
                        input,
                        options,
                        label,
                        disabled
                      }: { input: InputHook<string>, options: { id: string, name: string }[], label: string, disabled?: boolean }) {
  return (
    <Labeled label={label}>
      <SelectInput
        input={mapInput(input, val => [val ?? ''], ([newV]) => newV!)}
        options={options}
        disabled={disabled}
        fullWidth={false}
        multiple={false}
      />
    </Labeled>
  )
}
