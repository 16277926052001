import {form, useInput} from "../../hooks/input";
import moment from "moment";
import {Validators} from "../../validators";
import React, {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import styles from "./edit-shift-modal.module.css";
import {OperatorAdminSettingsForm} from "../operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {TimeInput} from "../input/time/time-input";
import {Button} from "../button/Button";

export function EditShiftModal({
                                 startTime,
                                 endTime,
                                 changeTimes,
                                 cancelShift,
                                 open
                               }: { startTime?: string, endTime?: string, cancelShift: () => Promise<void>, changeTimes: (params?: { start: string, end: string }) => Promise<void>, open: boolean }) {
  const startInput = useInput(moment(), [Validators.required])
  const endInput = useInput(moment(), [Validators.required])
  const [modalIndex, setModalIndex] = useState(0)
  useEffect(() => {
    startInput.setValue(startTime ? moment(startTime, 'HH:mm') : moment())
    endInput.setValue(endTime ? moment(endTime, 'HH:mm') : moment().add(8, 'hours'))
  }, [startTime, endTime])
  const shiftForm = form([startInput, endInput])
  const changeTimesHandler = () => {
    changeTimes({
      start: startInput.value!.format('HH:mm'),
      end: endInput.value!.format('HH:mm')
    })
    setModalIndex(0)
  }
  const cancel = () => {
    setModalIndex(0)
    changeTimes()
  }
  return (
    <Dialog
      PaperProps={{classes: {root: styles.addShiftDialogRoot}}}
      disableScrollLock={true}
      open={open}>
      {modalIndex === 0 && <div className='d-flex flex-column' style={{gap: '20px'}}>
        <Button shape={"rounded"} colorScheme={"neutral"} onClick={() => cancelShift()}>Cancel Shift</Button>
        <Button shape={"rounded"} colorScheme={"neutral"} onClick={() => setModalIndex(1)}>Change Times</Button>
        <Button shape={"rounded"} colorScheme={"negative"} onClick={() => cancel()}>Cancel</Button>
      </div>}
      {modalIndex === 1 && <OperatorAdminSettingsForm title='Shift Time' submitLabel='Send' cancelCallback={() => cancel()}
                                  disableSubmit={!shiftForm.valid}
                                  paddingTop={false}
                                  editCallback={() => changeTimesHandler()}>
        <div className='d-flex flex-column' style={{gap: '20px'}}>
          <TimeInput input={startInput} label={'Start Time'}/>
          <TimeInput input={endInput} label={'End Time'}/>
        </div>
      </OperatorAdminSettingsForm>}
    </Dialog>
  )
}
