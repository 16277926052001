export function Search({isMobile = false}: {isMobile?: boolean}) {
  return isMobile ? <SearchMobile /> : <SearchDesktop />
}
function SearchDesktop() {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M5.435 2.45a5.639 5.639 0 1 1 4.41 10.38 5.639 5.639 0 0 1-4.41-10.38Z"
        stroke="#00B471"
        strokeWidth={2.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.627 11.627 16 16"
        stroke="#00B471"
        strokeWidth={2.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
function SearchMobile() {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M4.435 1.45a5.639 5.639 0 1 1 4.41 10.38 5.639 5.639 0 0 1-4.41-10.38Z"
        stroke="#00B471"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.627 10.627 15 15"
        stroke="#00B471"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
