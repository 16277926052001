import {RoutePaths, SettingsRouteSubPaths, useRoutePathMatch} from "../../app-router";
import {form, InputHook, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient, RateCard, SiteModel} from "../../client/c2-api-client";
import {OperatorAdminSettingsForm} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {useRoutingContext} from "../../hooks/routing-context";
import {SettingsSiteEditGroup} from "./settings-site-edit-group";
import {VerticalGroup} from "../../components/input/vertical-group/VerticalGroup";
import {Button} from "../../components/button/Button";
import {GroupModel} from "../settings-group-list/settings-group-list";
import {TextFormField} from "../../components/input/composite/text-form-field/text-form-field";
import styles from "../settings-profile-edit/settings-profile-edit.module.css";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {ArrowDownIcon} from "../../icons/ArrowDown";
import {Edit} from "../../icons/edit";
import {AddButton} from "../../components/add-button/add-button";
import moment from "moment";
import {DateInput} from "../../components/input/date/date-input";

type SiteDetails = Omit<SiteModel, 'id' | 'operatorId'>
type RatesType = Omit<RateCard, 'id' | 'groupId'>

export function SettingsSiteRateCards() {
  const match = useRoutePathMatch(SettingsRouteSubPaths.SITE_RATE_CARDS)
  const siteId = match?.params.siteId ?? ''
  const routingContext = useRoutingContext()
  const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const [siteDetails, setSiteDetails] = useState<SiteDetails>({name: '', address: '', postCode: '', qrCode: ''})
  const [rateCards, setRateCards] = useState<RateCard[]>([])
  const [allGroups, setAllGroups] = useState<GroupModel[]>([])
  const [ratesModalData, setRatesModalData] = useState<{ show: boolean, groupName?: string, groupId?: string, rates?: RatesType, ratesId?: string }>({show: false})


  useEffect(() => {
    Promise.all([c2ApiClient.getOperatorSites(), c2ApiClient.getRateCards(siteId)])
      .then(([sites, rates]) => {
        const site = sites.sites.find(site => siteId === site.id)
        if (site) {
          setSiteDetails(site)
        }
        setRateCards(rates)

      })
    c2ApiClient.getOperatorGroups().then(groups => setAllGroups(groups.groups))
  }, [siteId, c2ApiClient])

  const groupRateCards: RateCard[] = rateCards.filter(rate => !!rate.groupId)
  const ratesByGroup = groupRateCards.reduce((acc, r) => ({
    ...acc,
    [r.groupId!]: [...(acc[r.groupId!] ?? []), r]
  }), {} as Record<string, RateCard[]>)
  const groupsWithRates = rateCards.map(rate => rate.groupId).filter(groupId => !!groupId)
  const availableGroups = allGroups.filter(gr => !groupsWithRates.includes(gr.id))
  const defaultRateCard: RateCard | undefined = rateCards.filter(rate => !rate.groupId)[0]

  const groupNamesById: Record<string, string> = allGroups.reduce((acc, gr) => ({...acc, [gr.id]: gr.name}), {})

  const handleSubmit = (rates?: RatesType) => {
    console.log('saving rates', rates)
    const {ratesId} = ratesModalData
    setRatesModalData({show: false})
    if (!rates) {
      return;
    }
    const setRates$ = ratesId ?
      c2ApiClient.editRateCard(ratesId, rates) :
      c2ApiClient.addRateCard(siteId, {rates, groupId: ratesModalData.groupId})

    setRates$.then(rateCards => {
      setRateCards(rateCards)
    })

  }
  return (
    <OperatorAdminSettingsForm disableSubmit={true} editCallback={handleSubmit}
                               title={`Rate Cards For ${siteDetails.name}`}
    >
      <EditRatesPopup show={ratesModalData.show} onSave={handleSubmit} rates={ratesModalData.rates}
                      groupName={ratesModalData.groupName}/>
      <VerticalGroup>

        {defaultRateCard && (
          <div className='d-flex justify-content-between'>
            <span>Default rate card</span>
            <span style={{cursor: 'pointer'}} onClick={() => setRatesModalData({
              show: true,
              rates: defaultRateCard,
              ratesId: defaultRateCard.id
            })}><Edit/></span>
          </div>
        )}
        {Object.entries(ratesByGroup).sort((a, b) => a[0].localeCompare(b[0])).map(([groupId, rateCards]) => (
          <div key={groupId} className='d-flex flex-column'>
            <p><b>Rates for group: {groupNamesById[groupId]}</b></p>
            <div className='d-flex flex-column'>
              {rateCards.sort((a, b) => moment(a.activeFrom).diff(moment(b.activeFrom))).map((r) => (
                <div className='d-flex justify-content-between'>
                  <span>valid from: {r.activeFrom}</span>
                  <span style={{cursor: 'pointer'}}
                        onClick={() => setRatesModalData({
                          show: true,
                          rates: r,
                          ratesId: r.id,
                          groupId: groupId,
                          groupName: groupNamesById[groupId]
                        })}><Edit/></span>
                </div>
              ))}
            </div>
            <Button size='small' onClick={() => setRatesModalData({
              show: true,
              groupId: groupId,
              groupName: groupNamesById[groupId]
            })}>Add</Button>
          </div>
        ))}
        {defaultRateCard && availableGroups.length !== 0 && (
          <div>
            <div>Add Rate Card For Group</div>
            <div className='d-flex flex-wrap' style={{gap: '10px'}}>
              {
                availableGroups.map(group =>
                  <Button key={group.id} colorScheme='positive'
                          onClick={() => setRatesModalData({show: true, groupName: group.name, groupId: group.id})}>
                    {group.name}
                  </Button>
                )
              }
            </div>
          </div>
        )}
        {!defaultRateCard && (
          <AddButton onClick={() => setRatesModalData({show: true})} label={'Add Default Rate Card'}/>
          /*<Button
            onClick={() => setRatesModalData({show: true})}>Add Default Rate Card</Button>*/
        )}

      </VerticalGroup>
    </OperatorAdminSettingsForm>
  )
}

export function EditRatesPopup({
                                 show,
                                 groupName,
                                 rates,
                                 onSave
                               }: { show: boolean, groupName?: string, rates?: RatesType, onSave: (arg?: RatesType) => void }) {
  const mondayNormal = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const mondayMinHours = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const mondayOvernight = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const tuesdayNormal = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const tuesdayMinHours = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const tuesdayOvernight = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const wednesdayNormal = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const wednesdayMinHours = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const wednesdayOvernight = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const thursdayNormal = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const thursdayMinHours = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const thursdayOvernight = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const fridayNormal = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const fridayMinHours = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const fridayOvernight = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const saturdayNormal = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const saturdayMinHours = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const saturdayOvernight = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const sundayNormal = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const sundayMinHours = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const sundayOvernight = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const publicHolidayNormal = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const publicHolidayMinHours = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const publicHolidayOvernight = useInput('', [Validators.required, Validators.pattern(/\d+(\.\d+)?/)])
  const activeFromInput = useInput(moment(), [Validators.required])

  const byDay: {day: string, key: keyof RatesType, normal: InputHook<string>, minHours: InputHook<string>, overnight: InputHook<string>}[] = [
    {day: 'Monday', key: 'mondayRates', normal: mondayNormal, minHours: mondayMinHours, overnight: mondayOvernight},
    {day: 'Tuesday', key: 'tuesdayRates', normal: tuesdayNormal, minHours: tuesdayMinHours, overnight: tuesdayOvernight},
    {day: 'Wednesday', key: 'wednesdayRates', normal: wednesdayNormal, minHours: wednesdayMinHours, overnight: wednesdayOvernight},
    {day: 'Thursday', key: 'thursdayRates', normal: thursdayNormal, minHours: thursdayMinHours, overnight: thursdayOvernight},
    {day: 'Friday', key: 'fridayRates', normal: fridayNormal, minHours: fridayMinHours, overnight: fridayOvernight},
    {day: 'Saturday', key: 'saturdayRates', normal: saturdayNormal, minHours: saturdayMinHours, overnight: saturdayOvernight},
    {day: 'Sunday', key: 'sundayRates', normal: sundayNormal, minHours: sundayMinHours, overnight: sundayOvernight},
    {day: 'Public Holiday', key: 'publicHolidayRates', normal: publicHolidayNormal, minHours: publicHolidayMinHours, overnight: publicHolidayOvernight},
  ]

  useEffect(() => {
    if (show) {
      console.log('setting rates', rates)
      mondayNormal.setValue(rates?.mondayRates.normalRate.toString() ?? '', true)
      mondayMinHours.setValue(rates?.mondayRates.normalRateMinHours.toString() ?? '', true)
      mondayOvernight.setValue(rates?.mondayRates.overnightRate.toString() ?? '', true)
      tuesdayNormal.setValue(rates?.tuesdayRates.normalRate.toString() ?? '', true)
      tuesdayMinHours.setValue(rates?.tuesdayRates.normalRateMinHours.toString() ?? '', true)
      tuesdayOvernight.setValue(rates?.tuesdayRates.overnightRate.toString() ?? '', true)
      wednesdayNormal.setValue(rates?.wednesdayRates.normalRate.toString() ?? '', true)
      wednesdayMinHours.setValue(rates?.wednesdayRates.normalRateMinHours.toString() ?? '', true)
      wednesdayOvernight.setValue(rates?.wednesdayRates.overnightRate.toString() ?? '', true)
      thursdayNormal.setValue(rates?.thursdayRates.normalRate.toString() ?? '', true)
      thursdayMinHours.setValue(rates?.thursdayRates.normalRateMinHours.toString() ?? '', true)
      thursdayOvernight.setValue(rates?.thursdayRates.overnightRate.toString() ?? '', true)
      fridayNormal.setValue(rates?.fridayRates.normalRate.toString() ?? '', true)
      fridayMinHours.setValue(rates?.fridayRates.normalRateMinHours.toString() ?? '', true)
      fridayOvernight.setValue(rates?.fridayRates.overnightRate.toString() ?? '', true)
      saturdayNormal.setValue(rates?.saturdayRates.normalRate.toString() ?? '', true)
      saturdayMinHours.setValue(rates?.saturdayRates.normalRateMinHours.toString() ?? '', true)
      saturdayOvernight.setValue(rates?.saturdayRates.overnightRate.toString() ?? '', true)
      sundayNormal.setValue(rates?.sundayRates.normalRate.toString() ?? '', true)
      sundayMinHours.setValue(rates?.sundayRates.normalRateMinHours.toString() ?? '', true)
      sundayOvernight.setValue(rates?.sundayRates.overnightRate.toString() ?? '', true)
      publicHolidayNormal.setValue(rates?.publicHolidayRates.normalRate.toString() ?? '', true)
      publicHolidayMinHours.setValue(rates?.publicHolidayRates.normalRateMinHours.toString() ?? '', true)
      publicHolidayOvernight.setValue(rates?.publicHolidayRates.overnightRate.toString() ?? '', true)
      activeFromInput.setValue(moment(rates?.activeFrom))
    }
  }, [show])

  const applyRates = (inputs: typeof byDay[number], type: 'normal' | 'minHours' | 'overnight') => {
    const appliedRate = inputs[type]
    byDay.forEach(daily => {
      if (daily.day !== inputs.day) {
        daily[type].setValue(appliedRate.value!)
      }
    })
  }

  const saveRates = () => {
    const rates = byDay.reduce((acc, daily) => {
      // @ts-ignore
      acc[daily.key as keyof RatesType] = {
        normalRate: Number(daily.normal.value!),
        normalRateMinHours: Number(daily.minHours.value!),
        overnightRate: Number(daily.overnight.value!)
      }
      return acc
    }, {activeFrom: activeFromInput.value!.format('yyyy-MM-DD')} as RatesType)
    onSave(rates)
  }


  const siteForm = form([mondayNormal, mondayOvernight, mondayMinHours, tuesdayNormal, tuesdayOvernight, tuesdayMinHours,
    wednesdayNormal, wednesdayOvernight, wednesdayMinHours, thursdayNormal, thursdayOvernight, thursdayMinHours,
    fridayNormal, fridayOvernight, fridayMinHours, saturdayNormal, saturdayOvernight, saturdayMinHours,
    sundayNormal, sundayOvernight, sundayMinHours, publicHolidayNormal, publicHolidayOvernight, publicHolidayMinHours,
    activeFromInput])
  return <Dialog
    // PaperProps={{classes: {root: styles.resetShiftDialog}}}
    disableScrollLock={true}
    open={show}>
    <>
      <DialogTitle>Set {!groupName ? 'Default' : ''} Rate
        Card {groupName ? `for ${groupName} Group` : ''} {rates?.activeFrom ? `(${rates?.activeFrom})` : ''}</DialogTitle>
      <DialogContent>
        <div className='d-flex'>
          <VerticalGroup spacing='small'>
            {byDay.map(dayInputs => (
              <div key={dayInputs.day} className='d-flex flex-column justify-content-between'>
                <span>{dayInputs.day}</span>
                <div className='d-flex align-items-center' style={{gap: '5px'}}>
                  <TextFormField input={dayInputs.normal} name={`${dayInputs.day}-normal`} label='Regular Rate'/>
                  <span style={{cursor: 'pointer', visibility: dayInputs.day === 'Monday' ? 'visible' : 'hidden'}}
                        onClick={() => applyRates(dayInputs, 'normal')}><ArrowDownIcon/></span>
                  <TextFormField input={dayInputs.minHours} name={`${dayInputs.day}-min-hours`} label='Minimum Hours'/>
                  <span style={{cursor: 'pointer', visibility: dayInputs.day === 'Monday' ? 'visible' : 'hidden'}}
                        onClick={() => applyRates(dayInputs, 'minHours')}><ArrowDownIcon/></span>
                  <TextFormField input={dayInputs.overnight} name={`${dayInputs.day}-overnight`}
                                 label='Overnight Rate'/>
                  <span style={{cursor: 'pointer', visibility: dayInputs.day === 'Monday' ? 'visible' : 'hidden'}}
                        onClick={() => applyRates(dayInputs, 'overnight')}><ArrowDownIcon/></span>
                </div>
              </div>
            ))}
            <DateInput input={activeFromInput} label={'Active From'}/>
          </VerticalGroup>
        </div>
      </DialogContent>
      <Button colorScheme='negative' onClick={() => onSave()}>Cancel</Button>
      <Button colorScheme='positive' disabled={!siteForm.valid} onClick={() => saveRates()}>Save</Button>

    </>
  </Dialog>
}
