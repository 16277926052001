import styles from './timesheets.module.css'
import classNames from "classnames";
import {Table} from "../../components/table/table";
import {RowsFetcher, useTable} from "../../hooks/table";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {
    DriverFilters,
    OperatorAdminC2ApiClient,
    PublicHoliday,
    Shift,
    SiteModel,
    SiteReponse,
    TachoRowResult
} from "../../client/c2-api-client";
import moment, {Moment} from "moment";
import {TablePageContainer} from "../../components/table-page-container/table-page-container";
import {TablePageHeader} from "../../components/table-page-header/table-page-header";
import {TableFilterBar} from "../../components/table-filter-bar/table-filter-bar";
import {ArrowLeft} from "../../icons/arrow-left";
import {ArrowRight} from "../../icons/arrow-right";
import {ArrowDownIcon} from "../../icons/ArrowDown";
import {GroupModel} from "../settings-group-list/settings-group-list";
import {DriversTableFilterBar} from "../../components/drivers-table-filter-bar/drivers-table-filter-bar";
import {Popover} from "@mui/material";
import {timeService} from "../../services/time-service";
import {Button} from "../../components/button/Button";
import {AddShiftModal} from "../../components/add-shift-modal/add-shift-modal";
import {AddMissingShiftModal} from "../../components/add-missing-shift-modal/add-missing-shift-modal";
import {defaultFiltersConfig} from "../../components/drivers-table-filter-bar/default-drivers-filters-config";
import {defaultOperatorConfig} from "../settings-work-week/settings-work-week";
import {AddTachoModal} from "../../components/add-tacho-modal/add-tacho-modal";
import {Info} from "../../icons/Info";
import {TimeSheetsShiftPanel} from "./timesheets-shift-panel";
import {driverResponseToSummaryMapper, DriverSummary} from "./driver-summary";
import {ColGroups, driversTableConfig} from "./timesheets-table-config";
import {InvoicingEnabledProvider, WithInvoicing} from "./invoicing-enabled-context";
import {UploadTachoModal} from "./upload-tacho-modal";
import {InvoiceSentModal} from "../../components/invoice-sent-modal/invoice-sent-modal";
import {useSearchContext} from "../../components/navigation-shell/navigation-shell";
import {driversTableService} from "../../services/drivers-table-service";
import {AddDatesIntervalModal} from "../../components/add-dates-interval-modal/add-dates-interval-modal";


export function Timesheets() {
    const apiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
    const today = useMemo(() => moment(), [])
    const infoRef = useRef<HTMLDivElement>(null)
    const [infoOpen, setInfoOpen] = useState(false)
    const [endDate, setEndDate] = useState(moment().startOf('isoWeek').add(1, 'week').day("sunday"))
    const [invoicingEnabled, setInvoicingEnabled] = useState(false)
    const [publicHolidays, setPublicHolidays] = useState<PublicHoliday[]>([])
    const [tachoDiffConfig, setTachoDiffConfig] = useState<{ warning: string, error: string }>({
        warning: defaultOperatorConfig.tachoDiffWarning,
        error: defaultOperatorConfig.tachoDiffError
    })
    const [tachoUploadResult, setTachoUploadResult] = useState<{
        open: boolean,
        results?: TachoRowResult[]
    }>({open: false})
    const [sites, setSites] = useState<SiteModel[]>([])
    const [groups, setGroups] = useState<GroupModel[]>([])
    const days = new Array(7).fill(1).map((el, i) => moment(endDate).subtract(7 - i, 'days'))
    const [selectedShift, setSelectedShift] = useState<{
        shiftId: string,
        driver: { name: string, site?: string }
    } | null>(null)
    const weeksSelectorRef = useRef<HTMLDivElement>(null)
    const [weeksSelectorOpen, setWeeksSelectorOpen] = useState(false)
    const [invoicesApprovedByDriver, setInvoicesApprovedByDriver] = useState<Record<string, boolean>>({})
    const [showUploadTachoModal, setShowUploadTachoModal] = useState(false)

    const [showExportHolidaySickModal, setShowExportHolidaySickModal] = useState(false)

    useEffect(() => {
        apiClient.getPublicHolidays({
            before: timeService.formatDate(days[days.length - 1]),
            after: timeService.formatDate(days[0])
        }).then((holidays) => setPublicHolidays(holidays))
    }, [endDate])
    const uploadTachoData = (p?: { ev: React.ChangeEvent<HTMLInputElement>, csvFormat: string }) => {
        if (!p) {
            setShowUploadTachoModal(false)
            return
        }
        const {csvFormat, ev} = p
        const files = (ev.target as HTMLInputElement).files
        const selection: File | undefined = (files ?? [])[0]
        console.log('tacho file', ev.target.files, selection)
        if (selection) {
            apiClient.uploadTachoData({file: selection, csvFormat}).then(res => {
                setShowUploadTachoModal(false)
                setTachoUploadResult({open: true, results: res.data})
            }).finally(() => {
                setShowUploadTachoModal(false)
                driversTableHook.reload()
            })
        }
    }
    const filtersConfig = useMemo(() => defaultFiltersConfig(groups, sites), [groups, sites])
    useEffect(() => {
        Promise.all<SiteReponse, {
            groups: GroupModel[]
        }>([apiClient.getOperatorSites(), apiClient.getOperatorGroups()])
            .then(([sites, groups]) => {
                setSites(sites.sites)
                setGroups(groups.groups)
            })
        apiClient.getOperatorConfig().then(config => {
            setEndDate(timeService.getNextWeekDay(moment(), config?.firstDay ?? 'sunday'))
            if (config) {
                setTachoDiffConfig({warning: config.timeDiscrepancyWarning, error: config.timeDiscrepancyError})
            }
        })
        apiClient.getOperatorInvoiceConfig().then(invoiceConfig => {
            setInvoicingEnabled(invoiceConfig?.invoicingEnabled ?? false)
        })
    }, [])
    const onSelect = useCallback((shift: Shift, d: { name: string, site?: string }) => {
        setSelectedShift({shiftId: shift.id, driver: d})
    }, [])
    const driversFetcher = useMemo<RowsFetcher<DriverSummary, DriverFilters>>(() => {
        return ({page, pageSize, sort, ascending, filters}) => {
            const mapper = driverResponseToSummaryMapper(today, days)
            const startDate = days[0].format('YYYY-MM-DD')
            const endDate = days[days.length - 1].format('YYYY-MM-DD')
            return apiClient.getDriverShifts({
                page,
                pageSize,
                order: sort,
                ascending,
                startDate,
                endDate,
                filters
            }).then(driversPage => {
                const driversApproved = driversPage.data.reduce((acc, driverShifts) => {
                    const hasShiftsWithoutInvoice = driverShifts.daySlots.some(slot => slot.shifts.some(sh => !sh.invoiceId))
                    acc[driverShifts.driverId] = !hasShiftsWithoutInvoice
                    return acc
                }, {} as Record<string, boolean>);
                setInvoicesApprovedByDriver(driversApproved)
                return {driversPage, driversApproved}
            }).then(({driversPage, driversApproved}) => ({
                ...driversPage,
                data: driversPage.data.map((driverData) => mapper(driverData, driversApproved[driverData.driverId]))
            }))
        }
    }, [apiClient, today, days[0].toDate().getTime()])
    const driversTableHook = useTable({
        pageSize: 1000,
        rowsFetcher: driversFetcher,
        defaultSort: 'firstName',
        defaultSortAscending: true
    })


    const searchContext = useSearchContext()
    useEffect(() => {
        const listenerId = searchContext.attachListener(driversTableService.nameSearchFilterListener(driversTableHook))
        return () => searchContext.removeListener(listenerId)
    }, [])

    const downloadCsv = (driverId?: string) => {
        const {currentPage: page, pageSize, currentFilters: filters, currentSort: order} = driversTableHook
        const startDate = days[0].format('YYYY-MM-DD')
        const endDate = days[days.length - 1].format('YYYY-MM-DD');
        apiClient.exportDriverShifts({
            page,
            pageSize,
            order,
            startDate,
            endDate,
            filters
        }, driverId)
    }
    const downloadSickHoliday = () => {
        setShowExportHolidaySickModal(true)
    }
    const [addShiftData, setAddShiftData] = useState({
        show: false,
        earliestStart: '',
        date: moment(),
        driverId: ''
    })
    const [addMissingShiftData, setAddMissingShiftData] = useState({
        show: false,
        earliestStart: '',
        date: moment(),
        driverId: ''
    })
    const [invoiceSentModalData, setInvoiceSentModalData] = useState<{
        show: boolean,
        driverId?: string | undefined
    }>({show: false, driverId: undefined})
    const addShift = useCallback((driver: DriverSummary, date: Moment) => {
        const today = moment()
        if (today.isAfter(date, 'day')) {
            setAddMissingShiftData({
                show: true,
                earliestStart: driver.earliestStart,
                date: date,
                driverId: driver.id
            })
        } else {
            setAddShiftData({
                show: true,
                earliestStart: driver.earliestStart,
                date: date,
                driverId: driver.id
            })
        }
    }, [setAddShiftData, setAddMissingShiftData])
    const sendInvoice = (driverId: string) =>
        apiClient.emailDriverInvoice(driverId, timeService.formatDate(moment(endDate).subtract(7, 'days')), timeService.formatDate(moment(endDate).subtract(1, 'days')))
            .then(() => setInvoiceSentModalData({show: true, driverId}))
    const tableConfig = useMemo(() => driversTableConfig({
        table: driversTableHook,
        today,
        dates: days,
        onSelect,
        onDownload: downloadCsv,
        selectedId: selectedShift?.shiftId,
        addShift,
        tachoDiffConfig,
        sendInvoice,
        publicHolidays
    }), [driversTableHook, selectedShift?.shiftId ?? '', addShift, tachoDiffConfig])

    const addFutureShift = (param?: { start: string, end: string }) => {
        if (param) {
            apiClient.addShift({
                driverId: addShiftData.driverId,
                date: addShiftData.date?.format('YYYY-MM-DD')!,
                startTime: param.start,
                endTime: param.end
            }).then(() => {
                setAddShiftData((data) => ({...data, show: false}))
                driversTableHook.reload()
            })
        } else {
            setAddShiftData((data) => ({...data, show: false}))
        }
    }
    const addMissingShift = (param?: { start: string, end: string, breakTime: string, isOvernight: boolean }) => {
        if (param) {
            apiClient.addMissingShift({
                driverId: addMissingShiftData.driverId,
                date: addMissingShiftData.date?.format('YYYY-MM-DD')!,
                startTime: param.start,
                endTime: param.end,
                breakTime: param.breakTime,
                isOvernight: param.isOvernight
            }).then(() => {
                setAddMissingShiftData((data) => ({...data, show: false}))
                driversTableHook.reload()
            })
        } else {
            setAddMissingShiftData((data) => ({...data, show: false}))
        }
    }

    const fetchComments = useCallback(() => apiClient.getShiftComments(selectedShift!.shiftId), [selectedShift?.shiftId ?? ''])
    const usernameFetcher = (id: string) => apiClient.getUserName(id)
    const approveShift = (id: string) => {
        return apiClient.createShiftInvoice(id)
            .then(() => driversTableHook.reload())
    }
    const addComment = useCallback((txt: string) => apiClient.addShiftComment(selectedShift!.shiftId, txt).then(() => driversTableHook.reload()), [driversTableHook, selectedShift?.shiftId ?? ''])
    const addReceipt = useCallback(({
                                        description,
                                        cost,
                                        file
                                    }: {
            description: string,
            cost: number,
            file: File
        }) => apiClient.addShiftReceipt(selectedShift!.shiftId, {
            description,
            cost,
            file
        }).then(() => driversTableHook.reload()).then(() => void 0)
        , [selectedShift?.shiftId ?? ''])
    const setOvernight = useCallback((isOvernight: boolean) => apiClient.setShiftOvernight(selectedShift!.shiftId, isOvernight).then(() => {
        driversTableHook.reload()
    }), [driversTableHook, selectedShift?.shiftId ?? ''])

    const setOperatorTime = useCallback((time: string) => {
        return apiClient.setShiftOperatorTime(selectedShift!.shiftId, time)
            .then(() => driversTableHook.reload())
    }, [selectedShift?.shiftId ?? '', driversTableHook, apiClient])
    const weekBefore = () => {
        const newEndDate = moment(endDate).subtract(7, 'days')
        setEndDate(newEndDate)
    }
    const weekAfter = () => {
        const newEndDate = moment(endDate).add(7, 'days')
        setEndDate(newEndDate)
    }
    const selectDays = new Array(10).fill(1).map((_v, i) => moment(endDate).subtract(7 * (4 - i), 'days'))
    const hasUnapprovedDriverShifts = Object.entries(invoicesApprovedByDriver).some(([k, val]) => !val)

    const handleDateIntervalModal = (param?: { start: string, end: string }) => {
        const prom = param ? apiClient.exportHolidaySickInfo({
            startDate: param.start,
            endDate: param.end
        }) : Promise.resolve()
        prom.finally(() => {
            setShowExportHolidaySickModal(false)
        })

    }
    return (
        <InvoicingEnabledProvider invoicingEnabled={invoicingEnabled}>
            <div className='d-flex flex-row flex-nowrap align-items-stretch' style={{minHeight: 'calc(100vh - 70px)'}}>
                <AddShiftModal startTime={addShiftData.earliestStart} handle={addFutureShift}
                               open={addShiftData.show}/>
                <InvoiceSentModal open={invoiceSentModalData.show}
                                  onClose={() => setInvoiceSentModalData({show: false})}
                />
                <AddMissingShiftModal startTime={addMissingShiftData.earliestStart} handle={addMissingShift}
                                      open={addMissingShiftData.show}/>
                <AddTachoModal tachoUploadResults={tachoUploadResult.results ?? []}
                               onClose={() => setTachoUploadResult({open: false})}
                               open={tachoUploadResult.open}/>
                <AddDatesIntervalModal startDate={today.format('YYYY-MM-DD')} handle={handleDateIntervalModal}
                                       action={'exportHolidaySick'}
                                       open={showExportHolidaySickModal}/>
                <TablePageContainer>
                    <TablePageHeader>
                        <div
                            className={classNames('d-flex justify-content-start align-items-center', styles.headerBar)}>
                            <TableFilterBar padding={false}>
                                <div className='d-flex align-items-center'>
                                    <div style={{padding: '10px 15px', cursor: 'pointer'}} onClick={weekBefore}>
                                        <ArrowLeft/></div>
                                    <div ref={weeksSelectorRef}
                                         style={{padding: '10px 30px', background: '#E3F1EE', cursor: 'pointer'}}
                                         onClick={() => setWeeksSelectorOpen(true)} className='text-300'>
                                        Week: {moment(endDate).subtract(7, 'days').format('DD-MM')} - {moment(endDate).subtract(1, 'days').format('DD-MM')}
                                        <ArrowDownIcon/>
                                    </div>
                                    <Popover open={weeksSelectorOpen}
                                             anchorEl={weeksSelectorRef.current as unknown as Element}
                                             onClose={() => setWeeksSelectorOpen(false)}
                                             disableScrollLock={true}
                                             anchorOrigin={{
                                                 vertical: 'bottom',
                                                 horizontal: 'center',
                                             }}
                                             transformOrigin={{
                                                 vertical: 'top',
                                                 horizontal: 'center',
                                             }}>

                                        <div className={styles.editActionsContainer}>
                                            {selectDays.map(d => <div
                                                className={classNames(styles.editAction, d.isSame(endDate, 'days') && styles.editActionSelected)}
                                                key={d.toDate().getTime()}
                                                onClick={() => {
                                                    setEndDate(d);
                                                    setWeeksSelectorOpen(false)
                                                }}>Week: {moment(d).subtract(7, 'days').format('DD-MM')} - {moment(d).subtract(1, 'days').format('DD-MM')}</div>)}

                                        </div>
                                    </Popover>
                                    <div style={{padding: '10px 15px', cursor: 'pointer'}} onClick={weekAfter}>
                                        <ArrowRight/></div>
                                </div>
                            </TableFilterBar>
                            <DriversTableFilterBar table={driversTableHook} filtersConfig={filtersConfig}/>


                        </div>
                        <div style={{paddingTop: '10px', gap: '5px'}} className='d-flex align-items-center'>
                            {/*<input style={{display: 'none'}} id='tacho-upload' name='receipt-upload' type='file'
                     onChange={(ev) => uploadTachoData(ev)}/>
              <label htmlFor='tacho-upload' style={{
                border: '1px solid black',
                cursor: 'pointer',
                borderRadius: '6px',
                padding: '5px',
                margin: '0px'
              }}>Upload Tacho Data</label>*/}
                            <Button size={'small'} onClick={() => setShowUploadTachoModal(true)}>Upload Tacho Data</Button>
                            <UploadTachoModal onUpload={uploadTachoData} show={showUploadTachoModal}/>
                            <Button size={'small'} onClick={() => downloadCsv()}>Download Timesheets CSV</Button>
                            <Button size={'small'} onClick={() => downloadSickHoliday()}>Download Activity CSV</Button>

                            <WithInvoicing>
                                <Button size={'small'} disabled={hasUnapprovedDriverShifts} onClick={() => {
                                    apiClient.downloadMasterInvoice(timeService.formatDate(moment(endDate).subtract(7, 'days')), timeService.formatDate(moment(endDate).subtract(1, 'days')))
                                }}>Download Master Invoice</Button>
                                {hasUnapprovedDriverShifts &&
                                    <span ref={infoRef} onClick={() => setInfoOpen(true)}
                                          style={{cursor: 'pointer'}}><Info/></span>}
                                <Popover open={infoOpen} anchorEl={infoRef.current as unknown as Element}
                                         onClose={() => setInfoOpen(false)}
                                         disableScrollLock={true}
                                         anchorOrigin={{
                                             vertical: 'bottom',
                                             horizontal: 'left',
                                         }}
                                         transformOrigin={{
                                             vertical: 'top',
                                             horizontal: 'left',
                                         }}>
                                    <div className={styles.editActionsContainer}>
                                        <p>You can not download master invoice until all the timesheets are approved for the week</p>
                                    </div>
                                </Popover>
                            </WithInvoicing>
                        </div>
                    </TablePageHeader>
                    <div className={styles.tableContainer}>
                        <Table columnsConfig={tableConfig} colGroups={ColGroups(tableConfig)}
                               tableHook={driversTableHook}/>
                    </div>
                </TablePageContainer>
                {selectedShift &&
                    <TimeSheetsShiftPanel driver={selectedShift.driver} shiftId={selectedShift.shiftId}
                                          fetchComments={fetchComments}
                                          onClose={() => setSelectedShift(null)}
                                          fetchUsername={usernameFetcher}
                                          approveShift={approveShift}
                                          setOvernight={setOvernight}
                                          addComment={addComment}
                                          addReceipt={addReceipt}
                                          setOperatorTime={setOperatorTime}
                    />}
            </div>
        </InvoicingEnabledProvider>
    )
}


