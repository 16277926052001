import {RoutePaths, useRoutePathMatch} from "../../app-router";
import styles from './driver-week-view.module.css'
import moment from "moment";
import {useEffect, useMemo, useState} from "react";
import {ArrowLeft} from "../../icons/arrow-left";
import {ArrowRight} from "../../icons/arrow-right";
import classnames from "classnames";
import {useApiClientContext} from "../../hooks/api-client-context";
import {DriverC2ApiClient, DriverDaySlot, Shift} from "../../client/c2-api-client";
import {DayLane, DayLaneShift} from "../../components/lanes/day-lane/day-lane";
import {DaySideBar} from "../../components/lanes/day-side-bar/day-side-bar";
import {timeService} from "../../services/time-service";
import {shiftService} from "../../services/shift/shift-service";
import {useRoutingContext} from "../../hooks/routing-context";
import {slotService} from "../../services/slot-service";

export function DriverWeekView() {
  const dateParam = useRoutePathMatch(RoutePaths.WEEK_VIEW)
  const apiClient = useApiClientContext().asOrFail(DriverC2ApiClient)
  const [startOfWeek, setStartOfWeek] = useState('sunday')
  const routingContext = useRoutingContext()
  const date = useMemo(() => {
    return dateParam ? timeService.parseDate(dateParam.params.date) : moment()
  }, [dateParam?.params.date])
  const startDate = useMemo(() => {
    return timeService.getNextWeekDay(moment(date), startOfWeek).subtract(7, 'days')
  }, [dateParam?.params.date, startOfWeek])
  const endDate = useMemo(() => {
    return timeService.getNextWeekDay(moment(date), startOfWeek).subtract(1, 'days')
  }, [dateParam?.params.date, startOfWeek])
  const today = useMemo(() => timeService.formatDate(moment()), [])
  const days = useMemo(() => {
    let current = moment(startDate)
    const res = []
    while (!current.isAfter(endDate)) {
      res.push({
        day: current.format("dd"),
        date: current.date(),
        formatted: current.format('YYYY-MM-DD')
      })
      current = current.add(1, 'day')
    }
    return res

  }, [dateParam?.params.date, startOfWeek])
  const [daySlots, setDaySlots] = useState<{loading: boolean, data: {slots: Record<string, DriverDaySlot>, shifts: Record<string, DayLaneShift[]>}}>({loading: true, data: {slots: {}, shifts: {}}})
  const [allShifts, setAllShifts] = useState<Shift[]>([])
  useEffect(() => {
    apiClient.getOperatorConfig().then(config => {
      if(config) {
        setStartOfWeek(config.firstDay)
      }
    })
  })


  useEffect(() => {
    setDaySlots({loading: true, data: {slots: {}, shifts: {}}})
    apiClient.getDriverSlots({startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD')})
      .then(shiftsRes => {
        const {allShifts, slotsByDate, shiftsByDate} = slotService.partitionByDate(shiftsRes)
        setAllShifts(allShifts)
        setDaySlots({loading: false, data: {slots: slotsByDate, shifts: shiftsByDate}})
      })

  }, [endDate.toDate().getTime()])

  console.log('days', days)
  console.log('day slots', daySlots)
  const weekBack = () => {
    const newDate = moment(date).subtract(7, 'days')
    routingContext.navigate(RoutePaths.WEEK_VIEW.getPath({date: newDate.format('YYYY-MM-DD')}))
  }
  const weekForward = () => {
    const newDate = moment(date).add(7, 'days')
    routingContext.navigate(RoutePaths.WEEK_VIEW.getPath({date: newDate.format('YYYY-MM-DD')}))
  }
  return (
    <div className='flex-fill d-flex flex-column align-items-stretch'>
      <div className='d-flex justify-content-center'>
        <i>Total Time:</i> {timeService.formatDuration(shiftService.totalApprovedDuration(allShifts))},
        <i>Total Break:</i> {timeService.formatDuration(shiftService.totalBreak(allShifts))}
      </div>
      <div className={classnames('d-flex text-100', styles.weekHeader)}>
        <div className={classnames(styles.sideBar, 'd-flex flex-column align-items-stretch')}>
          <div className={styles.arrowContainer} onClick={weekBack} style={{cursor: 'pointer'}}>
            <ArrowLeft/>
          </div>
          <div className={styles.arrowContainer} onClick={weekForward} style={{cursor: 'pointer'}}>
            <ArrowRight/>
          </div>
        </div>
        <div className='flex-fill d-flex'>
          {days.map(d => <div key={d.formatted} className={classnames(styles.dayHeading, 'd-flex flex-column', d.formatted === today ? styles.todayColumn : '')}><p
            className={styles.dayLetter}>{d.day[0]}</p><p>{d.date}</p></div>)}
        </div>
      </div>
      <div className='flex-fill d-flex'>
        <div className={styles.sideBar}><DaySideBar/></div>
        <div className='flex-fill d-flex'>
          {days.map(d => <div key={d.formatted} className='flex-fill'><DayLane day={d} loading={daySlots.loading} shifts={daySlots.data.shifts[d.formatted] ?? []} slot={daySlots.data.slots[d.formatted]} isToday={d.formatted === today} /></div>)}
        </div>
      </div>
    </div>
  )
}
