import './ResetPasswordForm.css'
import React, {useState} from "react";
import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {Input} from "../../components/input/text/Input";
import {Button} from "../../components/button/Button";
import {Heading} from "../heading/Heading";
import {RouterLink} from "../link/router-link/RouterLink";
import {RoutePaths} from "../../app-router";

export interface ResetPasswordFormProps {
  onSubmit: (email: string) => void
}
export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const usernameInput = useInput<string>('', [Validators.required]);
  const resetForm = form([usernameInput]);
  const [error, setError] = useState<boolean>(false);
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit(usernameInput.value as string);
  }
  return (
      <form
        onSubmit={onSubmit}
        id="resetPasswordForm"
        className="reset-password-form d-flex flex-column"
        noValidate>

        <Heading className="justify-content-start">
          <span>Reset password</span>
        </Heading>
        <p className="small-text">Enter the username associated with this account and<br/>
          we’ll send an email with instructions on how to<br/> reset your password.</p>
        <Input type="text"
               name="username"
               id="username"
               autoComplete="username"
               label="Username"
               inputHook={usernameInput}
        ></Input>
        {/*<div className="error-input-container">
          {error && <span className="login-error-message">The email you have entered is incorrect</span>}
          {false && <span className="login-error-message">Something went wrong, please try again later...</span>}
        </div>*/}

        <Button
          type="submit"
          size='large'
          colorScheme="positive" shape='rounded'
          disabled={!resetForm.valid}>
          Send Reset Link
        </Button>
        <div className="d-flex justify-content-center">
          <RouterLink to={RoutePaths.LOGIN.getPath()}>
            Return to login
          </RouterLink>
        </div>
      </form>
  );
}
