import {InputHook, useInput} from "../../../hooks/input";
import moment, {Moment} from "moment";
import {useEffect, useState} from "react";
import {Validators} from "../../../validators";
import {Labeled} from "../labeled/Labeled";
import {DatePicker} from "@mui/lab";
import {Input} from "../text/Input";
import {DatePickerLogo} from "../../../icons/DatePickerLogo";

const datePattern = /\d{4}-\d{2}-\d{2}/
export function DateInput({input, label}: { input: InputHook<Moment>, label: string }) {
  const [open, setIsOpen] = useState(false)
  const inputHook = useInput('', [Validators.required, Validators.pattern(datePattern)])
  useEffect(() => {
    const mom = moment(inputHook.value ?? '', 'YYYY-MM-DD')
    if(mom.isValid() && inputHook.value?.length === 10) {
      const inputVal = input.value!
      console.log('diff', mom.diff(inputVal, 'days'))
      if(inputHook.value !== inputVal.format('YYYY-MM-DD')) {
        input.setValue(mom)
      }
    }
  }, [inputHook.value])
  useEffect(() => {
    const val = input.value?.format('YYYY-MM-DD') ?? ''
    if(val !== inputHook.value) {
      inputHook.setValue(val)
    }
  }, [input.value])

  return (
    <Labeled label={label}>
      <DatePicker<Moment>
        open={open}
        onClose={() => setIsOpen(false)}
        value={input.value}
        onChange={(v) => v ? input.setValue(v) : null}
        renderInput={(props) => {
          return <span ref={props.inputRef}><Input name='expirationDate' inputHook={inputHook}
                                                   layout='reverse'>
          <div onClick={() => setIsOpen(true)} style={{cursor: 'pointer', paddingLeft: '10px'}}
               className='d-flex align-items-center'><DatePickerLogo/></div>
        </Input></span>
        }}
      />
    </Labeled>
  )
}
