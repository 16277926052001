import * as React from "react"
type OvernightIconColor = 'green' | 'orange' | 'gray'

const colorToFill: Record<OvernightIconColor, string> = {
  green: '#18C671',
  gray: 'rgba(0, 0, 0, 0.33)',
  orange: '#F2994A'
}
export function OvernightIcon({color = 'green'}: {color?: OvernightIconColor}) {
  const fill = colorToFill[color]
  return (
    <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.149.936a.317.317 0 0 0-.095-.23.118.118 0 0 0-.017-.012 5.533 5.533 0 1 0 7.27 7.27v-.001a.118.118 0 0 0-.014-.017.317.317 0 0 0-.23-.095A6.915 6.915 0 0 1 4.15.936Zm7.792 7.301A6.225 6.225 0 1 1 3.763.06c.55-.237 1.077.279 1.077.877a6.223 6.223 0 0 0 6.224 6.224c.598 0 1.114.527.877 1.077Z"
        fill={fill}
      />
    </svg>
  )
}
