import classNames from "classnames";
import styles from './operator-admin-settings-navigation.module.css'
import navStyles from "../../navigation-shell/navigation-shell.module.css";
import React from "react";
import {matchPath, useLocation} from "react-router-dom";
import {UserProfiles} from "../../../icons/UserProfiles";
import {Sites} from "../../../icons/Sites";
import {Groups} from "../../../icons/Groups";
import {SettingsRouteSubPaths} from "../../../app-router";
import {useRoutingContext} from "../../../hooks/routing-context";
import {WorkWeek} from "../../../icons/work-week";
import { Rounds } from "../../../icons/round";

type SidebarLink = { id: string, icon: (p: { active: boolean }) => React.ReactNode, text: string, path: string }

export function isActive(path: string, routePath: string) {
  return !!matchPath(path, {path: routePath, exact: false})
}

export function OperatorAdminSettingsNavigation() {
  const location = useLocation()
  const routingContext = useRoutingContext()
  const sidebarLinks: SidebarLink[] = [
    {id: 'users', icon: UserProfiles, text: 'User Profiles', path: SettingsRouteSubPaths.USER_PROFILES_USERS.getPath()},
    {id: 'workWeek', icon: WorkWeek, text: 'Work Week', path: SettingsRouteSubPaths.USER_PROFILES_WORK_WEEK.getPath()},
    {id: 'sites', icon: Sites, text: 'Sites', path: SettingsRouteSubPaths.USER_PROFILES_SITES.getPath()},
    {id: 'rounds', icon: Rounds, text: 'Rounds', path: SettingsRouteSubPaths.USER_PROFILES_ROUNDS.getPath()},
    {id: 'groups', icon: Groups, text: 'Groups', path: SettingsRouteSubPaths.USER_PROFILES_GROUPS.getPath()}
  ]
  const routesActive: Record<string, boolean> = {
    users: isActive(location.pathname, SettingsRouteSubPaths.USER_PROFILES_USERS.pathFormat),
    workWeek: isActive(location.pathname, SettingsRouteSubPaths.USER_PROFILES_WORK_WEEK.pathFormat),
    sites: isActive(location.pathname, SettingsRouteSubPaths.USER_PROFILES_SITES.pathFormat),
    rounds: isActive(location.pathname, SettingsRouteSubPaths.USER_PROFILES_ROUNDS.pathFormat),
    groups: isActive(location.pathname, SettingsRouteSubPaths.USER_PROFILES_GROUPS.pathFormat)
  }

  const navigateTo = (path: string) => {
    routingContext.navigate(path)
  }
  return (
    <div className={classNames(navStyles.navColumn, navStyles.sideBar, 'd-flex flex-column')}>
      {sidebarLinks.map(l => (
        <div key={l.id} className={classNames(navStyles.sidebarLink, 'text-400')} onClick={() => navigateTo(l.path)}>
          <span className={styles.linkImage}>{l.icon({active: routesActive[l.id]})}</span>
          {l.text}</div>))}
    </div>
  )
}
