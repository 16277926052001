import {SettingsRouteSubPaths, useRoutePathMatch} from "../../app-router";
import {Input} from "../../components/input/text/Input";
import {form, InputHook, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient} from "../../client/c2-api-client";
import {OperatorAdminSettingsForm} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {Labeled} from "../../components/input/labeled/Labeled";
import {useRoutingContext} from "../../hooks/routing-context";

type GroupDetails = { name: string }

export function SettingsGroupEdit() {
  const match = useRoutePathMatch(SettingsRouteSubPaths.USER_PROFILES_GROUPS_EDIT)
  const groupId = match?.params.groupId
  const routingContext = useRoutingContext()
  const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const [group, setGroup] = useState<GroupDetails>({name: ''})
  const name = useInput(group.name, [Validators.required])
  const groupForm = form([name])
  useEffect(() => {
    if (groupId) {
      c2ApiClient.getOperatorGroup(groupId).then(groupData => {
          setGroup(groupData)
          name.setValue(groupData.name, true)
      })
    }
  }, [groupId, c2ApiClient])


  const handleSubmit = () => {
    if (!groupForm.valid) {
      return;
    }
    const save$ = groupId ?
      c2ApiClient.editGroup({
        id: groupId,
        name: name.value!
      }) :
      c2ApiClient.createGroup({
        name: name.value!
      })
    save$.then(() => routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_GROUPS.getPath()))
  }
  return (
    <OperatorAdminSettingsForm disableSubmit={!groupForm.valid} editCallback={handleSubmit} title="Add Group"
                               submitLabel="Save Group" cancelRoute={SettingsRouteSubPaths.USER_PROFILES_GROUPS.getPath()}>
      <NameField input={name}/>
    </OperatorAdminSettingsForm>
  )
}

function NameField({input}: { input: InputHook<string> }) {
  return <Labeled label='Group Name'>
    <div>
      <Input name='groupName' inputHook={input}/>
    </div>
  </Labeled>
}

