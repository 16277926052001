import {CSSProperties, useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient, RoundModel} from "../../client/c2-api-client";
import {timeService} from "../../services/time-service";
import moment from "moment";
import styles from "./availability-summary.module.css";
import {Button} from "../../components/button/Button";
import Dialog from "@mui/material/Dialog";
import {DriverWithRoundsAndSlots} from "./driver-planning-table-types";


export type DateSummary = {
    roundsQuotaTaken: Map<string, number>,
    availableTimes: string[],
    bookedTimes: string[],
    sick: number,
    holiday: number,
    dateStr: string
};
const numbersStyles = {textAlign: 'center' as const, flexBasis: 0, flexShrink: 1, flexGrow: 1}

export function AvailabilitySummary({
                                        summaries,
                                        allRounds,
                                        summaryCoords
                                    }: {
    summaries: DateSummary[],
    allRounds: RoundModel[],
    summaryCoords: { left?: number, right?: number }
}) {
    const [operatorConfigHours, setOperatorConfigHours] = useState<{ amStart: string, amEnd: string }>({
        amStart: '04:00',
        amEnd: '14:00'
    })
    const apiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)

    const [underAssignedRoundsDialogData, setUnderAssignedRoundsDialogData] = useState<{
        open: boolean,
        rounds: (RoundModel & { unsatisfied: number, dailyQuota: number })[],
        date: string
    }>({
        open: false,
        rounds: [],
        date: ''
    })
    const [overAssignedRoundsDialogData, setOverAssignedRoundsDialogData] = useState<{
        open: boolean,
        rounds: (RoundModel & { assigned: number, dailyQuota: number })[],
        date: string
    }>({
        open: false,
        rounds: [],
        date: ''
    })
    const roundsById = allRounds.reduce((map, round) => {
        map.set(round.id, round)
        return map
    }, new Map<string, RoundModel>())
    useEffect(() => {
        apiClient.getOperatorConfig().then(config => config && setOperatorConfigHours({
            amStart: config.amStart,
            amEnd: config.amEnd
        }))
    }, [])
    const onUnderAssignedRoundsDialogClose = () => {
        setUnderAssignedRoundsDialogData({open: false, rounds: [], date: ''})
    }
    const openUnderAssignedRoundsDialog = (date: string) => {
        const summary = summariesGrouped.filter((s) => s.dateStr === date)[0]
        if (summary) {
            setUnderAssignedRoundsDialogData({
                open: true,
                rounds: Array.from(summary.underAssignedRounds).map(([roundId, unsatisfied]) => {
                    const roundInfo = roundsById.get(roundId)!
                    const dailyQuota = roundDailyQuota(roundInfo, moment(date).day())
                    return {...roundInfo, unsatisfied, dailyQuota}
                }),
                date
            })
        }
    }
    const onOverAssignedRoundsDialogClose = () => {
        setOverAssignedRoundsDialogData({open: false, rounds: [], date: ''})
    }
    const openOverAssignedResourcedRoundsDialog = (date: string) => {
        const summary = summariesGrouped.filter((s) => s.dateStr === date)[0]
        if (summary) {
            setOverAssignedRoundsDialogData({
                open: true,
                rounds: Array.from(summary.overAssignedRounds).map(([roundId, unsatisfied]) => {
                    const roundInfo = roundsById.get(roundId)!
                    const dailyQuota = roundDailyQuota(roundInfo, moment(date).day())
                    return {...roundInfo, assigned: dailyQuota + Math.abs(unsatisfied), dailyQuota}
                }),
                date
            })
        }
    }
    const amStartMoment = timeService.parseTime(operatorConfigHours.amStart)
    const amEndMoment = timeService.parseTime(operatorConfigHours.amEnd)
    const summariesGrouped = summaries.map(s => {
        const amAvailables = s.availableTimes.map(t => timeService.parseTime(t)).filter(m => m.isSameOrAfter(amStartMoment) && m.isSameOrBefore(amEndMoment))
        const amBooked = s.bookedTimes.map(t => timeService.parseTime(t)).filter(m => m.isSameOrAfter(amStartMoment) && m.isSameOrBefore(amEndMoment))
        return {
            underAssignedRounds: underAssignedRounds(s),
            overAssignedRounds: overAssignedRounds(s),
            dateStr: s.dateStr,
            sick: s.sick,
            holiday: s.holiday,
            amAvailable: amAvailables.length,
            pmAvailable: s.availableTimes.length - amAvailables.length,
            amBooked: amBooked.length,
            pmBooked: s.bookedTimes.length - amBooked.length
        }
    })

    const summaryStyles =
        !underAssignedRoundsDialogData.open && !overAssignedRoundsDialogData.open
        && summaryCoords.left !== undefined && summaryCoords.right !== undefined ?
            {
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                position: 'absolute',
                top: 30,
                width: summaryCoords.right - summaryCoords.left! + 300 + 90,
                right: 0,
                zIndex: 2000,
                background: 'white',
                border: '1px solid green',
                borderRadius: '8px',
                padding: '10px'
            } as unknown as CSSProperties
            : {display: 'none'} as unknown as CSSProperties;

    return (
        <div style={summaryStyles} className={styles.summaryContainer}>
            <UnderAssignedRoundsPopup rounds={underAssignedRoundsDialogData.rounds}
                                      date={underAssignedRoundsDialogData.date}
                                      open={underAssignedRoundsDialogData.open}
                                      onClose={onUnderAssignedRoundsDialogClose}/>
            <OverAssignedRoundsPopup rounds={overAssignedRoundsDialogData.rounds}
                                     date={overAssignedRoundsDialogData.date}
                                     open={overAssignedRoundsDialogData.open}
                                     onClose={onOverAssignedRoundsDialogClose}/>
            <div className='d-flex' style={{padding: '10px'}}>
                <div style={{width: '300px'}}>Under-Resourced Rounds</div>
                <div className='flex-fill d-flex justify-content-start align-items-center'>
                    {summariesGrouped.map((s) => <div key={s.dateStr}
                                                      style={{
                                                          ...numbersStyles,
                                                          cursor: s.underAssignedRounds.size ? 'pointer' : 'auto'
                                                      }}
                                                      onClick={() => s.underAssignedRounds.size && openUnderAssignedRoundsDialog(s.dateStr)}>{s.underAssignedRounds.size}</div>)}
                </div>
            </div>
            <div className='d-flex' style={{padding: '10px'}}>
                <div style={{width: '300px'}}>Over-Resourced Rounds</div>
                <div className='flex-fill d-flex justify-content-start align-items-center'>
                    {summariesGrouped.map((s) => <div key={s.dateStr}
                                                      style={{
                                                          ...numbersStyles,
                                                          cursor: s.overAssignedRounds.size ? 'pointer' : 'auto'
                                                      }}
                                                      onClick={() => s.overAssignedRounds.size && openOverAssignedResourcedRoundsDialog(s.dateStr)}>{s.overAssignedRounds.size}</div>)}
                </div>
            </div>

            <div className='d-flex' style={{padding: '10px'}}>
                <div style={{width: '300px'}}>Total Sick</div>
                <div className='flex-fill d-flex justify-content-start align-items-center'>
                    {summariesGrouped.map((s) => <div key={s.dateStr}
                                                      style={numbersStyles}>{s.sick}</div>)}
                </div>
            </div>

            <div className='d-flex' style={{padding: '10px'}}>
                <div style={{width: '300px'}}>Total Holidays</div>
                <div className='flex-fill d-flex justify-content-start align-items-center'>
                    {summariesGrouped.map((s) => <div key={s.dateStr}
                                                      style={numbersStyles}>{s.holiday}</div>)}
                </div>
            </div>

            <div className='d-flex'
                 style={{background: 'rgba(255, 203, 77, 0.14)', borderRadius: '8px', padding: '10px'}}>
                <div style={{width: '300px'}}>Total AM Drivers Available</div>
                <div className='flex-fill d-flex justify-content-start align-items-center'>
                    {summariesGrouped.map((s) => <div key={s.dateStr} style={numbersStyles}>{s.amAvailable}</div>)}
                </div>
            </div>

            <div className='d-flex align-items-center'
                 style={{background: 'rgba(255, 203, 77, 0.14)', borderRadius: '8px', padding: '10px'}}>
                <div style={{width: '300px'}}>Total PM Drivers Available</div>
                <div className='flex-fill d-flex justify-content-start align-items-center'>
                    {summariesGrouped.map((s) => <div key={s.dateStr} style={numbersStyles}>{s.pmAvailable}</div>)}
                </div>
            </div>

            <div className='d-flex align-items-center'
                 style={{background: 'rgba(255, 203, 77, 0.14)', borderRadius: '8px', padding: '10px'}}>
                <div style={{width: '300px'}}>Total AM Drivers Booked</div>
                <div className='flex-fill d-flex justify-content-start align-items-center'>
                    {summariesGrouped.map((s) => <div key={s.dateStr} style={numbersStyles}>{s.amBooked}</div>)}
                </div>
            </div>

            <div className='d-flex align-items-center'
                 style={{background: 'rgba(255, 203, 77, 0.14)', borderRadius: '8px', padding: '10px'}}>
                <div style={{width: '300px'}}>Total PM Drivers Booked</div>
                <div className='flex-fill d-flex justify-content-start align-items-center'>
                    {summariesGrouped.map((s) => <div key={s.dateStr} style={numbersStyles}>{s.pmBooked}</div>)}
                </div>
            </div>
        </div>
    )
}

function UnderAssignedRoundsPopup(props: {
    rounds: (RoundModel & { unsatisfied: number, dailyQuota: number })[],
    date: string,
    open: boolean,
    onClose: () => void
}) {
    return (
        <Dialog
            PaperProps={{classes: {root: styles.unsatisfiedDialogRoot}}}
            disableScrollLock={true}
            open={props.open}>
            <h4 style={{paddingBottom: '20px'}}>Under-Resourced Rounds For Day: {props.date}</h4>
            <div>
                {props.rounds.map(({name, description, dailyQuota, unsatisfied}) => <p key={name}>{name} ({description})
                    - {dailyQuota - unsatisfied} / {dailyQuota}</p>)}
            </div>
            <div style={{padding: '10px 0px', width: '100%'}}>
                <Button onClick={props.onClose}>Close</Button>
            </div>
        </Dialog>
    )
}

function OverAssignedRoundsPopup(props: {
    rounds: (RoundModel & { assigned: number, dailyQuota: number })[],
    date: string,
    open: boolean,
    onClose: () => void
}) {
    return (
        <Dialog
            PaperProps={{classes: {root: styles.unsatisfiedDialogRoot}}}
            disableScrollLock={true}
            open={props.open}>
            <h4 style={{paddingBottom: '20px'}}>Over-Resourced Rounds For Day: {props.date}</h4>
            <div>
                {props.rounds.map(({name, description, dailyQuota, assigned}) => <p key={name}>{name} ({description})
                    - {assigned} / {dailyQuota}</p>)}
            </div>
            <div style={{padding: '10px 0px', width: '100%'}}>
                <Button onClick={props.onClose}>Close</Button>
            </div>
        </Dialog>
    )
}

function roundDailyQuota(round: RoundModel, day: number) {
    return [
        round.sundayQuota,
        round.mondayQuota,
        round.tuesdayQuota,
        round.wednesdayQuota,
        round.thursdayQuota,
        round.fridayQuota,
        round.saturdayQuota
    ][day]
}

function roundQuotas(allRounds: RoundModel[], dateStr: string): Map<string, number> {
    const day = moment(dateStr).day()
    return allRounds
        .reduce((map, round) => {
            map.set(round.id, roundDailyQuota(round, day))
            return map
        }, new Map<string, number>());
}

export function getAvailabilitySummary(data: DriverWithRoundsAndSlots[], allRounds: RoundModel[]): DateSummary[] {
    // const amStart = timeService.parseTime(operatorConfigHours.amStart)
    // const amEnd = timeService.parseTime(operatorConfigHours.amEnd)
    const summariesByDate = data.reduce((summaries, driver) => {
        Object.entries(driver.status).forEach(([dateStr, status]) => {
            const currentDateSummary = summaries[dateStr] ?? {
                roundsQuotaTaken: roundQuotas(allRounds, dateStr),
                availableTimes: [] as string[],
                bookedTimes: [] as string[],
                sick: 0,
                holiday: 0
            }
            const allStatuses = status.map(s => s.type)
            if (allStatuses.includes('holiday')) {
                currentDateSummary.holiday += 1
            } else if (allStatuses.includes('sick')) {
                currentDateSummary.sick += 1
            } else if (allStatuses.includes('available')) {
                currentDateSummary.availableTimes = [...currentDateSummary.availableTimes, status[0].startTime]
            } else if (status.filter(s => s.shiftId).length) {
                currentDateSummary.bookedTimes = [...currentDateSummary.bookedTimes, status[0].startTime]
            }
            const assignment = driver.roundAssignments.get(dateStr)
            if (assignment && currentDateSummary.roundsQuotaTaken.has(assignment)) {
                const newUnassigned = currentDateSummary.roundsQuotaTaken.get(assignment)! - 1
                currentDateSummary.roundsQuotaTaken.set(assignment, newUnassigned)
            }
            summaries[dateStr] = currentDateSummary
        })
        return summaries;
    }, {} as Record<string, DateSummary>)
    return Object.keys(summariesByDate).sort((a, b) => {
        const firstDate = timeService.parseDate(a)
        const secondDate = timeService.parseDate(b)
        // console.log('dates', firstDate, secondDate, firstDate.isAfter(secondDate))
        return firstDate.isAfter(secondDate) ? 1 : -1
    }).map(dateStr => ({
        ...summariesByDate[dateStr],
        dateStr
    }))

}

const filterMapByValue = <K, V>(map: Map<K, V>, predicate: (v: V) => boolean): Map<K, V> => {
    return Array.from(map.entries())
        .filter(([_, v]) => predicate(v))
        .reduce((map, [k, v]) => {
            map.set(k, v)
            return map
        }, new Map<K, V>())
}
const overAssignedRounds = (summary: DateSummary): Map<string, number> => filterMapByValue(summary.roundsQuotaTaken, (quota) => quota < 0)

const underAssignedRounds = (summary: DateSummary): Map<string, number> => filterMapByValue(summary.roundsQuotaTaken, (quota) => quota > 0)