import {RoutePaths, useRoutePathMatch} from "../../app-router";
import moment from "moment";
import {useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {DriverC2ApiClient} from "../../client/c2-api-client";
import {timeService} from "../../services/time-service";
import {useRoutingContext} from "../../hooks/routing-context";

export function StartSiteShift() {
  const apiClient = useApiClientContext().asOrFail(DriverC2ApiClient)
  const routingContext = useRoutingContext()
  const siteIdMatch = useRoutePathMatch(RoutePaths.START_SITE_SHIFT)
  const siteId = siteIdMatch?.params.siteId ?? ''

  const [label, setLabel] = useState<string>('Please wait while we find and start Your shift')
  useEffect(() => {
    apiClient.startShiftAtSite({
      siteId,
      startTime: timeService.formatTimestamp(moment())
    }).then(shift => {
      setTimeout(() => routingContext.navigate(RoutePaths.DAY_VIEW.getPath({date: timeService.formatDate(moment())})), 3000)
      return shift
    }).catch((e) => {
      const errorMessage = e?.toString()
      if(errorMessage) {
        setLabel(errorMessage)
      }
      setTimeout(() => {
        routingContext.navigate(RoutePaths.HOME.getPath())
      }, 5000)
    })
  }, [])

  return (
    <div className='flex-fill d-flex flex-column'>
      {label}
    </div>
  )
}
