import styles from "./operator-admin-settings-list.module.css";
import classNames from "classnames";
import React from "react";
import {AddButton} from "../../add-button/add-button";
import {OperatorSettingsListTitle} from "../list-title-container/operator-settings-list-title";

export interface OperatorAdminSettingsListProps {
  title: string;
  addBtnLabel: string;
  addBtnHandler: () => void;
  children: React.ReactNode
}

export function OperatorAdminSettingsList({
                                            title,
                                            addBtnHandler,
                                            addBtnLabel,
                                            children
                                          }: OperatorAdminSettingsListProps) {
  return (
    <div className={styles.columnsWrapper}>
      <div className={styles.listColumn}>
        <div className={classNames('d-flex justify-content-between align-items-center', styles.titleContainer)}>
          <OperatorSettingsListTitle title={title} />
          <AddButton onClick={addBtnHandler} label={addBtnLabel} />
        </div>
        <div className={styles.listContainer}>
          {children}
        </div>
      </div>
    </div>
  )

}
