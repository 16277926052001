import React from "react";
import styles from './VerticalGroup.module.css'
import classnames from "classnames";
export type VerticalGroupSpacing = 'default' | 'small'
const spacingClass: Record<VerticalGroupSpacing, string> = {
  default: styles.defaultGap,
  small: styles.smallGap
}

export function VerticalGroup({spacing, children}: {spacing?: VerticalGroupSpacing, children: React.ReactNode}) {
  const applySpacing = spacing ?? 'default'
  return (
    <div className={classnames(styles.fieldsContainer, spacingClass[applySpacing])}>{children}</div>
  )
}
