import {InputHook} from "../../../hooks/input";
import classNames from "classnames";
import styles from './radio-group.module.css'

export function RadioGroup<T>({
                    input,
                    options
                  }: { input: InputHook<T>, options: { id: T, name: string }[] }) {
  return (
      <div style={{gap: '20px'}} className={classNames(styles.selectInput, 'd-flex', 'align-items-center')}>
        {options.map(opt => <label key={opt.name}><input type='radio' value={opt.name} checked={input.value === opt.id}
                                                       onChange={ev => ev.target.checked ? input.setValue(opt.id) : undefined}/>{opt.name}
        </label>)}
      </div>
  )
}
