import styles from './table-filter-bar.module.css'
import {ReactNode} from "react";
import classNames from "classnames";

export function TableFilterBar({children, padding}: {children: ReactNode, padding?: boolean}) {
  const includePadding = padding ?? true
  return (
    <div className={classNames(styles.filterBar, 'text-200', includePadding && styles.withPadding)}>{children}</div>
  )
}
