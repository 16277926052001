import {Shift} from "../../client/c2-api-client";
import moment, {Moment} from "moment";
import {timeService} from "../time-service";

export const shiftService = {
  driverDuration: (shift: Shift | undefined) => {
    const {endedAt, startedAt} = shift || {}
    if (!endedAt || !startedAt) return null
    const diff = timeService.parseTimestamp(endedAt).diff(timeService.parseTimestamp(startedAt))
    return moment.duration(diff)
  },
  totalApprovedDuration: (shifts: Shift[]) => {
    return  shifts.reduce((total, shift) => {
      if(!shift.operatorTime) {
        const driverTime = shiftService.driverDuration(shift)
        return driverTime === null ? total : total.add(driverTime)
      }
      return total.add(moment.duration(shift.operatorTime))
    }, moment.duration(0, 'minutes'))
  },
  totalBreak: (shifts: Shift[]) => {
    return  shifts.reduce((total, shift) => {
      if(!shift.breakTime) {
        return total
      }
      return total.add(moment.duration(shift.breakTime))
    }, moment.duration(0, 'minutes'))
  },
  calculateShiftEventTime: (shiftDate: string, eventTime?: string): Moment => {
    const date = timeService.parseDate(shiftDate)
    const time = eventTime ? timeService.parseTime(eventTime) : moment()
    return moment(date).hours(time.hours()).minutes(time.minutes())
  },
  calculateShiftEndTime: (shiftDate: string, startTime: string, endTime: string): Moment => {
    const date = timeService.parseDate(shiftDate)
    const startMoment = timeService.parseTime(startTime)
    const endMoment = timeService.parseTime(endTime)
    const endDate = endMoment.isBefore(startMoment) ? date.add(1, 'days') : date
    return endDate.hours(endMoment.hours()).minutes(endMoment.minutes())
  },
  statusHandler({warning, error}: { warning: string; error: string }): (shift: Shift) => ShiftApproveStatusType {
    const warningMoment = timeService.parseTime(warning)
    const errorMoment = timeService.parseTime(error)
    const warningDuration = moment.duration(warningMoment.hours(), 'hours').add(warningMoment.minutes(), 'minutes')
    const errorDuration = moment.duration(errorMoment.hours(), 'hours').add(errorMoment.minutes(), 'minutes')
    return (shift) => {
      const shiftDriverTime = shiftService.driverDuration(shift)
      if(!shiftDriverTime) return 'unhandled'
      const matchTime = shift.operatorTime ?? shift.tachoData?.totalShiftTime ?? undefined
      if(!matchTime) return 'unhandled'
      const matchMoment = timeService.parseTime(matchTime)
      const matchDuration = moment.duration(matchMoment.hours(), 'hours').add(matchMoment.minutes(), 'minutes')
      const diff = Math.abs(matchDuration.asMinutes() - shiftDriverTime.asMinutes())
      if(diff > errorDuration.asMinutes()) return 'error'
      if(diff > warningDuration.asMinutes()) return 'warning'
      if(shift.needsApprovalAfterTacho) return 'needsApprovalAfterTacho'
      return 'match'
    }
  }
}

export type ShiftApproveStatusType = 'unhandled' | 'match' | 'warning' | 'error' | 'needsApprovalAfterTacho'
