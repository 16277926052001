import {form, useInput} from "../../hooks/input";
import moment from "moment";
import {Validators} from "../../validators";
import {useEffect} from "react";
import {Dialog} from "@mui/material";
import styles from "./add-dates-interval-modal.module.css";
import {OperatorAdminSettingsForm} from "../operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {DateInput} from "../input/date/date-input";

export type AddDatesAction = 'sick' | 'holiday' | 'training' | 'induction' | 'unavailable' | 'exportHolidaySick'
const actionToTitle: Record<AddDatesAction, string> = {
    holiday: 'Add Holiday',
    sick: 'Add Sick Leave',
    training: 'Add Training Day',
    unavailable: 'Add Unavailable Day',
    induction: 'Add Induction Day',
    exportHolidaySick: 'Export Holiday and Sick Interval'
}
export function AddDatesIntervalModal({
                                        action,
                                        startDate,
                                        handle,
                                        open
                                      }: {action: AddDatesAction, startDate: string, handle: (params?: { start: string, end: string }) => void, open: boolean }) {
  const startInput = useInput(moment(), [Validators.required])
  const endInput = useInput(moment(), [Validators.required])
  useEffect(() => {
    startInput.setValue(moment(startDate, 'YYYY-MM-DD'))
    endInput.setValue(moment(startDate, 'YYYY-MM-DD').add(1, 'days'))
  }, [startDate])
  const shiftForm = form([startInput, endInput])
  return (
    <Dialog
      PaperProps={{classes: {root: styles.addShiftDialogRoot}}}
      disableScrollLock={true}
      open={open}>
      <OperatorAdminSettingsForm title={actionToTitle[action]} submitLabel={action === 'exportHolidaySick' ? 'Download' : 'Send'} cancelCallback={() => handle()}
                                 disableSubmit={!shiftForm.valid}
                                 paddingTop={false}
                                 editCallback={() => handle({
                                   start: startInput.value!.format('YYYY-MM-DD'),
                                   end: endInput.value!.format('YYYY-MM-DD')
                                 })}>
        <div className='d-flex flex-column' style={{gap: '20px'}}>
          <DateInput input={startInput} label={'Start Date'}/>
          <DateInput input={endInput} label={'End Date'}/>
        </div>
      </OperatorAdminSettingsForm>
    </Dialog>
  )
}
