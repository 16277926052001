import {TableHook} from "../hooks/table";
import {SearchListener} from "../components/navigation-shell/navigation-shell";

export type WithNameFilter = Partial<{ fullName: string }>
export const driversTableService = {
    nameSearchFilterListener: (tableHook: TableHook<any, WithNameFilter>): SearchListener => {
        return (searchString) => {
            const fullName = searchString ? {fullName: searchString} : {}
            tableHook.setFilters({
                ...tableHook.currentFilters,
                ...fullName
            })
        }
    }
}