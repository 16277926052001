import {RoutePaths, useRoutePathMatch} from "../../app-router";
import moment, {Moment} from "moment";
import {useEffect, useMemo, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {DriverC2ApiClient, Shift} from "../../client/c2-api-client";
import {useHistory, useLocation} from "react-router-dom";
import {DayHeader} from "../../components/day-header/day-header";
import {ShiftDetails} from "../../components/shift-details/shift-details";
import {shiftService} from "../../services/shift/shift-service";
import {timeService} from "../../services/time-service";
import {Edit} from "../../icons/edit";
import {Dialog} from "@mui/material";
import styles from "../drivers-planning/drivers-planning.module.css";
import {OperatorAdminSettingsForm} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {TimeInput} from "../../components/input/time/time-input";
import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useRoutingContext} from "../../hooks/routing-context";

export function DriverShiftResources() {
  const dateParam = useRoutePathMatch(RoutePaths.SHIFT_RESOURCES)
  const apiClient = useApiClientContext().asOrFail(DriverC2ApiClient)
  const routingContext = useRoutingContext()
  const location = useLocation()
  const history = useHistory()
  const activeSectionSearch = new URLSearchParams(location.search).get('section')
  const activeSection = (activeSectionSearch === 'comments' || activeSectionSearch === 'receipts') ? activeSectionSearch : undefined
  const date = useMemo(() => {
    return dateParam ? moment(dateParam.params.date, 'YYYY-MM-DD') : moment()
  }, [dateParam?.params.date])

  const [shift, setShift] = useState<{ loading: boolean, shift?: Shift }>({loading: true})
  const [editHours, setEditHours] = useState(false)
  const [editBreak, setEditBreak] = useState(false)
  const getDriverShift = () => {
    setShift({loading: true})
    apiClient.getDriverSlots({startDate: moment(date).subtract(1, 'days').format('YYYY-MM-DD'), endDate: date.format('YYYY-MM-DD')})
      .then(shiftsRes => {
        if(!shiftsRes[0]) {
          history.goBack()
          return
        }
        const shift = shiftsRes.flatMap(slot => slot.shifts).filter(sh => dateParam?.params.shiftId === sh.id)[0]
        if(!shift) {
          history.goBack()
          return
        }
        setShift({loading: false, shift: shift})
      })
  }
  useEffect(() => {
    getDriverShift()
  }, [dateParam?.params.date])

  const dayBack = () => {
    const newDate = moment(date).subtract(1, 'days')
    routingContext.navigate(RoutePaths.DAY_VIEW.getPath({date: newDate.format('YYYY-MM-DD')}))
  }
  const dayForward = () => {
    const newDate = moment(date).add(1, 'days')
    routingContext.navigate(RoutePaths.DAY_VIEW.getPath({date: newDate.format('YYYY-MM-DD')}))
  }

  const submitEditHours = (times?: {startTime: Moment, endTime: Moment}) => {
    const shiftedTimes = times ? {
      start: timeService.parseDate(shift.shift?.date!).hours(times.startTime.hours()).minutes(times.startTime.minutes()),
      end: (times.endTime.hours() < times.startTime.hours() ?
        timeService.parseDate(shift.shift?.date!).add(1, 'days') :
        timeService.parseDate(shift.shift?.date!))
          .hours(times.endTime.hours()).minutes(times.endTime.minutes())
    } : undefined
    const p = shiftedTimes ?
      apiClient.endShift(shift.shift!.id, timeService.formatTimestamp(shiftedTimes.end))
        .then(sh => setShift({loading: false, shift: sh}))
      : Promise.resolve()
    p.then(() => {
      setEditHours(false)
    })
  }
  const submitBreak = (breakTime?: string) => {
    const p = breakTime ?
      apiClient.setShiftBreak(shift.shift!.id, breakTime!)
        .then(() => setShift({loading: false, shift: {...shift.shift!, breakTime: breakTime}}))
      : Promise.resolve()
    p.then(() => {
      setEditBreak(false)
    })
  }

  const fetchShiftActivity = (shiftId: string) => apiClient.getShiftActivity(shiftId)

  return (
    <div className='flex-fill d-flex flex-column align-items-stretch'>
      {editHours &&
      <EditHoursDialog
        startTime={timeService.parseTimestamp(shift.shift?.startedAt!)}
        endTime={shift.shift?.endedAt ? timeService.parseTimestamp(shift.shift?.endedAt) : moment()}
        handle={submitEditHours}/>}
      {editBreak &&
      <EditBreakDialog
        currentBreak={shift.shift?.breakTime}
        handle={submitBreak}/>}
      <DayHeader date={date} dayBack={dayBack} dayForward={dayForward}/>
      <div className='flex-fill flex-column d-flex align-items-stretch' style={{height: 'calc(100vh - 110px)'}}>
        {shift.shift && (
          <>
            <div className='d-flex justify-content-between' style={{padding: '10px 40px'}}>
              <div style={{color: 'rgba(0, 0, 0, 1)'}}>Driver Time</div>
              <div style={{color: 'rgba(0, 0, 0, 1)'}}>
                {timeService.formatDuration(shiftService.driverDuration(shift.shift))}
                {!shift.shift?.operatorTime && <span style={{cursor: 'pointer'}} onClick={() => setEditHours(true)}> <Edit /></span>}

              </div>
            </div>
            {shift.shift.tachoData?.totalShiftTime && <div className='d-flex justify-content-between' style={{padding: '10px 40px'}}>
              <div style={{color: 'rgba(0, 0, 0, 1)'}}>Tacho Time</div>
              <div style={{color: 'rgba(0, 0, 0, 1)'}}>
                {timeService.formatDuration(moment.duration(shift.shift.tachoData.totalShiftTime))}
              </div>
            </div>}
            {shift.shift.operatorTime && <div className='d-flex justify-content-between' style={{padding: '10px 40px'}}>
              <div style={{color: 'rgba(0, 0, 0, 1)'}}>Operator Time</div>
              <div style={{color: 'rgba(0, 0, 0, 1)'}}>
                {shift.shift.operatorTime}
              </div>
            </div>}
            <div className='d-flex justify-content-between' style={{padding: '10px 40px'}}>
              <div style={{color: 'rgba(0, 0, 0, 1)'}}>Break</div>
              <div style={{color: 'rgba(0, 0, 0, 1)'}}>
                {shift.shift.breakTime ?? '-'}
                {!shift.shift?.operatorTime && <span style={{cursor: 'pointer'}} onClick={() => setEditBreak(true)}> <Edit /></span>}
              </div>
            </div>
          </>
        )}
        {shift.shift &&
        <ShiftDetails activeSection={activeSection} shift={shift.shift!} fetchComments={() => apiClient.getShiftComments(shift.shift!.id)}
                      usernameFetcher={(id) => apiClient.getUserName(id)}
                      addComment={(t) => apiClient.addShiftComment(shift.shift!.id, t)}
                      setOvernight={(val) => apiClient.setShiftOvernight(shift.shift!.id, val).then(() => getDriverShift())}
                      fetchReceipts={() => apiClient.getShiftReceipts(shift.shift!.id)}
                      addReceipt={({
                                     cost,
                                     description,
                                     file
                                   }) => apiClient.addShiftReceipt(shift.shift!.id, {cost, description, file})}
                      downloadReceipt={(id) => apiClient.downloadReceipt(id).then(d => {
                        window.open(d.downloadUrl, '_blank')
                      })}
                      fetchActivity={fetchShiftActivity}

        />}
      </div>
    </div>
  )
}
function EditHoursDialog({startTime, endTime, handle}: {startTime: Moment, endTime: Moment, handle: (arg? : {startTime: Moment, endTime: Moment}) => void}) {
  const startInput = useInput(startTime, [Validators.required])
  const endInput = useInput(endTime, [Validators.required])
  const timesForm = form([startInput, endInput])

  return (
    <Dialog
      PaperProps={{classes: {root: styles.addShiftDialogRoot}}}
      disableScrollLock={true}
      open={true}>
      <OperatorAdminSettingsForm title='Shift Time' submitLabel='Send' cancelCallback={() => handle()}
                                 disableSubmit={!timesForm.valid}
                                 paddingTop={false}
                                 editCallback={() => handle({
                                   startTime: startInput.value!,
                                   endTime: endInput.value!
                                 })}>
        <div className='d-flex flex-column' style={{gap: '20px'}}>
          <TimeInput input={startInput} label={'Start Time'}/>
          <TimeInput input={endInput} label={'End Time'}/>
        </div>
      </OperatorAdminSettingsForm>
    </Dialog>
  )
}

function EditBreakDialog({currentBreak, handle}: {currentBreak?: string, handle: (arg? : string) => void}) {
  const breakInput = useInput(moment(currentBreak ?? '00:30', 'HH:mm'), [Validators.required])
  const timesForm = form([breakInput])

  return (
    <Dialog
      PaperProps={{classes: {root: styles.addShiftDialogRoot}}}
      disableScrollLock={true}
      open={true}>
      <OperatorAdminSettingsForm title='Break Time' submitLabel='Send' cancelCallback={() => handle()}
                                 disableSubmit={!timesForm.valid}
                                 paddingTop={false}
                                 editCallback={() => handle(breakInput.value!.format('HH:mm'))}>
        <div className='d-flex flex-column' style={{gap: '20px'}}>
          <TimeInput input={breakInput} label={'Break Time'}/>
        </div>
      </OperatorAdminSettingsForm>
    </Dialog>
  )
}
