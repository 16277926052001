import Dialog from "@mui/material/Dialog";
import {Button} from "../button/Button";
import styles from './invoice-sent-modal.module.css'

export function InvoiceSentModal({open, onClose}: { open: boolean, onClose: () => void}) {
  return (
      <Dialog
          PaperProps={{classes: {root: styles.invoiceSentDialogRoot}}}
          disableScrollLock={true}
          open={open}>
        <div className='d-flex flex-row align-items-center'>
          <p>Email with driver invoice for the week has been sent, it should arrive to their inbox shortly</p>
        </div>
          <Button onClick={onClose} size='normal' colorScheme='neutral' >
              Close
          </Button>
      </Dialog>
  )
}