import {StyledLink} from "../styled-link/StyledLink";
import {Link} from "react-router-dom";
import React from "react";
import {useRoutingContext} from "../../../hooks/routing-context";

export interface RouterLinkProps {
  to: string;
  children: React.ReactNode;
}
export function RouterLink(props: RouterLinkProps) {
  const routingContext = useRoutingContext()
  return (
    <Link  to={routingContext.getPath(props.to)}  component={(linkProps) => {
      return <StyledLink onClick={() => linkProps.navigate()}>{props.children}</StyledLink>
    }}/>
  );
}
