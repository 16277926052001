import React from "react";
import styles from './table.module.css'
import {TableHook} from "../../hooks/table";
import classNames from "classnames";

export interface ColumnConfig<T> {
  key: string
  header: React.ReactNode
  cellFactory: (row: T) => React.ReactNode
}
interface TableController {
  setSort(field: string): void
}
export interface TableProps<T, F> {
  colGroups?: React.ReactNode
  columnsConfig: ColumnConfig<T>[]
  headerClass?: string
  bodyClass?: string
  tableHook: TableHook<T, F>
}
function TableRows<T>(props: {rows: T[], columns: ColumnConfig<T>[], pageSize: number}) {
  const Rows = props.rows.map((row, i) => <tr key={i}>
    {props.columns.map((col, j) => <React.Fragment key={col.key}>{col.cellFactory(row)}</React.Fragment>)}
  </tr>)
  return <>{Rows}</>
}
export function Table<T, F=any>(props: TableProps<T, F>) {
  const currentData = props.tableHook.currentData;
  const columnConfig = props.columnsConfig
  const pageSize = props.tableHook.pageSize
  return (
    <table width="100%">
      {props.colGroups && props.colGroups}
      <thead className={classNames(styles.tableHeader, props.headerClass)}>
      <tr>
        {columnConfig.map((col, i) => <React.Fragment key={col.key}>{col.header}</React.Fragment>)}
      </tr>
      </thead>
      <tbody className={classNames(styles.tableBody, props.bodyClass)}>
      {
        currentData.rows.length > 0 && <TableRows rows={currentData.rows} columns={columnConfig} pageSize={pageSize} />
      }
      {
        (currentData.isLoading === false && currentData.rows.length === 0) && <tr><td  style={{textAlign: "center"}} colSpan={props.columnsConfig.length}>No Data</td></tr>
      }
      </tbody>
    </table>
  )
}
