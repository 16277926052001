import {createContext, ReactNode, useContext} from "react";


const InvoicingEnabledContext = createContext(false)

export function useInvoicingEnabledContext() {
  return useContext(InvoicingEnabledContext)
}

export function InvoicingEnabledProvider({invoicingEnabled, children}: {invoicingEnabled: boolean, children: ReactNode}) {
  return <InvoicingEnabledContext.Provider value={invoicingEnabled}>{children}</InvoicingEnabledContext.Provider>
}
export function WithInvoicing({children}: {children: ReactNode}) {
  const invoicingEnabled = useInvoicingEnabledContext() ?? false
  return (
    (invoicingEnabled || null) && <>{children}</>
  )
}
