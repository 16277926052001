import React from "react";
import classNames from "classnames";
import './Heading.css';

export interface HeadingProps {
  className?: string;
  children: React.ReactNode;
}

export function Heading(props: HeadingProps) {
  return (
    <h1 className={classNames('d-flex', 'heading-box', props.className)}>
      {props.children}
    </h1>
  )
}
