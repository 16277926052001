import {VerticalGroup} from "../../components/input/vertical-group/VerticalGroup";
import {InputHook} from "../../hooks/input";
import {Labeled} from "../../components/input/labeled/Labeled";
import {Input} from "../../components/input/text/Input";
import {useCallback, useRef} from "react";

export interface SettingsSiteEditGroupProps {
  name: InputHook<string>
  address: InputHook<string>
  postCode: InputHook<string>
}
export function SettingsSiteEditGroup({name, address, postCode}: SettingsSiteEditGroupProps) {

  return (
    <VerticalGroup>
      <SiteNameInput input={name}/>
      <AddressInput input={address} setPostCode={pc => postCode.setValue(pc)}/>
      <PostCodeInput input={postCode} />
    </VerticalGroup>
  )
}

function SiteNameInput({input}: { input: InputHook<string> }) {
  return <Labeled label='Site Name'>
    <div>
      <Input name='siteName' inputHook={input}/>
    </div>
  </Labeled>
}

function AddressInput({input, setPostCode}: { input: InputHook<string>, setPostCode: (arg: string) => void }) {
  const autocompleteField = useRef<any>(null)
  const tryRef = useCallback((current) => {
    if(!current) return;
    console.log('in try ref', current)
    autocompleteField.current = current
    // @ts-ignore
    const autocomplete = new google.maps.places.Autocomplete((autocompleteField.current), {
      types: ['address'],
      componentRestrictions: { country: 'uk' },
    });
    autocomplete.addListener('place_changed', () => {
      const addressDetails = [
        {address: 'administrative_area_level_1', id: 'addressLine1'},
        {address: 'administrative_area_level_2', id: 'addressLine2'},
        {address: 'postal_town', id: 'city'},
        {address: 'postal_code', id: 'postCode'},
      ];
      const place = autocomplete.getPlace();
      console.log('place', place)
      if (place) {
        input.setValue(place.formatted_address)
        const postCode = place.address_components.filter((add: { types: string | string[]; }) => add.types.indexOf('postal_code') > -1)[0]
        if (postCode) {
          setPostCode(postCode.long_name)
        }
      }
    })
    return () => autocomplete.removeAllListeners()
  }, [setPostCode])

  return <Labeled label='Address'>
    <div>
      <Input name='addressInput' inputHook={input} ref={tryRef}/>
    </div>
  </Labeled>
}

function PostCodeInput({input}: { input: InputHook<string> }) {
  return <Labeled label='Postal Code'>
    <div>
      <Input name='postCodeInput' inputHook={input}/>
    </div>
  </Labeled>
}
