import {ErrorHandler, QueryParams, RestService} from "./rest-service";
import {GroupModel} from "../page/settings-group-list/settings-group-list";

const serverUrl = process.env.REACT_APP_SERVER_URL ?? 'http://localhost:3001'

export type Role = 'SUPER_ADMIN' | 'OPERATOR_ADMIN' | 'DRIVER';

export interface SessionInfo {
  userId: string
  sessionToken: string
  role: Role
  name: string
  operatorId?: string
  driverId?: string
}

export interface PaginationParams {
  page: number,
  pageSize: number,
  order?: string,
  ascending?: boolean
}

export interface ListPaginatedResponse<T> {
  data: T[]
  page: number
  totalPages: number
  pageSize: number
}

export interface OperatorSummary {
  id: string,
  name: string
  alias: string
  driversCount: number
  sitesCount: number
}

export interface CreateOperator {
  operatorName: string
  operatorAlias: string
  admin: {
    firstName: string
    lastName: string
    email: string
    phone: string
  }
  site: {
    name: string
    address: string
    postCode: string
  }
}

export interface CreateOperatorResult {
  id: string
  adminPasswordToken: string
}

export type SiteReponse = { sites: SiteModel[] }

export type RoundResponse = { rounds: RoundModel[] }

export interface SiteModel {
  id: string
  name: string
  address: string
  postCode: string
  operatorId: string
  qrCode: string
}

export interface RoundModel {
  id: string
  name: string
  description: string,
  mondayQuota: number,
  tuesdayQuota: number,
  wednesdayQuota: number,
  thursdayQuota: number,
  fridayQuota: number,
  saturdayQuota: number,
  sundayQuota: number
}

export enum NightOutPreference {
  YES = 'yes',
  NO = 'no',
  POSS = 'poss'
}

export type RotaType =
  '4_on_4_off'
  | '4_on_3_off'
  | '4_on_2_off'
  | '5_on_3_off'
  | '5_on_2_off'
  | '1_on_5_off'
  | '2_on_5_off'
  | '2_on_2_off'
  | '7_on_0_off'

export interface CreateDriverInput {
  firstName: string
  lastName: string
  email: string
  username: string
  phone: string
  bambooId: string
  earliestStartTime: string
  expirationDate: string
  siteId: string,
  groupId: string | undefined,
  nightOut: NightOutPreference,
  tachoNumber: string,
  licences: LicenceCategory[],
  rotaType: RotaType,
  rotaStartDate: string
}

export interface EditDriverInput {
  id: string,
  firstName: string
  lastName: string
  email: string,
  phone: string,
  bambooId: string,
  assessedDate?: string
  earliestStartTime: string
  expirationDate: string
  siteId: string,
  groupId: string | undefined,
  nightOut: NightOutPreference,
  tachoNumber: string,
  licences: LicenceCategory[],
  rotaType: RotaType,
  rotaStartDate: string
}

export interface OperatorResponse {
  id: string
  name: string
  alias?: string
}

export enum LicenceCategory {
  NO_LICENCE = 'NO_LICENCE',
  B = 'B',
  BE = 'BE',
  B_AUTO = 'B_AUTO',
  C = 'C',
  C1 = 'C1',
  CE = 'CE',
  C1E = 'C1E',
  D = 'D',
  D1 = 'D1',
  DE = 'DE',
  D1E = 'D1E',

}

export interface DriverResponse {
  id: string
  firstName: string
  lastName: string
  licences: LicenceCategory[]
  phone: string
  bambooId: string
  tachoNumber: string
  earliestStartTime: string
  assessedDate?: string
  expirationDate: string
  nightOut: NightOutPreference
  group?: GroupModel
  site?: SiteModel
  operator: OperatorResponse
  user: { email: string, username: string },
  rotaType: RotaType,
  rotaStartDate: string
  rotaRounds: DriverRotaRound[]
}

export interface DriverAvailabilityOutput {
  id: string,
  name: string,
  labels: string,
  siteName: string,
  availability: { [k: string]: DriverStatus }
}

export interface DriverStatus {
  working: boolean,
  startTime: string
}

export interface DriverAvailabilityInput extends PaginationParams {
  startDate: string,
  endDate: string
}


export interface DriverFilters {
  fullName: string,
  assessed: boolean
  siteIds: string[]
  licences: LicenceCategory[]
  groupIds: string[]
  nightOut: NightOutPreference
  roundIds: string[]
  id: string
  assigned: boolean
}

export type AddShiftUCParams = { driverId: string, startTime: string, endTime: string, date: string }
export type AddMissingShiftUCParams = AddShiftUCParams & { breakTime: string, isOvernight: boolean }
export type DriverShiftsParams =
  { startDate: string, endDate: string } & PaginationParams & { filters?: Partial<DriverFilters> }
export type DriverShiftsResponse = {
  driverId: string
  firstName: string
  lastName: string
  group: string | undefined
  site: string | undefined
  licence: LicenceCategory[]
  nightOut: NightOutPreference
  earliestStartTime: string,
  isRotaWorkingDay: boolean,
  daySlots: Array<DriverDaySlot>
  roundSlots: Array<{ date: string, roundId: string }>
}
export type DriverDaySlot = {
  type: 'assigned' | 'available' | 'holiday' | 'sick' | 'unavailable',
  date: string,
  isRotaWorkingDay: boolean,
  shifts: Shift[]
}
export type Shift = {
  id: string,
  date: string,
  startLocation: string,
  startTime: string,
  endTime: string,
  status: ShiftStatus,
  startedAt?: string,
  endedAt?: string,
  breakTime?: string,
  isOvernight: boolean,
  operatorTime?: string,
  hasComments: boolean,
  hasReceipts: boolean
  tachoData?: { totalShiftTime: string }
  invoiceId?: string
  needsApprovalAfterTacho?: boolean
}
export type ShiftComment = { id: string, userId: string, message: string, commentedAt: string }
export type ShiftReceipt = { id: string, userId: string, description: string, displayName: string, cost: number, addedAt: string, approved: boolean }
export type ShiftStatus = 'not_confirmed' | 'confirmed' | 'started' | 'ended'
export type ShiftActivity = {
  id: string,
  type: ShiftActivityType,
  additionalData: any,
  addedAt: string,
  userId: string
}
export type ShiftActivityType =
  'shift_sent'
  | 'shift_times_changed'
  | 'shift_canceled'
  | 'shift_confirmed'
  | 'shift_started'
  | 'receipt_submitted'
  | 'receipt_validated'
  | 'shift_ended'
  | 'shift_validated'
  | 'shift_tacho_added'
type AddLeaveParams = { driverId: string; startDate: string; endDate: string }
type RemoveLeaveParams = { driverId: string; date: string; block: boolean }

type WeekConfig = {
  firstDay: string,
  amStart: string,
  amEnd: string,
  pmStart: string,
  pmEnd: string,
  timeDiscrepancyWarning: string,
  timeDiscrepancyError: string
}
type InvoiceConfig = {
  invoicingEnabled: boolean
  invoiceOperatorEmail: string
  invoiceAddress: string
  qrScanShiftTimeDistance: string
  requireAgencyData: boolean
}

type OperatorBambooConfig = {
  bambooIntegrationEnbaled: boolean
  bambooApiUrl: string
  bambooApiToken: string
}

export interface TachoRowResult {
  driverName: string,
  tachoNumber: string,
  workingDate: string,
  status: 'success' | 'noDriverFound' | 'noShiftFound' | 'alreadyUploaded'
}

export type DriverAgencyData = {
  companyName: string,
  companyNumber: string,
  companyAddress: string,
  accountNumber: string,
  sortCode: string
  vatIncluded: boolean
}
export type DailyRates = { normalRate: number, normalRateMinHours: number, overnightRate: number }
export type RateCard = {
  id: string
  groupId?: string,
  mondayRates: DailyRates,
  tuesdayRates: DailyRates,
  wednesdayRates: DailyRates,
  thursdayRates: DailyRates,
  fridayRates: DailyRates,
  saturdayRates: DailyRates,
  sundayRates: DailyRates,
  publicHolidayRates: DailyRates,
  activeFrom: string
}

export interface PublicHoliday {
  title: string
  date: string
  bunting: boolean
}

export class UnauthorizedC2ApiClient {

  protected restService: RestService

  constructor(errorHandlers?: ErrorHandler[]) {
    this.restService = new RestService(serverUrl, {}, errorHandlers)
  }

  public setPassword(param: { password: string; token: string; }): Promise<SessionInfo> {
    return this.restService.post({url: 'set-password', body: param})
  }

  public login(loginParams: { username?: string, password?: string }): Promise<SessionInfo> {
    return this.restService.post({url: 'login', body: loginParams})
  }

  resetPassword(resetPasswordParams: { username: string; }): Promise<{ email: string }> {
    return this.restService.post({url: 'reset-password', body: resetPasswordParams})
  }
}

export class SuperAdminC2ApiClient {
  private restService: RestService

  constructor(private sessionToken: string, private errorHandlers?: ErrorHandler[]) {
    this.restService = new RestService(serverUrl, {'C2-Session': sessionToken}, errorHandlers)
  }

  getUserName(userId: string): Promise<{ id: string, name: string }> {
    return this.restService.get<{ id: string, name: string }>({
      url: `users/${userId}`
    })
  }

  listOperators(paginationParams: PaginationParams): Promise<ListPaginatedResponse<OperatorSummary>> {
    return this.restService.get<ListPaginatedResponse<OperatorSummary>>({
      url: 'operators',
      query: paginationParams as unknown as QueryParams
    })
  }

  getOperatorDetails(operatorId: string) {
    return this.restService.get<OperatorResponse>({url: `operators/${operatorId}`})
  }

  createOperator(data: CreateOperator): Promise<CreateOperatorResult> {
    return this.restService.post<CreateOperatorResult>({url: 'operators', body: data})
  }

  editOperator(operatorDetails: { id: string, name: string, alias: string }) {
    return this.restService.post<OperatorResponse>({url: `operators/${operatorDetails.id}`, body: operatorDetails})
  }

  listDrivers(params: PaginationParams): Promise<ListPaginatedResponse<DriverResponse>> {
    return this.restService.get<ListPaginatedResponse<DriverResponse>>({
      url: `drivers`,
      query: params as unknown as QueryParams
    })
  }

  getOperatorInvoiceConfig(operatorId: string): Promise<InvoiceConfig | undefined> {
    return this.restService.get<{ data: InvoiceConfig | undefined }>({
      url: `operators/${operatorId}/invoice-config`
    }).then(res => res.data)
  }

  setOperatorInvoiceConfig(operatorId: string, config: InvoiceConfig): Promise<InvoiceConfig> {
    return this.restService.post<InvoiceConfig>({
      url: `operators/${operatorId}/invoice-config`,
      body: config
    })
  }

  getOperatorBambooConfig(operatorId: string): Promise<OperatorBambooConfig | undefined> {
    return this.restService.get<{ data: OperatorBambooConfig | undefined }>({
      url: `operators/${operatorId}/operator-bamboo-config`
    }).then(res => res.data)
  }

  setOperatorBambooConfig(operatorId: string, config: OperatorBambooConfig): Promise<OperatorBambooConfig> {
    return this.restService.post<OperatorBambooConfig>({
      url: `operators/${operatorId}/operator-bamboo-config`,
      body: config
    })
  }

  asOperatorClient(operatorId: string): OperatorAdminC2ApiClient {
    return new OperatorAdminC2ApiClient(this.sessionToken, operatorId, this.errorHandlers)
  }

}

export type DriverRotaRound = {
  roundId: string,
  firstDate: string
}
export type AssignDriverRoundForDatesParam = {
  driverId: string,
  roundId: string,
  startDate: string,
  endDate: string
}
export class OperatorAdminC2ApiClient {
  private restService: RestService

  constructor(private sessionToken: string, private operatorId: string, errorHandlers?: ErrorHandler[]) {
    this.restService = new RestService(serverUrl, {'C2-Session': sessionToken}, errorHandlers)
  }

  getUserName(userId: string): Promise<{ id: string, name: string }> {
    return this.restService.get<{ id: string, name: string }>({
      url: `users/${userId}`
    })
  }

  generateUsername(params: { firstName: string, lastName: string, username?: string }): Promise<{ username: string, unique: boolean }> {
    return this.restService.post<{ username: string, unique: boolean }>({
      url: `operators/${this.operatorId}/generate-username`,
      body: params
    })
  }

  getOperatorDetails() {
    return this.restService.get<OperatorResponse>({url: `operators/${this.operatorId}`})
  }


  getOperatorSites(): Promise<SiteReponse> {
    return this.restService.get<SiteReponse>({url: `operators/${this.operatorId}/sites`})
  }

  createDriver(input: CreateDriverInput): Promise<DriverResponse> {
    return this.restService.post<DriverResponse>({url: `operators/${this.operatorId}/drivers`, body: input})
  }

  checkCreateDriverInput(input: Pick<CreateDriverInput, 'tachoNumber' | 'firstName' | 'lastName' | 'email'>): Promise<DriverResponse[]> {
    return this.restService.post<DriverResponse[]>({url: `operators/${this.operatorId}/drivers-check`, body: input})
  }

  editDriver(input: EditDriverInput): Promise<DriverResponse> {
    return this.restService.post<DriverResponse>({url: `operators/${this.operatorId}/drivers/${input.id}`, body: input})
  }

  resetDriverPassword(driverId: string): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${driverId}/reset-password`,
      body: {}
    })
  }

  setDriverPassword(driverId: string, password: string): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${driverId}/set-password`,
      body: {password}
    })
  }


  resetOperatorPassword(operatorId: string): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/admins/${operatorId}/reset-password`,
      body: {}
    })
  }

  patchDriver(input: Pick<EditDriverInput, 'id'> & Partial<Omit<EditDriverInput, 'id'>>): Promise<DriverResponse> {
    return this.restService.patch<DriverResponse>({
      url: `operators/${this.operatorId}/drivers/${input.id}`,
      body: input
    })
  }

  listDrivers({
                pageSize,
                page,
                filters = {},
                order,
                ascending
              }: { pageSize: number; page: number; filters?: Partial<DriverFilters>; order?: string, ascending?: boolean }): Promise<ListPaginatedResponse<DriverResponse>> {
    const params = {pageSize, page, order, ascending, ...filters}
    return this.restService.get<ListPaginatedResponse<DriverResponse>>({
      url: `operators/${this.operatorId}/drivers`,
      query: params as unknown as QueryParams
    })
  }

  getDriver(id: string): Promise<DriverResponse> {
    return this.restService.get<DriverResponse>({
      url: `operators/${this.operatorId}/drivers/${id}`
    })
  }

  getDriverAvailability(input: DriverAvailabilityInput): Promise<ListPaginatedResponse<DriverAvailabilityOutput>> {
    return this.restService.get<ListPaginatedResponse<DriverAvailabilityOutput>>({
      url: `operators/${this.operatorId}/drivers-availability`, query: input as unknown as QueryParams
    })
  }

  getDriverShifts(params: DriverShiftsParams): Promise<ListPaginatedResponse<DriverShiftsResponse>> {
    const {filters = {}, ...restParams} = params
    return this.restService.get<ListPaginatedResponse<DriverShiftsResponse>>({
      url: `operators/${this.operatorId}/drivers-shifts`, query: {...restParams, ...filters} as unknown as QueryParams
    })
  }

  exportDriverShifts(params: DriverShiftsParams, driverId?: string): Promise<void> {
    const {filters = {}, ...restParams} = params
    return this.restService.download({
      url: `operators/${this.operatorId}/drivers-shifts/export`,
      query: {...restParams, ...filters, ...(driverId ? {id: driverId} : {})} as unknown as QueryParams
    })
  }
  exportDriverRounds(params: DriverShiftsParams): Promise<void> {
    const {filters = {}, ...restParams} = params
    return this.restService.download({
      url: `operators/${this.operatorId}/drivers-shifts/export-rounds`,
      query: {...restParams, ...filters} as unknown as QueryParams
    })
  }
  exportHolidaySickInfo(params: { startDate: string, endDate: string }): Promise<void> {
    return this.restService.download({
      url: `operators/${this.operatorId}/drivers-shifts/export-holiday-sick`,
      query: params as unknown as QueryParams
    })
  }

  getShiftDetails(shiftId: string): Promise<Shift> {
    return this.restService.get<Shift>({
      url: `shifts/${shiftId}`
    })
  }


  getShiftActivity(shiftId: string): Promise<ShiftActivity[]> {
    return this.restService.get<ShiftActivity[]>({
      url: `shifts/${shiftId}/activity`
    })
  }

  addShift(params: AddShiftUCParams): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/shifts`, body: params
    })
  }

  cancelShift(shiftId: string): Promise<void> {
    return this.restService.delete<void>({
      url: `shifts/${shiftId}`
    })
  }

  addSickLeave(params: AddLeaveParams): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/sick`, body: params
    })
  }

  deleteSickLeave(params: RemoveLeaveParams): Promise<void> {
    return this.restService.delete<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/sick`,
      query: {date: params.date, block: params.block}
    })
  }

  addHoliday(params: AddLeaveParams): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/holiday`, body: params
    })
  }

  addTraining(params: AddLeaveParams): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/training`, body: params
    })
  }

  addInduction(params: AddLeaveParams): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/induction`, body: params
    })
  }

  deleteTrainingLeave(params: RemoveLeaveParams): Promise<void> {
    return this.restService.delete<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/training`,
      query: {date: params.date, block: params.block}
    })
  }

  deleteInductionLeave(params: RemoveLeaveParams): Promise<void> {
    return this.restService.delete<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/induction`,
      query: {date: params.date, block: params.block}
    })
  }

  deleteHolidayLeave(params: RemoveLeaveParams): Promise<void> {
    return this.restService.delete<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/holiday`,
      query: {date: params.date, block: params.block}
    })
  }

  addUnavailable(params: AddLeaveParams): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/unavailable`, body: params
    })
  }

  assignRoundForDates(params: AssignDriverRoundForDatesParam): Promise<void> {
    const {driverId, ...body} = params
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${driverId}/date-rounds`, body
    })
  }
  deleteUnavailable(params: RemoveLeaveParams): Promise<void> {
    return this.restService.delete<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/unavailable`,
      query: {date: params.date, block: params.block}
    })
  }

  addMissingShift(params: AddMissingShiftUCParams): Promise<void> {
    return this.restService.post<void>({
      url: `operators/${this.operatorId}/drivers/${params.driverId}/timesheets`, body: params
    })
  }

  getShiftComments(shiftId: string): Promise<ShiftComment[]> {
    return this.restService.get<ShiftComment[]>({
      url: `shifts/${shiftId}/comments`
    })
  }

  addShiftComment(shiftId: string, message: string): Promise<void> {
    return this.restService.post<void>({
      url: `shifts/${shiftId}/comments`, body: {message}
    })
  }


  getShiftReceipts(shiftId: string): Promise<ShiftReceipt[]> {
    return this.restService.get<ShiftReceipt[]>({
      url: `shifts/${shiftId}/receipts`
    })
  }

  downloadReceipt(receiptId: string): Promise<{ downloadUrl: string }> {
    return this.restService.get<{ downloadUrl: string }>({
      url: `shift-receipts/${receiptId}/download`
    })
  }

  addShiftReceipt(shiftId: string, receiptData: { description: string, cost: number, file: File }): Promise<void> {
    const form = new FormData()
    if (receiptData.description) {
      form.append('description', receiptData.description)
    }
    form.append('cost', String(receiptData.cost))
    form.append('displayName', receiptData.file.name)
    form.append('receipt', receiptData.file)
    return this.restService.postFormData<void>({
      url: `shifts/${shiftId}/receipts`, data: form
    })
  }

  validateShiftReceipt(shiftId: string, receiptId: string, validateData: { approved: boolean }): Promise<void> {
    return this.restService.post<void>({
      url: `shifts/${shiftId}/receipts/${receiptId}`, body: validateData
    })
  }

  uploadTachoData(tachoData: { file: File, csvFormat: string }): Promise<{ data: TachoRowResult[] }> {
    const form = new FormData()
    form.append('tacho', tachoData.file)
    form.append('csvFormat', tachoData.csvFormat)
    return this.restService.postFormData<{ data: TachoRowResult[] }>({
      url: `operators/${this.operatorId}/tacho`, data: form
    })
  }

  editSite(data: { id: string; name: string; postCode: string; address: string }) {
    return this.restService.post<SiteModel>({url: `operators/${this.operatorId}/sites/${data.id}`, body: data})
  }

  createSite(data: { name: string; postCode: string; address: string }) {
    const {name, postCode, address} = data
    return this.restService.post<SiteModel>({
      url: `operators/${this.operatorId}/sites`,
      body: {name, postCode, address}
    })
  }

  deleteSite(siteId: string) {
    return this.restService.delete<void>({
      url: `operators/${this.operatorId}/sites/${siteId}`
    })
  }

  createRound(data: { name: string; description: string;
    mondayQuota: number;
    tuesdayQuota: number;
    wednesdayQuota: number;
    thursdayQuota: number;
    fridayQuota: number;
    saturdayQuota: number;
    sundayQuota: number; }) {
    const {name, description,   mondayQuota,
      tuesdayQuota,
      wednesdayQuota,
      thursdayQuota,
      fridayQuota,
      saturdayQuota,
      sundayQuota} = data
    return this.restService.post<RoundModel>({
      url: `operators/${this.operatorId}/rounds`,
      body: {name, description, mondayQuota,
        tuesdayQuota,
        wednesdayQuota,
        thursdayQuota,
        fridayQuota,
        saturdayQuota,
        sundayQuota}
    })
  }

  editRound(data: { id: string; name: string; description: string;
    mondayQuota: number;
    tuesdayQuota: number;
    wednesdayQuota: number;
    thursdayQuota: number;
    fridayQuota: number;
    saturdayQuota: number;
    sundayQuota: number; }) {
    return this.restService.post<RoundModel>({url: `operators/${this.operatorId}/rounds/${data.id}`, body: data})
  }

  getOperatorRounds(): Promise<RoundResponse> {
    return this.restService.get<RoundResponse>({url: `operators/${this.operatorId}/rounds`})
  }
  deleteOperatorRound(roundId: string): Promise<void> {
    return this.restService.delete<void>({url: `operators/${this.operatorId}/rounds/${roundId}`})
  }

  deleteDriver(driverId: string) {
    return this.restService.delete<void>({
      url: `operators/${this.operatorId}/drivers/${driverId}`
    })
  }

  getOperatorAdmins() {
    return this.restService.get<OperatorAdminList>({
      url: `operators/${this.operatorId}/admins`
    })
  }

  getOperatorAdmin(id: string) {
    return this.restService.get<OperatorAdminDetails>({
      url: `operators/${this.operatorId}/admins/${id}`
    })
  }

  editAdmin(editData: Omit<OperatorAdminDetails, 'username'>) {
    return this.restService.post<OperatorAdminDetails>({
      url: `operators/${this.operatorId}/admins/${editData.id}`,
      body: editData
    })
  }

  createAdmin(editData: Omit<OperatorAdminDetails, 'id'>) {
    return this.restService.post<OperatorAdminDetails>({
      url: `operators/${this.operatorId}/admins`,
      body: editData
    })
  }

  getOperatorGroups(): Promise<{ groups: Group[] }> {
    return this.restService.get<{ groups: Group[] }>({
      url: `operators/${this.operatorId}/groups`
    })
  }

  getOperatorGroup(id: string) {
    return this.restService.get<Group>({
      url: `operators/${this.operatorId}/groups/${id}`
    })
  }

  createGroup(createData: CreateGroup) {
    return this.restService.post<Group>({
      url: `operators/${this.operatorId}/groups`,
      body: createData
    })
  }

  editGroup(editData: EditGroup) {
    return this.restService.post<Group>({
      url: `operators/${this.operatorId}/groups/${editData.id}`,
      body: editData
    })
  }
  deleteGroup(groupId: string): Promise<void> {
    return this.restService.delete<void>({
      url: `operators/${this.operatorId}/groups/${groupId}`,
    })
  }


  setShiftOvernight(shiftId: string, isOvernight: boolean): Promise<void> {
    return this.restService.put({
      url: `shifts/${shiftId}/overnight`, body: {
        overnight: isOvernight
      }
    })
  }

  setShiftOperatorTime(shiftId: string, operatorTime: string): Promise<Shift> {
    return this.restService.put<Shift>({
      url: `shifts/${shiftId}/operator-status`, body: {
        operatorTime
      }
    })
  }

  setShiftTimes(shiftId: string, startTime: string, endTime: string): Promise<Shift> {
    return this.restService.put<Shift>({
      url: `shifts/${shiftId}/operator-status`, body: {
        shiftTimes: {
          startTime, endTime
        }
      }
    })
  }

  downloadMasterInvoice(startDate: string, endDate: string): Promise<void> {
    return this.restService.download({
      url: `shift-invoices/operator/${this.operatorId}/master`, query: {
        startDate, endDate
      }
    })
  }

  emailDriverInvoice(driverId: string, startDate: string, endDate: string): Promise<void> {
    return this.restService.get({
      url: `shift-invoices/operator/${this.operatorId}/drivers/${driverId}/email`, query: {
        startDate, endDate
      }
    })
  }

  createShiftInvoice(shiftId: string): Promise<void> {
    return this.restService.post<void>({
      url: `shift-invoices/${shiftId}`,
      body: {}
    })
  }

  getOperatorConfig(): Promise<WeekConfig | undefined> {
    return this.restService.get<{ data: WeekConfig | undefined }>({
      url: `operators/${this.operatorId}/week-config`
    }).then(res => res.data)
  }

  setOperatorConfig(config: WeekConfig): Promise<WeekConfig> {
    return this.restService.post<WeekConfig>({
      url: `operators/${this.operatorId}/week-config`,
      body: config
    })
  }

  getRateCards(siteId: string): Promise<RateCard[]> {
    return this.restService.get<{ rateCards: RateCard[] }>({
      url: `operators/${this.operatorId}/sites/${siteId}/rate-cards`
    }).then(res => res.rateCards)
  }

  addRateCard(siteId: string, rates: { groupId?: string, rates: Omit<RateCard, 'id' | 'groupId'> }): Promise<RateCard[]> {
    return this.restService.post<{ rateCards: RateCard[] }>({
      url: `operators/${this.operatorId}/sites/${siteId}/rate-cards`,
      body: rates
    }).then(res => res.rateCards)
  }

  editRateCard(ratesId: string, rates: Omit<RateCard, 'id' | 'groupId'>): Promise<RateCard[]> {
    return this.restService.post<{ rateCards: RateCard[] }>({
      url: `operators/${this.operatorId}/rate-cards/${ratesId}`,
      body: rates
    }).then(res => res.rateCards)
  }

  getOperatorInvoiceConfig(): Promise<InvoiceConfig | undefined> {
    return this.restService.get<{ data: InvoiceConfig | undefined }>({
      url: `operators/${this.operatorId}/invoice-config`
    }).then(res => res.data)
  }

  getOperatorBambooConfig(): Promise<OperatorBambooConfig | undefined> {
    return this.restService.get<{ data: OperatorBambooConfig | undefined }>({
      url: `operators/${this.operatorId}/operator-bamboo-config`
    }).then(res => res.data)
  }

  getDriverAgencyData(driverId: string): Promise<DriverAgencyData | undefined> {
    return this.restService.get<{ data: DriverAgencyData | undefined }>({
      url: `operators/${this.operatorId}/drivers/${driverId}/agency-data`
    }).then(res => res.data)
  }

  getPublicHolidays({before, after}: { before: string, after: string }): Promise<PublicHoliday[]> {
    return this.restService.get<{ holidays: PublicHoliday[] }>({
      url: `public-holidays`,
      query: {before, after}
    }).then(({holidays}) => holidays)
  }

  assignDriverRounds({driverId, rounds}: { driverId: string; rounds: { roundId?: string | undefined, date: string }[] }): Promise<{ rounds: DriverRotaRound[] }> {
    return this.restService.post<{rounds: DriverRotaRound[]}>({
      url: `/operators/${this.operatorId}/drivers/${driverId}/rota-rounds`,
      body: {
        rounds
      }
    })
  }
}

export class DriverC2ApiClient {
  private restService: RestService

  constructor(private sessionToken: string, private operatorId: string, private driverId: string, errorHandlers?: ErrorHandler[]) {
    this.restService = new RestService(serverUrl, {'C2-Session': sessionToken}, errorHandlers)
  }

  getDriverSlots(params: { startDate: string, endDate: string }): Promise<Array<DriverDaySlot>> {
    return this.restService.get<Array<DriverDaySlot>>({
      url: `operators/${this.operatorId}/drivers/${this.driverId}/slots`, query: {...params} as unknown as QueryParams
    })
  }

  startShiftAtSite(params: { startTime: string, siteId: string }): Promise<Shift> {
    return this.restService.post<Shift>({
      url: `shifts/operator/${this.operatorId}/driver/${this.driverId}`,
      body: {scheduledAt: params.startTime, siteId: params.siteId}
    })
  }

  getShiftActivity(shiftId: string): Promise<ShiftActivity[]> {
    return this.restService.get<ShiftActivity[]>({
      url: `shifts/${shiftId}/activity`
    })
  }

  confirmShift(shiftId: string): Promise<Shift> {
    return this.restService.put<Shift>({
      url: `shifts/${shiftId}/driver-status`, body: {
        status: 'confirmed'
      }
    })
  }

  startShift(shiftId: string, startTime: string): Promise<Shift> {
    return this.restService.put<Shift>({
      url: `shifts/${shiftId}/driver-status`, body: {
        status: 'started',
        startedAt: startTime
      }
    })
  }

  endShift(shiftId: string, endedAt: string): Promise<Shift> {
    return this.restService.put<Shift>({
      url: `shifts/${shiftId}/driver-status`, body: {
        status: 'ended',
        endedAt
      }
    })
  }

  setShiftOvernight(shiftId: string, isOvernight: boolean): Promise<void> {
    return this.restService.put({
      url: `shifts/${shiftId}/overnight`, body: {
        overnight: isOvernight
      }
    })
  }

  setShiftBreak(shiftId: string, breakTime: string): Promise<void> {
    return this.restService.put({
      url: `shifts/${shiftId}/break-time`, body: {
        breakTime
      }
    })
  }

  /*setShiftOperatorTime(shiftId: string, operatorTime: string): Promise<Shift> {
    return this.restService.patch<Shift>({
      url: `shifts/${shiftId}`, body: {
        operatorTime
      }
    })
  }*/

  getUserName(userId: string): Promise<{ id: string, name: string }> {
    return this.restService.get<{ id: string, name: string }>({
      url: `users/${userId}`
    })
  }


  getShiftComments(shiftId: string): Promise<ShiftComment[]> {
    return this.restService.get<ShiftComment[]>({
      url: `shifts/${shiftId}/comments`
    })
  }

  addShiftComment(shiftId: string, message: string): Promise<void> {
    return this.restService.post<void>({
      url: `shifts/${shiftId}/comments`, body: {message}
    })
  }


  getShiftReceipts(shiftId: string): Promise<ShiftReceipt[]> {
    return this.restService.get<ShiftReceipt[]>({
      url: `shifts/${shiftId}/receipts`
    })
  }

  addShiftReceipt(shiftId: string, receiptData: { description?: string, cost: number, file: File }): Promise<void> {
    const form = new FormData()
    if (receiptData.description) {
      form.append('description', receiptData.description)
    }
    form.append('displayName', receiptData.file.name)
    form.append('cost', String(receiptData.cost))
    form.append('receipt', receiptData.file)
    return this.restService.postFormData<void>({
      url: `shifts/${shiftId}/receipts`, data: form
    })
  }

  downloadReceipt(receiptId: string): Promise<{ downloadUrl: string }> {
    return this.restService.get<{ downloadUrl: string }>({
      url: `shift-receipts/${receiptId}/download`
    })
  }

  getOperatorConfig(): Promise<WeekConfig | undefined> {
    return this.restService.get<{ data: WeekConfig | undefined }>({
      url: `operators/${this.operatorId}/week-config`
    }).then(res => res.data)
  }


  getOperatorInvoiceConfig(): Promise<InvoiceConfig | undefined> {
    return this.restService.get<{ data: InvoiceConfig | undefined }>({
      url: `operators/${this.operatorId}/invoice-config`
    }).then(res => res.data)
  }

  getOperatorBambooConfig(): Promise<OperatorBambooConfig | undefined> {
    return this.restService.get<{ data: OperatorBambooConfig | undefined }>({
      url: `operators/${this.operatorId}/operator-bamboo-config`
    }).then(res => res.data)
  }

  getDriverAgencyData(): Promise<DriverAgencyData | undefined> {
    return this.restService.get<{ data: DriverAgencyData | undefined }>({
      url: `operators/${this.operatorId}/drivers/${this.driverId}/agency-data`
    }).then(res => res.data)
  }

  setDriverAgencyData(data: DriverAgencyData): Promise<DriverAgencyData> {
    return this.restService.post<DriverAgencyData>({
      url: `operators/${this.operatorId}/drivers/${this.driverId}/agency-data`,
      body: data
    })
  }

  getPublicHolidays({before, after}: { before: string, after: string }): Promise<PublicHoliday[]> {
    return this.restService.get<{ holidays: PublicHoliday[] }>({
      url: `public-holidays`,
      query: {before, after}
    }).then(({holidays}) => holidays)
  }

}

export interface Group {
  id: string;
  name: string;
  operatorId: string
}

export interface CreateGroup {
  name: string
}

export interface EditGroup {
  id: string;
  name: string;
}

export interface OperatorAdminDetails {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  username: string,
  sendInvoiceEmail: boolean,
  phone: string,
  active: boolean
}

export interface OperatorAdminList {
  data: OperatorAdminDetails[]
}

type ClientConstructor<T> = { new(...params: any): T };

export class ContextApiClient {
  constructor(private client: UnauthorizedC2ApiClient | DriverC2ApiClient | OperatorAdminC2ApiClient | SuperAdminC2ApiClient) {
  }

  public as<T>(clientClass: ClientConstructor<T>): T | null {
    if (this.client instanceof clientClass) {
      return this.client as T
    }
    return null
  }

  public asOrFail<T>(clientClass: ClientConstructor<T>): T {
    const client = this.as(clientClass)
    if (!client) {
      throw new Error('client is of wrong type')
    }
    return client
  }
}

function createClient(sessionInfo?: SessionInfo | undefined, errorHandlers?: ErrorHandler[]) {
  switch (sessionInfo?.role) {
    case "SUPER_ADMIN":
      return new SuperAdminC2ApiClient(sessionInfo.sessionToken, errorHandlers)
    case 'OPERATOR_ADMIN':
      return new OperatorAdminC2ApiClient(sessionInfo.sessionToken, sessionInfo.operatorId!, errorHandlers)
    case 'DRIVER':
      return new DriverC2ApiClient(sessionInfo.sessionToken, sessionInfo.operatorId!, sessionInfo.driverId!, errorHandlers)
    default:
      return new UnauthorizedC2ApiClient(errorHandlers)
  }
}

export function clientFactory(sessionInfo?: SessionInfo | undefined, errorHandlers?: ErrorHandler[]) {
  const client = createClient(sessionInfo, errorHandlers);
  return new ContextApiClient(client);
}
