import {
    DriverFilters,
    DriverShiftsResponse,
    NightOutPreference,
    OperatorAdminC2ApiClient,
    RoundModel
} from "../../client/c2-api-client";
import {AddDatesAction} from "../../components/add-dates-interval-modal/add-dates-interval-modal";
import {RowsFetcher, TableHook} from "../../hooks/table";
import {ColumnConfig} from "../../components/table/table";
import {DriverTableHeader} from "../../components/table/column-header-components";
import styles from "./drivers-planning.module.css";
import {EyeIcon} from "../../icons/Eye";
import {Moment} from "moment";
import {RefObject, useRef, useState} from "react";
import classNames from "classnames";
import {DriverWithRoundsAndSlots, nightOutLabel} from "./driver-planning-table-types";
import {DriverDateCell, DriverDateHeader, EditActions} from "./driver-planning-table-components";
import {AvailabilitySummary, DateSummary, getAvailabilitySummary} from "./availability-summary";

export type DriverSlotsFetcherParams = {
    today: Moment,
    days: Moment[],
    operatorRounds: RoundModel[],
    apiClient: OperatorAdminC2ApiClient,
    setSummaries: (a: DateSummary[]) => void
}

export function driversWithSlotsFetcher({
                                            apiClient,
                                            days,
                                            operatorRounds,
                                            setSummaries,
                                            today
                                        }: DriverSlotsFetcherParams): RowsFetcher<DriverWithRoundsAndSlots, DriverFilters> {
    return ({page, pageSize, sort, ascending, filters}) => {
        const mapper = driverResponseToSummaryMapper(today, days)
        const startDate = days[0].format('YYYY-MM-DD')
        const endDate = days[days.length - 1].format('YYYY-MM-DD')
        return apiClient.getDriverShifts({
            page,
            pageSize,
            order: sort,
            ascending,
            startDate,
            endDate,
            filters
        }).then(driversPage => ({
            ...driversPage,
            data: driversPage.data.map(mapper)
        })).then(d => {
            const res = getAvailabilitySummary(d.data, operatorRounds)
            console.log(d.data)
            console.log('summaries', res)
            setSummaries(res)
            return d;
        })
    }
}

export function driverResponseToSummaryMapper(today: Moment, days: Moment[]): (driver: DriverShiftsResponse) => DriverWithRoundsAndSlots {
    return driver => ({
        id: driver.driverId,
        name: `${driver.firstName} ${driver.lastName}`,
        nightOut: driver.nightOut,
        earliestStart: driver.earliestStartTime,
        licence: driver.licence.join(),
        group: driver.group ?? '',
        status: days.reduce((res, d) => {
            const dateStr = d.format('YYYY-MM-DD')
            const slots = driver.daySlots.filter(s => s.date === dateStr)
            if(!slots.length) {
                res[d.format('YYYY-MM-DD')] = [{isRotaWorkingDay: false, type: 'off', startTime: driver.earliestStartTime}]
                return res
            }
            const slot = slots[0]
            if(slot.type !== 'assigned') {
                res[d.format('YYYY-MM-DD')] = [{isRotaWorkingDay: slot.isRotaWorkingDay, type: slot.type, startTime: driver.earliestStartTime, endTime: undefined, shiftId: undefined}]
                return res
            }

            res[d.format('YYYY-MM-DD')] = slot.shifts.map(sh => ({
                type: sh.status,
                startTime: sh.startTime,
                endTime: sh.endTime,
                shiftId: sh.id,
                isRotaWorkingDay: slot.isRotaWorkingDay
            }))
            return res;
        }, {} as DriverWithRoundsAndSlots['status']),
        roundAssignments: driver.roundSlots.reduce((map, slot) => {
            map.set(slot.date, slot.roundId)
            return map
        }, new Map<string, string>())
    });
}


type AddShiftHandler = (row: DriverWithRoundsAndSlots, date: Moment) => void
type AddDaySlotHandler = (row: DriverWithRoundsAndSlots, type: AddDatesAction) => void
type TableConfigParams = {
    table: TableHook<any>, dates: Moment[], today: Moment, addHandler: AddShiftHandler,
    addDaySlot: AddDaySlotHandler, firstHeaderRef: RefObject<HTMLTableHeaderCellElement>,
    editHandler: (driverId: string, type: 'sick' | 'holiday' | 'training' | 'induction' | 'unavailable', date: string) => void,
    shiftHandler: (shiftId: string, startTime: string, endTime: string) => void
    assignRoundHandler: (driver: DriverWithRoundsAndSlots) => void
    summaries: DateSummary[],
    operatorRounds: RoundModel[],
    tableType: 'Shifts' | 'Rounds',
    rounds: RoundModel[]
}

export function driversTableConfig({
                                       addDaySlot,
                                       addHandler,
                                       assignRoundHandler,
                                       dates,
                                       firstHeaderRef,
                                       editHandler,
                                       rounds,
                                       shiftHandler,
                                       table,
                                       summaries,
                                       operatorRounds,
                                       tableType,
                                       today
                                   }: TableConfigParams): ColumnConfig<DriverWithRoundsAndSlots>[] {
    return [{
        key: 'name',
        header: <DriverTableHeader label='Driver' table={table} sort='firstName' classes={styles.tableHeader}/>,
        cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}>{row.name}</td>
    }, {
        key: 'licences',
        header: <DriverTableHeader label='Licence' table={table} sort='licences' classes={styles.tableHeader}/>,
        cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}>{row.licence}</td>
    }, {
        key: 'group',
        header: <DriverTableHeader label='Group' table={table} sort='group' classes={styles.tableHeader}/>,
        cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}>{row.group}</td>
    }, {
        key: 'nightOut',
        header: <DriverTableHeader label='Night Out' table={table} sort='nightOut' classes={styles.tableHeader}/>,
        cellFactory: row => <td
            className={classNames(styles.tableCell, 'text-300')}>{nightOutLabel[row.nightOut as NightOutPreference]}</td>
    }, {
        key: 'earliestStartTime',
        header: <DriverTableHeader label='Earliest Start' table={table} sort='earliestStartTime'
                                   classes={styles.tableHeader}/>,
        cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}>{row.earliestStart}</td>
    },
        ...dates.map((d, i) => ({
            key: `${d.toDate().getTime()}`,
            header: <DriverDateHeader date={d}
                                      table={table}
                                      tableType={tableType}
                                      refCallback={i == 0 ? firstHeaderRef : undefined}
                                      compare={d.format('YYYY-MM-DD') === today.format('YYYY-MM-DD') ? 0 : d.isAfter(today) ? 1 : -1}/>,
            cellFactory: row => <DriverDateCell key={`${row.id}-${d.toDate().getTime()}`} row={row} date={d} addHandler={() => addHandler(row, d)}
                                                editHandler={editHandler}
                                                compare={d.format('YYYY-MM-DD') === today.format('YYYY-MM-DD') ? 0 : d.isAfter(today) ? 1 : -1}
                                                shiftHandler={shiftHandler}
                                                tableType={tableType}
                                                rounds={rounds}
            />
        } as ColumnConfig<DriverWithRoundsAndSlots>)), {
            key: 'actions',
            header: <th>
                <SummaryTableHeader summaries={summaries} operatorRounds={operatorRounds} firstHeaderRef={firstHeaderRef} />
            </th>,
            cellFactory: row => <td>
                <div className={classNames(styles.tableCell, 'd-flex justify-content-center')}><EditActions
                    assignRound={() => assignRoundHandler(row)}
                    addSlot={(type) => addDaySlot(row, type)}/>
                </div>
            </td>
        }
    ]
}

function SummaryTableHeader({summaries, operatorRounds, firstHeaderRef}: { summaries: DateSummary[], operatorRounds: RoundModel[], firstHeaderRef: RefObject<HTMLTableHeaderCellElement> }) {
    const [summaryCoords, setSummaryCoords] = useState<{left?: number, right?: number}>({})
    const iconHolderRef = useRef<HTMLDivElement>(null)
    const eyeClickHandler = () => {


        setSummaryCoords((current) => {
            if(current.left && current.right) return {}

            const summaryCoords = {
                left: firstHeaderRef.current?.getBoundingClientRect().right,
                right: iconHolderRef.current?.getBoundingClientRect().left
            }

            console.log('summary coords', summaryCoords)
            return summaryCoords
        })

    }
    return <>
        <div ref={iconHolderRef} style={{cursor: 'pointer', position: 'relative'}} onClick={eyeClickHandler}><EyeIcon active={summaryCoords.left !== undefined}/>

            <AvailabilitySummary summaries={summaries}
                                 summaryCoords={summaryCoords}
                                 allRounds={operatorRounds}/>
        </div>

    </>
}