import {createContext, useContext} from "react";
import {Session} from "./session";
import {clientFactory, SessionInfo} from "../client/c2-api-client";

export interface SessionContext {
  session: Readonly<Session>;
  setSession: (s: SessionInfo) => void
  clearSession: () => void
}

export const sessionContext = createContext<SessionContext>({session: undefined, setSession: () => {}, clearSession: () => {}});

export function useSessionContext() {
  return useContext(sessionContext);
}
