import {RoutePaths, useRoutePathMatch} from "../../app-router";
import styles from './driver-month-view.module.css'
import moment from "moment";
import {useEffect, useMemo, useState} from "react";
import {ArrowLeft} from "../../icons/arrow-left";
import {ArrowRight} from "../../icons/arrow-right";
import classnames from "classnames";
import classNames from "classnames";
import {useApiClientContext} from "../../hooks/api-client-context";
import {DriverC2ApiClient, DriverDaySlot, Shift, ShiftStatus} from "../../client/c2-api-client";
import {dayNames, timeService} from "../../services/time-service";
import {shiftService} from "../../services/shift/shift-service";
import {useRoutingContext} from "../../hooks/routing-context";
import {statusBackgrounds} from "../../services/status-backgrounds";
import {slotService} from "../../services/slot-service";
import {DayLaneShift} from "../../components/lanes/day-lane/day-lane";

export function DriverMonthView() {
  const dateParam = useRoutePathMatch(RoutePaths.MONTH_VIEW)
  const apiClient = useApiClientContext().asOrFail(DriverC2ApiClient)
  // const [startOfWeek, setStartOfWeek] = useState('sunday')
  const routingContext = useRoutingContext()
  const date = useMemo(() => {
    return dateParam ? timeService.parseDate(dateParam.params.date) : moment()
  }, [dateParam?.params.date])
  const startDate = useMemo(() => {
    return moment(date).startOf('months')
  }, [dateParam?.params.date])
  const endDate = useMemo(() => {
    return moment(date).endOf('months')
    // return timeService.getNextWeekDay(moment(date), startOfWeek).subtract(1, 'days')
  }, [dateParam?.params.date])
  const today = useMemo(() => timeService.formatDate(moment()), [])
  const days = useMemo(() => {
    let current = moment(startDate)
    const res = []
    while (!current.isAfter(endDate)) {
      res.push({
        moment: moment(current),
        date: current.date(),
        formatted: current.format('YYYY-MM-DD')
      })
      current = current.add(1, 'day')
    }
    return res

  }, [dateParam?.params.date])
  const [daySlots, setDaySlots] = useState<{loading: boolean, data: {slots: Record<string, DriverDaySlot>, shifts: Record<string, DayLaneShift[]>}}>({loading: true, data: {slots: {}, shifts: {}}})
  const [allShifts, setAllShifts] = useState<Shift[]>([])

  useEffect(() => {
    setDaySlots({loading: true, data: {slots: {}, shifts: {}}})
    apiClient.getDriverSlots({startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD')})
      .then(shiftsRes => {
        const {allShifts, slotsByDate, shiftsByDate} = slotService.partitionByDate(shiftsRes)
        setAllShifts(allShifts)
        setDaySlots({loading: false, data: {slots: slotsByDate, shifts: shiftsByDate}})

      })

  }, [endDate.toDate().getTime()])

  console.log('days', days)
  console.log('day slots', daySlots)
  const monthBack = () => {
    const newDate = moment(date).subtract(1, 'month')
    routingContext.navigate(RoutePaths.MONTH_VIEW.getPath({date: newDate.format('YYYY-MM-DD')}))
  }
  const monthForward = () => {
    const newDate = moment(date).add(1, 'month')
    routingContext.navigate(RoutePaths.MONTH_VIEW.getPath({date: newDate.format('YYYY-MM-DD')}))
  }
  const dayOfFirstDate = days[0].moment.weekday()
  const daysInFirstWeek = 7 - dayOfFirstDate
  const firstWeek  = new Array(dayOfFirstDate).fill({formatted: null}).concat(days.slice(0, daysInFirstWeek))
  const numberOfWeeks = Math.ceil((days.length - daysInFirstWeek) / 7)
  const weeks: Array<Array<{formatted: string | null, date?: number}>> = new Array(numberOfWeeks).fill({}).reduce((res, val, ind) => {
    const firstDay = (ind + 1) * 7 - dayOfFirstDate
    const lastDay = firstDay + 7 < days.length ? (firstDay + 7) : days.length
    const week = days.slice(firstDay, lastDay)
    const fullWeek = week.length < 7 ? [...week, ...new Array(7 - week.length).fill({}).map(() => ({formatted: null}))] : week
    return [...res, fullWeek]
  }, [firstWeek])
  console.log(weeks)
  return (
    <div className='flex-fill d-flex flex-column align-items-stretch'>
      <div className='d-flex justify-content-center'>
        <i>Total Time:</i> {timeService.formatDuration(shiftService.totalApprovedDuration(allShifts))},
        <i>Total Break:</i> {timeService.formatDuration(shiftService.totalBreak(allShifts))}
      </div>
      <div className={classnames('d-flex text-100', styles.weekHeader)}>
        <div className={classnames(styles.sideBar, 'd-flex flex-column align-items-stretch')}>
          <div className={styles.arrowContainer} onClick={monthBack} style={{cursor: 'pointer'}}>
            <ArrowLeft/>
          </div>
          <div className={styles.arrowContainer} onClick={monthForward} style={{cursor: 'pointer'}}>
            <ArrowRight/>
          </div>
        </div>
        <div className='flex-fill d-flex align-items-center'>
          {dayNames.map((n, i) => <div key={`${n}-${i}`} className={classNames(styles.flexChild, 'd-flex', 'justify-content-center')}>{n.substring(0, 1).toUpperCase()}</div>)}
        </div>
      </div>
      <div className='flex-fill d-flex'>
        <div className={styles.sideBar}></div>
        <div className='flex-fill d-flex flex-column'>
          {weeks.map((week, weekNum) => (
            <WeekRow key={week[0].formatted ?? 'empty'} week={week} weekNum={weekNum} daySlots={daySlots.data}/>
          ))}
        </div>
      </div>
    </div>
  )
}

function WeekRow({week, weekNum, daySlots}: {week: {formatted: string | null, date?: number}[], weekNum: number, daySlots: {slots: Record<string, DriverDaySlot>}}) {
  return <>
    <div key={week[0].formatted ?? 'empty'} className={classnames(styles.flexChild, 'd-flex')}>
      {
        week.map((d, dayNum) => (
          <DayTile date={d.date} slot={d.formatted ? daySlots.slots[d.formatted] : null} formatted={d.formatted} key={d.formatted ?? `${dayNum}-${weekNum}`} />
        ))
      }
    </div>
  </>
}

function DayTile({date, slot, formatted}: {date?: number, formatted: string | null, slot: DriverDaySlot | null}) {
  const routingContext = useRoutingContext()
  const status = slot === null || slot === undefined ? 'off' : (slot.shifts[0] ? slot.shifts[0].status : slot.type) as  ShiftStatus | 'available' | 'holiday' | 'sick' | 'off'
  const cursor = status !== 'off' && status !== 'available' ? 'pointer' : 'auto'
  const onClick = () => {
    if(cursor !== 'pointer' || !formatted) return
    routingContext.navigate(RoutePaths.DAY_VIEW.getPath({date: formatted}))
  }
  return <>
    <div className={classNames(styles.flexChild, styles.dayTile, 'd-flex', 'justify-content-center', 'flex-column', 'align-items-center')}>
      <div>{date}</div>
      <div style={{backgroundColor: slot ? statusBackgrounds[status] : 'auto', flexBasis: '50%', width: '100%' ,cursor}} onClick={onClick}></div>

    </div>
  </>
}
