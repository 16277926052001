import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import MomentUtils from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import './App.css';
import {SessionAndClientProvider} from "./components/session/provider/session-and-client-provider";
import {HeaderPageWrapper} from "./components/header-page-wrapper/HeaderPageWrapper";
import {AppRouter} from "./app-router";
import {RoutingContextProvider} from "./components/routing/provider/routing-context-provider";

function App() {
  return (
    <Router>
      <SessionAndClientProvider>
        <RoutingContextProvider>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <HeaderPageWrapper>
              <AppRouter/>
            </HeaderPageWrapper>
          </LocalizationProvider>
        </RoutingContextProvider>
      </SessionAndClientProvider>
    </Router>
  );
}


export default App;
