import './PasswordInput.css'
import React, {useState} from "react";
import {Input} from "../text/Input";
import eyeIcon from "../../../icons/eye.svg";
import {InputHook} from "../../../hooks/input";

interface PasswordInputProps {
  name: string;
  id?: string;
  label?: string;
  autoComplete?: string;
  placeholder?: string;
  inputHook: InputHook<string>
}
export function PasswordInput(props: PasswordInputProps) {
  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const togglePasswordVisible = () => {
    setShowPassword(!isShowPassword);
  };
  return (
    <Input
        type={isShowPassword ? 'text' : 'password'}
        name={props.name}
        id={props.id}
        autoComplete={props.autoComplete}
        placeholder={props.placeholder}
        inputHook={props.inputHook}
        label={props.label}>
      <img src={eyeIcon} className="show-password-icon" onClick={togglePasswordVisible} />
    </Input>
  );
}
