import styles from "./timesheets.module.css";
import {OperatorAdminSettingsForm} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {Dialog} from "@mui/material";
import {useInput} from "../../hooks/input";
import {SelectInput} from "../../components/input/select/select";


const tachoFormatOptions = [{id: 'convey', name: 'Convey'}, {id: 'jds', name: 'JDS'}];

export function UploadTachoModal({onUpload, show}: {onUpload: (p?: {ev: React.ChangeEvent<HTMLInputElement>, csvFormat: string}) => void, show: boolean}) {
  const csvFormatInput = useInput(['convey'])
  return (
    <Dialog
      PaperProps={{classes: {root: styles.addShiftDialogRoot}}}
      disableScrollLock={true}
      open={show}>
      <OperatorAdminSettingsForm title='Upload Tacho Data' cancelCallback={() => onUpload()}
                                 disableSubmit={false}
                                 paddingTop={false}
                                 editCallback={() => onUpload()}>
        <div className='d-flex flex-column' style={{gap: '20px'}}>
          <SelectInput input={csvFormatInput} multiple={false} options={tachoFormatOptions} />
          <input style={{display: 'none'}} id='tacho-upload' name='receipt-upload' type='file'
                 onChange={(ev) => onUpload({ev, csvFormat: csvFormatInput.value![0]})}/>
          <label htmlFor='tacho-upload' style={{
            border: '1px solid black',
            cursor: 'pointer',
            borderRadius: '6px',
            padding: '5px',
            margin: '0px'
          }}>Upload Tacho Data</label>
        </div>
      </OperatorAdminSettingsForm>
    </Dialog>
  )
}
