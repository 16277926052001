import {RoutePaths, SettingsRouteSubPaths, useRoutePathMatch} from "../../app-router";
import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient, SiteModel} from "../../client/c2-api-client";
import {OperatorAdminSettingsForm} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {useRoutingContext} from "../../hooks/routing-context";
import {SettingsSiteEditGroup} from "./settings-site-edit-group";
import {VerticalGroup} from "../../components/input/vertical-group/VerticalGroup";
import {Button} from "../../components/button/Button";
import {Edit} from "../../icons/edit";

type SiteDetails = Omit<SiteModel, 'id' | 'operatorId'>

export function SettingsSiteEdit() {
  const match = useRoutePathMatch(SettingsRouteSubPaths.USER_PROFILES_SITES_EDIT)
  const siteId = match?.params.siteId
  const routingContext = useRoutingContext()
  const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const [siteDetails, setSiteDetails] = useState<SiteDetails>({name: '', address: '', postCode: '', qrCode: ''})
  const [rateCardsNumber, setRateCardsNumber] = useState<number>()
  const [invoicingEnabled, setInvoicingEnabled] = useState<boolean>()
  const name = useInput(siteDetails.name, [Validators.required])
  const address = useInput(siteDetails.address, [Validators.required])
  const postCode = useInput(siteDetails.postCode, [Validators.required])
  const siteForm = form([name, address, postCode])
  useEffect(() => {
    if (siteId) {
      c2ApiClient.getOperatorSites().then(sitesData => {
        const site = sitesData.sites.find(site => siteId === site.id)
        if (site) {
          setSiteDetails(site)
          name.setValue(site.name, true)
          address.setValue(site.address, true)
          postCode.setValue(site.postCode, true)
        }
      })
      c2ApiClient.getOperatorInvoiceConfig()
        .then(invoicingConfig => setInvoicingEnabled(invoicingConfig?.invoicingEnabled ?? false))
      c2ApiClient.getRateCards(siteId).then((rateCards) => setRateCardsNumber(rateCards.length))
    }
  }, [siteId, c2ApiClient])


  const handleSubmit = () => {
    if (!siteForm.valid) {
      return;
    }
    const save$ = siteId ?
      c2ApiClient.editSite({
        id: siteId,
        name: name.value!,
        address: address.value!,
        postCode: postCode.value!
      }) :
      c2ApiClient.createSite({
        name: name.value!,
        address: address.value!,
        postCode: postCode.value!
      })
    save$.then(() => routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_SITES.getPath()))
  }
  return (
    <OperatorAdminSettingsForm disableSubmit={!siteForm.valid} editCallback={handleSubmit}
                               title={siteId ? "Edit Site" : "Add New Site"}
                               submitLabel="Save Site"
                               cancelRoute={SettingsRouteSubPaths.USER_PROFILES_SITES.getPath()}>
      <div className='d-flex' style={{gap: '10px'}}>
        <SettingsSiteEditGroup name={name} address={address} postCode={postCode}/>
        <VerticalGroup>
          <div className='flex-fill d-flex flex-column justify-content-between'>
            <img style={{cursor: 'pointer'}} onClick={() => window.open(siteDetails.qrCode, 'blank')}
                 src={siteDetails.qrCode}/>
            {siteId && invoicingEnabled && (
                <div>Rate Cards: {rateCardsNumber}{' '}
                   <span style={{cursor: 'pointer'}}
                        onClick={() => routingContext.navigate(SettingsRouteSubPaths.SITE_RATE_CARDS.getPath({siteId}))}><Edit/></span>
                </div>
            )}
          </div>
        </VerticalGroup>
      </div>
    </OperatorAdminSettingsForm>
  )
}
