import React from "react";
import './CheckboxInput.css';
import {InputHook} from "../../../hooks/input";

export interface CheckboxInputProps {
  label?: string;
  input: InputHook<boolean>;
}
export function CheckboxInput(props: CheckboxInputProps) {
  return (
    <label className="checkbox-container">
      <input type="checkbox" checked={props.input.value} onChange={ev => props.input.setValue(ev.target.checked)}/>
      <span className="checkbox-label-text">{props.label}</span>
    </label>
  );
}
