import {useEffect, useMemo} from "react";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import styles from "./review-deleted-drivers-modal.module.css";
import {DriverResponse} from "../../client/c2-api-client";
import {RowsFetcher, useTable} from "../../hooks/table";
import {ColumnConfig, Table} from "../table/table";
import {Button} from "../button/Button";


const reviewDeletedDriversConfig = (selectDriver: (id: string) => void): ColumnConfig<DriverResponse>[] => ([
    {
        key: 'firstName',
        header: <th>First Name</th>,
        cellFactory: (row) => {
            return <td><span style={{wordWrap: 'break-word'}}>{row.firstName}</span></td>
        }
    }, {
        key: 'lastName',
        header: <th>Last Name</th>,
        cellFactory: (row) => {
            return <td><span style={{wordWrap: 'break-word'}}>{row.lastName}</span></td>
        }
    },
    {
        key: 'email',
        header: <th>Email</th>,
        cellFactory: (row) => {
            return <td><span style={{wordWrap: 'break-word'}}>{row.user.email}</span></td>
        }
    },
    {
        key: 'username',
        header: <th>Username</th>,
        cellFactory: (row) => {
            return <td>{row.user.username}</td>
        }
    },
    {
        key: 'select',
        header: <th></th>,
        cellFactory: (row) => {
            return <td><Button size={'small'} colorScheme={'positive'} onClick={() => selectDriver(row.id)}>Select</Button></td>
        }
    }
])

export function ReviewDeletedDriversModal({
                                              deletedDriversMatch,
                                              handle,
                                              dismiss,
                                              open
                                          }: {
    deletedDriversMatch: DriverResponse[] | undefined,
    handle: (params?: { chosenId: string }) => void,
    dismiss: () => void,
    open: boolean
}) {

    const rowsFetcher: RowsFetcher<DriverResponse> = useMemo(() => async () => {
        console.log('reloading')
        return {
            data: deletedDriversMatch ?? [],
            page: 1,
            totalPages: 1,
            pageSize: deletedDriversMatch?.length ?? 0
        }
    }, [deletedDriversMatch?.length ?? 0])
    const matchesTable = useTable({
        rowsFetcher
    })

    const matchesTableConfig = useMemo(() => reviewDeletedDriversConfig((id) => handle({chosenId: id})), [deletedDriversMatch?.length ?? 0])

    useEffect(() => {
        matchesTable.reload()
    }, [deletedDriversMatch?.length ?? 0])
    return (
        <Dialog
            PaperProps={{classes: {root: styles.addTachoDialogRoot}}}
            disableScrollLock={true}
            open={open}>

            <DialogTitle>Deleted drivers with matching data</DialogTitle>
            <DialogContent classes={{root: styles.addTachoDialogRoot}}>
                <p style={{fontWeight: 'bold'}}>Choose if you want to merge the form data into one of deleted drivers that match input info, or create a new driver</p>
                <Table columnsConfig={matchesTableConfig} tableHook={matchesTable}/>
                <div style={{gap: 10}} className='d-flex flex-row justify-content-end'>
                    <Button onClick={() => handle()}>Create a new Driver</Button>
                    <Button colorScheme={'negative'} onClick={() => dismiss()}>Back To Form</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
