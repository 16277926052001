import {Dispatch, useCallback, useEffect, useState} from "react";
import {
    clientFactory,
    ContextApiClient, DriverC2ApiClient,
    OperatorAdminC2ApiClient,
    SessionInfo,
    SuperAdminC2ApiClient
} from "../client/c2-api-client";



export type Session = SessionInfo | undefined;

const getSessionFromStorage: () => Session = () => {
    const session = localStorage.getItem('sessionInfo')
    return session ? JSON.parse(session) : undefined
}
const saveSessionToStorage: (arg0: Session) => void = (session) => {
    if(!session) {
        localStorage.removeItem('sessionInfo');
        return;
    }
    localStorage.setItem('sessionInfo', JSON.stringify(session));
}

export function useSession(propsSession?: Session): [Session, Dispatch<Session>] {
    const sessionInfo = propsSession ? propsSession : getSessionFromStorage();
    const [session, setSession] = useState<Session>(sessionInfo)
    const sessionSetter = useCallback((s: Session) => {
        if(propsSession) return
        saveSessionToStorage(s)
        setSession(s)
    }, [propsSession?.sessionToken ?? '']);
    useEffect(() => {
        if(sessionInfo) {
            if(!sessionInfo.userId) {
                sessionSetter(undefined)
                return;
            }
            let client = clientFactory(sessionInfo)
            const nameGetter = getMyUsername(client, sessionInfo.userId)
            nameGetter().catch(e => sessionSetter(undefined))
        }
    }, [])
    return [session, sessionSetter]
}
const getMyUsername = (client: ContextApiClient, myId: string): (() => Promise<{id: string, name: string}>) => {
    const trySuper = client.as(SuperAdminC2ApiClient)
    if(trySuper) {
        return () => trySuper.getUserName(myId)
    }
    const tryAdmin  = client.as(OperatorAdminC2ApiClient)
    if(tryAdmin) {
        return () => tryAdmin.getUserName(myId)
    }
    const driver = client.asOrFail(DriverC2ApiClient)
    return () => driver.getUserName(myId)
}
