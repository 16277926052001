import styles from './driver-shell.module.css'
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import classnames from "classnames";
import {NotificationBell} from '../../icons/notification-bell';
import {RouteParam, RoutePath, RoutePaths, useRoutePathMatch} from "../../app-router";
import moment from "moment";
import {match} from "react-router-dom";
import {useSessionContext} from "../../hooks/session-context";
import {useRoutingContext} from "../../hooks/routing-context";
import {QrIcon} from "../../icons/qr-icon";
import {useApiClientContext} from "../../hooks/api-client-context";
import {DriverC2ApiClient} from "../../client/c2-api-client";
import {Edit} from "../../icons/edit";

export interface DriverShellProps {
  children: React.ReactNode;
}


function getDateFromPath(monthMatch: match<RouteParam<RoutePath<{ date: string }>>> | null, weekMatch: match<RouteParam<RoutePath<{ date: string }>>> | null, dayMatch: match<RouteParam<RoutePath<{ date: string }>>> | null, shiftMatch: match<RouteParam<RoutePath<{ date: string }>>> | null) {
  const match = [monthMatch, weekMatch, dayMatch, shiftMatch].filter(m => m !== null)
  if(!match.length) return moment()
  const m = match[0]
  return moment(m!.params.date)
}

export function DriverShell(props: DriverShellProps) {
  const session = useSessionContext()
  const apiClient = useApiClientContext().asOrFail(DriverC2ApiClient)
  const routingContext = useRoutingContext()
  const monthMatch = useRoutePathMatch(RoutePaths.MONTH_VIEW)
  const weekMatch = useRoutePathMatch(RoutePaths.WEEK_VIEW)
  const dayMatch = useRoutePathMatch(RoutePaths.DAY_VIEW)
  const shiftMatch = useRoutePathMatch(RoutePaths.SHIFT_RESOURCES)
  const date = getDateFromPath(monthMatch, weekMatch, dayMatch, shiftMatch)
  const [agencyEnabled, setAgencyEnabled] = useState(false)
  const title = date.format('MMMM YY')
  const filterActive: 'month' | 'week' | 'day' | undefined = monthMatch ? 'month' : (weekMatch ? 'week' :  (dayMatch ? 'day' : undefined))
  const goToDay = () => {
    routingContext.navigate(RoutePaths.DAY_VIEW.getPath({ date: date.format('YYYY-MM-DD')}))
  }
  const goToWeek = () => {
    routingContext.navigate(RoutePaths.WEEK_VIEW.getPath({ date: date.format('YYYY-MM-DD')}))
  }
  const goToMonth = () => {
    routingContext.navigate(RoutePaths.MONTH_VIEW.getPath({ date: date.format('YYYY-MM-DD')}))
  }
  const goToScanSiteCode = () => {
    routingContext.navigate(RoutePaths.SCAN_SITE_CODE.getPath())
  }
  const goToAgencyPage = () => {
    routingContext.navigate(RoutePaths.ENTER_AGENCY_DATA.getPath())
  }
  useEffect(() => {
    Promise.all([
      apiClient.getOperatorInvoiceConfig(),
      apiClient.getDriverAgencyData()
    ])
    .then(([config, agencyData]) => {
      const isAgencyEnabled = (config?.invoicingEnabled && config?.requireAgencyData) ?? false
      if(isAgencyEnabled && agencyData === undefined) {
        routingContext.navigate(RoutePaths.ENTER_AGENCY_DATA.getPath())
      }
      setAgencyEnabled(isAgencyEnabled)

    })
  }, [])
  return (
    <>
      <div className={classNames('d-flex align-items-center', styles.headerRow)}>
        <div className={classnames('d-flex justify-content-start', styles.headerGroup)}>
          <div className={classnames('d-flex text-200', styles.timeFilterContainer)}>
            <div onClick={() => goToMonth()} className={classnames(styles.timeFilterLeft, filterActive === 'month' ? styles.activeTimeFilter : '')}>Month</div>
            <div onClick={() => goToWeek()} className={classnames(styles.timeFilterCenter, filterActive === 'week' ? styles.activeTimeFilter : '')}>Week</div>
            <div onClick={() => goToDay()} className={classnames(styles.timeFilterRight, filterActive === 'day' ? styles.activeTimeFilter : '')}>Day</div>
          </div>
        </div>
        <div className={classnames(styles.headerGroup, 'd-flex justify-content-center')}>
          {title}
        </div>
        <div className={classnames('d-flex justify-content-end', styles.headerGroup)}>
          <span style={{cursor: 'pointer', width: '30px', height: '30px'}} onClick={goToScanSiteCode}><QrIcon /></span>
          {agencyEnabled ? (
              <div style={{cursor: 'pointer', width: '30px', height: '30px'}} onClick={goToAgencyPage}><Edit /></div>
          ) : null}

          <div onClick={() => session?.clearSession()} style={{cursor: 'pointer'}}><NotificationBell /></div>
        </div>
      </div>
      <div className={classNames('d-flex flex-fill align-items-stretch')}>
        {props.children}
      </div>
    </>
  )
}
