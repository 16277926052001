import * as React from "react"

export function WorkWeek({active = false}: {active?: boolean}) {
  return (
  <svg width={19} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity={0.33}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.077.462v.923h8.308V.462a.462.462 0 0 1 .923 0v.923h1.384a2.77 2.77 0 0 1 2.77 2.769V15.23A2.77 2.77 0 0 1 15.692 18H2.77A2.77 2.77 0 0 1 0 15.23V4.155a2.77 2.77 0 0 1 2.77-2.77h1.384V.463a.462.462 0 1 1 .923 0Zm8.308 2.769a.462.462 0 0 0 .923 0v-.923h1.384c1.02 0 1.846.826 1.846 1.846v3.23H.924v-3.23c0-1.02.827-1.846 1.846-1.846h1.385v.923a.462.462 0 1 0 .923 0v-.923h8.308v.923Zm-12.462 12c0 1.02.827 1.846 1.846 1.846h12.923c1.02 0 1.846-.827 1.846-1.846V8.308H.924v6.923Z"
      fill={active ? '#18C671' : '#000'}
    />
  </svg>
)}
