import {PhoneNumber} from "../../components/input/phone-number/phone-number";
import {VerticalGroup} from "../../components/input/vertical-group/VerticalGroup";
import {InputHook} from "../../hooks/input";
import {ReactNode} from "react";
import {TextFormField} from "../../components/input/composite/text-form-field/text-form-field";
import {CheckboxInput} from "../../components/input/checkbox/CheckboxInput";

export interface SettingsProfileEditGroupProps {
  firstName: InputHook<string>
  lastName: InputHook<string>
  email: InputHook<string>
  emailDisabled: boolean
  phone: InputHook<string>
  username?: InputHook<string>,
  sendInvoiceEmail?: InputHook<boolean>,
  children?: ReactNode
}

export function SettingsProfileEditGroup({children, email, username, emailDisabled, firstName, lastName, phone, sendInvoiceEmail}: SettingsProfileEditGroupProps) {
  return (
    <VerticalGroup>
      <TextFormField input={firstName} name={'firstName'} label={'First Name'} />
      <TextFormField input={lastName} name={'lastName'} label={'Last Name'} />
      <TextFormField input={email} name={'email'} label={'Email'} disabled={emailDisabled} />
      {username && <TextFormField input={username} name={'username'} label={'Username'} disabled={true}/>}
      <PhoneNumber input={phone}/>
      {sendInvoiceEmail && <CheckboxInput input={sendInvoiceEmail} label={'Send Invoice Emails to Admin'}/>}
      {children}
    </VerticalGroup>
  )
}
