import * as React from "react"

export function EyeIcon({active}: {active?: boolean}) {
  return (
  <svg width={16} height={9} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.856 7.273c-1.94 0-3.52-1.29-3.52-2.878 0-1.589 1.58-2.88 3.52-2.88s3.52 1.291 3.52 2.88c0 1.588-1.58 2.878-3.52 2.878ZM15.9 4.115C13.816 1.46 11.008 0 8 0S2.184 1.461.1 4.115a.44.44 0 0 0 0 .56C2.184 7.327 4.992 8.788 8 8.788s5.813-1.461 7.9-4.115a.44.44 0 0 0 0-.56Z"
      fill={(active ?? false) ? "#FFFFFF" : "#008553"}
    />
  </svg>
)}
