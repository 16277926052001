import {Button} from "../button/Button";
import {StyledLink} from "../link/styled-link/StyledLink";
import React from "react";
import './reset-password-success.css';
import envelope from '../../icons/envelope.svg';
import {Heading} from "../heading/Heading";
import { Link } from "react-router-dom";
import {RoutePaths} from "../../app-router";

export interface ResetPasswordSuccessProps {
  email: string;
}
export function ResetPasswordSuccess(props: ResetPasswordSuccessProps) {
  const atIndex = props.email.indexOf('@');
  const maskedEmail = atIndex >= 3 ? '***' + props.email.substring(3) : '***' + props.email.substring(atIndex);
  return (
    <div
      className="success-container d-flex flex-column">
      <div className="d-flex justify-content-center">
        <img src={envelope}/>
      </div>
      <div className="d-flex flex-column text-container">
        <Heading className="justify-content-center">
          <span>Check Your email</span>
        </Heading>
        <p className="text-center">
          We've sent password reset instructions to<br/>
          <b>{maskedEmail}</b>
        </p>
        <div className="resend-email-container d-flex justify-content-center">
          <StyledLink href={'http://www.google.com'}>
            Resend email
          </StyledLink>
        </div>
      </div>
      <Link to={RoutePaths.LOGIN.getPath()} component={(linkProps) => {
        return (
          <Button size='large' colorScheme="positive" shape='rounded' onClick={linkProps.navigate}>
            Return to login
          </Button>
        );
      }} />

    </div>
  );
}
