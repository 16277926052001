import './PublicPageWrapper.css'
import React from "react";
import {StyledLink} from "../link/styled-link/StyledLink";

export interface PublicPageWrapperProps {
  children: React.ReactNode;
}

export function PublicPageWrapper(props: PublicPageWrapperProps) {

  return (
    <>
      <section className="section">
        <div className="box">
          {props.children}
        </div>
      </section>
      <footer className="login-footer small-text">
        <StyledLink href="" variant="secondary">
          Privacy Policy
        </StyledLink>
        &bull;
        <StyledLink href="" variant="secondary">
          Terms & Conditions
        </StyledLink>
      </footer>
    </>
  );
}
