import {RoundModel, ShiftStatus} from "../../client/c2-api-client";
import {ColumnConfig} from "../../components/table/table";
import styles from "./drivers-planning.module.css";
import {SlotStatus, statusBackgrounds} from "../../services/status-backgrounds";
import {Moment} from "moment";
import {Ref, useRef, useState} from "react";
import classNames from "classnames";
import {timeService} from "../../services/time-service";
import Tooltip from "@mui/material/Tooltip";
import {VerticalDotsIcon} from "../../icons/VerticalDots";
import Popover from "@mui/material/Popover";
import {DriverWithRoundsAndSlots, statusTooltip} from "./driver-planning-table-types";
import {SortIndicator} from "../../components/table/column-header-components";
import {TableHook} from "../../hooks/table";


export function DriverDateHeader({
                                     date,
                                     table,
    tableType,
                                     compare,
                                     refCallback
                                 }: {
    date: Moment,
    table: TableHook<any>,
    tableType: 'Shifts' | 'Rounds',
    compare: number,
    refCallback?: Ref<HTMLTableHeaderCellElement>
}) {
    const isRounds = tableType === 'Rounds'
    const colorStyles = {
        backgroundColor: compare === 0 ? 'rgba(52, 193, 141, 1)' : 'auto',
        color: compare === 0 ? 'white' : '',
        cursor: isRounds ? 'pointer' : ''
    }
    const sortDate = `rounds_${timeService.formatDate(date)}`
    return (
        <th className={classNames(styles.dateHeader, 'text-200')} onClick={() => isRounds && table.setSort(sortDate)} style={colorStyles} ref={refCallback}>
            <div className='d-flex flex-column align-items-center'>
                <div style={{color: compare === 0 ? 'white' : '#5C5C5C'}}>{date.format('DD.MM')}</div>
                <div>{date.format('ddd')} <SortIndicator wantedSort={sortDate} currentSort={table.currentSort} currentAscending={table.currentAscending} /></div>
            </div>
        </th>
    )
}

function DriverShiftSentCell({
                                 time,
                                 todayCompare,
                                 status,
                                 shiftHandler
                             }: { time: string, todayCompare: number, status: ShiftStatus, shiftHandler: () => void }) {
    const canEdit = todayCompare >= 0 && (status === 'confirmed' || status === 'not_confirmed')
    const divStyle = {
        backgroundColor: statusBackgrounds[status], height: '100%',
        cursor: canEdit ? 'pointer' : 'auto'
    }
    return (
        <div className={styles.tableCell} style={{height: '55px',}}>
            <Tooltip title={statusTooltip[status]} placement='top'>
                <div className='d-flex justify-content-center align-items-center' style={divStyle}
                     onClick={() => canEdit && shiftHandler()}>
                    {time}
                </div>
            </Tooltip>
        </div>
    )
}

function DriverRoundCell({
                             round,
                             todayCompare
                         }: { round: string | null, todayCompare: number }) {
    const divStyle = {
        backgroundColor: round ? statusBackgrounds['available'] : statusBackgrounds['not_confirmed'], height: '100%',
        border: todayCompare === 0 ? '1px solid rgba(52, 193, 141, 0.33)' : '',
        padding: '2px 5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: ''
    }
    return (
        <div className={styles.tableCell} style={{height: '55px'}}>
            <Tooltip title={round ? round : 'No Round Assigned'} placement='top'>
                <div className='d-flex justify-content-center align-items-center' style={divStyle}>
                    {round ? round : 'N/A'}
                </div>
            </Tooltip>
        </div>
    )
}

function DriverAvailableCell({
                                 time,
                                 compareToToday,
                                 addHandler
                             }: { time: string, compareToToday: number, addHandler: () => void }) {
    const divStyle = {
        backgroundColor: statusBackgrounds['available'], height: '100%',
        border: compareToToday === 0 ? '1px solid rgba(52, 193, 141, 0.33)' : '',
        cursor: compareToToday >= 0 ? 'pointer' : ''
    }
    const clickHandler = () => {
        if (compareToToday < 0) return
        addHandler()
    }
    return (
        <div className={styles.tableCell} style={{height: '55px'}}>
            <div className='d-flex justify-content-center align-items-center' style={divStyle}
                 onClick={clickHandler}>
                {time}
            </div>
        </div>
    )

}

type AbsentSlotStatus = Extract<SlotStatus, 'holiday' | 'training' | 'sick' | 'induction' | 'unavailable'>

function isAbsentSlotStatus(s: SlotStatus): s is AbsentSlotStatus {
    return ['holiday', 'training', 'induction', 'unavailable', 'sick'].includes(s)
}

const statusLetter: Record<AbsentSlotStatus, string> = {
    holiday: 'H',
    sick: 'S',
    induction: 'I',
    training: 'T',
    unavailable: 'U'
}

function DriverAbsentCell({isToday, isRotaWorkingDay, editHandler, status}: {
    status: AbsentSlotStatus,
    isRotaWorkingDay: boolean,
    isToday: boolean,
    editHandler: () => void
}) {
    const divStyle = {
        backgroundColor: statusBackgrounds[status], height: '100%',
        color: 'white',
        border: isToday ? '1px solid rgba(52, 193, 141, 0.33)' : '',
        cursor: 'pointer'
    }
    return (
        <div className={styles.tableCell} style={{height: '55px'}}>
            <Tooltip title={(!isRotaWorkingDay ? 'Non Working ' : '') + statusTooltip[status]} placement='top'>
                <div className='d-flex justify-content-center align-items-center'
                     onClick={editHandler}
                     style={divStyle}>
                    {(!isRotaWorkingDay ? 'NW' : '') + statusLetter[status]}
                </div>
            </Tooltip>
        </div>
    )
}

function DriverOffCell({
                           enableAdd,
                           compareToToday,
                           addHandler
                       }: { enableAdd: boolean, compareToToday: number, addHandler: () => void }) {
    const canAdd = enableAdd && compareToToday >= 0
    const divStyle = {
        backgroundColor: compareToToday !== 0 ? 'rgba(243, 243, 243, 1)' : '', height: '100%',
        border: compareToToday === 0 ? '1px solid rgba(52, 193, 141, 0.33)' : '',
        cursor: canAdd ? 'pointer' : ''
    }
    const clickHandler = () => {
        if (canAdd) {
            addHandler()

        }
    }
    return (
        <div className={styles.tableCell} style={{height: '55px'}}>
            <div className='d-flex justify-content-center align-items-center' style={divStyle}
                 onClick={clickHandler}>
                {canAdd ? '+' : ''}
            </div>
        </div>
    )
}

// sve ovo sto dobijas u prop-ovima prebaci na row propertije, da radi rerender kako treba
export function DriverDateCell({
                                   row,
                                   date,
                                   compare,
                                   addHandler,
                                   editHandler,
                                   shiftHandler,
                                   tableType,
                                   rounds
                               }: {
    row: DriverWithRoundsAndSlots, date: Moment, compare: number, addHandler: () => void,
    editHandler: (driverId: string, type: 'sick' | 'holiday' | 'training' | 'induction' | 'unavailable', date: string) => void,
    shiftHandler: (shiftId: string, startTime: string, endTime: string) => void,
    tableType: string,
    rounds: RoundModel[]
}) {
    const dateStr = timeService.formatDate(date);
    const statuses = row.status[dateStr]
    const roundAssignment = row.roundAssignments.get(dateStr) || null;
    const roundObj = roundAssignment ? rounds.find(r => r.id === roundAssignment) : null;
    const roundName = roundObj ? roundObj.name : null
    const isShowRounds = tableType === 'Rounds'

    if (!statuses) return null
    return <td className='text-100'
               style={{backgroundColor: compare === 0 ? 'rgba(52, 193, 141, 0.11)' : 'auto', verticalAlign: 'top'}}>
        <div className='d-flex flex-column justify-content-start'>

            {statuses.filter((s, i) => !isShowRounds || i === 0).map((status, i) => {
                const slotType = status.type
                if (isAbsentSlotStatus(slotType)) {
                    return <DriverAbsentCell isRotaWorkingDay={status.isRotaWorkingDay} key={i} status={slotType} isToday={compare === 0}
                                             editHandler={() => editHandler(row.id, slotType, dateStr)}/>
                }
                if (isShowRounds) {
                    if (slotType === 'off' && !roundName) {
                        return <DriverOffCell key={i} enableAdd={!isShowRounds} addHandler={addHandler}
                                              compareToToday={compare}/>
                    }
                    return <DriverRoundCell key={i} round={roundName} todayCompare={compare}/>
                }
                if (slotType === 'off') {
                    return <DriverOffCell key={i} enableAdd={!isShowRounds} addHandler={addHandler}
                                          compareToToday={compare}/>
                }
                if (slotType === 'available') {
                    return <DriverAvailableCell key={i} addHandler={addHandler} time={status.startTime}
                                                compareToToday={compare}/>
                } else {
                    return <DriverShiftSentCell key={i} time={status.startTime} todayCompare={compare} status={slotType}
                                                shiftHandler={() => shiftHandler(status.shiftId!, status.startTime, status.endTime!)}/>
                }
            })}
        </div>
    </td>
}

export function ColGroups(tableConfig: ColumnConfig<DriverWithRoundsAndSlots>[], datesLength: number) {
    const others = tableConfig.length - datesLength - 2
    console.log('col groups', tableConfig.length, datesLength)
    const otherWidth = Math.round(30 / others)
    const datesWidth = Math.round(58 / datesLength)
    return (
        <colgroup>
            <col style={{width: '10%'}}/>
            {new Array(others).fill(1).map((a, i) => <col key={i} style={{width: `${otherWidth}%`}}/>)}
            {new Array(datesLength).fill(1).map((a, i) => <col key={i} style={{width: `${datesWidth}%`}}/>)}
            <col style={{width: '2%'}}/>
        </colgroup>

    )
}

type EditAction = 'sick' | 'holiday' | 'training' | 'induction' | 'unavailable'

export function EditActions({
                                addSlot,
                                assignRound
                            }: {
    addSlot: (type: EditAction) => void, assignRound
        :
        () => void
}) {
    const [open, setOpen] = useState(false)
    const dotsRef = useRef(null)
    const onAddSlot = (type: EditAction) => {
        setOpen(false)
        addSlot(type)
    }
    const onAssignRound = () => {
        setOpen(false)
        assignRound()
    }
    return (
        <>
            <div style={{cursor: 'pointer'}} onClick={() => setOpen(true)} ref={dotsRef}><VerticalDotsIcon/></div>
            <Popover open={open} anchorEl={dotsRef.current as unknown as Element} onClose={() => setOpen(false)}
                     disableScrollLock={true}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}>
                <div className={styles.editActionsContainer}>
                    <div className={styles.editAction} onClick={() => onAddSlot('sick')}>Add Sick Leave</div>
                    <div className={styles.editAction} onClick={() => onAddSlot('holiday')}>Add Holiday</div>
                    <div className={styles.editAction} onClick={() => onAddSlot('training')}>Add Training Day</div>
                    <div className={styles.editAction} onClick={() => onAddSlot('induction')}>Add Induction Day</div>
                    <div className={styles.editAction} onClick={() => onAddSlot('unavailable')}>Add Unavailable Day
                    </div>
                    <div className={styles.editAction} onClick={onAssignRound}>Assign Round For Dates</div>
                </div>
            </Popover>
        </>
    )
}
