import styles from './Input.module.css'
import React, {KeyboardEventHandler, Ref} from "react";
import {InputHook} from "../../../hooks/input";
import classNames from "classnames";

type InputSize = 'small' | 'medium' | 'large'
type InputVariant = 'rounded' | 'semi-rounded'
type InputLayout = 'normal' | 'reverse'

export interface InputProps {
    name: string;
    id?: string;
    label?: string;
    autoComplete?: string;
    placeholder?: string;
    disabled?: boolean;
    inputHook: InputHook<string>
    readonly?: boolean,
    type?: 'text' | 'email' | 'password';
    size?: InputSize;
    variant?: InputVariant;
    children?: React.ReactNode;
    layout?: InputLayout;
    onClick?: () => void,
    onSubmit?: () => void
}

const defaultProps: Required<Pick<InputProps, 'type' | 'variant' | 'size' | 'layout' | 'readonly'>> = {
    type: 'text',
    variant: 'semi-rounded',
    size: 'medium',
    layout: 'normal',
    readonly: false
}
const sizeClass: Record<InputSize, string> = {
    'small': styles.inputSmall,
    'medium': styles.inputMedium,
    'large': styles.inputLarge,
}
const variantClass: Record<InputVariant, string> = {
    'rounded': styles.inputRounded,
    'semi-rounded': styles.inputSemiRounded,
}
const layoutClass: Record<InputLayout, string> = {
    normal: 'd-flex',
    reverse: 'd-flex flex-row-reverse'
}

function InputComp(props: InputProps, ref: Ref<HTMLInputElement>) {
    const propsWithDefaults = {...defaultProps, ...props}
    const {
        autoComplete,
        children,
        id,
        disabled = false,
        inputHook,
        label,
        layout,
        name,
        placeholder,
        size,
        type,
        variant,
        readonly,
        onClick,
        onSubmit
    } = propsWithDefaults
    const showError = !inputHook.valid && !inputHook.pristine;
    const inputClass = classNames({
        [styles.inputBorderError]: showError,
        [styles.withLabel]: !!label,
        [styles.inputDisabled]: disabled,
    }, variantClass[variant], sizeClass[size], layoutClass[layout])
    const onKeyDown: KeyboardEventHandler = (ev) => {
        if(ev.code === 'Enter') {
            onSubmit?.()
        }
    }
    return (
        <div className={classNames(styles.inputContainer, inputClass)}>
            <input
                // className={}
                onClick={() => onClick && onClick()}
                type={type}
                name={name}
                id={id}
                disabled={disabled || readonly}
                autoComplete={autoComplete}
                placeholder={placeholder}
                value={inputHook.value}
                onChange={ev => inputHook.setValue(ev.target.value)}
                onKeyDown={onKeyDown}
                ref={ref}
            />
            {!!label && <span className={classNames(styles.label, 'small-text')}>{label}</span>}
            {children}
            {false && showError && <div className={styles.errorInputContainer}>
        <span className={styles.loginErrorMessage}>
                {showError && 'Invalid Email'}
              </span>

            </div>}
        </div>
    );
}

export const Input = React.forwardRef(InputComp)
