import {useRef, useState} from "react";
import {ArrowDownIcon} from "../../icons/ArrowDown";
import {Menu, MenuItem} from "@mui/material";
import styles from './dropdown-filter-entry.module.css'

export function DropdownFilterEntry<T>({
                                         filter,
                                         value,
                                         options,
                                         onChange,
                                       }: { filter: string, value: T | undefined, options: { id: T | undefined, name: string }[], onChange: (opt: T | undefined) => void }) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)
  const setFilter = (val: T | undefined) => {
    setOpen(false)
    onChange(val)
  }
  const label = options.filter(opt => opt.id === value)[0]!.name
  return (
    <div className={styles.filterEntry} ref={ref}>
      <div style={{cursor: 'pointer', paddingLeft: '5px'}} onClick={() => setOpen(true)}>
        <ArrowDownIcon/> <span>{filter}: {label}</span></div>
      <Menu open={open} onClose={() => setOpen(false)} anchorEl={ref.current as Element}
            disableScrollLock={true}>
        {options.map(opt => (
          <MenuItem key={opt.name} onClick={() => setFilter(opt.id)}>{opt.name}</MenuItem>
        ))}
      </Menu>
    </div>
  )
}
