import * as React from "react"
const filledPath = 'M15.489 3.675A1.6 1.6 0 0 0 16 2.502V1.6A1.6 1.6 0 0 0 14.4 0H1.6A1.6 1.6 0 0 0 0 1.6v.902c0 .445.185.87.511 1.173L5.6 8.4v6.47a.8.8 0 0 0 1.066.755l3.2-1.126a.8.8 0 0 0 .534-.755V8.4l5.089-4.725Z'
const emptyPath = 'M6.4 8.4v6.47l3.2-1.125V8.4a.8.8 0 0 1 .256-.586l5.088-4.725a.8.8 0 0 0 .256-.587V1.6a.8.8 0 0 0-.8-.8H1.6a.8.8 0 0 0-.8.8v.902a.8.8 0 0 0 .256.587l5.088 4.725A.8.8 0 0 1 6.4 8.4Zm9.089-4.725A1.6 1.6 0 0 0 16 2.502V1.6A1.6 1.6 0 0 0 14.4 0H1.6A1.6 1.6 0 0 0 0 1.6v.902c0 .445.185.87.511 1.173L5.6 8.4v6.47a.8.8 0 0 0 1.066.755l3.2-1.126a.8.8 0 0 0 .534-.755V8.4l5.089-4.725Z'
export function FilterIcon({filled = true}: {filled?: boolean}) {
  const path = filled ? filledPath : emptyPath
  return (<svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
  clipRule="evenodd"
      d={path}
      fill="#18C671"
    />
  </svg>)
}
