import {SettingsRouteSubPaths, useRoutePathMatch} from "../../app-router";
import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient} from "../../client/c2-api-client";
import {UserProfile} from "../../types/user-profile";
import {
    OperatorAdminSettingsForm
} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {useRoutingContext} from "../../hooks/routing-context";
import {Button} from "../../components/button/Button";
import {Dialog} from "@mui/material";
import styles from "./settings-profile-edit.module.css";
import {SettingsProfileEditGroup} from "./settings-profile-edit-group";
import {UsernameModal} from "../../components/username-modal/username-modal";

type UserDetails = Omit<UserProfile, 'id'>

export function SettingsProfileEdit() {
    const match = useRoutePathMatch(SettingsRouteSubPaths.USER_PROFILES_USERS_EDIT)
    const adminId = match?.params.adminId
    const routingContext = useRoutingContext()
    const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
    const [showUsernameModal, setShowUsernameModal] = useState(false)
    const [user, setUser] = useState<UserDetails>({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        sendInvoiceEmail: false,
        phone: '',
        active: true
    })
    const [showResetModal, setShowResetModal] = useState(false)
    const firstName = useInput(user.firstName, [Validators.required])
    const lastName = useInput(user.lastName, [Validators.required])
    const email = useInput(user.email, [Validators.required, Validators.email])
    const username = useInput(user.username, [Validators.required])
    const sendInvoiceEmail = useInput(user.sendInvoiceEmail, [Validators.required])
    const phone = useInput('', [Validators.required])
    const adminForm = form([firstName, lastName, email, phone])
    useEffect(() => {
        if (adminId) {
            c2ApiClient.getOperatorAdmins().then(adminData => {
                const admin = adminData.data.find(user => adminId === user.id)
                if (admin) {
                    setUser(admin)
                    firstName.setValue(admin.firstName, true)
                    lastName.setValue(admin.lastName, true)
                    email.setValue(admin.email, true)
                    username.setValue(admin.username, true)
                    sendInvoiceEmail.setValue(admin.sendInvoiceEmail, true)
                    phone.setValue(admin.phone)
                }
            })
        }
    }, [adminId, c2ApiClient])

    const handleSubmit = () => {
        if (!adminForm.valid) {
            return;
        }
        if (adminId) return handleSave()
        setShowUsernameModal(true)
    }

    const handleSave = (username?: string | undefined) => {

        const save$ = adminId ?
            c2ApiClient.editAdmin({
                id: adminId,
                email: email.value!,
                firstName: firstName.value!,
                lastName: lastName.value!,
                phone: phone.value!,
                sendInvoiceEmail: sendInvoiceEmail.value!,
                active: user.active
            }) :
            c2ApiClient.createAdmin({
                email: email.value!,
                firstName: firstName.value!,
                lastName: lastName.value!,
                phone: phone.value!,
                username: username!,
                sendInvoiceEmail: sendInvoiceEmail.value!,
                active: true
            })
        save$.then(() => routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_USERS.getPath()))
    }
    const changeUserActivity = () => {
        c2ApiClient.editAdmin({
            id: adminId!,
            ...user,
            active: !user.active
        }).then(() => routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_USERS.getPath()))
    }

    const resetPassword = () => {
        c2ApiClient.resetOperatorPassword(adminId!)
            .then(() => {
                setShowResetModal(true)
            })
    }

    const handleUsernameModal = (params?: { username: string; }) => {
        params && handleSave(params.username)
        setShowUsernameModal(false)
    }
    return (
        <OperatorAdminSettingsForm disableSubmit={!adminForm.valid} editCallback={handleSubmit}
                                   title={`${adminId ? 'Edit' : 'Add'} User/Admin`}
                                   submitLabel="Save User"
                                   cancelRoute={SettingsRouteSubPaths.USER_PROFILES_USERS.getPath()}>
            <UsernameModal firstName={firstName.value!} lastName={lastName.value!} handle={handleUsernameModal}
                           open={showUsernameModal}/>
            <Dialog
                PaperProps={{classes: {root: styles.resetShiftDialog}}}
                disableScrollLock={true}
                open={showResetModal}>
                <>
                    <div>Reset Password Email has been sent</div>
                    <Button onClick={() => setShowResetModal(false)}>Close</Button>
                </>
            </Dialog>
            <SettingsProfileEditGroup firstName={firstName} lastName={lastName} email={email} emailDisabled={!!adminId}
                                      sendInvoiceEmail={sendInvoiceEmail}
                                      phone={phone} username={adminId ? username : undefined}>
                {adminId && <Button colorScheme={user.active ? 'negative' : 'positive'}
                                    onClick={changeUserActivity}>{user.active ? 'Deactivate' : 'Activate'}</Button>}
                {adminId && <Button colorScheme='neutral' onClick={resetPassword}>Reset Password</Button>}
            </SettingsProfileEditGroup>
        </OperatorAdminSettingsForm>
    )
}
