export function UserProfiles({active = true}: {active: boolean}) {
  return (
    <svg
      width={17}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? 1 : 0.33} fillRule="evenodd" clipRule="evenodd" fill={active ? "var(--new-brand-1)" : "#333"}>
        <path d="M1 18v1a.5.5 0 0 1-1 0v-1a5.5 5.5 0 0 1 5.5-5.5h6A5.5 5.5 0 0 1 17 18v1a.5.5 0 0 1-1 0v-1a4.5 4.5 0 0 0-4.5-4.5h-6A4.5 4.5 0 0 0 1 18ZM12.5 5a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm1 0a5 5 0 1 0-10 0 5 5 0 0 0 10 0Z" />
      </g>
    </svg>
  )
}
