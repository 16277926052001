import classnames from "classnames";
import styles from '../day-lane/day-lane.module.css'

const hours = Array(24).fill(0).map((a, i) => i)

export function DaySideBar() {
  return (
    <>
      {hours.map(h => <div key={h}
                           className={classnames(styles.hourMark, 'text-100')}>{h == 0 ? '' : h > 12 ? `${h - 12} PM` : `${h} AM`}</div>)}
    </>
  )
}
