import {OperatorAdminSettingsNavigation} from "../operator-admin-settings-navigation/operator-admin-settings-navigation";
import React from "react";
import {SettingsRouteSubPaths, SettingsSubRoutesConfig} from "../../../app-router";
import { Route, Switch} from "react-router-dom";
import {useRoutingContext} from "../../../hooks/routing-context";
import {RoutingRedirect} from "../../routing/redirect/RoutingRedirect";

export function OperatorSettingsWrapper() {
  const routingContext = useRoutingContext()
  return (
    <div className='d-flex fill-height align-items-stretch'>
      <OperatorAdminSettingsNavigation></OperatorAdminSettingsNavigation>
      <Switch>
        {Object.values(SettingsSubRoutesConfig).map((config) => {
          return <Route key={config.pathFormat} path={routingContext.getPath(config.pathFormat)}
                        exact={config.exact}>{config.component}</Route>
        })}
        <Route render={() => <RoutingRedirect to={SettingsRouteSubPaths.USER_PROFILES_USERS.pathFormat}/>}/>
      </Switch>
    </div>)
}
