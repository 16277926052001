import {ReactNode} from "react";
import './super-admin-page-wrapper.css'
import classNames from "classnames";

export interface SuperAdminPageWrapperProps {
  fillWidth?: boolean
  children: ReactNode
}

export function SuperAdminPageWrapper(props: SuperAdminPageWrapperProps) {
  return (
    <div className={classNames("page-wrapper", props.fillWidth && "page-wrapper-fill-width")}>
      {props.children}
    </div>
  )
}
