import {createContext, useContext} from "react";
export type RoutingContext = {getPath: (path: string) => string, navigate: (path: string) => void, replace: (path: string) => void}
export const routingContext = createContext<RoutingContext>({
  getPath: (s: string) => s,
  navigate: (s: string) => {},
  replace: (s: string) => {}
})
export function useRoutingContext() {
  return useContext(routingContext)
}
