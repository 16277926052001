import {Dialog} from "@mui/material";
import styles from "./add-tacho-modal.module.css";
import {
    OperatorAdminSettingsForm
} from "../operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {TachoRowResult} from "../../client/c2-api-client";

const statusToLabel: Record<"success" | "noDriverFound" | "noShiftFound" | "alreadyUploaded", string> = {
    noDriverFound: 'No Driver Found By Tacho Number',
    noShiftFound: 'No Shift Found For Working Date',
    success: 'Tacho Data Added To Shift',
    alreadyUploaded: 'Tacho Data Already Present'
}

const rowStatusToColor: Record<"success" | "noDriverFound" | "noShiftFound" | "alreadyUploaded", string> = {
    noDriverFound: 'red',
    noShiftFound: 'yellow',
    success: 'green',
    alreadyUploaded: 'yellow'
}

export function AddTachoModal({
                                  open,
                                  tachoUploadResults,
                                  onClose
                              }: { open: boolean, onClose: () => void, tachoUploadResults: TachoRowResult[] }) {

    return (
        <Dialog
            PaperProps={{classes: {root: styles.addTachoDialogRoot}}}
            disableScrollLock={true}
            open={open}>
            <OperatorAdminSettingsForm title='Tacho Upload Results' submitLabel='Close'
                                       disableSubmit={false}
                                       size={'large'}
                                       paddingTop={false}
                                       editCallback={() => onClose()}>
                <div className='d-flex flex-column' style={{gap: '10px', overflowY: 'scroll'}}>
                    {tachoUploadResults.map(resultRow =>
                        (<div className='d-flex flex-row' style={{gap: '5px', borderBottom: '1px solid black'}}>
                            <p className={styles.tachoResultLabel}>{resultRow.driverName}</p>
                            <p className={styles.tachoResultLabel}>{resultRow.tachoNumber}</p>
                            <p className={styles.tachoResultLabel}>{resultRow.workingDate}</p>
                            <p className={styles.tachoResultLabel}
                               style={{borderLeft: `1px solid ${rowStatusToColor[resultRow.status]}`}}>{statusToLabel[resultRow.status]}</p>
                        </div>)
                    )}
                    {!tachoUploadResults.length &&
                        <div className='d-flex flex-column justify-content-center'>No Rows In Upload File</div>}
                </div>
            </OperatorAdminSettingsForm>
        </Dialog>
    )
}
