import {AppRouter, RoutePaths, useRoutePathMatch} from "../../app-router";
import {RoutingRedirect} from "../../components/routing/redirect/RoutingRedirect";
import {RoutingContextProvider} from "../../components/routing/provider/routing-context-provider";
import {SessionAndClientProvider} from "../../components/session/provider/session-and-client-provider";
import {SessionInfo} from "../../client/c2-api-client";
import {useSessionContext} from "../../hooks/session-context";
import {HeaderPageWrapper} from "../../components/header-page-wrapper/HeaderPageWrapper";
import React from "react";


export function SuperAdminOperatorOverview() {
  const match = useRoutePathMatch(RoutePaths.SUPER_ADMIN_OPERATOR_OVERVIEW)
  const {operatorId} = match?.params ?? {}
  const sessionInfo = useSessionContext()

  if (!operatorId) {
    return <RoutingRedirect to={RoutePaths.HOME.getPath()}/>
  }
  const operatorSession: SessionInfo = {...sessionInfo.session!, operatorId, role: 'OPERATOR_ADMIN'}

  return (
    <RoutingContextProvider root={RoutePaths.SUPER_ADMIN_OPERATOR_OVERVIEW.getPath({operatorId})}>
      <SessionAndClientProvider session={operatorSession}>
        <HeaderPageWrapper>
          <AppRouter/>
        </HeaderPageWrapper>
      </SessionAndClientProvider>
    </RoutingContextProvider>
  )

}
