import classnames from "classnames";
import styles from "./day-header.module.css";
import {ArrowLeft} from "../../icons/arrow-left";
import {ArrowRight} from "../../icons/arrow-right";
import {Moment} from "moment";

export function DayHeader({date, dayBack, dayForward}: {date: Moment, dayBack: () => void, dayForward: () => void}) {
  return (
    <div className={classnames('d-flex text-100', styles.weekHeader)}>
      <div className={classnames(styles.sideBar, 'd-flex flex-column align-items-stretch')}>
        <div className={styles.arrowContainer} onClick={dayBack} style={{cursor: 'pointer'}}>
          <ArrowLeft/>
        </div>
        <div className={styles.arrowContainer} onClick={dayForward} style={{cursor: 'pointer'}}>
          <ArrowRight/>
        </div>
      </div>
      <div className={classnames('flex-fill d-flex text-500', styles.dayHeading)}>
        <p>{date.format('dddd DD')}</p>
      </div>
      <div style={{width: '40px'}}></div>
    </div>
  )
}
