import {form, InputHook, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useEffect} from "react";
import {RoutePaths, useParamMatch} from "../../app-router";
import {SettingsSiteEditGroup} from "../../page/settings-site-edit/settings-site-edit-group";
import {SettingsProfileEditGroup} from "../../page/settings-profile-edit/settings-profile-edit-group";
import {useApiClientContext} from "../../hooks/api-client-context";
import {SuperAdminC2ApiClient} from "../../client/c2-api-client";
import {useHistory} from "react-router-dom";
import moment, {Moment} from "moment";
import {timeService} from "../../services/time-service";
import {OperatorAdminSettingsForm} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {VerticalGroup} from "../../components/input/vertical-group/VerticalGroup";
import {CheckboxInput} from "../../components/input/checkbox/CheckboxInput";
import {TimeInput} from "../../components/input/time/time-input";
import {Labeled} from "../../components/input/labeled/Labeled";
import {Input} from "../../components/input/text/Input";

export interface CreateOperatorData {
  name: string,
  alias: string,
  adminEmail: string
  adminName: string
  adminPhone: string
  siteName: string
  siteAddress: string
  sitePostCode: string
}

export function WriteOperator() {

  const editOperatorMatch = useParamMatch(RoutePaths.EDIT_OPERATOR)

  const apiClient = useApiClientContext().asOrFail(SuperAdminC2ApiClient)
  const history = useHistory()
  const operatorId: string | undefined = editOperatorMatch?.params.operatorId
  const operatorNameInput = useInput('', [Validators.required])
  const operatorAliasInput = useInput('', [Validators.required])
  const invoicingEnabledInput = useInput(false, [Validators.required])
  const invoiceAddressInput = useInput('', [Validators.required])
  const invoiceEmailAddressInput = useInput('', [Validators.required, Validators.email])
  const invoiceTimeDistance = useInput<Moment>(moment().hours(0).minutes(15), [Validators.required])
  const invoiceRequireAgencyDataInput = useInput(false, [Validators.required])
  const adminEmailInput = useInput('', [Validators.required, Validators.email])
  const adminFirstNameInput = useInput('', [Validators.required])
  const adminLastNameInput = useInput('', [Validators.required])
  const adminPhoneInput = useInput('', [Validators.required])

  const siteNameInput = useInput('', [Validators.required])
  const siteAddressInput = useInput('', [Validators.required])
  const sitePostCodeInput = useInput('', [Validators.required])

  const bambooIntegrationEnabledInput = useInput(false, [Validators.required])
  const bambooApiUrlInput = useInput('', [Validators.required])
  const bambooApiTokenInput = useInput('', [Validators.required])

  const operatorFields = [operatorNameInput, operatorAliasInput]
  const adminFields = [adminEmailInput, adminFirstNameInput, adminLastNameInput, adminPhoneInput]
  const siteFields = [siteNameInput, siteAddressInput, sitePostCodeInput]
  const formFields = operatorId ? operatorFields : [...operatorFields, ...adminFields, ...siteFields]
  const operatorForm = form(formFields)

  useEffect(() => {
    if (operatorId) {
      Promise.all([
        apiClient.getOperatorDetails(operatorId),
        apiClient.getOperatorInvoiceConfig(operatorId),
        apiClient.getOperatorBambooConfig(operatorId)
      ]).then(([details, invoiceConfig, operatorBambooConfig]) => {
        operatorNameInput.setValue(details.name)
        operatorAliasInput.setValue(details.alias ?? '')
        console.log('invoice config', invoiceConfig)
        invoicingEnabledInput.setValue(invoiceConfig?.invoicingEnabled ?? false)
        invoiceEmailAddressInput.setValue(invoiceConfig?.invoiceOperatorEmail ?? '')
        invoiceAddressInput.setValue(invoiceConfig?.invoiceAddress ?? '')
        invoiceTimeDistance.setValue(timeService.parseTime(invoiceConfig?.qrScanShiftTimeDistance ?? '00:15'))
        invoiceRequireAgencyDataInput.setValue(invoiceConfig?.requireAgencyData ?? false)
        bambooIntegrationEnabledInput.setValue(operatorBambooConfig?.bambooIntegrationEnbaled ?? false)
        bambooApiUrlInput.setValue(operatorBambooConfig?.bambooApiUrl ?? '')
        bambooApiTokenInput.setValue(operatorBambooConfig?.bambooApiToken ?? '')
      })
    }
  }, [operatorId ?? ''])
  const handleNextClick = () => {
    const saveOperator$ = operatorId ?
      apiClient.editOperator({id: operatorId, name: operatorNameInput.value!, alias: operatorAliasInput.value!})
        .then(() => apiClient.setOperatorInvoiceConfig(operatorId, {
          invoicingEnabled: invoicingEnabledInput.value!,
          invoiceAddress: invoiceAddressInput.value!,
          invoiceOperatorEmail: invoiceEmailAddressInput.value!,
          qrScanShiftTimeDistance: timeService.formatTime(invoiceTimeDistance.value!),
          requireAgencyData: invoiceRequireAgencyDataInput.value!
        }))
        .then(() => apiClient.setOperatorBambooConfig(operatorId, {
          bambooIntegrationEnbaled: bambooIntegrationEnabledInput.value!,
          bambooApiUrl: bambooApiUrlInput.value!,
          bambooApiToken: bambooApiTokenInput.value!
        }))
      : apiClient.createOperator({
        operatorName: operatorNameInput.value!,
        operatorAlias: operatorAliasInput.value!,
        admin: {
          firstName: adminFirstNameInput.value!,
          lastName: adminLastNameInput.value!,
          email: adminEmailInput.value!,
          phone: adminPhoneInput.value!,
        },
        site: {
          name: siteNameInput.value!,
          address: siteAddressInput.value!,
          postCode: sitePostCodeInput.value!,
        }
      });
    saveOperator$.then(() => {
      history.push(RoutePaths.HOME.getPath())
    })

  }


  return (
    <OperatorAdminSettingsForm disableSubmit={!operatorForm.valid} editCallback={handleNextClick}
                               size={'large'}
                               title={`${operatorId ? 'Edit' : 'Create'} Operator`}
                               submitLabel="Save Operator"
                               cancelRoute={RoutePaths.HOME.getPath()}>

      <div className='d-flex flex-row justify-content-center' style={{gap: '20px'}}>
        <VerticalGroup>
          <TextField input={operatorNameInput} label='Operator Name'/>
          <TextField input={operatorAliasInput} label='Operator Alias'/>
          <CheckboxInput input={invoicingEnabledInput} label='Enable Invoicing'/>
          {invoicingEnabledInput.value && <TextField input={invoiceAddressInput} label='Invoicing Address'/>}
          {invoicingEnabledInput.value && <TextField input={invoiceEmailAddressInput} type={'email'} label='Operator Email Shown In Invoice'/>}
          {invoicingEnabledInput.value && <TimeInput input={invoiceTimeDistance} label='Shift Time Distance For QR Scan Shift Start'/>}
          {invoicingEnabledInput.value && <CheckboxInput input={invoiceRequireAgencyDataInput} label='Require Driver Agency Data'/>}
          <CheckboxInput input={bambooIntegrationEnabledInput} label='Bamboo Integration Enabled'/>
          {bambooIntegrationEnabledInput.value && <TextField input={bambooApiUrlInput} label='Bamboo API Domain (if bamboo URL is "https://MY_COMPANY.bamboohr.com", then domain is MY_COMPANY)'/>}
          {bambooIntegrationEnabledInput.value && <TextField input={bambooApiTokenInput} label='Bamboo API Token'/>}
        </VerticalGroup>
        {!operatorId &&
        <SettingsSiteEditGroup name={siteNameInput} address={siteAddressInput} postCode={sitePostCodeInput}/>}
        {!operatorId &&
        <SettingsProfileEditGroup firstName={adminFirstNameInput} lastName={adminLastNameInput} email={adminEmailInput}
                                  emailDisabled={false} phone={adminPhoneInput}/>}
      </div>
    </OperatorAdminSettingsForm>
  )
}


function TextField({input, type, label}: { input: InputHook<string>, type?: 'text' | 'email',  label: string }) {
  return <Labeled label={label}>
    <div>
      <Input name={label}  inputHook={input} type={type ?? 'text'}/>
    </div>
  </Labeled>
}
