import moment, {Duration} from "moment";
import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useEffect} from "react";
import {Dialog} from "@mui/material";
import styles from "./timesheets.module.css";
import {OperatorAdminSettingsForm} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {TimeInput} from "../../components/input/time/time-input";

export function AddOperatorTimeModal({
                                driverTime,
                                handle,
                                open
                              }: { driverTime: Duration | null, handle: (params?: { start: string }) => void, open: boolean }) {
  const startInput = useInput(moment(), [Validators.required])
  useEffect(() => {
    if (driverTime) {
      startInput.setValue(moment().hours(driverTime.hours()).minutes(driverTime.minutes()))
    }
  }, [driverTime?.asMilliseconds() ?? '-'])
  const shiftForm = form([startInput])
  return (
    <Dialog
      PaperProps={{classes: {root: styles.addShiftDialogRoot}}}
      disableScrollLock={true}
      open={open}>
      <OperatorAdminSettingsForm title='Shift Time' submitLabel='Set' cancelCallback={() => handle()}
                                 disableSubmit={!shiftForm.valid}
                                 paddingTop={false}
                                 editCallback={() => handle({
                                   start: startInput.value!.format('HH:mm')
                                 })}>
        <div className='d-flex flex-column' style={{gap: '20px'}}>
          <TimeInput input={startInput} label={'Operator Time'}/>
        </div>
      </OperatorAdminSettingsForm>
    </Dialog>
  )
}

