import {DriverFilters} from "../../client/c2-api-client";
import {TableFilterBar} from "../table-filter-bar/table-filter-bar";
import {useRef, useState} from "react";
import {ArrowDownIcon} from "../../icons/ArrowDown";
import {Menu, MenuItem} from "@mui/material";
import styles from './driver-table-type-switch.module.css'

export interface FilterConfig {
  label: string
  filterField: keyof DriverFilters
  options: {id: any, name: string, defaultValue?: boolean}[]
}

type DriversTableTypeSwitchProps = {
  type: string;
  changeType: (val: 'Shifts' | 'Rounds') => void;
};

export function DriversTableTypeSwitch({type, changeType}: DriversTableTypeSwitchProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)
  const setTable = (val: 'Shifts' | 'Rounds') => {
    setOpen(false)
    changeType(val)
  }
  return (
    <TableFilterBar>
      <div className='d-flex align-items-center justify-content-start'>

        <div className={styles.filterEntry} ref={ref}>
      <div style={{cursor: 'pointer', paddingLeft: '5px'}} onClick={() => setOpen(true)}>
        <ArrowDownIcon/> <span>Table Type: {type}</span></div>
      <Menu open={open} onClose={() => setOpen(false)} anchorEl={ref.current as Element}
            disableScrollLock={true}>
          <MenuItem key={'shift'} onClick={() => setTable('Shifts')}>{'Shifts'}</MenuItem>
          <MenuItem key={'rounds'} onClick={() => setTable('Rounds')}>{'Rounds'}</MenuItem>
      </Menu>
    </div>

      </div>
    </TableFilterBar>
  )
}