import {form, useInput} from "../../hooks/input";
import moment from "moment";
import {Validators} from "../../validators";
import {useEffect} from "react";
import {Dialog} from "@mui/material";
import styles from "./add-missing-shift-modal.module.css";
import {OperatorAdminSettingsForm} from "../operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {TimeInput} from "../input/time/time-input";
import {CheckboxInput} from "../input/checkbox/CheckboxInput";

export function AddMissingShiftModal({
                         startTime,
                         handle,
                         open
                       }: { startTime: string, handle: (params?: { start: string, end: string, breakTime: string, isOvernight: boolean }) => void, open: boolean }) {
  const startInput = useInput(moment(), [Validators.required])
  const endInput = useInput(moment(), [Validators.required])
  const breakInput = useInput(moment('00:00', 'HH:mm'), [Validators.required])
  const overnightInput = useInput(false, [Validators.required])
  useEffect(() => {
    startInput.setValue(moment(startTime, 'HH:mm'))
    endInput.setValue(moment(startTime, 'HH:mm').add(8, 'hours'))
  }, [startTime])
  const shiftForm = form([startInput, endInput])
  return (
    <Dialog
      PaperProps={{classes: {root: styles.addShiftDialogRoot}}}
      disableScrollLock={true}
      open={open}>
      <OperatorAdminSettingsForm title='Shift Time' submitLabel='Send' cancelCallback={() => handle()}
                                 disableSubmit={!shiftForm.valid}
                                 paddingTop={false}
                                 editCallback={() => handle({
                                   start: startInput.value!.format('HH:mm'),
                                   end: endInput.value!.format('HH:mm'),
                                   breakTime: breakInput.value!.format('HH:mm'),
                                   isOvernight: overnightInput.value!
                                 })}>
        <div className='d-flex flex-column' style={{gap: '20px'}}>
          <TimeInput input={startInput} label={'Start Time'}/>
          <TimeInput input={endInput} label={'End Time'}/>
          <TimeInput input={breakInput} label={'Break Time'}/>
          <CheckboxInput input={overnightInput} label={'Overnight'}/>
        </div>
      </OperatorAdminSettingsForm>
    </Dialog>
  )
}
