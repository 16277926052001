import styles from './settings-group-list.module.css'
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import classnames from "classnames";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient} from "../../client/c2-api-client";
import {
  OperatorAdminSettingsList
} from "../../components/operator-admin-settings/operator-admin-settings-list/operator-admin-settings-list";
import {
  OperatorAdminSettingsCard
} from "../../components/operator-admin-settings/operator-admin-settings-card/operator-admin-settings-card";
import {SettingsRouteSubPaths} from "../../app-router";
import {useRoutingContext} from "../../hooks/routing-context";
import {Dialog} from "@mui/material";
import {Button} from "../../components/button/Button";

export interface GroupModel {
  id: string,
  name: string
}
function GroupContainer(props: { group: GroupModel, editCallback: () => void, deleteCallback: () => void }) {
  const { name } = props.group
  return (
    <OperatorAdminSettingsCard editCallback={props.editCallback} deleteCallback={props.deleteCallback} >
      <div className={classNames(styles.userName, 'text-300')}>{name}</div>
    </OperatorAdminSettingsCard>
  )
}

export function SettingsGroupList() {
  const routingContext = useRoutingContext()
  const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const [groups, setGroups] = useState<GroupModel[]>([])
  const [selectedGroupId, setSelectedGroupId] = useState<string>()
  const selectedGroup = groups.find(g => g.id === selectedGroupId)
  const editGroup = (group: GroupModel) => {
    routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_GROUPS_EDIT.getPath({groupId: group.id}))
  }

  const deleteGroup = (group: GroupModel) => {
    setSelectedGroupId(group.id)
  }
  const onDeleteGroup = () => (selectedGroup ? c2ApiClient.deleteGroup(selectedGroup.id) : Promise.resolve()).then(() => c2ApiClient.getOperatorGroups().then(groups => setGroups(groups.groups)))
  const onDismiss = () => setSelectedGroupId(undefined)
  useEffect(() => {
    c2ApiClient.getOperatorGroups().then(result => setGroups(result.groups))
  }, [c2ApiClient])

  return (
    <OperatorAdminSettingsList title="Group List" addBtnLabel="Add Group"
                                    addBtnHandler={() => routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_GROUPS_ADD.getPath())}>
      <DeleteGroupDialog group={selectedGroup} onDelete={onDeleteGroup} onDismiss={onDismiss} />
      {groups.map(group => <GroupContainer key={group.id} group={group} deleteCallback={() => deleteGroup(group)} editCallback={() => editGroup(group)}/>)}
    </OperatorAdminSettingsList>)
}
function DeleteGroupDialog(props: {group?: GroupModel, onDelete: () => Promise<void>, onDismiss: () => void}) {
  return (
      <Dialog
          PaperProps={{classes: {root: styles.deleteRoundDialogRoot}}}
          disableScrollLock={true}
          onClose={props.onDismiss}
          open={!!props.group}>
        <div>
          <div className={classnames('text-500', styles.title)}>Are you sure you want to delete group: {props.group?.name}</div>
          <div className='d-flex flex-column' style={{gap: '20px'}}>
            <Button shape={"rounded"} colorScheme={"neutral"} onClick={props.onDelete}>Delete</Button>
            <Button shape={"rounded"} colorScheme={"negative"} onClick={props.onDismiss}>Cancel</Button>
          </div>
        </div>
      </Dialog>
  )
}