import React from "react";
import classNames from "classnames";
import styles from "./operator-admin-settings-card.module.css"
import {Edit} from "../../../icons/edit";
import {CloseIcon} from "../../../icons/close";

export interface OperatorAdminSettingsCardProps {
    editCallback: () => void
    deleteCallback?: () => void
    children: React.ReactNode
}

export function OperatorAdminSettingsCard({children, editCallback, deleteCallback}: OperatorAdminSettingsCardProps) {
    const showDelete = !!deleteCallback
    return (
        <div
            className={classNames('d-flex justify-content-between', styles.userContainer)}>
            <div>
                {children}
            </div>
            <div className='d-flex' style={{gap: '10px'}}>
                <div style={{cursor: 'pointer'}} onClick={editCallback}
                     className={classNames('d-flex align-items-center', styles.editBtnContainer)}>
                    <Edit/>
                </div>
                {showDelete && <div style={{cursor: 'pointer'}} onClick={deleteCallback}
                                    className={classNames('d-flex align-items-center', styles.editBtnContainer)}>
                    <CloseIcon/>
                </div>}
            </div>
        </div>
    )
}
