import styles from './settings-profile-list.module.css'
import {useEffect, useState} from "react";
import {SettingsRouteSubPaths} from "../../app-router";
import classNames from "classnames";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient} from "../../client/c2-api-client";
import {UserProfile} from "../../types/user-profile";
import {OperatorAdminSettingsList} from "../../components/operator-admin-settings/operator-admin-settings-list/operator-admin-settings-list";
import {OperatorAdminSettingsCard} from "../../components/operator-admin-settings/operator-admin-settings-card/operator-admin-settings-card";
import {useRoutingContext} from "../../hooks/routing-context";

function UserContainer(props: { user: { firstName: string, lastName: string, email: string, phone: string; active: boolean }, editCallback: () => void }) {
  const {firstName, lastName, email, phone, active} = props.user
  return (
    <OperatorAdminSettingsCard editCallback={props.editCallback} >
      <div className={classNames(styles.userName, 'text-300')}>{firstName} {lastName} {!active && <span className={styles.inactiveLabel}>(Inactive)</span>}</div>
      <div className='text-200'>{email}</div>
      <div className='text-200'>{phone}</div>
    </OperatorAdminSettingsCard>
  )
}

export function SettingsProfileList() {
  const routingContext = useRoutingContext()
  const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const [admins, setAdmins] = useState<UserProfile[]>([])
  const editUser = (user: { id: string, firstName: string; lastName: string; phone: string; }) => {
    routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_USERS_EDIT.getPath({adminId: user.id}))
  }
  useEffect(() => {
    c2ApiClient.getOperatorAdmins().then(adminsRes => setAdmins(adminsRes.data))
  }, [])

  return (
    <OperatorAdminSettingsList title="Admin" addBtnLabel="Add User"
                                    addBtnHandler={() => routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_USERS_ADD.getPath())}>
      {admins.map(user => <UserContainer key={user.id} user={user} editCallback={() => editUser(user)}/>)}
    </OperatorAdminSettingsList>)
}
