import './reset-password.css'
import React, {useState} from "react";
import {PublicPageWrapper} from "../../components/public-page-wrapper/PublicPageWrapper";
import {ResetPasswordForm} from "../../components/reset-password-form/ResetPasswordForm";
import {ResetPasswordSuccess} from "../../components/reset-password-success/reset-password-success";
import {useApiClientContext} from "../../hooks/api-client-context";
import {UnauthorizedC2ApiClient} from "../../client/c2-api-client";

export function ResetPassword() {
  const apiClient = useApiClientContext().asOrFail(UnauthorizedC2ApiClient)
  const [isSubmitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const  onSubmit = (submittedUsername: string) => {
    apiClient.resetPassword({username: submittedUsername})
      .then(({email}: {email: string}) => {
        setSubmitted(true);
        setEmail(email);
      })
  }
  return (

    <PublicPageWrapper>
      {!isSubmitted && <ResetPasswordForm onSubmit={onSubmit} />}
      {isSubmitted && <ResetPasswordSuccess email={email} />}
    </PublicPageWrapper>
  );
}
