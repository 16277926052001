import {ColumnConfig} from "../../components/table/table";
import {
  DriverResponse,
  DriverRotaRound,
  LicenceCategory,
  NightOutPreference,
  RotaType
} from "../../client/c2-api-client";
import moment, {Moment} from "moment";
import classNames from "classnames";
import styles from "./drivers-management.module.css";
import {useRef, useState} from "react";
import {TableHook} from "../../hooks/table";
import {Popover} from "@mui/material";
import {VerticalDotsIcon} from "../../icons/VerticalDots";
import {DatePicker} from "@mui/lab";
import {CheckMarkIcon} from "../../icons/CheckMark";
import {LicenceLabels} from "../driver-edit/driver-edit";
import {DriverTableHeader} from "../../components/table/column-header-components";

export type DriverSummary = {
  id: string;
  name: string;
  assessedDate?: Moment;
  site: string;
  licence: string;
  group: string;
  nightOut: string;
  earliestStart: string;
  rota: RotaType;
  rotaStart: string;
  rotaRounds: DriverRotaRound[]
}
export const driverResponseToSummaryMapper = (driver: DriverResponse) => ({
  id: driver.id,
  name: `${driver.firstName} ${driver.lastName}`,
  site: driver.site?.name ?? 'Not Assigned',
  nightOut: driver.nightOut,
  earliestStart: driver.earliestStartTime,
  licence: driver.licences.join(),
  group: driver.group?.name ?? '',
  assessedDate: driver.assessedDate ? moment(driver.assessedDate, 'YYYY-MM-DD') : undefined,
  rota: driver.rotaType,
  rotaStart: driver.rotaStartDate,
  rotaRounds: driver.rotaRounds
})
export const nightOutLabel: Record<NightOutPreference, string> = {
  [NightOutPreference.YES]: 'Yes',
  [NightOutPreference.NO]: 'No',
  [NightOutPreference.POSS]: 'Poss'
}

export function AssessedDateCell({date, editDate}: { date?: Moment, editDate: (arg: Moment) => void }) {
  const [open, setIsOpen] = useState(false)
  console.log('cell', date)
  return (
    <DatePicker<Moment>
      open={open}
      onClose={() => setIsOpen(false)}
      value={date ?? moment()}
      onChange={(v) => v ? editDate(v) : null}
      renderInput={(props) => {
        return <div ref={props.inputRef}>
          {date &&
              <span onClick={() => setIsOpen(true)}
                    style={{cursor: 'pointer'}}><CheckMarkIcon/> {date.format('YYYY-MM-DD')}</span>}
          {!date && <span onClick={() => setIsOpen(true)} className='text-200' style={{
            borderRadius: '8px',
            border: '1px solid #EEEEEE',
            padding: '5px 10px',
            color: '#F7C345',
            fontWeight: 'bold',
            cursor: 'pointer'
          }}>+ Add Date</span>}
        </div>
      }}
    />
  )
}

export type DriversTableConfigArg = {
  deleteDriver: (driver: DriverSummary) => void,
  resetPassword: (driver: DriverSummary) => void,
  setPassword: (driver: DriverSummary) => void,
  editDriver: (driver: DriverSummary) => void,
  setAssessedDate: (id: string, date: Moment) => void,
  openAssignRoundsDialog: (driver: DriverSummary) => void,
  table: TableHook<any>
}
export const driversTableConfig: (arg: DriversTableConfigArg) => ColumnConfig<DriverSummary>[] = ({
                                                                                                    deleteDriver,
                                                                                                    resetPassword,
                                                                                                    setPassword,
                                                                                                    editDriver,
                                                                                                    setAssessedDate,
                                                                                                    openAssignRoundsDialog,
                                                                                                    table
                                                                                                  }) => {
  return [{
    key: 'name',
    header: <DriverTableHeader label='Driver' table={table} sort='firstName' classes={styles.tableHeader}/>,
    cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}>{row.name}</td>
  }, {
    key: 'assessedDate',
    header: <DriverTableHeader label='Assessed' table={table} sort='assessedDate' classes={styles.tableHeader}/>,
    cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}><AssessedDateCell
      date={row.assessedDate}
      editDate={v => setAssessedDate(row.id, v)}/>
    </td>
  }, {
    key: 'site',
    header: <DriverTableHeader label='Site' table={table} sort='site' classes={styles.tableHeader}/>,
    cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}>{row.site}</td>
  }, {
    key: 'licences',
    header: <DriverTableHeader label='Licence' table={table} sort='licences' classes={styles.tableHeader}/>,
    cellFactory: row => <td
      className={classNames(styles.tableCell, 'text-300')}>{LicenceLabels[row.licence as LicenceCategory] ?? row.licence}</td>
  }, {
    key: 'group',
    header: <DriverTableHeader label='Group' table={table} sort='group' classes={styles.tableHeader}/>,
    cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}>{row.group}</td>
  }, {
    key: 'nightOut',
    header: <DriverTableHeader label='Night Out' table={table} sort='nightOut' classes={styles.tableHeader}/>,
    cellFactory: row => <td
      className={classNames(styles.tableCell, 'text-300')}>{nightOutLabel[row.nightOut as NightOutPreference]}</td>
  }, {
    key: 'earliestStartTime',
    header: <DriverTableHeader label='Earliest Start' table={table} sort='earliestStartTime'
                               classes={styles.tableHeader}/>,
    cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}>{row.earliestStart}</td>
  }, {
    key: 'editActions',
    header: <th className={classNames(styles.tableHeader, 'text-200')}></th>,
    cellFactory: row => <td className={classNames(styles.tableCell, 'text-300')}><EditActions row={row}
                                                                                              deleteDriver={deleteDriver}
                                                                                              assignDriverRounds={openAssignRoundsDialog}
                                                                                              resetPassword={resetPassword}
                                                                                              setPassword={setPassword}
                                                                                              editDriver={editDriver}/>
    </td>
  },]
}
const EditActions = ({
                       row,
                       deleteDriver,
                       resetPassword,
                       setPassword,
                       editDriver,
                       assignDriverRounds
                     }: { row: DriverSummary, resetPassword: (arg: DriverSummary) => void, setPassword: (arg: DriverSummary) => void, deleteDriver: (arg: DriverSummary) => void, editDriver: (arg: DriverSummary) => void, assignDriverRounds: (driver: DriverSummary) => void }) => {
  const [open, setOpen] = useState(false)
  const dotsRef = useRef(null)
  const onDeleteDriver = () => {
    setOpen(false)
    deleteDriver(row)
  }
  const onResetPassword = () => {
    setOpen(false)
    resetPassword(row)
  }
  const onSetPassword = () => {
    setPassword(row)
    setOpen(false)
  }
  const onAssignRounds = () => {
    assignDriverRounds(row)
    setOpen(false)
  }
  return (
    <>
      <div style={{cursor: 'pointer'}} onClick={() => setOpen(true)} ref={dotsRef}><VerticalDotsIcon/></div>
      <Popover open={open} anchorEl={dotsRef.current as unknown as Element} onClose={() => setOpen(false)}
               disableScrollLock={true}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}
               transformOrigin={{
                 vertical: 'top',
                 horizontal: 'center',
               }}>
        <div className={styles.editActionsContainer}>
          <div className={styles.editAction} onClick={() => editDriver(row)}>Edit</div>
          <div className={styles.editAction} onClick={onResetPassword}>Reset Password</div>
          <div className={styles.editAction} onClick={onSetPassword}>Set Driver Password</div>
          <div className={styles.editAction} onClick={onDeleteDriver}>Delete</div>
          <div className={styles.editAction} onClick={onAssignRounds}>Assign Rotation Rounds</div>
        </div>
      </Popover>
    </>
  )
}
export const ColGroups = (tableConfig: ColumnConfig<DriverSummary>[]) => (
  <colgroup>
    {tableConfig.slice(0, tableConfig.length - 1).map((h, i) => <col key={i}/>)}
    <col style={{width: '35px'}}/>
  </colgroup>
)
