import {SettingsRouteSubPaths, useRoutePathMatch} from "../../app-router";
import {form, useInput} from "../../hooks/input";
import {Validators} from "../../validators";
import {useEffect, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient, RoundModel} from "../../client/c2-api-client";
import {OperatorAdminSettingsForm} from "../../components/operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {useRoutingContext} from "../../hooks/routing-context";
import { SettingsRoundEditGroup } from "./settings-round-edit-group";

type RoundDetails = Omit<RoundModel, 'id'>

export function SettingsRoundEdit() {
  const match = useRoutePathMatch(SettingsRouteSubPaths.USER_PROFILES_ROUNDS_EDIT)
  const roundId = match?.params.roundId
  const routingContext = useRoutingContext()
  const c2ApiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const [roundDetails, setRoundDetails] = useState<RoundDetails>({name: '', description: '', mondayQuota: 1, tuesdayQuota: 1, wednesdayQuota: 1, thursdayQuota: 1, fridayQuota: 1, saturdayQuota: 1, sundayQuota: 1})
  const name = useInput(roundDetails.name, [Validators.required])
  const description = useInput(roundDetails.description)
  const mondayQuota = useInput(`${roundDetails.mondayQuota}`, [Validators.required, Validators.pattern(/\d+/, 'number')])
  const tuesdayQuota = useInput(`${roundDetails.tuesdayQuota}`, [Validators.required, Validators.pattern(/\d+/, 'number')])
  const wednesdayQuota = useInput(`${roundDetails.wednesdayQuota}`, [Validators.required, Validators.pattern(/\d+/, 'number')])
  const thursdayQuota = useInput(`${roundDetails.thursdayQuota}`, [Validators.required, Validators.pattern(/\d+/, 'number')])
  const fridayQuota = useInput(`${roundDetails.fridayQuota}`, [Validators.required, Validators.pattern(/\d+/, 'number')])
  const saturdayQuota = useInput(`${roundDetails.saturdayQuota}`, [Validators.required, Validators.pattern(/\d+/, 'number')])
  const sundayQuota = useInput(`${roundDetails.sundayQuota}`, [Validators.required, Validators.pattern(/\d+/, 'number')])
  const roundForm = form([name, description, mondayQuota, tuesdayQuota, wednesdayQuota, thursdayQuota, fridayQuota, saturdayQuota, sundayQuota])
  useEffect(() => {
    if (roundId) {
      c2ApiClient.getOperatorRounds().then(roundsData => {
        const round = roundsData.rounds.find(round => roundId === round.id)
        if (round) {
          setRoundDetails(round)
          name.setValue(round.name, true)
          description.setValue(round.description, true)
          mondayQuota.setValue(`${round.mondayQuota}`, true)
          tuesdayQuota.setValue(`${round.tuesdayQuota}`, true)
          wednesdayQuota.setValue(`${round.wednesdayQuota}`, true)
          thursdayQuota.setValue(`${round.thursdayQuota}`, true)
          fridayQuota.setValue(`${round.fridayQuota}`, true)
          saturdayQuota.setValue(`${round.saturdayQuota}`, true)
          sundayQuota.setValue(`${round.sundayQuota}`, true)
        }
      })
    }
  }, [roundId, c2ApiClient])


  const handleSubmit = () => {
    if (!roundForm.valid) {
      return;
    }
    const save$ = roundId ?
      c2ApiClient.editRound({
        id: roundId,
        name: name.value!,
        description: description.value!,
        mondayQuota: Number(mondayQuota.value!),
        tuesdayQuota: Number(tuesdayQuota.value!),
        wednesdayQuota: Number(wednesdayQuota.value!),
        thursdayQuota: Number(thursdayQuota.value!),
        fridayQuota: Number(fridayQuota.value!),
        saturdayQuota: Number(saturdayQuota.value!),
        sundayQuota: Number(sundayQuota.value!),
      }) :
      c2ApiClient.createRound({
        name: name.value!,
        description: description.value!,
        mondayQuota: Number(mondayQuota.value!),
        tuesdayQuota: Number(tuesdayQuota.value!),
        wednesdayQuota: Number(wednesdayQuota.value!),
        thursdayQuota: Number(thursdayQuota.value!),
        fridayQuota: Number(fridayQuota.value!),
        saturdayQuota: Number(saturdayQuota.value!),
        sundayQuota: Number(sundayQuota.value!),
      })
    save$.then(() => routingContext.navigate(SettingsRouteSubPaths.USER_PROFILES_ROUNDS.getPath()))
  }

  return (
    <OperatorAdminSettingsForm disableSubmit={!roundForm.valid} editCallback={handleSubmit}
      title={roundId ? "Edit Round" : "Add New Round"}
      submitLabel="Save Round"
      cancelRoute={SettingsRouteSubPaths.USER_PROFILES_ROUNDS.getPath()}>
    <div className='d-flex' style={{gap: '10px'}}>
    <SettingsRoundEditGroup name={name} description={description}
    mondayQuota={mondayQuota} tuesdayQuota={tuesdayQuota} wednesdayQuota={wednesdayQuota} thursdayQuota={thursdayQuota} fridayQuota={fridayQuota}
    saturdayQuota={saturdayQuota} sundayQuota={sundayQuota}/>
     </div>
    </OperatorAdminSettingsForm>
  )
}
