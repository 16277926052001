import {TableHook} from "../../hooks/table";
import classNames  from "classnames";
import styles from "./column-header-components.module.css";

export function DriverTableHeader({label, sort, table, classes}: { label: string, sort: string, table: TableHook<any> ,classes?: string }) {
  return <th className={classNames(classes, styles.tableHeader, 'text-200')} onClick={() => sort && table.setSort(sort)}>{label}<SortIndicator wantedSort={sort} currentSort={table.currentSort} currentAscending={table.currentAscending} /></th>
}
export function SortIndicator({currentSort, currentAscending, wantedSort}: {currentSort?: string, currentAscending?: boolean, wantedSort?: string}) {
  if(!wantedSort || currentSort !== wantedSort) return <></>
  if(currentAscending) return <>&#8963;</>
  return <>&#8964;</>
}
