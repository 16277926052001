import {Role} from "./client/c2-api-client";
import React from "react";
import {Login} from "./page/login/login";
import {ResetPassword} from "./page/reset-password/reset-password";
import {CreatePassword} from "./page/create-password/create-password";
import {SuperAdminHome} from "./page/super-admin-home/super-admin-home";
import {GuardedRoute} from "./components/session/guarded-route/guarded-route";
import {match, Route, Switch, useRouteMatch} from "react-router-dom";
import {useSessionContext} from "./hooks/session-context";
import {OperatorSettingsWrapper} from "./components/operator-admin-settings/operator-settings-wrapper/operator-settings-wrapper";
import {SettingsProfileList} from "./page/settings-profile-list/settings-profile-list";
import {SettingsProfileEdit} from "./page/settings-profile-edit/settings-profile-edit";
import {SettingsSiteList} from "./page/settings-site-list/settings-site-list";
import {SettingsSiteEdit} from "./page/settings-site-edit/settings-site-edit";
import {SettingsGroupList} from "./page/settings-group-list/settings-group-list";
import {SettingsGroupEdit} from "./page/settings-group-edit/settings-group-edit";
import {DriverEdit} from "./page/driver-edit/driver-edit";
import {DriversPlanning} from "./page/drivers-planning/drivers-planning";
import {DriverWeekView} from "./page/driver-week-view/driver-week-view";
import moment from "moment";
import {DriverDayView} from "./page/driver-day-view/driver-day-view";
import {DriverShiftResources} from "./page/driver-shift-resources/driver-shift-resources";
import {Timesheets} from "./page/timesheets/timesheets";
import {useRoutingContext} from "./hooks/routing-context";
import {RoutingRedirect} from "./components/routing/redirect/RoutingRedirect";
import {SuperAdminOperatorOverview} from "./page/super-admin-operator-overview/super-admin-operator-overview";
import {SettingsWorkWeek} from "./page/settings-work-week/settings-work-week";
import {WriteOperator} from "./page/write-operator/write-operator";
import {DriversManagement} from "./page/drivers-management/drivers-management";
import {DriverMonthView} from "./page/driver-month-view/driver-month-view";
import {ScanSiteCode} from "./page/scan-site-code/scan-site-code";
import {StartSiteShift} from "./page/start-site-shift/start-site-shift";
import {EnterAgencyData} from "./page/enter-agency-data/enter-agency-data";
import {SettingsSiteRateCards} from "./page/settings-site-edit/settings-site-rate-cards";
import { SettingsRoundList } from "./page/settings-rounds-list/settings-round-list";
import { SettingsRoundEdit } from "./page/settings-round-edit/settings-round-edit";

export interface RoutePath<T> {
  pathFormat: string;
  getPath: (params: T) => string;
}

export interface RouteConfig {
  pathFormat: string;
  exact?: boolean;
  component: React.ReactNode;
  requiresRole?: Role;
}

const CommonPaths = {
  LOGIN: {
    pathFormat: '/login',
    getPath: () => '/login',
  } as RoutePath<void>,
  FORGOT_PASSWORD: {
    pathFormat: '/forgot-password',
    getPath: () => '/forgot-password',
  } as RoutePath<void>,
  CREATE_PASSWORD: {
    pathFormat: '/create-password',
    getPath: () => '/create-password',
  } as RoutePath<void>,
  HOME: {
    pathFormat: '/',
    getPath: () => '/',
  } as RoutePath<void>
}
const SuperAdminPaths = {
  SUPER_ADMIN_OPERATOR_OVERVIEW: {
    pathFormat: '/operator-overview/:operatorId',
    getPath: (params: { operatorId: string }) => `/operator-overview/${params.operatorId}`
  } as RoutePath<{ operatorId: string }>,
  CREATE_OPERATOR: {
    pathFormat: '/operator',
    getPath: () => '/operator',
  } as RoutePath<void>,
  EDIT_OPERATOR: {
    pathFormat: '/operator/:operatorId',
    getPath: (params: { operatorId: string }) => `/operator/${params.operatorId}`
  } as RoutePath<{ operatorId: string }>
}

const OperatorAdminPaths = {
  DRIVERS_MANAGEMENT: {
    pathFormat: '/drivers-management',
    getPath: () => `/drivers-management`
  } as RoutePath<void>,
  DRIVERS_ADD: {
    pathFormat: `/drivers-management/add`,
    getPath: () => `/drivers-management/add`
  } as RoutePath<void>,
  DRIVERS_EDIT: {
    pathFormat: `/drivers-management/edit/:driverId`,
    getPath: (params: { driverId: string; }) => `/drivers-management/edit/${params.driverId}`
  } as RoutePath<{ driverId: string }>,
  DRIVERS_PLANNING: {
    pathFormat: `/drivers-planning`,
    getPath: () => `/drivers-planning`
  } as RoutePath<void>,
  TIMESHEETS: {
    pathFormat: '/timesheets/:endDate?',
    getPath: (params) => `/timesheets/${params?.endDate ?? ''}`
  } as RoutePath<{ endDate: string } | void>,
  SETTINGS: {
    pathFormat: '/settings',
    getPath: () => `/settings`
  } as RoutePath<void>
}

const DriverPaths = {
  MONTH_VIEW: {
    pathFormat: '/month/:date',
    getPath: ({date}: {date: string}) => `/month/${date}`
  } as RoutePath<{ date: string }>,
  WEEK_VIEW: {
    pathFormat: '/week/:date',
    getPath: ({date}: {date: string}) => `/week/${date}`
  } as RoutePath<{ date: string }>,
  DAY_VIEW: {
    pathFormat: '/day/:date',
    getPath: ({date}: {date: string}) => `/day/${date}`
  } as RoutePath<{ date: string }>,
  SHIFT_RESOURCES: {
    pathFormat: '/day/:date/shift/:shiftId/resources',
    getPath: ({date, shiftId}: {date: string, shiftId: string}) => `/day/${date}/shift/${shiftId}/resources`
  } as RoutePath<{ date: string, shiftId: string }>,
  SCAN_SITE_CODE: {
    pathFormat: '/scan-site-code',
    getPath: () => `/scan-site-code`
  } as RoutePath<void>,
  START_SITE_SHIFT: {
    pathFormat: '/start-site-shift/:siteId',
    getPath: (params: {siteId: string}) => `/start-site-shift/${params.siteId}`
  } as RoutePath<{siteId: string}>,
  ENTER_AGENCY_DATA: {
    pathFormat: '/agency-data',
    getPath: () => `/agency-data`
  } as RoutePath<void>,
}

export const RoutePaths = {
  ...CommonPaths,
  ...SuperAdminPaths,
  ...OperatorAdminPaths,
  ...DriverPaths
}
export type RouteParam<T> = T extends RoutePath<infer U> ? U : never

export function useRoutePathMatch<T extends RoutePath<U>, U = any>(route: T): match<RouteParam<T>> | null {
  const routingContext = useRoutingContext()
  return useRouteMatch<RouteParam<T>>(routingContext.getPath(route.pathFormat))
}

type RoutesConfig = { [k in keyof typeof RoutePaths]: RouteConfig };
type RoleRoutes = { HOME: RouteConfig } & Partial<Omit<RoutesConfig, 'HOME'>>

export function useParamMatch<T>(routePath: RoutePath<T>): match<T> | null {
  return useRouteMatch<T>(routePath.pathFormat)
}

export const CommonRoutes = {
  LOGIN: {
    pathFormat: RoutePaths.LOGIN.pathFormat,
    component: <Login/>,
  },
  FORGOT_PASSWORD: {
    pathFormat: RoutePaths.FORGOT_PASSWORD.pathFormat,
    component: <ResetPassword/>,
  },
  CREATE_PASSWORD: {
    pathFormat: RoutePaths.CREATE_PASSWORD.pathFormat,
    component: <CreatePassword/>,
  },
  HOME: {
    pathFormat: RoutePaths.HOME.pathFormat,
    exact: true,
    component: <Login/>
  }
}
const SuperAdminRoutes: RoleRoutes = {
  HOME: {
    pathFormat: RoutePaths.HOME.pathFormat,
    exact: true,
    component: <SuperAdminHome/>,
  },
  SUPER_ADMIN_OPERATOR_OVERVIEW: {
    pathFormat: RoutePaths.SUPER_ADMIN_OPERATOR_OVERVIEW.pathFormat,
    exact: false,
    component: <SuperAdminOperatorOverview/>,
  },
  CREATE_OPERATOR: {
    pathFormat: RoutePaths.CREATE_OPERATOR.pathFormat,
    exact: true,
    component: <WriteOperator />,
  },
  EDIT_OPERATOR: {
    pathFormat: RoutePaths.EDIT_OPERATOR.pathFormat,
    exact: true,
    component: <WriteOperator />,
  },
  CREATE_PASSWORD: CommonRoutes.CREATE_PASSWORD
}
const OperatorAdminRoutes: RoleRoutes = {
  HOME: {
    pathFormat: RoutePaths.HOME.pathFormat,
    exact: true,
    component: <RoutingRedirect to={RoutePaths.DRIVERS_MANAGEMENT.getPath()}/>,
  },
  SETTINGS: {
      pathFormat: RoutePaths.SETTINGS.pathFormat,
      exact: false,
      component: <OperatorSettingsWrapper />
  },
  DRIVERS_MANAGEMENT: {
    pathFormat: RoutePaths.DRIVERS_MANAGEMENT.pathFormat,
    exact: true,
    component: <DriversManagement/>
  },
  DRIVERS_ADD: {
    pathFormat: RoutePaths.DRIVERS_ADD.pathFormat,
    exact: true,
    component: <DriverEdit />
  },
  DRIVERS_EDIT: {
    pathFormat: RoutePaths.DRIVERS_EDIT.pathFormat,
    exact: true,
    component: <DriverEdit />
  },
  DRIVERS_PLANNING: {
    pathFormat: RoutePaths.DRIVERS_PLANNING.pathFormat,
    exact: true,
    component: <DriversPlanning />
  },
  TIMESHEETS: {
    pathFormat: RoutePaths.TIMESHEETS.pathFormat,
    exact: false,
    component: <Timesheets />
  },
  CREATE_PASSWORD: CommonRoutes.CREATE_PASSWORD
}
const DriverRoutes: RoleRoutes = {
  HOME: {
    pathFormat: RoutePaths.HOME.pathFormat,
    exact: true,
    component: <RoutingRedirect to={RoutePaths.WEEK_VIEW.getPath({date: moment().format('YYYY-MM-DD')})}/>,
  },
  MONTH_VIEW: {
    pathFormat: RoutePaths.MONTH_VIEW.pathFormat,
    exact: true,
    component: <DriverMonthView />
  },
  WEEK_VIEW: {
    pathFormat: RoutePaths.WEEK_VIEW.pathFormat,
    exact: true,
    component: <DriverWeekView />
  },
  DAY_VIEW: {
    pathFormat: RoutePaths.DAY_VIEW.pathFormat,
    exact: true,
    component: <DriverDayView />
  },
  SHIFT_RESOURCES: {
    pathFormat: RoutePaths.SHIFT_RESOURCES.pathFormat,
    exact: true,
    component: <DriverShiftResources />
  },
  START_SITE_SHIFT: {
    pathFormat: RoutePaths.START_SITE_SHIFT.pathFormat,
    exact: true,
    component: <StartSiteShift />
  },
  CREATE_PASSWORD: CommonRoutes.CREATE_PASSWORD,
  SCAN_SITE_CODE: {
    pathFormat: RoutePaths.SCAN_SITE_CODE.pathFormat,
    exact: true,
    component: <ScanSiteCode />
  },
  ENTER_AGENCY_DATA: {
    pathFormat: RoutePaths.ENTER_AGENCY_DATA.pathFormat,
    exact: true,
    component: <EnterAgencyData />
  }
}
const routeConfigByRole: Map<Role, RoleRoutes> = new Map<Role, RoleRoutes>()
routeConfigByRole.set('SUPER_ADMIN', SuperAdminRoutes)
routeConfigByRole.set('OPERATOR_ADMIN', OperatorAdminRoutes)
routeConfigByRole.set('DRIVER', DriverRoutes)

const routeGuardsByRole: Record<Role | 'UNAUTHORIZED', { guarded: boolean }> = {
  SUPER_ADMIN: {guarded: true},
  OPERATOR_ADMIN: {guarded: true},
  DRIVER: {guarded: true},
  UNAUTHORIZED: {guarded: false}
}
export function AppRouter() {
  const sessionContext = useSessionContext()
  const routingContext = useRoutingContext()
  const role = sessionContext?.session?.role
  const sessionRoutes = (role ? routeConfigByRole.get(role) : CommonRoutes) ?? CommonRoutes
  const guard = routeGuardsByRole[role ?? 'UNAUTHORIZED']
  console.log('session routes', sessionRoutes)
  return (
    <Switch>
      {Object.entries(sessionRoutes).map(([route, config]) => {
        const routeParams: any = {path: routingContext.getPath(config.pathFormat), requiresRole: role};
        if (config.exact) {
          routeParams.exact = true;
        }
        return guard.guarded ?
          <GuardedRoute key={routeParams.path}
                        fallbackRoute={RoutePaths.LOGIN.getPath()} {...routeParams}>{config.component}</GuardedRoute>
          :
          <Route {...routeParams} key={routeParams.path}>{config.component}</Route>
      })}
      <Route render={(props) => {
        console.log('path', props.location)
        const from = encodeURIComponent(props.location.pathname)
        return <RoutingRedirect to={`${RoutePaths.HOME.getPath()}?from=${from}`}  />
      }}/>
    </Switch>
  )
}

export const SettingsRouteSubPaths = {
  USER_PROFILES_USERS: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/users`,
    getPath: () => `${RoutePaths.SETTINGS.getPath()}/users`
  } as RoutePath<void>,
  USER_PROFILES_USERS_EDIT: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/users/edit/:adminId`,
    getPath: (params: { adminId: string }) => `${RoutePaths.SETTINGS.getPath()}/users/edit/${params.adminId}`
  } as RoutePath<{ adminId: string }>,
  USER_PROFILES_USERS_ADD: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/users/add`,
    getPath: () => `${RoutePaths.SETTINGS.getPath()}/users/add`
  } as RoutePath<void>,
  USER_PROFILES_WORK_WEEK: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/work-week`,
    getPath: () => `${RoutePaths.SETTINGS.getPath()}/work-week`
  } as RoutePath<void>,
  USER_PROFILES_SITES: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/sites`,
    getPath: () => `${RoutePaths.SETTINGS.getPath()}/sites`
  } as RoutePath<void>,
  USER_PROFILES_SITES_ADD: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/sites/add`,
    getPath: () => `${RoutePaths.SETTINGS.getPath()}/sites/add`
  } as RoutePath<void>,
  USER_PROFILES_SITES_EDIT: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/sites/edit/:siteId`,
    getPath: (params: { siteId: string; }) => `${RoutePaths.SETTINGS.getPath()}/sites/edit/${params.siteId}`
  } as RoutePath<{ siteId: string }>,
  USER_PROFILES_ROUNDS: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/rounds`,
    getPath: () => `${RoutePaths.SETTINGS.getPath()}/rounds`
  } as RoutePath<void>,
  USER_PROFILES_ROUNDS_ADD: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/rounds/add`,
    getPath: () => `${RoutePaths.SETTINGS.getPath()}/rounds/add`
  } as RoutePath<void>,
  USER_PROFILES_ROUNDS_EDIT: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/rounds/edit/:roundId`,
    getPath: (params: { roundId: string; }) => `${RoutePaths.SETTINGS.getPath()}/rounds/edit/${params.roundId}`
  } as RoutePath<{ roundId: string }>,
  SITE_RATE_CARDS: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/sites/edit/:siteId/rate-cards`,
    getPath: (params: { siteId: string; }) => `${RoutePaths.SETTINGS.getPath()}/sites/edit/${params.siteId}/rate-cards`
  } as RoutePath<{ siteId: string }>,
  USER_PROFILES_GROUPS: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/groups`,
    getPath: () => `${RoutePaths.SETTINGS.getPath()}/groups`
  } as RoutePath<void>,
  USER_PROFILES_GROUPS_ADD: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/groups/add`,
    getPath: () => `${RoutePaths.SETTINGS.getPath()}/groups/add`
  } as RoutePath<void>,
  USER_PROFILES_GROUPS_EDIT: {
    pathFormat: `${RoutePaths.SETTINGS.getPath()}/groups/edit/:groupId`,
    getPath: (params: { groupId: string; }) => `${RoutePaths.SETTINGS.getPath()}/groups/edit/${params.groupId}`
  } as RoutePath<{ groupId: string }>
}


type SettingsRoutesConfig = { [k in keyof typeof SettingsRouteSubPaths]: RouteConfig };
export const SettingsSubRoutesConfig :SettingsRoutesConfig = {
  USER_PROFILES_USERS: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_USERS.pathFormat,
    exact: true,
    component: <SettingsProfileList />
  },
  USER_PROFILES_USERS_EDIT: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_USERS_EDIT.pathFormat,
    exact: true,
    component: <SettingsProfileEdit/>
  },
  USER_PROFILES_USERS_ADD: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_USERS_ADD.pathFormat,
    exact: true,
    component: <SettingsProfileEdit/>
  },
  USER_PROFILES_WORK_WEEK: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_WORK_WEEK.pathFormat,
    exact: true,
    component: <SettingsWorkWeek />
  },
  USER_PROFILES_SITES: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_SITES.pathFormat,
    exact: true,
    component: <SettingsSiteList/>
  },
  USER_PROFILES_SITES_EDIT: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_SITES_EDIT.pathFormat,
    exact: true,
    component: <SettingsSiteEdit />
  },
  SITE_RATE_CARDS: {
    pathFormat: SettingsRouteSubPaths.SITE_RATE_CARDS.pathFormat,
    exact: true,
    component: <SettingsSiteRateCards />
  },
  USER_PROFILES_SITES_ADD: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_SITES_ADD.pathFormat,
    exact: true,
    component: <SettingsSiteEdit />
  },
  USER_PROFILES_ROUNDS: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_ROUNDS.pathFormat,
    exact: true,
    component: <SettingsRoundList/>
  },
  USER_PROFILES_ROUNDS_ADD: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_ROUNDS_ADD.pathFormat,
    exact: true,
    component: <SettingsRoundEdit />
  },
  USER_PROFILES_ROUNDS_EDIT: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_ROUNDS_EDIT.pathFormat,
    exact: true,
    component: <SettingsRoundEdit />
  },
  USER_PROFILES_GROUPS: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_GROUPS.pathFormat,
    exact: true,
    component: <SettingsGroupList />
  },
  USER_PROFILES_GROUPS_ADD: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_GROUPS_ADD.pathFormat,
    exact: true,
    component: <SettingsGroupEdit />
  },
  USER_PROFILES_GROUPS_EDIT: {
    pathFormat: SettingsRouteSubPaths.USER_PROFILES_GROUPS_EDIT.pathFormat,
    exact: true,
    component: <SettingsGroupEdit />
  },
}
