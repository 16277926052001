import {Dialog} from "@mui/material";
import styles from "./edit-leave-modal.module.css";
import {Button} from "../button/Button";
import React from "react";
import classnames from "classnames";
import {AddDatesAction} from "../add-dates-interval-modal/add-dates-interval-modal";
export type DeleteLeaveType = 'sick' | 'holiday' | 'training' | 'induction' | 'unavailable'
const typeToTitle: Record<AddDatesAction, string> = {
    exportHolidaySick: "",
    holiday: 'Delete Holiday',
    sick: 'Delete Sick Leave',
    training: 'Delete Training Days',
    induction: 'Delete Induction Days',
    unavailable: 'Delete Unavailable Days'
}
export function DeleteLeaveModal({
                                        type,
                                        date,
                                        handle,
                                        open
                                      }: {type: DeleteLeaveType, date: string, handle: (params?: { span: 'single' | 'block' }) => void, open: boolean }) {

  return (
    <Dialog
      PaperProps={{classes: {root: styles.addShiftDialogRoot}}}
      disableScrollLock={true}
      onClose={() => handle()}
      open={open}>
      <div>
        <div className={classnames('text-500', styles.title)}>{`${typeToTitle[type]} (${date})`}</div>
        <div className='d-flex flex-column' style={{gap: '20px'}}>
          <Button shape={"rounded"} colorScheme={"neutral"} onClick={() => handle({span: 'single'})}>Just For This Date</Button>
          <Button shape={"rounded"} colorScheme={"neutral"} onClick={() => handle({span: 'block'})}>This Date and All Consecutive Dates</Button>
          <Button shape={"rounded"} colorScheme={"negative"} onClick={() => handle()}>Cancel</Button>
        </div>
      </div>
      {/*</OperatorAdminSettingsForm>*/}
    </Dialog>
  )
}
