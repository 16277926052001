import {FilterConfig, FiltersSetState} from "./drivers-table-filter-bar";
import {LicenceCategory, NightOutPreference, SiteModel} from "../../client/c2-api-client";
import {GroupModel} from "../../page/settings-group-list/settings-group-list";
import {DropdownFilterEntry} from "../dropdown-filter-entry/dropdown-filter-entry";
import {FilterMultiSelectModal} from "../filter-multiselect-modal/fitler-multiselect-modal";

const assessedOptions = [
    {id: undefined, name: 'All'},
    {id: true, name: 'Assessed'},
    {id: false, name: 'Not Assessed'}
]

const licenceOptions: { id: LicenceCategory, name: string }[] = Object.values(LicenceCategory).map(l => ({
    id: l,
    name: l
}))

const nightOutOptions = [{id: undefined, name: 'All'}, {id: NightOutPreference.YES, name: 'Yes'}, {
    id: NightOutPreference.NO,
    name: 'No'
}, {id: NightOutPreference.POSS, name: 'Poss'}]

export type SingleKeyFilterUpdater<T, K extends keyof T> = (arg: T[K] | undefined) => void
export const singleKeyFiltersUpdater = <T, K extends keyof T>(setState: FiltersSetState<Partial<T>>, key: K): SingleKeyFilterUpdater<T, K> => {
    return value => setState(currentFilters => ({
        ...currentFilters,
        [key]: value
    }))
}
export const defaultFiltersConfig: (groups: GroupModel[], sites: SiteModel[]) => FilterConfig[] =
    (groups, sites) => ([
        {
            id: 'assessed',
            defaultValue: (filters) => filters.assessed === undefined,
            filterComponent: (val, setVal) => <DropdownFilterEntry<boolean> filter={'Assessed'} value={val.assessed}
                                                                            options={assessedOptions}
                                                                            onChange={singleKeyFiltersUpdater(setVal, 'assessed')}
            />
        }, {
            id: 'site',
            defaultValue: (currentFilters) => currentFilters.siteIds === undefined,
            filterComponent: (val, setVal) => <FilterMultiSelectModal label='Sites' availableSelections={sites}
                                                                      currentSelection={val.siteIds}
                                                                      setFilter={singleKeyFiltersUpdater(setVal, 'siteIds')}
            />
        }, {
            id: 'licence',
            defaultValue: (currentFilters) => currentFilters.licences === undefined,
            filterComponent: (val, setVal) => <FilterMultiSelectModal label={'Licences'}
                                                                      availableSelections={licenceOptions}
                                                                      currentSelection={val.licences}
                                                                      setFilter={(licences) => singleKeyFiltersUpdater(setVal, 'licences')(licences as LicenceCategory[] | undefined)}/>
        }, {
            id: 'groupIds',
            defaultValue: (currentFilters) => currentFilters.groupIds === undefined,
            filterComponent: (val, setVal) => <FilterMultiSelectModal label='Groups' includeNA={true}
                                                                      availableSelections={groups}
                                                                      currentSelection={val.groupIds}
                                                                      setFilter={singleKeyFiltersUpdater(setVal, 'groupIds')}/>
        }, {
            id: 'nightOut',
            defaultValue: (currentFilters) => currentFilters.nightOut === undefined,
            filterComponent: (val, setVal) => <DropdownFilterEntry filter={'Night Out'} value={val.nightOut}
                                                                   options={nightOutOptions}
                                                                   onChange={singleKeyFiltersUpdater(setVal, 'nightOut')}/>
        }])
