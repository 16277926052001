import * as React from "react"

export function ArrowDownIcon() {
  return (
    <svg
      width={11}
      height={6}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={0.33}
        d="M10.332.501 9.988.166a.58.58 0 0 0-.406-.162.58.58 0 0 0-.406.162L5.252 3.962 1.324.162A.58.58 0 0 0 .918 0a.58.58 0 0 0-.406.162L.168.495a.543.543 0 0 0 0 .786l4.677 4.54A.596.596 0 0 0 5.252 6h.002a.596.596 0 0 0 .406-.178l4.672-4.529a.552.552 0 0 0 0-.792Z"
        fill="#000"
      />
    </svg>
  )
}

