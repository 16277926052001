import { Route} from "react-router-dom";
import React from "react";
import {SessionContext, useSessionContext} from "../../../hooks/session-context";
import {Role} from "../../../client/c2-api-client";
import {RouteProps} from "react-router";
import {RoutingRedirect} from "../../routing/redirect/RoutingRedirect";

export interface GuardedRouteProps extends RouteProps {
  children: React.ReactNode;
  fallbackRoute: string;
  requiresRole?: Role
}

function hasRequiredSession(sessionContext: SessionContext, requiredRole: Role | undefined) {
  if(requiredRole === undefined) {
    return true;
  }
  if(sessionContext.session === undefined) {
    return false;
  }
  return sessionContext.session.role === requiredRole;
}

export function GuardedRoute(props: GuardedRouteProps) {
  const sessionContext = useSessionContext();
  return (
    <Route render={(location) => {
      return hasRequiredSession(sessionContext, props.requiresRole)
        ?
        props.children :
        <RoutingRedirect to={props.fallbackRoute} />
    }}></Route>
  )
}
