import {DriverDaySlot, Shift} from "../client/c2-api-client";
import {timeService} from "./time-service";
import {DayLaneShift} from "../components/lanes/day-lane/day-lane";
import {Moment} from "moment";
import moment from "moment";

export type ShiftsPartitioned = {
    slotsByDate: Record<string, DriverDaySlot>,
    shiftsByDate: Record<string, DayLaneShift[]>,
    allShifts: Shift[]
}
export const slotService = {
    partitionByDate: (slots: DriverDaySlot[]): ShiftsPartitioned => {
        return slots.reduce((res, slot) => {
            res.slotsByDate[slot.date] = slot
            slot.shifts.forEach((shift) => {
                res.allShifts.push(shift)
                const slotShownTimes = slotService.getStartAndEndMoments(shift)
                const shiftStartDate = timeService.formatDate(slotShownTimes.start)
                const shiftEndDate = timeService.formatDate(slotShownTimes.end);
                const forStart = res.shiftsByDate[shiftStartDate] ?? []
                forStart.push({
                    id: shift.id,
                    status: shift.status,
                    startTime: timeService.formatTime(slotShownTimes.start),
                    endTime: shiftEndDate !== shiftStartDate ? '23:59' : timeService.formatTime(slotShownTimes.end)
                })
                res.shiftsByDate[shiftStartDate] = forStart

                if (shiftEndDate !== shiftStartDate) {
                    const forEnd = res.shiftsByDate[shiftEndDate] ?? []
                    forEnd.push({
                        id: shift.id,
                        status: shift.status,
                        startTime: '00:00',
                        endTime: timeService.formatTime(slotShownTimes.end)
                    })
                    res.shiftsByDate[shiftEndDate] = forEnd
                }
            })
            return res
        }, {slotsByDate: {}, shiftsByDate: {}, allShifts: []} as ShiftsPartitioned)

    },
    getStartAndEndMoments: (shift: Shift): { start: Moment, end: Moment } => {
        const shiftDate = timeService.parseDate(shift.date);
        if (!shift.startedAt) {
            const startMoment = timeService.parseTime(shift.startTime);
            const endMoment = timeService.parseTime(shift.endTime);
            return {
                start: timeService.combineMoments(shiftDate, startMoment),
                end: timeService.combineMoments(endMoment.isBefore(startMoment) ? moment(shiftDate).add(1,'days') : shiftDate, endMoment)
            }
        }
        const startTime = timeService.parseTimestamp(shift.startedAt)
        if(shift.operatorTime) {
            const operatorMoment = timeService.parseTime(shift.operatorTime)
            const endTime = moment(startTime).add(operatorMoment.hours(), 'hours').add(operatorMoment.minutes(), 'minutes')
            return {
                start: startTime,
                end: endTime
            }
        }
        if(shift.endedAt) {
            return {
                start: startTime,
                end: timeService.parseTimestamp(shift.endedAt)
            }
        }
        const endTime = moment(startTime).add(8, 'hours')
        return {
            start: startTime,
            end: endTime
        }
    }
}