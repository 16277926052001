import {form, useInput} from "../../hooks/input";
import moment from "moment";
import {Validators} from "../../validators";
import {useEffect} from "react";
import {Dialog} from "@mui/material";
import styles from "./add-shift-modal.module.css";
import {
    OperatorAdminSettingsForm
} from "../operator-admin-settings/operator-admin-settings-form/operator-admin-settings-form";
import {TimeInput} from "../input/time/time-input";

export function AddShiftModal({
                                  title,
                                  startTime,
                                  handle,
                                  open
                              }: {
    title?: string,
    startTime: string,
    handle: (params?: { start: string, end: string }) => void,
    open: boolean
}) {
    const startInput = useInput(moment(), [Validators.required])
    const endInput = useInput(moment(), [Validators.required])
    useEffect(() => {
        startInput.setValue(moment(startTime, 'HH:mm'))
        endInput.setValue(moment(startTime, 'HH:mm').add(8, 'hours'))
    }, [startTime])
    const shiftForm = form([startInput, endInput])
    return (
        <Dialog
            PaperProps={{classes: {root: styles.addTachoDialogRoot}}}
            disableScrollLock={true}
            open={open}>
            <OperatorAdminSettingsForm title={title ?? 'Shift Time'} submitLabel='Send' cancelCallback={() => handle()}
                                       disableSubmit={!shiftForm.valid}
                                       paddingTop={false}
                                       editCallback={() => handle({
                                           start: startInput.value!.format('HH:mm'),
                                           end: endInput.value!.format('HH:mm')
                                       })}>
                <div className='d-flex flex-column' style={{gap: '20px'}}>
                    <TimeInput input={startInput} label={'Start Time'}/>
                    <TimeInput input={endInput} label={'End Time'}/>
                </div>
            </OperatorAdminSettingsForm>
        </Dialog>
    )
}
