import {TableHook} from "../../hooks/table";
import {Button} from "../button/Button";
import arrowleft from '../../icons/arrow-left.svg'
import arrowRight from '../../icons/arrow-right.svg'

export interface TablePaginationProps {
  tableHook: TableHook<any>
  children?: React.ReactNode
  hideIcons?: boolean,
  hideLabels?: boolean
}
export function TablePagination(props: TablePaginationProps) {
  const tableHook = props.tableHook
  const totalPages = tableHook.totalPages
  const currentPage = tableHook.currentPage
  const isLoading = tableHook.currentData.isLoading
  const showLabels = !props.hideLabels
  const showIcons = !props.hideIcons
  const getPreviousPage = () => {
    tableHook.setPage(currentPage - 1)
  }
  const getNextPage = () => {
    tableHook.setPage(currentPage + 1)
  }
  return (
    <div className="d-flex justify-content-between">
      <Button disabled={isLoading || currentPage  === 1 || totalPages === 0} size="normal" colorScheme="blank" shape='semi-rounded' onClick={getPreviousPage}>
        {showIcons && <span style={{margin: '8px', display: 'inline-block'}}>
          <img src={arrowleft} />
        </span>}
        {showLabels && <span className="small-text">Previous</span>}
      </Button>
      {props.children}
      <Button disabled={isLoading || currentPage === totalPages || totalPages === 0} size="normal" colorScheme="blank" shape='semi-rounded' onClick={getNextPage}>
        {showLabels && <span className='small-text'>Next</span>}
        {showIcons && <span style={{margin: '8px', display: 'inline-block'}}>
          <img src={arrowRight} />
        </span>}
      </Button>
    </div>
  )
}
