import * as React from "react"

export function ArrowLeft() {
  return (
    <svg width={6} height={11} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m5.499.168.335.344a.58.58 0 0 1 .162.406.58.58 0 0 1-.162.406L2.038 5.248l3.8 3.928A.58.58 0 0 1 6 9.582a.58.58 0 0 1-.162.406l-.333.344a.543.543 0 0 1-.786 0L.179 5.655A.596.596 0 0 1 0 5.248v-.002c0-.154.074-.298.178-.406L4.707.168a.552.552 0 0 1 .792 0Z"
        fill="#ABABAB"
      />
    </svg>
  )
}
