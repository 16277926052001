import styles from './drivers-planning.module.css'
import classNames from "classnames";
import {Table} from "../../components/table/table";
import {RowsFetcher, useTable} from "../../hooks/table";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useApiClientContext} from "../../hooks/api-client-context";
import {DriverFilters, OperatorAdminC2ApiClient, RoundModel, SiteModel} from "../../client/c2-api-client";
import moment, {Moment} from "moment";
import {TablePageContainer} from "../../components/table-page-container/table-page-container";
import {TablePageHeader} from "../../components/table-page-header/table-page-header";
import {TableFilterBar} from "../../components/table-filter-bar/table-filter-bar";
import {ArrowDownIcon} from "../../icons/ArrowDown";
import {DatePicker} from "@mui/lab";
import {Popover} from "@mui/material";
import {AddShiftModal} from "../../components/add-shift-modal/add-shift-modal";
import {Info} from "../../icons/Info";
import {AddDatesIntervalModal} from "../../components/add-dates-interval-modal/add-dates-interval-modal";
import {DateSummary} from "./availability-summary";
import {DeleteLeaveModal} from '../../components/edit-leave-modal/delete-leave-modal';
import {DriversTableFilterBar} from "../../components/drivers-table-filter-bar/drivers-table-filter-bar";
import {
    defaultFiltersConfig,
    singleKeyFiltersUpdater
} from "../../components/drivers-table-filter-bar/default-drivers-filters-config";
import {EditShiftModal} from "../../components/edit-shift-modal/edit-shift-modal";
import {SlotStatus, statusBackgrounds} from '../../services/status-backgrounds';
import {
    AssignRoundDatesHandler,
    AssignRoundDatesModal
} from "../../components/assign-round-dates-modal/assign-round-dates-modal";
import {DriversTableTypeSwitch} from '../../components/driver-table-type-switch/driver-table-type-switch';
import {ColGroups} from './driver-planning-table-components';
import {driversTableConfig, driversWithSlotsFetcher} from "./driver-planning-table-configuration";
import {DriverWithRoundsAndSlots, statusTooltip} from "./driver-planning-table-types";
import {Button} from "../../components/button/Button";
import {TableDatesLengthSwitch} from "../../components/table-dates-length-switch/table-dates-length-switch";
import {GroupModel} from "../settings-group-list/settings-group-list";
import {driversTableService} from "../../services/drivers-table-service";
import {useSearchContext} from "../../components/navigation-shell/navigation-shell";
import {DropdownFilterEntry} from "../../components/dropdown-filter-entry/dropdown-filter-entry";
import {FilterMultiSelectModal} from "../../components/filter-multiselect-modal/fitler-multiselect-modal";


export function DriversPlanning() {
    const apiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
    const today = useMemo(() => moment(), [])
    const infoRef = useRef<HTMLDivElement>(null)
    const [infoOpen, setInfoOpen] = useState(false)
    const [startDate, setStartDate] = useState(moment().startOf('week'))
    const [sites, setSites] = useState<SiteModel[]>([])
    const [groups, setGroups] = useState<GroupModel[]>([])
    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [addShiftData, setAddShiftData] = useState<{
        open: boolean,
        driver?: DriverWithRoundsAndSlots,
        date?: Moment
    }>({open: false})
    const [editShiftData, setEditShiftData] = useState<{
        open: boolean,
        shiftId?: string,
        startTime?: string,
        endTime?: string
    }>({open: false})
    const [addDateIntervalData, setAddDateIntervalData] = useState<{
        state: 'closed' | 'sick' | 'holiday' | 'training' | 'induction' | 'unavailable',
        driver?: DriverWithRoundsAndSlots
    }>({state: 'closed'})
    const [assignRoundForDatesData, setAssignRoundForDatesData] = useState<{
        open: boolean,
        driver?: DriverWithRoundsAndSlots
    }>({open: false})
    const [editLeaveData, setEditLeaveData] = useState<{
        state: 'closed' | 'sick' | 'holiday' | 'training' | 'induction' | 'unavailable',
        driverId?: string,
        date?: string
    }>({state: 'closed'})
    const [operatorRounds, setOperatorRounds] = useState<RoundModel[]>([])
    const [summaries, setSummaries] = useState<DateSummary[]>([])
    const [tableType, setTableType] = useState<'Shifts' | 'Rounds'>('Shifts')
    const [daysLength, setDaysLength] = useState(14)
    const firstDateHeaderRef = useRef<HTMLTableHeaderCellElement>(null)
    const days = new Array(daysLength).fill(1).map((el, i) => moment(startDate).add(i, 'days'))

    const changeDaysLength = (newLength: number) => {
        setStartDate(moment())
        setDaysLength(newLength)
    }

    const driversFetcher = useMemo<RowsFetcher<DriverWithRoundsAndSlots, DriverFilters>>(() => {
        return driversWithSlotsFetcher({
            apiClient,
            today,
            days,
            operatorRounds,
            setSummaries
        })
    }, [apiClient, today, days[0].toDate().getTime(), days[days.length - 1].toDate().getTime(), operatorRounds.length])
    const driversTableHook = useTable({
        pageSize: 1000,
        rowsFetcher: driversFetcher,
        defaultSort: 'firstName',
        defaultSortAscending: true
    })
    const searchContext = useSearchContext()
    useEffect(() => {
        const listenerId = searchContext.attachListener(driversTableService.nameSearchFilterListener(driversTableHook))
        return () => searchContext.removeListener(listenerId)
    }, [])

    const addHandler = useCallback((row: DriverWithRoundsAndSlots, date: Moment) => {
        console.log('add shift modal', row.id, date.format('YYYY-MM-DD'))
        setAddShiftData({
            open: true,
            driver: row,
            date: date
        })
    }, [])
    const planningFiltersConfig = useMemo(() => defaultFiltersConfig(groups, sites)
            .filter(conf => ['site', 'groupIds'].includes(conf.id))
            .concat([{
                id: 'assigned',
                defaultValue: (currentFilters) => currentFilters.assigned === undefined,
                filterComponent: (val, setVal) => <DropdownFilterEntry<boolean> value={val.assigned}
                                                                                filter={'Assigned'}
                                                                                options={[{id: undefined, name: 'All'}, {id: false, name: 'Not Assigned'}]}
                                                                                onChange={singleKeyFiltersUpdater(setVal, 'assigned')} />
            }, {
                id: 'roundIds',
                defaultValue: (currentFilters) => currentFilters.roundIds === undefined,
                filterComponent: (val, setVal) => <FilterMultiSelectModal label='Rounds' includeNA={true} currentSelection={val.roundIds}
                                                                     setFilter={singleKeyFiltersUpdater(setVal, 'roundIds')} availableSelections={operatorRounds}/>
            }])
        , [sites, groups, operatorRounds])


    useEffect(() => {
        Promise.all([apiClient.getOperatorSites(), apiClient.getOperatorRounds(), apiClient.getOperatorGroups()])
            .then(([sitesResponse, roundsResponse, groupsResponse]) => {
                setSites(sitesResponse.sites)
                setOperatorRounds(roundsResponse.rounds)
                setGroups(groupsResponse.groups)
            })
    }, [])

    const editHandler: (driverId: string, type: 'sick' | 'holiday' | 'training' | 'induction' | 'unavailable', date: string) => void = (driverId, type, date) => {
        setEditLeaveData({
            state: type,
            driverId,
            date
        })
    };
    const shiftHandler = (shiftId: string, startTime: string, endTime: string) => {
        setEditShiftData({open: true, shiftId, startTime, endTime})
    }
    const downloadRoundsCsv = () => {
        const {currentPage: page, pageSize, currentFilters: filters, currentSort: order} = driversTableHook
        const startDate = days[0].format('YYYY-MM-DD')
        const endDate = days[days.length - 1].format('YYYY-MM-DD');
        apiClient.exportDriverRounds({
            page,
            pageSize,
            order,
            startDate,
            endDate,
            filters
        })
    }

    const tableConfig = useMemo(() => driversTableConfig({
        table: driversTableHook,
        today,
        dates: days,
        addHandler,
        addDaySlot: (row, type) => setAddDateIntervalData({state: type === 'exportHolidaySick' ? 'closed' : type, driver: row}),
        firstHeaderRef: firstDateHeaderRef,
        operatorRounds,
        summaries,
        editHandler: editHandler,
        shiftHandler,
        assignRoundHandler: (driver) => setAssignRoundForDatesData({open: true, driver}),
        tableType,
        rounds: operatorRounds
    }), [driversTableHook, summaries, operatorRounds, tableType, days.length])

    const handleModal = (param?: { start: string, end: string }) => {
        console.log('closed modal, res', param, addShiftData.driver?.id)
        if (param) {
            apiClient.addShift({
                driverId: addShiftData.driver?.id!,
                date: addShiftData.date?.format('YYYY-MM-DD')!,
                startTime: param.start,
                endTime: param.end
            }).then(() => {
                setAddShiftData((data) => ({...data, open: false}))
                driversTableHook.reload()
            })
        } else {
            setAddShiftData((data) => ({...data, open: false}))
        }
    }

    const cancelShift = () => {
        return apiClient.cancelShift(editShiftData.shiftId!).then(() => {
            setEditShiftData({open: false})
            driversTableHook.reload()
        })
    }

    const editShiftTimes = (params?: { start: string, end: string }) => {
        if (!params) {
            setEditShiftData({open: false})
            return Promise.resolve()
        } else {
            return apiClient.setShiftTimes(editShiftData.shiftId!, params.start, params.end).then(() => {
                setEditShiftData({open: false})
                driversTableHook.reload()
            })
        }
    }

    const handleDateIntervalModal = (params: any) => {
        if (!params) {
            setAddDateIntervalData({state: 'closed'})
            return
        }
        const apiParams = {driverId: addDateIntervalData.driver!.id, startDate: params.start, endDate: params.end};
        const state = addDateIntervalData.state
        if (state === 'closed') return
        const stateToAction: Record<'sick' | 'holiday' | 'induction' | 'training' | 'unavailable', () => Promise<void>> = {
            'sick': () => apiClient.addSickLeave(apiParams),
            'holiday': () => apiClient.addHoliday(apiParams),
            'training': () => apiClient.addTraining(apiParams),
            'induction': () => apiClient.addInduction(apiParams),
            'unavailable': () => apiClient.addUnavailable(apiParams)
        }
        const res = stateToAction[state]()
        res.then(() => {
            setAddDateIntervalData({state: 'closed'})
            driversTableHook.reload()
        })


    }
    const handleAssignDriverRoundForDates: AssignRoundDatesHandler = (params) => {
        if (!params) {
            setAssignRoundForDatesData({open: false})
            return
        }
        const apiParams = {
            driverId: assignRoundForDatesData.driver!.id,
            startDate: params.start,
            endDate: params.end,
            roundId: params.roundId
        };
        const modalOpen = assignRoundForDatesData.open
        if (!modalOpen) return

        apiClient.assignRoundForDates(apiParams).then(() => {
            setAssignRoundForDatesData({open: false})
            driversTableHook.reload()
        })


    }

    const handleDeleteLeaveModal = (params?: { span: 'single' | 'block' }) => {
        if (!params) {
            setEditLeaveData(data => ({...data, state: 'closed'}))
            return;
        }
        if (editLeaveData.state === 'closed') return;

        const apiParams = {
            date: editLeaveData.date!,
            driverId: editLeaveData.driverId!,
            block: params.span === 'block'
        }
        const stateToAction: Record<'sick' | 'holiday' | 'induction' | 'training' | 'unavailable', () => Promise<void>> = {
            'sick': () => apiClient.deleteSickLeave(apiParams),
            'holiday': () => apiClient.deleteHolidayLeave(apiParams),
            'training': () => apiClient.deleteTrainingLeave(apiParams),
            'induction': () => apiClient.deleteInductionLeave(apiParams),
            'unavailable': () => apiClient.deleteUnavailable(apiParams),
        }
        const p = stateToAction[editLeaveData.state]()

        p.then(() => {
            setEditLeaveData(data => ({...data, state: 'closed'}))
            driversTableHook.reload()
        })
    }


    return (
        <TablePageContainer>
            <AddShiftModal startTime={addShiftData.driver?.earliestStart ?? ''} handle={handleModal}
                           open={addShiftData.open}/>
            <EditShiftModal startTime={editShiftData.startTime} endTime={editShiftData.endTime}
                            cancelShift={cancelShift}
                            changeTimes={editShiftTimes}
                            open={editShiftData.open}/>
            <AddDatesIntervalModal startDate={today.format('YYYY-MM-DD')} handle={handleDateIntervalModal}
                                   action={addDateIntervalData.state === 'closed' ? 'sick' : addDateIntervalData.state}
                                   open={addDateIntervalData.state !== 'closed'}/>
            <DeleteLeaveModal date={editLeaveData.date!} handle={handleDeleteLeaveModal}
                              type={editLeaveData.state === 'closed' ? 'sick' : editLeaveData.state}
                              open={editLeaveData.state !== 'closed'}/>
            <AssignRoundDatesModal startDate={today.format('YYYY-MM-DD')} handle={handleAssignDriverRoundForDates}
                                   open={assignRoundForDatesData.open}/>
            <TablePageHeader>
                <div className={classNames('d-flex justify-content-start align-items-center', styles.headerBar)}>

                    <DatePicker<Moment>
                        open={datePickerOpen}
                        onClose={() => {
                            setDatePickerOpen(false)
                        }}
                        value={startDate}
                        onChange={(v) => v ? setStartDate(v) : null}

                        renderInput={(props) => {
                            return (
                                <TableFilterBar>
                                    <div ref={props.inputRef} className='d-flex align-items-center'
                                         style={{cursor: 'pointer', gap: '20px'}}
                                         onClick={() => setDatePickerOpen(true)}>
                                        <span className='text-300'>Date: {startDate.format('DD.MM.YYYY')}</span>
                                        <ArrowDownIcon/>
                                    </div>
                                </TableFilterBar>

                            )
                        }}
                    />

                    <DriversTableFilterBar table={driversTableHook} filtersConfig={planningFiltersConfig}/>
                    <DriversTableTypeSwitch type={tableType} changeType={setTableType}/>
                    <TableDatesLengthSwitch currentLength={daysLength} setLength={changeDaysLength}/>
                    <div ref={infoRef} onClick={() => setInfoOpen(true)} style={{cursor: 'pointer'}}><Info/></div>
                    <Popover open={infoOpen} anchorEl={infoRef.current as unknown as Element}
                             onClose={() => setInfoOpen(false)}
                             disableScrollLock={true}
                             anchorOrigin={{
                                 vertical: 'bottom',
                                 horizontal: 'left',
                             }}
                             transformOrigin={{
                                 vertical: 'top',
                                 horizontal: 'left',
                             }}>
                        <div className={styles.editActionsContainer}>
                            {
                                (['not_confirmed', 'confirmed', 'started', 'ended', 'holiday', 'sick', 'training', 'induction', 'unavailable', 'available'] as (SlotStatus)[])
                                    .map((status) => (
                                            <div className='d-flex justify-content-between align-items-center'
                                                 style={{gap: '10px'}} key={status}>
                                                <span>{statusTooltip[status]}</span>
                                                <div style={{
                                                    width: '10px',
                                                    height: '10px',
                                                    border: '1px solid black',
                                                    backgroundColor: statusBackgrounds[status]
                                                }}></div>
                                            </div>
                                        )
                                    )
                            }
                        </div>
                    </Popover>
                    {tableType === 'Rounds' ? <Button onClick={downloadRoundsCsv}>Download Rounds CSV</Button> : null}
                </div>
            </TablePageHeader>
            <div className={styles.tableContainer}>
                <Table columnsConfig={tableConfig} colGroups={ColGroups(tableConfig, daysLength)}
                       tableHook={driversTableHook}/>
            </div>
        </TablePageContainer>
    )
}
