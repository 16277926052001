import * as React from "react"

export function DatePickerLogo() {
  return <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5.5v1h9v-1a.5.5 0 0 1 1 0v1H17a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-12a3 3 0 0 1 3-3h1.5v-1a.5.5 0 0 1 1 0Zm9 3a.5.5 0 0 0 1 0v-1H17a2 2 0 0 1 2 2V8H1V4.5a2 2 0 0 1 2-2h1.5v1a.5.5 0 0 0 1 0v-1h9v1ZM1 16.5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9H1v7.5Z"
      fill="#ABABAB"
    />
  </svg>
}
