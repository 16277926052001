import {useApiClientContext} from "../../hooks/api-client-context";
import {OperatorAdminC2ApiClient, Shift, ShiftComment} from "../../client/c2-api-client";
import {useCallback, useEffect, useRef, useState} from "react";
import moment, {Duration} from "moment";
import {shiftService} from "../../services/shift/shift-service";
import {Button} from "../../components/button/Button";
import {CloseIcon} from "../../icons/close";
import {timeService} from "../../services/time-service";
import {ShiftDetails} from "../../components/shift-details/shift-details";
import {AddOperatorTimeModal} from "./add-shift-operator-time-modal";

export type ShiftPanelProps = {
  driver: { name: string, site?: string },
  shiftId: string,
  fetchComments: () => Promise<ShiftComment[]>,
  fetchUsername: (id: string) => Promise<{ id: string, name: string }>,
  approveShift: (id: string) => Promise<void>,
  addComment: (txt: string) => Promise<void>,
  addReceipt: (receipt: { description: string, cost: number, file: File }) => Promise<void>,
  setOvernight: (val: boolean) => Promise<void>,
  setOperatorTime: (time: string) => Promise<void>
  onClose: () => void
}

export function TimeSheetsShiftPanel({
                                       driver,
                                       shiftId,
                                       fetchComments,
                                       fetchUsername,
  approveShift,
                                       addComment,
                                       addReceipt,
                                       setOvernight,
                                       setOperatorTime,
                                       onClose
                                     }: ShiftPanelProps) {
  const apiClient = useApiClientContext().asOrFail(OperatorAdminC2ApiClient)
  const [shift, setShift] = useState<Shift>()
  const containerRef = useRef<HTMLDivElement>(null)
  const [changeTimestamp, setChangeTimestamp] = useState(new Date().getMilliseconds())
  const [absolutePositionData, setAbsolutePositionData] = useState<{
    top: number,
    height: string,
    left: number,
    width: number
  }>()
  const [dialogData, setDialogData] = useState<{ open: boolean, driverTime: Duration | null }>({
    open: false,
    driverTime: null
  })
  const fetchShift = useCallback(() => {
    apiClient.getShiftDetails(shiftId)
      .then(sh => setShift(sh))
  }, [apiClient, setShift, shiftId])
  useEffect(() => {
    fetchShift()
  }, [shiftId])
  const setAbsoluteData = useCallback(() => {
    if (containerRef.current) {
      const {top: boundingTop, left, width} = containerRef.current.getBoundingClientRect()
      const top = window.pageYOffset + boundingTop
      const height = (document.documentElement.clientHeight - window.pageYOffset) - boundingTop
      setAbsolutePositionData({
        top,
        height: `${height}px`,
        left,
        width
      })
    }
  }, [])
  useEffect(() => {
      const listener = () => {
        setAbsoluteData()
      }
      window.addEventListener('resize', listener)
      setAbsoluteData()
      return () => window.removeEventListener('resize', listener)
    }, [containerRef.current ?? '']
  )

  const openOperatorTimeDialog = () => {
    setDialogData({
      open: true,
      driverTime: shift?.operatorTime ? moment.duration(shift?.operatorTime) : shiftService.driverDuration(shift)
    })
  }

  const handleOperatorTimeDialog = (s?: { start: string }) => {
    if (s) {
      setOperatorTime(s.start)
        .then(() => fetchShift())
        .then(() => setChangeTimestamp(new Date().getMilliseconds()))
    }
    setDialogData({open: false, driverTime: null})
  }

  const fetchReceipts = useCallback(() => apiClient.getShiftReceipts(shift!.id), [shift?.id ?? ''])
  const fetchShiftActivity = useCallback((shiftId: string) => apiClient.getShiftActivity(shiftId), [changeTimestamp])
  const validateReceipt = (shiftId: string, receiptId: string, approved: boolean) => apiClient.validateShiftReceipt(shiftId, receiptId, {approved})

  return (
    <>
      <AddOperatorTimeModal driverTime={dialogData.driverTime} handle={handleOperatorTimeDialog}
                            open={dialogData.open}/>
      <div ref={containerRef}
           style={{width: '20%', flexShrink: 0, borderLeft: '1px solid #E3F1EE'}}>
        {containerRef.current && <div className='d-flex flex-column' style={{
          position: 'fixed',
          ...(absolutePositionData ? absolutePositionData : {})
        }}>
            <div className='d-flex justify-content-end' style={{padding: '10px 40px'}}>
                <Button withoutPadding colorScheme='blank' shape='semi-rounded' size='small' onClick={onClose}>
                    <CloseIcon/>
                </Button>
            </div>
            <div className='d-flex justify-content-between' style={{padding: '10px 40px'}}>
                <div>
                    <div style={{color: 'rgba(0, 133, 83, 1)'}}>Driver</div>
                    <div style={{color: 'rgba(0, 0, 0, 1)'}}>{driver.name}</div>
                </div>
                <div>
                    <div style={{color: 'rgba(0, 133, 83, 1)'}}>Site</div>
                    <div style={{color: 'rgba(0, 0, 0, 1)'}}>{driver.site ?? '-'}</div>
                </div>
            </div>
            <div className='d-flex justify-content-between' style={{padding: '10px 40px'}}>
                <div style={{color: 'rgba(0, 0, 0, 1)'}}>Driver Time</div>
                <div
                    style={{color: 'rgba(0, 0, 0, 1)'}}>{shift && timeService.formatDuration(shiftService.driverDuration(shift))}</div>
            </div>
          {shift?.tachoData?.totalShiftTime &&
          (<div className='d-flex justify-content-between' style={{padding: '10px 40px'}}>
            <div style={{color: 'rgba(0, 0, 0, 1)'}}>Tacho Time</div>
            <div
              style={{color: 'rgba(0, 0, 0, 1)'}}>{timeService.formatDuration(moment.duration(shift.tachoData.totalShiftTime))}</div>
          </div>)}
            <div className='d-flex justify-content-between' style={{padding: '10px 40px'}}>
                <div style={{color: 'rgba(0, 0, 0, 1)'}}>Operator Time</div>
                <div
                    onClick={() => openOperatorTimeDialog()}
                    className='d-flex justify-content-center' style={{
                  cursor: 'pointer',
                  minWidth: '100px',
                  color: 'rgba(0, 0, 0, 1)',
                  borderRadius: '6px',
                  border: '1px solid #E3F1EE',
                  background: 'rgba(227, 237, 235, 0.14)'
                }}>{shift?.operatorTime ?? ':'}</div>
            </div>
            <div className='d-flex justify-content-between' style={{padding: '10px 40px'}}>
                <div style={{color: 'rgba(0, 0, 0, 1)'}}>Break</div>
                <div style={{color: 'rgba(0, 0, 0, 1)'}}>
                  {shift?.breakTime ?? '-'}
                </div>
            </div>
          {!shift?.invoiceId && (shift?.operatorTime || shift?.tachoData?.totalShiftTime) &&
          <div className='d-flex justify-content-around' style={{padding: '10px 40px'}}>
              <Button size={'small'} colorScheme='positive' onClick={() => approveShift(shift.id).then(() => fetchShift()).then(() => setChangeTimestamp(new Date().getMilliseconds()))}>Approve Timesheet</Button>
          </div>}
          {shift && <ShiftDetails shift={shift} fetchComments={fetchComments}
                                  usernameFetcher={fetchUsername}
                                  addComment={addComment}
                                  fetchReceipts={fetchReceipts}
                                  addReceipt={addReceipt}
                                  downloadReceipt={(id) => apiClient.downloadReceipt(id).then(d => window.open(d.downloadUrl, '_blank'))}
                                  setOvernight={(v) => setOvernight(v).then(() => fetchShift())}
                                  fetchActivity={fetchShiftActivity}
                                  validateReceipt={validateReceipt}
          />}
        </div>}

      </div>
    </>
  )
}
