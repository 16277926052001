import React from "react";
import {Header} from "../header-bar/Header";
import './HeaderPageWrapper.css'
import {useSessionContext} from "../../hooks/session-context";
import {NavigationShell} from "../navigation-shell/navigation-shell";
import { DriverShell } from "../driver-shell/driver-shell";

export interface HeaderPageWrapperProps {
  children: React.ReactNode;
}

export function HeaderPageWrapper(props: HeaderPageWrapperProps) {
  const role = useSessionContext().session?.role
  return (
    <div className="section-container">
      {(role === 'SUPER_ADMIN' || role === undefined) && <><Header></Header>{props.children}</>}
      {role === 'OPERATOR_ADMIN' && <NavigationShell>{props.children}</NavigationShell>}
      {role === 'DRIVER' && <DriverShell>{props.children}</DriverShell>}
    </div>
  );
}
